import { LogFactory } from './cb-debug';
import { CbAuthURLService } from './cb-auth-url.service';
import { stage } from './cb-envars';
import * as i0 from "@angular/core";
import * as i1 from "./cb-auth-url.service";
var log = LogFactory('CbBannerService', false);
// @todo: replace it later
// let stage = STAGE || 'development';
// if (stage === 'dev') {
//   stage = 'development';
// }
var BUCKET_NAME = "lf-login-platform-assets-" + stage;
var BUCKET_URL = "https://" + BUCKET_NAME + ".s3.amazonaws.com";
var FOLDER = {
    JOINNOW: 'joinnow',
    CVENT: 'cvent',
    LF: 'linux',
    PROJECT: 'project',
};
var POSSIBLE_EXTENSIONS = ['.png', '.svg', '.jpg'];
var DEAULT_IMAGE_NAME = 'login/default.png';
var DEFAULT_BANNER = "https://lf-login-platform-assets-production.s3.amazonaws.com/login/default.png";
var CbBannerService = /** @class */ (function () {
    function CbBannerService(cbAuthURLService) {
        this.cbAuthURLService = cbAuthURLService;
    }
    CbBannerService.prototype.getEventNameFromURL = function () {
        var _a = this.cbAuthURLService.getAllURLParams(), event_name = _a.event_name, eventName = _a.eventName;
        var name = event_name || eventName;
        log('getEventNameFromURL  name ?', { name: name });
        return name;
    };
    CbBannerService.prototype.getEventNameFromReturnTo = function () {
        var _a = this.cbAuthURLService.getParamsFromReturnTo(), event_name = _a.event_name, eventName = _a.eventName;
        var name = event_name || eventName;
        log('getEventNameFromReturnTo  name ?', { name: name });
        return name;
    };
    CbBannerService.prototype.getImageFileFromName = function (name) {
        if (name === void 0) { name = ''; }
        if (!name) {
            return '';
        }
        var imageFile = '';
        imageFile = decodeURIComponent(name);
        imageFile = imageFile.toLowerCase();
        imageFile = imageFile.replace(/ /gi, '-');
        imageFile = imageFile.replace(/[0-9]/gi, '');
        return imageFile;
    };
    CbBannerService.prototype.getImageFileWithNumbersFromName = function (name) {
        if (name === void 0) { name = ''; }
        if (!name) {
            return '';
        }
        var imageFile = decodeURIComponent(name).toLowerCase().replace(/ /gi, '-');
        return imageFile;
    };
    CbBannerService.prototype.GetImagesWithExtensions = function (folder, name) {
        if (!folder || !name) {
            return [];
        }
        var baseURL = BUCKET_URL + "/" + folder + "/" + name;
        var images = POSSIBLE_EXTENSIONS.map(function (extension) {
            return "" + baseURL + extension;
        });
        return images;
    };
    CbBannerService.prototype.JoinGroupOfImages = function (groupA, groupB) {
        if (groupA === void 0) { groupA = []; }
        if (groupB === void 0) { groupB = []; }
        var collection = groupA.slice();
        groupB.forEach(function (image) {
            if (!collection.includes(image)) {
                collection.push(image);
            }
        });
        return collection;
    };
    CbBannerService.prototype.GetPossibleImages = function (folderName, name) {
        if (folderName === void 0) { folderName = ''; }
        if (name === void 0) { name = ''; }
        var nameOne = this.getImageFileFromName(name);
        var nameTwo = this.getImageFileWithNumbersFromName(name);
        var images = this.GetImagesWithExtensions(folderName, nameOne);
        if (nameOne === nameTwo) {
            return images;
        }
        var moreImages = this.GetImagesWithExtensions(folderName, nameTwo);
        images = this.JoinGroupOfImages(images, moreImages);
        return images;
    };
    CbBannerService.prototype.GetCventImageURL = function (bannerName) {
        if (bannerName === void 0) { bannerName = ''; }
        log('entered GetCventImageURL', { bannerName: bannerName });
        var eventName = bannerName || this.getEventNameFromURL() || this.getEventNameFromReturnTo();
        if (!eventName) {
            return null;
        }
        var folder = FOLDER.CVENT;
        var name = eventName;
        if (name.includes(',')) {
            name = name.split(',')[0];
        }
        var images = this.GetPossibleImages(folder, name);
        return images;
    };
    CbBannerService.prototype.preloadImage = function (URL) {
        var img = new Image();
        img.src = URL;
    };
    CbBannerService.prototype.GetJoinnowBanners = function () {
        log('entered GetJoinnowBanners');
        var _a = this.cbAuthURLService.getAllURLParams(), app = _a.app, project = _a.project;
        if (app !== 'joinnow' || !project) {
            return null;
        }
        var folder = FOLDER.JOINNOW;
        var name = project;
        var images = this.GetPossibleImages(folder, name);
        return images;
    };
    CbBannerService.prototype.GetPossibleBanners = function (banners) {
        var _this = this;
        if (banners === void 0) { banners = []; }
        return banners.map(function (URL) {
            _this.preloadImage(URL);
            return {
                URL: URL,
                hide: true,
            };
        });
    };
    CbBannerService.prototype.GetProjectBanner = function () {
        log('entered GetProjectBanner');
        var _a = this.cbAuthURLService.getAllURLParams(), app = _a.app, project = _a.project;
        if (app !== 'registration' || !project) {
            return null;
        }
        var folder = FOLDER.PROJECT;
        var name = project;
        var images = this.GetPossibleImages(folder, name);
        return images;
    };
    CbBannerService.prototype.GetBanners = function () {
        log('entered GetBanner');
        var banners = [];
        var cventBanners = this.GetCventImageURL();
        if (cventBanners) {
            banners = cventBanners;
        }
        var joinnowBanenrs = this.GetJoinnowBanners();
        if (joinnowBanenrs) {
            banners = joinnowBanenrs;
        }
        var projectBanners = this.GetProjectBanner();
        if (projectBanners) {
            banners = projectBanners;
        }
        banners = this.GetPossibleBanners(banners);
        log('possible banners', { banners: banners });
        return banners;
    };
    CbBannerService.prototype.GetDefaultbanner = function () {
        return DEFAULT_BANNER;
    };
    CbBannerService.prototype.IsDefaultBanner = function (banner) {
        if (banner === void 0) { banner = ''; }
        return banner && banner.includes(DEAULT_IMAGE_NAME);
    };
    CbBannerService.prototype.isSmaller = function (banner) {
        var imageURL = (banner && banner.URL) || '';
        var smallBanners = [FOLDER.JOINNOW, FOLDER.PROJECT + "/"];
        return smallBanners.some(function (folder) { return imageURL.includes(folder); });
    };
    CbBannerService.prototype.GetAppClassName = function () {
        var app = this.cbAuthURLService.getAllURLParams().app;
        if (!app) {
            return '';
        }
        return "app-" + app;
    };
    CbBannerService.ngInjectableDef = i0.defineInjectable({ factory: function CbBannerService_Factory() { return new CbBannerService(i0.inject(i1.CbAuthURLService)); }, token: CbBannerService, providedIn: "root" });
    return CbBannerService;
}());
export { CbBannerService };
