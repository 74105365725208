import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './containers/home/home.component';
import { CbLoginCustomConfig } from './lib/services/cb-login-custom.service';
import { CbLoginModule } from './lib/login-controller.module';
import {
  centralizedLogin,
  auth0Domain,
  auth0ClientID,
  apiURL,
  ssoStaticApp,
  recapcha,
  cdn,
} from './lib/services/cb-envars';

export const myCustomConfig: CbLoginCustomConfig = {
  debug: false,
  cbAuthCentralizedLogin: centralizedLogin,
  domain: auth0Domain,
  clientId: auth0ClientID,
  cbBackend: apiURL,
  cbSsoStaticApp: ssoStaticApp,
  cbRecaptchaSiteKey: recapcha,
  cbLogoLink: '#',
  cbCDN: cdn,
  cbBackgroundImage: cdn + '/assets/cb-assets/cb-login-background.png',
  cbBorderImage: cdn + '/assets/cb-assets/cb-login-background-border.png',
  cbDefaultLogo: cdn + '/assets/cb-assets/cb-logo-white.svg',
  cbDefaultLogoNoSidebar: cdn + '/assets/cb-assets/lf-logo-blue.png',
};

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [BrowserModule, AppRoutingModule, CbLoginModule, RouterModule],
  providers: [
    {
      provide: CbLoginCustomConfig,
      useValue: myCustomConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
