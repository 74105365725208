import { Component, Input } from '@angular/core';

export type ButtonType = '' | 'button' | 'submit';
export type Style = '' | 'disabled';
export type Size = '' | 'small' | 'medium' | 'large';

@Component({
  selector: 'cb-button',
  templateUrl: './cb-button.component.html',
  styleUrls: ['./cb-button.component.scss'],
})
export class CbButtonComponent {
  isDisabled = false;

  @Input() id = '';
  @Input() type = 'is-info';
  @Input() style = '';
  @Input() isLoading = false;
  @Input() size: Size = '';
  @Input() buttonType: ButtonType = 'button';
  @Input('disabled') set disabled(conditional: boolean) {
    this.isDisabled = conditional !== false;
  }
  get disabled() {
    return this.isDisabled;
  }
}
