<cb-loading-page [hidden]="!isLoading" loadingMessage="Please wait, we are verifying your email"> </cb-loading-page>

<div [hidden]="isLoading">
  <div class="columns is-flex is-centered">
    <div class="column is-two-fifths is-four-fifths-mobile">
      <cb-alert [type]="typeMessage">
        {{ message }}
      </cb-alert>

      <div *ngIf="pageMessage" class="actions cb-title-group">
        <div class="primary-text">{{ pageTitle }}</div>
        <p [innerHTML]="pageMessage | safeHtml"></p>
      </div>
    </div>
  </div>
</div>
