import * as tslib_1 from "tslib";
import { CbLoginCustomConfig } from './cb-login-custom.service';
import * as i0 from "@angular/core";
import * as i1 from "./cb-login-custom.service";
var CbLoginConfig = /** @class */ (function () {
    function CbLoginConfig(cbLoginCustomConfig) {
        this.cbLoginCustomConfig = cbLoginCustomConfig;
        this.domain = '';
        this.clientId = '';
        this.clientName = 'Community Bridge';
        this.cbBackend = 'https://lf-login.dev.platform.linuxfoundation.org/api';
        this.cbSidebarContent = '';
        this.debug = false;
        this.cbDefaultLogo = '/assets/cb-assets/cb-logo-white.svg';
        this.cbDefaultLogoNoSidebar = '/assets/cb-assets/lf-logo-blue.png';
        this.cbLogoLink = '/';
        this.cbBackgroundImage = '/assets/cb-background.png';
        this.cbBorderImage = '/assets/cb-border.png';
        this.cbCDN = '';
        this.cbWarningTime = 5 * 60 * 1000; // default 5min
        this.cbResponseType = 'token id_token';
        this.cbSkipLocalChanges = false;
        this.cbVerifyAccount = false;
        this.cbRecaptchaSiteKey = '';
        // @info: there are 3 ways to use cb-login
        // 1. As Auth0App (cbAuthCentralizedLogin: true)
        // 2. As SSOStaticApp (cbSsoStaticApp: true)
        // 3. As AngularModule (cbAuthCentralizedLogin and cbSsoStaticApp both false)
        this.cbAuthCentralizedLogin = false;
        this.cbSsoStaticApp = false;
        this.cbContentFactory = function () { return ({}); };
        Object.assign(this, tslib_1.__assign({}, this.cbLoginCustomConfig));
    }
    CbLoginConfig.ngInjectableDef = i0.defineInjectable({ factory: function CbLoginConfig_Factory() { return new CbLoginConfig(i0.inject(i1.CbLoginCustomConfig)); }, token: CbLoginConfig, providedIn: "root" });
    return CbLoginConfig;
}());
export { CbLoginConfig };
