import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
var CbErrorService = /** @class */ (function () {
    function CbErrorService() {
    }
    CbErrorService.prototype.setErrorData = function (config) {
        if (!config) {
            this.errorData = {};
            return;
        }
        this.errorData = tslib_1.__assign({}, config);
    };
    CbErrorService.prototype.getErrorInformation = function () {
        var _this = this;
        var ErrorMessageHeader = '\n\n Error Information: \n\n';
        var errorDataKeys = Object.keys(this.errorData);
        var ErrorMessageBody = '';
        if (!errorDataKeys.length) {
            ErrorMessageBody = 'No error information provided.';
        }
        else {
            ErrorMessageBody = errorDataKeys.reduce(function (acc, key) {
                var row = "\n " + key + ": " + _this.errorData[key];
                return acc + row;
            }, '');
        }
        return ErrorMessageHeader + ErrorMessageBody;
    };
    CbErrorService.ngInjectableDef = i0.defineInjectable({ factory: function CbErrorService_Factory() { return new CbErrorService(); }, token: CbErrorService, providedIn: "root" });
    return CbErrorService;
}());
export { CbErrorService };
