import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EllipsisModule } from 'ngx-ellipsis';

import { AuthComponent } from './components/auth/auth.component';
import { CbBottomBannerComponent } from './components/cb-bottom-banner/cb-bottom-banner.component';
import { CbSidebarComponent } from './components/cb-sidebar/cb-sidebar.component';
import { CbTopBannerAppComponent } from './components/cb-top-banner-app/cb-top-banner-app.component';
import { CbTopBannerCventComponent } from './components/cb-top-banner-cvent/cb-top-banner-cvent.component';
import { CbTopBannerRegistrationComponent } from './components/cb-top-banner-registration/cb-top-banner-registration.component';
import { CbTopBannerZoomBannerComponent } from './components/cb-top-banner-zoom-banner/cb-top-banner-zoom-banner.component';
import { CbTopBannerComponent } from './components/cb-top-banner/cb-top-banner.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CreateAccountEmailComponent } from './components/create-account-email/create-account-email.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { CbCventUserTypeComponent } from './components/cvent-user-type/cvent-user-type.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { CbErrorEmailVerificationComponent } from './components/error-email-verification/error-email-verification.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { CbUnauthorizeComponent } from './components/error-unauthorize/error-unauthorize.component';
import { GroupioConfirmInviteComponent } from './components/groupio-confirm-invite/groupio-confirm-invite.component';
import { GroupioSelectEmailSendComponent } from './components/groupio-select-email-send/groupio-select-email-send.component';
import { CbJoinnowCopyComponent } from './components/joinnow-copy/joinnow-copy.component';
import { LFXMeetingsComponent } from './components/lfx-meetings/lfx-meetings.component';
import { LoginComponent } from './components/login/login.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { CbProcessContinueComponent } from './components/process-continue/process-continue.component';
import { ResetPasswordConfirmationComponent } from './components/reset-password/confirmation.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SelectEmailComponent } from './components/select-email/select-email.component';
import { RequestConfirmationComponent } from './components/service-desk/request-confirmation.component';
import { ServiceDeskComponent } from './components/service-desk/service-desk.component';
import { SocialUsernameComponent } from './components/social-username/social-username.component';
import { VerifyAccountComponent } from './components/verify-account/verify-account.component';
import { CbDirectivesModule } from './directives/cb-directives.module';
import { CbHideOnActiveDirective } from './directives/hide-on-active.directive';
import { CbAuthDeactivateGuard } from './services/cb-auth-deactivate.guard';
import { CbAuthPageGuard } from './services/cb-auth-page.guard';
import { CbAuthGuard } from './services/cb-auth.guard';
import { CbAuthService } from './services/cb-auth.service';
import { CbClientParametersService } from './services/cb-client-parameters.service';
import { CbErrorService } from './services/cb-error.service';
import { CbLoginBannerService } from './services/cb-login-banner.service';
import { CbLoginCustomConfig } from './services/cb-login-custom.service';
import { CbLoginConfig } from './services/cb-login.config';
import { CbRecaptchaService } from './services/cb-recpatcha.service';
import { CbSocialLoginService } from './services/cb-social-login.service';
import { CbStateService } from './services/cb-state.service';
import { ServiceDeskService } from './services/service-desk.service';
import { CbPipesModule } from './shared/cb-pipes/cb-pipes.module';
import { CbUIModule } from './shared/cb-ui/cb-ui.module';

// @info: In order to avoid the latency injecting routes
// We must inject directly the routes in the main route tree (app-routing.module.ts)
// LoginControllerRoutingModule,

@NgModule({
  declarations: [
    LoginComponent,
    CreateAccountComponent,
    CreateAccountEmailComponent,
    AuthComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmationComponent,
    ChangePasswordComponent,
    ServiceDeskComponent,
    MainLayoutComponent,
    CbHideOnActiveDirective,
    RequestConfirmationComponent,
    CbSidebarComponent,
    SocialUsernameComponent,
    VerifyAccountComponent,
    ErrorPageComponent,
    SelectEmailComponent,
    GroupioSelectEmailSendComponent,
    EmailVerificationComponent,
    CbCventUserTypeComponent,
    GroupioConfirmInviteComponent,
    CbUnauthorizeComponent,
    CbErrorEmailVerificationComponent,
    CbProcessContinueComponent,
    CbJoinnowCopyComponent,
    CbTopBannerComponent,
    CbTopBannerCventComponent,
    CbTopBannerAppComponent,
    CbTopBannerRegistrationComponent,
    CbBottomBannerComponent,
    CbTopBannerZoomBannerComponent,
    LFXMeetingsComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CbUIModule,
    CbPipesModule,
    CbDirectivesModule,
    EllipsisModule,
  ],
  providers: [
    CbLoginCustomConfig,
    CbLoginConfig,
    CbAuthService,
    ServiceDeskService,
    CbAuthGuard,
    CbAuthDeactivateGuard,
    CbAuthPageGuard,
    CbSocialLoginService,
    CbRecaptchaService,
    CbErrorService,
    CbClientParametersService,
    CbStateService,
    CbLoginBannerService,
  ],
  exports: [CbHideOnActiveDirective, MainLayoutComponent],
})
export class CbLoginModule {}
