import { Injectable } from '@angular/core';

import { CbAuthURLService } from './cb-auth-url.service';
import { CbBannerService } from './cb-banner.service';
import { LogFactory } from './cb-debug';
import { cdn } from './cb-envars';
import { CLIENTS_METADATA } from './cb-login-banner.metadata';

const log = LogFactory('CbLoginBannerService', false);

export const defaultLogoURL =
  'https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/thelinuxfoundation-color.svg';

@Injectable({ providedIn: 'root' })
export class CbLoginBannerService {
  bannerType = 'default';
  appName = '';
  clientId = '';

  // info: General used by several apps
  project = '';

  // Cvent
  eventName = '';
  eventImages = [];

  // LF Registration
  zoomID = '';
  id = '';
  registrationType = '';
  isRegistration = false;

  isLoading = true;
  displayDefault = false;
  appImages = [];
  appMetadata = null;

  bottomImages = [];

  isAppLogoActive = true;
  isProjectLogoActive = false;
  withAppLogotoLeft = false;

  isMeeting = false;
  meetingName = '';
  meetingDescription = '';
  meetingVisibility = 'public';
  meetingID = '';
  meetingType = '';
  meetingDate = '';
  inviteID = '';
  registrantName = '';
  meetingError = false;

  constructor(private cbAuthURLService: CbAuthURLService, private cbBannerService: CbBannerService) {
    this.initValues();
  }

  getPersistentParamsInURL() {
    log('entered getPersistentParamsInURL');
    const urlParams = this.cbAuthURLService.getAllURLParams();
    const decodedParams = this.cbAuthURLService.getDecodedToken();
    const returnToParams = this.cbAuthURLService.getParamsFromReturnTo();
    const auth0params = this.cbAuthURLService.getAuth0ConfigParams();
    const fragments = this.cbAuthURLService.getValuesFromFragments(urlParams.fragment) as any;

    this.appName =
      urlParams.clientName ||
      auth0params.title ||
      decodedParams.clientName ||
      returnToParams.clientName ||
      urlParams.client_name ||
      decodedParams.client_name ||
      returnToParams.client_name ||
      auth0params.app ||
      urlParams.app ||
      decodedParams.app ||
      returnToParams.app ||
      fragments.clientName ||
      '';

    this.clientId =
      urlParams.clientId ||
      decodedParams.clientId ||
      returnToParams.clientId ||
      urlParams.client_id ||
      decodedParams.client_id ||
      returnToParams.client_id ||
      '';

    if (this.appName && Array.isArray(this.appName)) {
      this.appName = this.appName[0];
    }

    this.setAppMetadata();

    this.zoomID =
      auth0params.zoomID ||
      urlParams.zoomID ||
      returnToParams.zoomID ||
      auth0params.zoom_id ||
      urlParams.zoom_id ||
      returnToParams.zoom_id;

    this.id = auth0params.id || urlParams.id || returnToParams.id;

    if (this.id && Array.isArray(this.id)) {
      this.id = this.id[0];
    }

    this.registrationType =
      auth0params.registrationType ||
      auth0params.registration_type ||
      urlParams.registrationType ||
      urlParams.registration_type ||
      returnToParams.registrationType ||
      returnToParams.registration_type;

    if (this.registrationType && Array.isArray(this.registrationType)) {
      this.registrationType = this.registrationType[0];
    }

    const projectSlugFromMetadata = (this.appMetadata && this.appMetadata.projectSlug) || '';

    this.project = projectSlugFromMetadata || auth0params.project || urlParams.project || returnToParams.project;

    if (this.project && Array.isArray(this.project)) {
      this.project = this.project[0];
    }

    this.eventName =
      auth0params.eventName ||
      urlParams.eventName ||
      returnToParams.eventName ||
      auth0params.event_name ||
      urlParams.event_name ||
      returnToParams.event_name;

    if (this.eventName && Array.isArray(this.eventName)) {
      this.eventName = this.eventName[0];
    }

    this.setMeetingInfo(urlParams, fragments, returnToParams);

    return {
      appName: this.appName,
      eventName: this.eventName,
      project: this.project,
      id: this.id,
      registrationType: this.registrationType,
      zoomID: this.zoomID,
      clientId: this.clientId,
      meetingName: this.meetingName,
      meetingDescription: this.meetingDescription,
      meetingVisibility: this.meetingVisibility,
      meetingID: this.meetingID,
      meetingType: this.meetingType,
      meetingDate: this.meetingDate,
      inviteID: this.inviteID,
      registrantName: this.registrantName,
      meetingError: this.meetingError,
    };
  }

  initValues() {
    log('entered int values');

    this.getPersistentParamsInURL();

    this.isRegistration = this.appName.toLowerCase().includes('lf registration');
    // this.isMeeting = this.appName.toLowerCase().includes('lfx meeting');

    log('data ? ', {
      appName: this.appName,
      project: this.project,
      eventName: this.eventName,
      isRegistration: this.isRegistration,
      id: this.id,
      registrationType: this.registrationType,
      zoomID: this.zoomID,
      clientId: this.clientId,
      meetingName: this.meetingName,
      meetingDescription: this.meetingDescription,
      meetingVisibility: this.meetingVisibility,
      meetingID: this.meetingID,
      meetingType: this.meetingType,
      meetingDate: this.meetingDate,
      inviteID: this.inviteID,
      registrantName: this.registrantName,
      meetingError: this.meetingError,
    });

    // if (this.isMeeting) {
    //   return;
    // }

    if (this.isRegistration) {
      const RegistrationIDFound = this.id && this.registrationType;
      if (RegistrationIDFound) {
        // this.setRegistrationLogos();
        this.setBottomImages();
        return;
      }

      // info: Registration ID is not found
      this.isRegistration = false;
      this.setProjectLogo();
      return;
    }

    if (this.eventName) {
      this.setEventImage();
      return;
    }

    if (this.appName) {
      this.setAppLogoURL();
      return;
    }

    if (this.project) {
      this.setProjectLogo();
      return;
    }

    this.setDefaultBanner();
  }

  setAppMetadata() {
    const medatada = CLIENTS_METADATA[this.appName];

    if (medatada) {
      this.appMetadata = medatada;
    }
  }

  setRegistrationLogos() {}

  setBottomImages() {
    let images = this.getProjectLogoURL();
    const defaultImage = {
      hide: true,
      URL: defaultLogoURL,
    };

    if (images && images.length === 0) {
      images = [defaultImage];
    }

    this.bottomImages = [
      ...images,
      // defaultImage
    ];

    return images;
  }

  setDefaultBanner() {
    log('entered setDefaultBanner');

    this.eventName = '';
    this.appImages = [];
    this.isRegistration = false;
    this.displayDefault = true;
  }

  setEventImage() {
    log('entered setEventImage');

    const eventImages = this.cbBannerService.GetCventImageURL(this.eventName) || [];
    this.eventImages = this.cbBannerService.GetPossibleBanners(eventImages);
    log('cvent images ? ', { images: this.eventImages });

    if (!this.eventImages || !this.eventImages.length) {
      this.setDefaultBanner();
    }
  }

  removeEventImage(index: number) {
    log('entered removeEventImage');

    this.eventImages.splice(index, 1);
    if (!this.eventImages.length) {
      this.setDefaultBanner();
    }
  }

  displayEventImage(banner: any) {
    log('entered displayEventImage', { banner });
    // todo: once we have 1 loaded , hide others
    banner.hide = false;
  }

  getAppLogoURL() {
    log('entered getAppLogoURL');

    const appName = this.appName.toLowerCase();
    log('app name', { appName });

    let imageURL = '';
    let logoCss = '';

    if (appName.includes('jira')) {
      imageURL = cdn + '/assets/applogos/jira.svg';
      this.withAppLogotoLeft = true;
    } else if (appName.includes('confluence')) {
      imageURL = cdn + '/assets/applogos/confluence.svg';
      this.withAppLogotoLeft = true;
    } else if (appName.includes('wiki')) {
      imageURL = cdn + '/assets/applogos/confluence.svg';
      // imageURL = cdn + '/assets/applogos/wiki.svg';
      this.withAppLogotoLeft = true;
    } else if (appName.includes('jenkins')) {
      imageURL = cdn + '/assets/applogos/jenkins.svg';
      this.withAppLogotoLeft = true;
    } else if (appName.includes('nexus3')) {
      imageURL = cdn + '/assets/applogos/nexus3.svg';
      this.withAppLogotoLeft = true;
    } else {
      const appImage = CLIENTS_METADATA[this.appName];
      if (appImage && appImage.logoURL) {
        imageURL = `${cdn}/assets/applogos/${appImage.logoURL}`;
        logoCss = appImage.logoCss || '';
      }
    }

    log(' image ? ', { imageURL });

    if (!imageURL) {
      return null;
    }

    return {
      hide: true,
      URL: imageURL,
      logoCss,
    };
  }

  getProjectLogoURL(): any[] {
    log('entered getProjectLogoURL', { project: this.project });

    if (!this.project) {
      return [];
    }

    const projectName = this.project.toLowerCase();
    const buckets = [
      `https://lf-master-project-logos-prod.s3.amazonaws.com/${projectName}.svg`,
      `https://lf-login-platform-assets-production.s3.amazonaws.com/project/${projectName}.svg`,
    ];

    const images = buckets.map((item) => {
      return {
        hide: false,
        URL: item,
      };
    });

    log('images ? ', { images });

    return images;
  }

  setAppLogoURL() {
    log('entered setAppLogoURL');

    const appImage = this.getAppLogoURL();
    log('appImage ', { appImage });

    if (appImage) {
      this.appImages = [appImage];
      this.setBottomImages();
      return;
    }

    this.isAppLogoActive = false;
    this.isProjectLogoActive = true;

    this.setProjectLogo();
  }

  removeAppImage(index) {
    log('entered removeAppImage', { index });

    this.appImages.splice(index, 1);

    if (!this.appImages.length) {
      this.setDefaultBanner();
      return;
    }
  }

  displayAppImage(image) {
    log('enterd displayAppImage', { image });
    image.hide = false;
  }

  removePossibleImage(index, list) {
    log('entered removePossibleImage', { index });

    list.splice(index, 1);
    if (list.length === 0) {
      list = [
        {
          hide: false,
          URL: defaultLogoURL,
        },
      ];
    }

    // if (!list.length) {
    //   this.setDefaultBanner();
    //   return;
    // }
  }

  displayPossibleImage(image, list: any[]) {
    log('enterd displayPossibleImage', { image });
    // const isDefaultImage = list.findIndex(item => item.URL === image.URL) === list.length;
    const isOneActive = list.find((item) => item.hide === false);
    log('isOneActive ? ', { isOneActive });
    if (!isOneActive) {
      image.hide = false;
      return;
    }

    const isOneActiveDefault = isOneActive && isOneActive.URL === defaultLogoURL;
    log(' isOneActiveDefault ? ', { isOneActiveDefault });

    if (isOneActiveDefault) {
      list.forEach((item) => {
        item.hide = true;
      });
      image.hide = false;
    }
  }

  setProjectLogo() {
    log('enterd setProjectLogo');

    const projectImages = this.getProjectLogoURL();
    log('projectImages ? ', { projectImages });
    this.appImages = projectImages;

    if (!projectImages.length) {
      this.setDefaultBanner();
    }
  }

  setMeetingInfo(urlParams, fragments, returnToParams) {
    if (returnToParams.fragment) {
      fragments = this.cbAuthURLService.getValuesFromFragments(returnToParams.fragment);
    }

    log('meeting values ? ', { urlParams, fragments, returnToParams });
    this.meetingID = urlParams.meetingID || fragments.meetingID || returnToParams.meetingID;

    if (urlParams.topic || fragments.topic || returnToParams.topic) {
      this.meetingName = urlParams.topic || fragments.topic || returnToParams.topic;
      this.meetingDescription = urlParams.agenda || fragments.agenda || returnToParams.agenda;
      this.meetingVisibility = urlParams.visibility || fragments.visibility || returnToParams.visibility;
      this.meetingType = urlParams.recurring || fragments.recurring || returnToParams.recurring;
      this.meetingDate = urlParams.start_time || fragments.start_time || returnToParams.start_time;
      this.inviteID = urlParams.inviteID || fragments.inviteID || returnToParams.inviteID;
      this.registrantName = urlParams.firstName
        ? `${urlParams.firstName} ${urlParams.lastName}`
        : fragments.firstName
        ? `${fragments.firstName} ${fragments.lastName}`
        : `${returnToParams.firstName} ${returnToParams.lastName}`;
    }

    if (urlParams.error || fragments.error || returnToParams.error) {
      this.meetingError = true;
    }
  }
}
