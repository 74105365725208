import { Component, OnInit } from '@angular/core';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';

@Component({
  selector: 'cb-top-banner-app',
  templateUrl: 'cb-top-banner-app.component.html',
  styleUrls: ['./cb-top-banner-app.component.scss'],
})
export class CbTopBannerAppComponent implements OnInit {
  display2Logos = false;
  constructor(public cbLoginBannerService: CbLoginBannerService) {}

  ngOnInit() {
    this.display2Logos = this.cbLoginBannerService.withAppLogotoLeft;
  }

  removeAppImage(index) {
    this.cbLoginBannerService.removeAppImage(index);
  }

  displayAppImage(image: any) {
    image.hide = false;
    this.cbLoginBannerService.appImages = [image];
    // this.cbLoginBannerService.displayAppImage(image);
  }
}
