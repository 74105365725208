export enum CreateAccountUiErrorMessages {
  EMAIL = 'A valid email is required',
  USERNAME = 'Username must be between 8 to 512 characters, can only be alphanumeric' +
    'and cannot start with a number',
  PASSWORD = 'Password must be at least 8 characters long, alphanumeric ' +
    'must contain at least 1 Upper case letter and at least 1 symbol',
  FIRST_NAME = 'First name is required and must be at least 2 characters long',
  LAST_NAME = 'Last name is required and must be at least 2 characters long',
  IS_AGREEMENT_ACCEPTED = 'You need to accept our Term of Use and Privacy Policy in order to proceed.',
  GENERIC = 'An error occurred while trying to create your account, please try again later',
  ACCOUNT_EXISTS = 'Account already exists. Username and email must be unique.',
}

export class CbAuthServiceErrorMessage {
  email = 'A valid email is required';
  username =
    'Username must be between 3 to 512 characters, can only be alphanumeric' + 'and cannot start with a number';
  password =
    'Password must be at least 8 characters long, alphanumeric ' +
    'must contain at least 1 Upper case letter and at least 1 symbol';
  firstName = 'First name is required and must be at least 2 characters long';
  lastName = 'Last name is required and must be at least 2 characters long';
  isAgreementAccepted = 'You need to accept our Term of Use and Privacy Policy in order to proceed.';
  accountExists = 'Account already exists. Username and email must be unique';
  generic = 'An error occurred while trying to create your account, please try again later.';

  constructor(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }

  overwriteContent(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }
}

export type fields = 'username' | 'email' | 'firstName' | 'lastName' | 'password' | 'isAgreementAccepted' | '';

export enum DATA_PATH {
  USERNAME = '/username',
  EMAIL = '/email',
  PASSWORD = '/password',
  FIRST_NAME = '/firstName',
  LAST_NAME = '/lastName',
  IS_AGREEMENT_ACCEPTED = '/isAgreementAccepted',
}
