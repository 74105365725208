/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-top-banner-app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../cb-bottom-banner/cb-bottom-banner.component.ngfactory";
import * as i4 from "../cb-bottom-banner/cb-bottom-banner.component";
import * as i5 from "../../services/cb-login-banner.service";
import * as i6 from "./cb-top-banner-app.component";
var styles_CbTopBannerAppComponent = [i0.styles];
var RenderType_CbTopBannerAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbTopBannerAppComponent, data: {} });
export { RenderType_CbTopBannerAppComponent as RenderType_CbTopBannerAppComponent };
function View_CbTopBannerAppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "column has-text-centered"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "2-logos": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "cb-bottom-banner", [], null, null, null, i3.View_CbBottomBannerComponent_0, i3.RenderType_CbBottomBannerComponent)), i1.ɵdid(4, 114688, null, 0, i4.CbBottomBannerComponent, [i5.CbLoginBannerService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column has-text-centered"; var currVal_1 = _ck(_v, 2, 0, _co.display2Logos); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 4, 0); }, null); }
function View_CbTopBannerAppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "figure", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "is-hidden": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "img", [], [[8, "src", 4]], [[null, "error"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.removeAppImage(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.displayAppImage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { "is-app-logo ": 0, "is-project-logo": 1, "is-width-200": 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.hide); _ck(_v, 2, 0, currVal_0); var currVal_2 = _ck(_v, 6, 0, _co.cbLoginBannerService.isAppLogoActive, _co.cbLoginBannerService.isProjectLogoActive, (_v.context.$implicit.logoCss && _v.context.$implicit.logoCss.includes("is-width-200"))); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.URL; _ck(_v, 4, 0, currVal_1); }); }
export function View_CbTopBannerAppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "top-banner-project columns is-centered my-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerAppComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "column has-text-centered top-banner-logo-right"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "top-banner-logo-right": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerAppComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.display2Logos; _ck(_v, 2, 0, currVal_0); var currVal_1 = "column has-text-centered top-banner-logo-right"; var currVal_2 = _ck(_v, 5, 0, _co.display2Logos); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.cbLoginBannerService.appImages; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_CbTopBannerAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-top-banner-app", [], null, null, null, View_CbTopBannerAppComponent_0, RenderType_CbTopBannerAppComponent)), i1.ɵdid(1, 114688, null, 0, i6.CbTopBannerAppComponent, [i5.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbTopBannerAppComponentNgFactory = i1.ɵccf("cb-top-banner-app", i6.CbTopBannerAppComponent, View_CbTopBannerAppComponent_Host_0, {}, {}, []);
export { CbTopBannerAppComponentNgFactory as CbTopBannerAppComponentNgFactory };
