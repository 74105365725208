<!-- <div class="cb-separator-2rem"></div>
<div class="cb-row">
  <cb-logo></cb-logo>
</div> -->
<cb-loading-page *ngIf="isLoading" [loadingMessage]="loadingMessage"> </cb-loading-page>

<div class="columns is-flex is-centered">
  <div class="column is-two-fifths is-four-fifths-mobile">
    <div class="cb-title-group" *ngIf="isEmailVerified">
      <div class="primary-text">
        Your email is already verified.
      </div>
      <div class="cb-separator-2rem"></div>
      <cb-button type="is-info is-rounded" (click)="gotoLogin()">
        Go back to Login Page
      </cb-button>
    </div>

    <div class="cb-title-group" *ngIf="error">
      <div class="primary-text">
        {{ errorMessage }}
        <div class="cb-separator-2rem"></div>
        <cb-button type="is-info is-rounded" (click)="gotoLogin()">
          Go back to Login Page
        </cb-button>
      </div>
    </div>
  </div>
</div>
