<ng-container *ngIf="imageExists">
  <div class="banner-zoom columns is-centered my-1 my-0-mobile">
    <div class="column has-text-centered pb-0 p-0-mobile">
      <ng-container *ngFor="let banner of possibleImages; let index = index">
        <figure class="" [ngClass]="{ 'is-hidden': banner.hide }">
          <img class="img-banner" [src]="banner.URL" (error)="removeBanner(index)" (load)="displayBanner(banner)" />
        </figure>
      </ng-container>
    </div>
  </div>
</ng-container>
<div [hidden]="imageExists">
  <cb-top-banner-registration></cb-top-banner-registration>
</div>
