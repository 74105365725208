import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, Input, forwardRef } from '@angular/core';

export const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => CbCheckboxComponent),
  multi: true,
};
@Component({
  selector: 'cb-checkbox',
  templateUrl: './cb-checkbox.component.html',
  styleUrls: ['./cb-checkbox.component.scss'],
  providers: [VALUE_ACCESSOR],
})
export class CbCheckboxComponent implements ControlValueAccessor {
  isDisabled: boolean;
  isChecked: boolean;
  idx: any = Math.floor(Math.random() * 10000);

  @Input() name = this.idx;
  @Input() display = '';
  @Input('checked') set checked(conditional: boolean) {
    this.isChecked = conditional === true ? true : false;
  }
  @Input('disabled') set disabled(conditional: boolean) {
    this.isDisabled = conditional !== false;
  }

  constructor() {}

  propagateChange = (_: any) => {};
  writeValue(value: any) {
    this.checked = value;
  }
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  onChange(event: any) {
    this.writeValue(event.target.checked);
    this.propagateChange(event.target.checked);
  }

  registerOnTouched() {}
}
