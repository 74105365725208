import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { forwardRef } from '@angular/core';
export var VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(function () { return CbCheckboxComponent; }),
    multi: true,
};
var CbCheckboxComponent = /** @class */ (function () {
    function CbCheckboxComponent() {
        this.idx = Math.floor(Math.random() * 10000);
        this.name = this.idx;
        this.display = '';
        this.propagateChange = function (_) { };
    }
    Object.defineProperty(CbCheckboxComponent.prototype, "checked", {
        set: function (conditional) {
            this.isChecked = conditional === true ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CbCheckboxComponent.prototype, "disabled", {
        set: function (conditional) {
            this.isDisabled = conditional !== false;
        },
        enumerable: true,
        configurable: true
    });
    CbCheckboxComponent.prototype.writeValue = function (value) {
        this.checked = value;
    };
    CbCheckboxComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    CbCheckboxComponent.prototype.onChange = function (event) {
        this.writeValue(event.target.checked);
        this.propagateChange(event.target.checked);
    };
    CbCheckboxComponent.prototype.registerOnTouched = function () { };
    return CbCheckboxComponent;
}());
export { CbCheckboxComponent };
