/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./cb-icon.component";
var styles_CbIconComponent = [i0.styles];
var RenderType_CbIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbIconComponent, data: {} });
export { RenderType_CbIconComponent as RenderType_CbIconComponent };
export function View_CbIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaIconService], { iconProp: [0, "iconProp"], size: [1, "size"] }, null), i1.ɵpad(2, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, _co.type, _co.name); var currVal_2 = _co.size; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0); }); }
export function View_CbIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-icon", [], null, null, null, View_CbIconComponent_0, RenderType_CbIconComponent)), i1.ɵdid(1, 49152, null, 0, i5.CbIconComponent, [], null, null)], null, null); }
var CbIconComponentNgFactory = i1.ɵccf("cb-icon", i5.CbIconComponent, View_CbIconComponent_Host_0, { type: "type", name: "name", size: "size" }, {}, []);
export { CbIconComponentNgFactory as CbIconComponentNgFactory };
