import { AbstractControl, FormControl } from '@angular/forms';

export const regexPattern = {
  strongPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~@#$%^&*+=<>`|{}\/:;!.?\"()\[\]-])(?=.{8,})/,
  password: new RegExp(
    /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/
  ),
  email: /^[-_A-Za-z0-9\+]+(\.[-_A-Za-z0-9\+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})+$/,
  username: /^[a-z][a-z0-9.]+[a-z0-9]$/,
  namePattern: /^[^<>:\/"\\&\x80-\xA0\xAD\u2000-\u200F\u2028-\u202F\u205F-\u206F\uFEFF\uFF01-\uFF60\uFFF9-\uFFFD\x00-\x1F]+$/,
};

export class CustomValidators {
  static OptionalEmail(control: FormControl) {
    if (!control.value) {
      return null;
    }

    const isValid = regexPattern.email.test(control.value);
    if (isValid) {
      return null;
    }

    return { invalid_email: true };
  }

  static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  static noStartWithNumber(control: FormControl) {
    const firstLetter = control.value.charAt(0);
    const isNumber = firstLetter.match(/[0-9]/g);
    const isValid = !isNumber;
    return isValid ? null : { noStartWithNumber: true };
  }

  static termsAndConditionsRequired(control: AbstractControl): { [key: string]: any } | null {
    return !control.value ? { forbidden: { value: control.value } } : null;
  }

  static formCondition(control: AbstractControl) {
    const condition = control.get('condition');
    const newEmail = control.get('newEmail');
    const email = control.get('email');

    if (condition.value === 'yes') {
      return null;
    }

    if (email.value) {
      return null;
    }

    if (newEmail.value) {
      return null;
    }

    return { form_invalid: true };
  }

  static passwordMatcher(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if (password.pristine || confirmPassword.pristine) {
      return null;
    }

    if (password.value === confirmPassword.value) {
      return null;
    }
    return { passwordMatch: true };
  }
}
