import { Component, Input } from '@angular/core';

@Component({
  selector: 'cb-warning-page',
  templateUrl: './cb-warning-page.component.html',
  styleUrls: ['./cb-warning-page.component.scss']
})

export class CbWarningPageComponent {
  @Input() warningMessage = 'Something wrong, please try again';
}
