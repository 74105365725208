import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CbLoginConfig } from 'src/app/lib/services/cb-login.config';
import { CbAuthURLService } from 'src/app/lib/services/cb-auth-url.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  unauthorized = false;

  constructor(
    private router: Router,
    private cbAuthURLService: CbAuthURLService,
    private cbLoginConfig: CbLoginConfig
  ) {}

  ngOnInit(): void {
    if (this.cbLoginConfig.cbSsoStaticApp) {
      this.unauthorized = true;
      return;
    }

    const isSignup = this.cbAuthURLService.isSignupFirstPage();

    if (isSignup) {
      this.router.navigate(['/cb/account'], { skipLocationChange: true });
      return;
    }

    this.router.navigate(['/cb/login'], { skipLocationChange: true });
  }
}
