import { LogFactory } from './cb-debug';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { load } from 'recaptcha-v3';

import { CbLoginConfig } from './cb-login.config';
const log = LogFactory('CbRecaptchaService', false);

@Injectable()
export class CbRecaptchaService {
  constructor(private loginConfig: CbLoginConfig) {}

  getCaptchaToken(action: string): Observable<any> {
    return new Observable((observer) => {
      load(this.loginConfig.cbRecaptchaSiteKey, {
        // Due to limitations in certain countries (ie. China)
        // it's required to use recaptcha.net instead of google.com.
        useRecaptchaNet: true,
      })
        .then((recaptcha) => {
          recaptcha
            .execute(action)
            .then((token) => {
              log('token ', { token });
              observer.next(token);
              observer.complete();
            })
            .catch((error) => {
              log('error ', { error });

              observer.error(error);
              observer.complete();
            });
        })
        .catch((error) => {
          log('error ', { error });
          observer.error(error);
          observer.complete();
        });
    });
  }
}
