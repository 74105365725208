import * as tslib_1 from "tslib";
import { to } from 'await-to-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbBackendService } from '../../services/cb-backend.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { CbSocialLoginService } from '../../services/cb-social-login.service';
import { CbWebApi } from '../../services/cb-webapi';
import { regexPattern } from '../../shared/custom-validations/custom-validations';
import { SocialUsernameComponentContent } from './social-username-content.component';
import { environment } from 'src/environments/environment';
var SocialUsernameComponent = /** @class */ (function () {
    function SocialUsernameComponent(route, fb, cbSocialLoginService, cbWebApi, cbAuthURLService, cbFullstoryService, cbBackendService) {
        this.route = route;
        this.fb = fb;
        this.cbSocialLoginService = cbSocialLoginService;
        this.cbWebApi = cbWebApi;
        this.cbAuthURLService = cbAuthURLService;
        this.cbFullstoryService = cbFullstoryService;
        this.cbBackendService = cbBackendService;
        this.destroy$ = new Subject();
        this.formLoading = false;
        this.error = false;
        this.fragment = null;
        this.errorMessage = 'An error occurred!';
        this.pageContent = new SocialUsernameComponentContent();
        this.intervalID = null;
        this.loadingMessage = 'We are processing your request, please wait';
        this.isLocal = environment.isLocal;
    }
    SocialUsernameComponent.prototype.ngOnInit = function () {
        this.createForm();
        if (this.isLocal) {
            // INFO: avoid validate auth0 redirection state in development
            return;
        }
        this.cbWebApi.verifyUserCanContinue();
    };
    SocialUsernameComponent.prototype.createForm = function () {
        var _this = this;
        this.form = this.fb.group({
            username: [
                '',
                [
                    Validators.required,
                    Validators.pattern(regexPattern.username),
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ],
            ],
        });
        this.username = this.form.get('username');
        this.username.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(function (value) {
            var lowercasevalue = (value && value.toLowerCase()) || '';
            if (lowercasevalue !== value) {
                _this.username.setValue(lowercasevalue);
            }
        });
    };
    SocialUsernameComponent.prototype.getReturnValuefromAuth0Rule = function () {
        var dataFromURL = this.route.snapshot.fragment;
        var _a = dataFromURL.split('&return='), returnValue = _a[1];
        return returnValue;
    };
    SocialUsernameComponent.prototype.validateTokenAndState = function () {
        if (this.isLocal) {
            // INFO: avoid validate auth0 redirection state in development
            // we can use this to validate Error messages
            return {
                token: '',
                state: '',
                validSub: '',
            };
        }
        var _a = this.cbAuthURLService.getAllURLParams(), token = _a.token, state = _a.state;
        var validSub = this.cbSocialLoginService.getSubFromToken(token);
        if (!validSub) {
            this.cbWebApi.goToErrorPage('Invalid_token');
            return;
        }
        return { token: token, state: state, validSub: validSub };
    };
    SocialUsernameComponent.prototype.submit = function () {
        var _this = this;
        this.formLoading = true;
        this.error = false;
        var _a = this.validateTokenAndState(), token = _a.token, state = _a.state, validSub = _a.validSub;
        if (!this.username.value) {
            this.handleFormErrors('username');
            return;
        }
        if (!this.form.valid) {
            return;
        }
        var body = { username: this.username.value, userId: validSub, token: token };
        this.cbSocialLoginService
            .createUserWithUsername(body)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (response) {
            if (response.error) {
                return _this.handleError(response.error.error);
            }
            _this.cbFullstoryService.sendEvent('create_account', { username: _this.username.value });
            _this.cbFullstoryService.sendEvent('successfully_authenticated', { username: _this.username.value });
            _this.loadingMessage = 'We are setting up your profile. Please wait.';
            _this.startPollingUser(response.email, response.newToken, state);
        }, function (error) { return _this.handleError(error.error.error); });
    };
    SocialUsernameComponent.prototype.startPollingUser = function (email, token, state, counter) {
        if (counter === void 0) { counter = 0; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var maxIntents, _a, err, response, userExists;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        counter++;
                        maxIntents = 30;
                        return [4 /*yield*/, to(this.cbBackendService.getUser(email, token).toPromise())];
                    case 1:
                        _a = _b.sent(), err = _a[0], response = _a[1];
                        userExists = (response && response.data) || false;
                        if (userExists || counter > maxIntents || err) {
                            this.redirectToAuth0AppOnSuccess(token, state);
                            return [2 /*return*/];
                        }
                        setTimeout(function () {
                            _this.startPollingUser(email, token, state, counter);
                        }, 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    SocialUsernameComponent.prototype.redirectToAuth0AppOnSuccess = function (token, state) {
        var _this = this;
        var extras = {
            isSignup: true,
        };
        setTimeout(function () {
            _this.cbWebApi.continueToAuth0(token, state, extras);
        }, 1000);
    };
    SocialUsernameComponent.prototype.cancel = function () {
        this.cbWebApi.returnToAuthorizePage();
        return;
    };
    SocialUsernameComponent.prototype.clearErrors = function () {
        this.error = false;
    };
    SocialUsernameComponent.prototype.handleError = function (error) {
        this.formLoading = false;
        this.error = true;
        this.errorMessage = (error && error.message) || this.pageContent.errorMessages['default'];
        var emailTaken = 'email already taken';
        if (this.errorMessage.toLowerCase().includes(emailTaken)) {
            this.handleEmailTakenError();
            return;
        }
        return false;
    };
    // INFO: Validate if email is already taken by another LFID
    SocialUsernameComponent.prototype.handleEmailTakenError = function () {
        this.errorMessage = this.pageContent.errorMessages['emailTaken'];
        this.form.disable();
    };
    SocialUsernameComponent.prototype.handleFormErrors = function (type) {
        this.error = true;
        this.formLoading = false;
        this.errorMessage = this.pageContent.errorMessages[type];
        return false;
    };
    SocialUsernameComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    return SocialUsernameComponent;
}());
export { SocialUsernameComponent };
