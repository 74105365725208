<div [hidden]="formLoading" class="login-page">
  <div class="columns">
    <div class="column has-text-centered my-0-mobile mb-1 font-12px has-text-lfblack">
      New user?
      <a class="is-link has-text-info" data-cy="btn-create-account" (click)="goToCreateAccountPage()">
        Create an account
      </a>
    </div>
  </div>

  <div class="columns">
    <div class="login-form column pt-0">
      <div class="columns is-centered">
        <div class="column is-10">
          <form [formGroup]="form">
            <cb-alert *ngIf="redirectionMessage" type="success">
              {{ redirectionMessage }}
            </cb-alert>
            <cb-alert *ngIf="alertMessage" [type]="alertType">
              {{ alertMessage }}
            </cb-alert>
            <cb-alert *ngIf="error && error.code" type="error">
              <ng-container [ngSwitch]="error.code">
                <ng-container *ngSwitchCase="'unverified_email_not_supported'">
                  To log in you need to give us access to your email address or use a verified email. Please try again
                  or use other method.
                </ng-container>
                <ng-container *ngSwitchCase="'too_many_attempts'">
                  Your account has been blocked after multiple consecutive login attempts.
                  <a class="cb-link white" cbNavigateTo="/cb/help">Contact us.</a>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ error.description || error.code }}
                </ng-container>
              </ng-container>
            </cb-alert>

            <div class="columns">
              <div class="column has-text-centered font-12px has-text-weight-bold has-text-lfblack">
                CONTINUE WITH YOUR LFID
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="field">
                  <cb-input
                    label="Username or Email"
                    autofocus
                    iconName="envelope"
                    [errors]="content.usernameErrors"
                    formControlName="username"
                  >
                  </cb-input>
                </div>

                <div class="field mb-0">
                  <cb-input
                    label="Password"
                    type="password"
                    iconName="lock"
                    [errors]="content.passwordErrors"
                    [forgotLinkLabel]="content.forgotPasswordLabel"
                    [linkForgot]="true"
                    formControlName="password"
                  >
                  </cb-input>
                </div>

                <div class="field has-text-right forgot-link">
                  <b
                    ><a class="font-12px has-text-lfblue font-source-sans-pro bold-400" (click)="goToForgotPassword()"
                      >Forgot Password?</a
                    ></b
                  >
                </div>

                <div class="actions">
                  <cb-button
                    buttonType="submit"
                    type="is-info px-3"
                    data-cy="btn-signin"
                    [disabled]="!form.valid"
                    (click)="submit()"
                  >
                    SIGN IN
                  </cb-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="is-divider-vertical p-0"></div>
    <div class="login-social column pt-0">
      <div class="columns">
        <div class="column has-text-centered font-12px has-text-weight-bold has-text-lfblack">
          OR CONTINUE WITH
        </div>
      </div>
      <div class="columns is-centered is-flex mb-0">
        <div class="column is-8-mobile is-8 social-login-options my-1">
          <a data-cy="login-google" (click)="googlLogin()">
            <figure class="image is-32x32">
              <img [src]="CDN + '/assets/icons/google-icon.svg'" />
            </figure>
          </a>
          <!-- <a data-cy="login-fb" (click)="facebook()">
            <figure class="image is-32x32">
              <img style="height: 32px;" [src]="CDN + '/assets/icons/facebook-2.svg'" />
            </figure>
          </a> -->
          <a data-cy="login-git" class="has-text-lfblack" (click)="githubLogin()">
            <cb-icon type="fab" name="github" size="2x"></cb-icon>
          </a>
          <a data-cy="login-linkedin" (click)="linkedinLogin()">
            <figure class="image is-32x32">
              <img [src]="CDN + '/assets/icons/linkedin-icon.svg'" />
            </figure>
          </a>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-10 has-text-centered font-12px font-source-sans-pro">
          By continuing, you agree to the creation of an LFID in accordance with our
          <a class="cb-link skyblue" href="https://www.linuxfoundation.org/privacy/" target="_blank"> Privacy Policy</a>
          and
          <a class="cb-link skyblue" href="https://www.linuxfoundation.org/terms/" target="_blank"> Terms of Use.</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="formLoading">
  <cb-loading-page loadingMessage="We are processing your session"> </cb-loading-page>
</div>
