import { Component, Input } from '@angular/core';

@Component({
  selector: 'cb-icon',
  templateUrl: './cb-icon.component.html',
  styleUrls: ['./cb-icon.component.scss']
})
export class CbIconComponent {
  @Input() type = 'fas';
  @Input() name = '';
  @Input() size = '';
}
