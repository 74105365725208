import { Injectable } from '@angular/core';
import { CbLoginCustomConfig } from './cb-login-custom.service';

@Injectable({ providedIn: 'root' })
export class CbLoginConfig {
  domain = '';
  clientId = '';
  clientName = 'Community Bridge';
  cbBackend = 'https://lf-login.dev.platform.linuxfoundation.org/api';
  cbSidebarContent = '';
  debug = false;
  cbDefaultLogo = '/assets/cb-assets/cb-logo-white.svg';
  cbDefaultLogoNoSidebar = '/assets/cb-assets/lf-logo-blue.png';
  cbLogoLink = '/';
  cbBackgroundImage = '/assets/cb-background.png';
  cbBorderImage = '/assets/cb-border.png';
  cbCDN = '';
  cbWarningTime = 5 * 60 * 1000; // default 5min
  cbResponseType = 'token id_token';
  cbSkipLocalChanges = false;
  cbVerifyAccount = false;
  cbRecaptchaSiteKey = '';
  // @info: there are 3 ways to use cb-login
  // 1. As Auth0App (cbAuthCentralizedLogin: true)
  // 2. As SSOStaticApp (cbSsoStaticApp: true)
  // 3. As AngularModule (cbAuthCentralizedLogin and cbSsoStaticApp both false)
  cbAuthCentralizedLogin = false;
  cbSsoStaticApp = false;

  constructor(private cbLoginCustomConfig: CbLoginCustomConfig) {
    Object.assign(this, { ...this.cbLoginCustomConfig });
  }

  cbContentFactory: any = () => ({});
}
