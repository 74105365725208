import { Component } from '@angular/core';
import { CbAuthService } from '../../services/cb-auth.service';

@Component({
  selector: 'cb-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent {
  url: any;
  tokenObject: any;
  constructor(private cbAuthService: CbAuthService) {
    if (window.location.hash) {
      this.saveToken();
    } else {
      this.redirect();
    }
  }

  saveToken() {
    this.cbAuthService.saveToken(window.location.hash).subscribe(() => {
      this.redirect();
    });
  }
  redirect() {
    this.cbAuthService.redirectTo();
  }
}
