/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/cb-ui/cb-confirmation/cb-confirmation.component.ngfactory";
import * as i2 from "../../shared/cb-ui/cb-confirmation/cb-confirmation.component";
import * as i3 from "../../services/confirmation-alert-data";
import * as i4 from "../../services/cb-webapi";
import * as i5 from "../../services/cb-login.config";
import * as i6 from "./confirmation.component";
import * as i7 from "../../services/cb-backend.service";
import * as i8 from "@angular/router";
import * as i9 from "../../services/cb-recpatcha.service";
import * as i10 from "../../services/cb-auth.service";
var styles_ResetPasswordConfirmationComponent = [];
var RenderType_ResetPasswordConfirmationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResetPasswordConfirmationComponent, data: {} });
export { RenderType_ResetPasswordConfirmationComponent as RenderType_ResetPasswordConfirmationComponent };
export function View_ResetPasswordConfirmationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-confirmation", [], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.resendResetPasswordEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CbConfirmationComponent_0, i1.RenderType_CbConfirmationComponent)), i0.ɵdid(1, 49152, null, 0, i2.CbConfirmationComponent, [i3.ConfirmationAlertData, i4.CbWebApi, i5.CbLoginConfig], { headerTitle: [0, "headerTitle"], confirmationTitle: [1, "confirmationTitle"], confirmationMessage: [2, "confirmationMessage"], buttonText: [3, "buttonText"], linkText: [4, "linkText"], linkRouterPath: [5, "linkRouterPath"] }, { buttonClicked: "buttonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.headerTitle, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.confirmationTitle, ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.confirmationMessage, ""); var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.buttonText, ""); var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.linkText, ""); var currVal_5 = i0.ɵinlineInterpolate(1, "", _co.linkRouterPath, ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ResetPasswordConfirmationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-reset-password-confirmation", [], null, null, null, View_ResetPasswordConfirmationComponent_0, RenderType_ResetPasswordConfirmationComponent)), i0.ɵdid(1, 245760, null, 0, i6.ResetPasswordConfirmationComponent, [i7.CbBackendService, i5.CbLoginConfig, i3.ConfirmationAlertData, i8.ActivatedRoute, i9.CbRecaptchaService, i10.CbAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResetPasswordConfirmationComponentNgFactory = i0.ɵccf("cb-reset-password-confirmation", i6.ResetPasswordConfirmationComponent, View_ResetPasswordConfirmationComponent_Host_0, {}, {}, []);
export { ResetPasswordConfirmationComponentNgFactory as ResetPasswordConfirmationComponentNgFactory };
