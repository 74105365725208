/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-loading-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./cb-loading-page.component";
var styles_CbLoadingPageComponent = [i0.styles];
var RenderType_CbLoadingPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbLoadingPageComponent, data: {} });
export { RenderType_CbLoadingPageComponent as RenderType_CbLoadingPageComponent };
export function View_CbLoadingPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "columns is-flex-mobile is-centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "column is-one-third is-four-fifths-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "has-text-centered"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingMessage; _ck(_v, 5, 0, currVal_0); }); }
export function View_CbLoadingPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-loading-page", [], null, null, null, View_CbLoadingPageComponent_0, RenderType_CbLoadingPageComponent)), i1.ɵdid(1, 49152, null, 0, i2.CbLoadingPageComponent, [], null, null)], null, null); }
var CbLoadingPageComponentNgFactory = i1.ɵccf("cb-loading-page", i2.CbLoadingPageComponent, View_CbLoadingPageComponent_Host_0, { loadingMessage: "loadingMessage" }, {}, []);
export { CbLoadingPageComponentNgFactory as CbLoadingPageComponentNgFactory };
