import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { CbBackendService } from '../../services/cb-backend.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomValidators, regexPattern } from '../../shared/custom-validations/custom-validations';
import { CbLoginConfig } from '../../services/cb-login.config';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from 'src/app/lib/services/cb-auth-url.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { isExpired } from '../verify-account/verify-account.features';
import { to } from 'await-to-js';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(fb, cbBackendService, cbAuthURLService, cbloginConfig, cbWebApi, cbFullstoryService) {
        this.fb = fb;
        this.cbBackendService = cbBackendService;
        this.cbAuthURLService = cbAuthURLService;
        this.cbloginConfig = cbloginConfig;
        this.cbWebApi = cbWebApi;
        this.cbFullstoryService = cbFullstoryService;
        this.token = '';
        this.changePasswordFailed = false;
        this.changePasswordSuccess = false;
        this.failedMessage = '';
        this.formSubmitted = false;
        this.destroy$ = new Subject();
        this.formLoading = true;
        this.loginPath = '/cb/login';
        this.displayCloseWindowMessage = false;
        this.isExpired = false;
        this.pageLoading = true;
        this.passwordErrors = {
            required: 'Password is required.',
            pattern: 'Must have at least 8 characters, including upper, lower, numeric, and special characters.',
        };
        this.confirmPasswordErrors = {
            required: 'Confirm password is required.',
            pattern: 'Must have at least 8 characters, including upper, lower, numeric, and special characters.',
            passwordMatch: 'Passwords are not identical.',
        };
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, expired, err;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = this.cbAuthURLService.getAllURLParams().token;
                        expired = isExpired(token);
                        if (expired) {
                            this.isExpired = true;
                            this.formLoading = false;
                            this.pageLoading = false;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, to(this.cbBackendService.checkResetPasswordToken(token).toPromise())];
                    case 1:
                        err = (_a.sent())[0];
                        if (err) {
                            this.isExpired = true;
                            this.formLoading = false;
                            this.pageLoading = false;
                            return [2 /*return*/];
                        }
                        this.formLoading = false;
                        this.pageLoading = false;
                        this.token = token;
                        this.createForm();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChangePasswordComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            password: ['', [Validators.required, Validators.pattern(regexPattern.strongPassword)]],
            confirmPassword: ['', [Validators.required]],
        }, { validator: CustomValidators.passwordMatcher });
        this.password = this.form.get('password');
        this.confirmPassword = this.form.get('confirmPassword');
    };
    ChangePasswordComponent.prototype.submit = function () {
        var _this = this;
        this.formLoading = true;
        this.formSubmitted = true;
        if (this.form.valid) {
            this.changePasswordFailed = false;
            this.cbBackendService
                .changePassword(this.password.value, this.token)
                .pipe(takeUntil(this.destroy$))
                .subscribe(function () {
                _this.handleChangePasswordSucceed();
            }, function (err) {
                _this.formLoading = false;
                _this.failedMessage = err.error.error.message;
                _this.changePasswordFailed = true;
                _this.cbFullstoryService.fsError(_this.failedMessage);
            });
        }
    };
    ChangePasswordComponent.prototype.handleChangePasswordSucceed = function () {
        var authorizeURL = this.cbAuthURLService.getAllURLParams().authorizeURL;
        this.formLoading = false;
        this.changePasswordSuccess = true;
        var canContinue = authorizeURL ? true : false;
        if (canContinue) {
            this.cbWebApi.returnToAuthorizePage();
            return;
        }
        this.displayCloseWindowMessage = true;
    };
    ChangePasswordComponent.prototype.cancel = function () {
        this.cbWebApi.returnToAuthorizePage();
    };
    ChangePasswordComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
