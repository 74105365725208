/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component.ngfactory";
import * as i2 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component";
import * as i3 from "./auth.component";
import * as i4 from "../../services/cb-auth.service";
var styles_AuthComponent = [];
var RenderType_AuthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-loading-page", [["loadingMessage", "We are processing your session"]], null, null, null, i1.View_CbLoadingPageComponent_0, i1.RenderType_CbLoadingPageComponent)), i0.ɵdid(1, 49152, null, 0, i2.CbLoadingPageComponent, [], { loadingMessage: [0, "loadingMessage"] }, null)], function (_ck, _v) { var currVal_0 = "We are processing your session"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AuthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-auth", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i0.ɵdid(1, 49152, null, 0, i3.AuthComponent, [i4.CbAuthService], null, null)], null, null); }
var AuthComponentNgFactory = i0.ɵccf("cb-auth", i3.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
