export const timezones = {
  '(GMT-11:00) Midway Island, Samoa': 'Pacific/Midway',
  '(GMT-11:00) Pago Pago': 'Pacific/Pago_Pago',
  '(GMT-10:00) Hawaii': 'Pacific/Honolulu',
  '(GMT-8:00) Alaska': 'America/Anchorage',
  '(GMT-8:00) Juneau': 'America/Juneau',
  '(GMT-7:00) Vancouver': 'America/Vancouver',
  '(GMT-7:00) Pacific Time (US and Canada)': 'America/Los_Angeles',
  '(GMT-7:00) Tijuana': 'America/Tijuana',
  '(GMT-7:00) Arizona': 'America/Phoenix',
  '(GMT-6:00) Edmonton': 'America/Edmonton',
  '(GMT-6:00) Mountain Time (US and Canada)': 'America/Denver',
  '(GMT-6:00) Mazatlan': 'America/Mazatlan',
  '(GMT-6:00) Saskatchewan': 'America/Regina',
  '(GMT-6:00) Guatemala': 'America/Guatemala',
  '(GMT-6:00) El Salvador': 'America/El_Salvador',
  '(GMT-6:00) Managua': 'America/Managua',
  '(GMT-6:00) Costa Rica': 'America/Costa_Rica',
  '(GMT-6:00) Tegucigalpa': 'America/Tegucigalpa',
  '(GMT-6:00) Chihuahua': 'America/Chihuahua',
  '(GMT-5:00) Winnipeg': 'America/Winnipeg',
  '(GMT-5:00) Central Time (US and Canada)': 'America/Chicago',
  '(GMT-5:00) Mexico City': 'America/Mexico_City',
  '(GMT-5:00) Panama': 'America/Panama',
  '(GMT-5:00) Bogota': 'America/Bogota',
  '(GMT-5:00) Lima': 'America/Lima',
  '(GMT-5:00) Monterrey': 'America/Monterrey',
  '(GMT-4:00) Montreal': 'America/Montreal',
  '(GMT-4:00) Eastern Time (US and Canada)': 'America/New_York',
  '(GMT-4:00) Indiana (East)': 'America/Indianapolis',
  '(GMT-4:00) Puerto Rico': 'America/Puerto_Rico',
  '(GMT-4:00) Caracas': 'America/Caracas',
  '(GMT-4:00) Santiago': 'America/Santiago',
  '(GMT-4:00) La Paz': 'America/La_Paz',
  '(GMT-4:00) Guyana': 'America/Guyana',
  '(GMT-3:00) Halifax': 'America/Halifax',
  '(GMT-3:00) Montevideo': 'America/Montevideo',
  '(GMT-3:00) Recife': 'America/Araguaina',
  '(GMT-3:00) Buenos Aires, Georgetown': 'America/Argentina/Buenos_Aires',
  '(GMT-3:00) Sao Paulo': 'America/Sao_Paulo',
  '(GMT-3:00) Atlantic Time (Canada)': 'Canada/Atlantic',
  '(GMT-2:30) Newfoundland and Labrador': 'America/St_Johns',
  '(GMT-2:00) Greenland': 'America/Godthab',
  '(GMT-1:00) Cape Verde Islands': 'Atlantic/Cape_Verde',
  '(GMT+0:00) Azores': 'Atlantic/Azores',
  '(GMT+0:00) Universal Time UTC': 'UTC',
  '(GMT+0:00) Greenwich Mean Time': 'Etc/Greenwich',
  '(GMT+0:00) Reykjavik': 'Atlantic/Reykjavik',
  '(GMT+0:00) Nouakchott': 'Africa/Nouakchott',
  '(GMT+1:00) Dublin': 'Europe/Dublin',
  '(GMT+1:00) London': 'Europe/London',
  '(GMT+1:00) Lisbon': 'Europe/Lisbon',
  '(GMT+1:00) Casablanca': 'Africa/Casablanca',
  '(GMT+1:00) West Central Africa': 'Africa/Bangui',
  '(GMT+1:00) Algiers': 'Africa/Algiers',
  '(GMT+1:00) Tunis': 'Africa/Tunis',
  '(GMT+2:00) Belgrade, Bratislava, Ljubljana': 'Europe/Belgrade',
  '(GMT+2:00) Sarajevo, Skopje, Zagreb': 'CET',
  '(GMT+2:00) Oslo': 'Europe/Oslo',
  '(GMT+2:00) Copenhagen': 'Europe/Copenhagen',
  '(GMT+2:00) Brussels': 'Europe/Brussels',
  '(GMT+2:00) Amsterdam, Berlin, Rome, Stockholm, Vienna': 'Europe/Berlin',
  '(GMT+2:00) Amsterdam': 'Europe/Amsterdam',
  '(GMT+2:00) Rome': 'Europe/Rome',
  '(GMT+2:00) Stockholm': 'Europe/Stockholm',
  '(GMT+2:00) Vienna': 'Europe/Vienna',
  '(GMT+2:00) Luxembourg': 'Europe/Luxembourg',
  '(GMT+2:00) Paris': 'Europe/Paris',
  '(GMT+2:00) Zurich': 'Europe/Zurich',
  '(GMT+2:00) Madrid': 'Europe/Madrid',
  '(GMT+2:00) Harare, Pretoria': 'Africa/Harare',
  '(GMT+2:00) Warsaw': 'Europe/Warsaw',
  '(GMT+2:00) Prague Bratislava': 'Europe/Prague',
  '(GMT+2:00) Budapest': 'Europe/Budapest',
  '(GMT+2:00) Tripoli': 'Africa/Tripoli',
  '(GMT+2:00) Cairo': 'Africa/Cairo',
  '(GMT+2:00) Johannesburg': 'Africa/Johannesburg',
  '(GMT+2:00) Khartoum': 'Africa/Khartoum',
  '(GMT+3:00) Helsinki': 'Europe/Helsinki',
  '(GMT+3:00) Nairobi': 'Africa/Nairobi',
  '(GMT+3:00) Sofia': 'Europe/Sofia',
  '(GMT+3:00) Istanbul': 'Europe/Istanbul',
  '(GMT+3:00) Athens': 'Europe/Athens',
  '(GMT+3:00) Bucharest': 'Europe/Bucharest',
  '(GMT+3:00) Nicosia': 'Asia/Nicosia',
  '(GMT+3:00) Beirut': 'Asia/Beirut',
  '(GMT+3:00) Damascus': 'Asia/Damascus',
  '(GMT+3:00) Jerusalem': 'Asia/Jerusalem',
  '(GMT+3:00) Amman': 'Asia/Amman',
  '(GMT+3:00) Moscow': 'Europe/Moscow',
  '(GMT+3:00) Baghdad': 'Asia/Baghdad',
  '(GMT+3:00) Kuwait': 'Asia/Kuwait',
  '(GMT+3:00) Riyadh': 'Asia/Riyadh',
  '(GMT+3:00) Bahrain': 'Asia/Bahrain',
  '(GMT+3:00) Qatar': 'Asia/Qatar',
  '(GMT+3:00) Aden': 'Asia/Aden',
  '(GMT+3:00) Djibouti': 'Africa/Djibouti',
  '(GMT+3:00) Mogadishu': 'Africa/Mogadishu',
  '(GMT+3:00) Kiev': 'Europe/Kiev',
  '(GMT+3:00) Minsk': 'Europe/Minsk',
  '(GMT+4:00) Dubai': 'Asia/Dubai',
  '(GMT+4:00) Muscat': 'Asia/Muscat',
  '(GMT+4:00) Baku, Tbilisi, Yerevan': 'Asia/Baku',
  '(GMT+4:30) Tehran': 'Asia/Tehran',
  '(GMT+4:30) Kabul': 'Asia/Kabul',
  '(GMT+5:00) Yekaterinburg': 'Asia/Yekaterinburg',
  '(GMT+5:00) Islamabad, Karachi, Tashkent': 'Asia/Tashkent',
  '(GMT+5:30) India': 'Asia/Calcutta',
  '(GMT+5:30) Mumbai, Kolkata, New Delhi': 'Asia/Kolkata',
  '(GMT+5:45) Kathmandu': 'Asia/Kathmandu',
  '(GMT+6:00) Almaty': 'Asia/Almaty',
  '(GMT+6:00) Dacca': 'Asia/Dacca',
  '(GMT+6:00) Astana, Dhaka': 'Asia/Dhaka',
  '(GMT+6:30) Rangoon': 'Asia/Rangoon',
  '(GMT+7:00) Novosibirsk': 'Asia/Novosibirsk',
  '(GMT+7:00) Krasnoyarsk': 'Asia/Krasnoyarsk',
  '(GMT+7:00) Bangkok': 'Asia/Bangkok',
  '(GMT+7:00) Vietnam': 'Asia/Saigon',
  '(GMT+7:00) Jakarta': 'Asia/Jakarta',
  '(GMT+8:00) Irkutsk, Ulaanbaatar': 'Asia/Irkutsk',
  '(GMT+8:00) Beijing, Shanghai': 'Asia/Shanghai',
  '(GMT+8:00) Hong Kong SAR': 'Asia/Hong_Kong',
  '(GMT+8:00) Taipei': 'Asia/Taipei',
  '(GMT+8:00) Kuala Lumpur': 'Asia/Kuala_Lumpur',
  '(GMT+8:00) Singapore': 'Asia/Singapore',
  '(GMT+8:00) Perth': 'Australia/Perth',
  '(GMT+9:00) Yakutsk': 'Asia/Yakutsk',
  '(GMT+9:00) Seoul': 'Asia/Seoul',
  '(GMT+9:00) Osaka, Sapporo, Tokyo': 'Asia/Tokyo',
  '(GMT+9:30) Darwin': 'Australia/Darwin',
  '(GMT+9:30) Adelaide': 'Australia/Adelaide',
  '(GMT+10:00) Vladivostok': 'Asia/Vladivostok',
  '(GMT+10:00) Guam, Port Moresby': 'Pacific/Port_Moresby',
  '(GMT+10:00) Brisbane': 'Australia/Brisbane',
  '(GMT+10:00) Canberra, Melbourne, Sydney': 'Australia/Sydney',
  '(GMT+10:00) Hobart': 'Australia/Hobart',
  '(GMT+11:00) Magadan': 'Asia/Magadan',
  '(GMT+11:00) Solomon Islands': 'SST',
  '(GMT+11:00) New Caledonia': 'Pacific/Noumea',
  '(GMT+12:00) Kamchatka': 'Asia/Kamchatka',
  '(GMT+12:00) Fiji Islands, Marshall Islands': 'Pacific/Fiji',
  '(GMT+12:00) Auckland, Wellington': 'Pacific/Auckland',
  '(GMT+13:00) Independent State of Samoa': 'Pacific/Apia',
};

export function GetTimeZoneFriendlyNameByName(timezoneName) {
  for (const key in timezones) {
    if (timezones[key] === timezoneName) {
      const [, name] = key.split(') ');
      return name;
    }
  }
}
