import * as tslib_1 from "tslib";
import { v4 as uuidv4 } from 'uuid';
import * as querystring from 'querystring';
import { LogFactory } from './cb-debug';
import { CbAuthURLService } from './cb-auth-url.service';
import * as i0 from "@angular/core";
import * as i1 from "./cb-auth-url.service";
var log = LogFactory('CbFullstoryService', false);
var LIB_FS = window.FS;
var identified = false;
var CbFullstoryService = /** @class */ (function () {
    function CbFullstoryService(cbAuthURLService) {
        this.cbAuthURLService = cbAuthURLService;
        this.sessionid = '';
        this.setSessionId();
    }
    CbFullstoryService.prototype.getSessionidFromReturnTo = function () {
        var _a = (this.cbAuthURLService.getAllURLParams() || {}).authorizeURL, authorizeURL = _a === void 0 ? '' : _a;
        if (!authorizeURL) {
            return '';
        }
        var _b = authorizeURL.split('?'), _c = _b[1], search = _c === void 0 ? '' : _c;
        if (!search) {
            return '';
        }
        var params = querystring.parse(search) || {};
        return params.sessionid || '';
    };
    CbFullstoryService.prototype.setSessionId = function () {
        log('entered setSessionId');
        var params = this.cbAuthURLService.getAllURLParams() || {};
        if (params.sessionid) {
            this.sessionid = params.sessionid;
            log('sessionid', { sessionid: params.sessionid });
            return;
        }
        var sessionid = this.getSessionidFromReturnTo();
        if (sessionid) {
            this.sessionid = sessionid;
            log('sessionid', { sessionid: sessionid });
            return;
        }
        log('creating sessionid');
        this.sessionid = uuidv4();
        log('sessionid', { sessionid: this.sessionid });
        return true;
    };
    CbFullstoryService.prototype.identify = function (uid, data) {
        if (uid === void 0) { uid = ''; }
        var userVars = tslib_1.__assign({}, data, { sessionid: this.sessionid });
        log('FS identity', { uid: uid, userVars: userVars, identified: identified });
        if (identified) {
            this.setUserVars(userVars);
            return;
        }
        if (!uid) {
            this.setUserVars(userVars);
            return;
        }
        var isAuth0User = uid.includes('auth0|') || !uid.includes('|');
        log('FS identity', { isAuth0User: isAuth0User });
        if (!isAuth0User) {
            this.setUserVars(userVars);
            return;
        }
        var innerUID = uid.replace('auth0|', '');
        log(' set identity', { innerUID: innerUID, userVars: userVars });
        LIB_FS.identify(innerUID, userVars);
        identified = true;
    };
    CbFullstoryService.prototype.setUserVars = function (data) {
        if (data === void 0) { data = {}; }
        log('entered setUserVars', { data: data });
        var userVars = tslib_1.__assign({}, data, { sessionid: this.sessionid });
        LIB_FS.setUserVars(userVars);
    };
    CbFullstoryService.prototype.initFullstory = function () {
        log('entered initFullstory');
        var params = this.cbAuthURLService.getAllURLParams();
        var decoded = this.cbAuthURLService.getDecodedToken();
        var usernameFromSub = decoded.sub ? decoded.sub.replace('auth0|', '') : '';
        var uid = decoded.username || params.username || usernameFromSub;
        var firstName = decoded.firstName || decoded.given_name || params.firstName || params.given_name || '';
        var lastName = decoded.lastName || decoded.family_name || params.lastName || params.lastName || '';
        var email = decoded.email || params.email;
        var username = decoded.username || params.username;
        var displayName = firstName + " " + lastName;
        var data = {
            email: email,
            username: username,
            displayName: displayName.trim(),
            sessionid: this.sessionid,
        };
        this.identify(uid, data);
    };
    CbFullstoryService.prototype.sendEvent = function (eventName, payload) {
        if (eventName === void 0) { eventName = ''; }
        if (payload === void 0) { payload = {}; }
        if (!eventName) {
            return;
        }
        var data = tslib_1.__assign({}, payload, { sessionid: this.sessionid });
        log('sendEvent data ? ', { eventName: eventName, data: data });
        LIB_FS.event(eventName, payload);
    };
    CbFullstoryService.prototype.fsLog = function (message) {
        log('entered fsLog', { message: message });
        if (!message) {
            return;
        }
        LIB_FS.log(message);
    };
    CbFullstoryService.prototype.fsError = function (message) {
        log('entered fsError', { message: message });
        if (!message) {
            return;
        }
        // *info: disabled , no needed by now
        // LIB_FS.log('error', message);
    };
    CbFullstoryService.ngInjectableDef = i0.defineInjectable({ factory: function CbFullstoryService_Factory() { return new CbFullstoryService(i0.inject(i1.CbAuthURLService)); }, token: CbFullstoryService, providedIn: "root" });
    return CbFullstoryService;
}());
export { CbFullstoryService };
