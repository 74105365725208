import { CbAuthURLService } from './cb-auth-url.service';
import { CbStorage } from './cb-storage';
import * as i0 from "@angular/core";
import * as i1 from "./cb-storage";
import * as i2 from "./cb-auth-url.service";
var CbAlertService = /** @class */ (function () {
    function CbAlertService(cbStorage, cbAuthURLService) {
        this.cbStorage = cbStorage;
        this.cbAuthURLService = cbAuthURLService;
    }
    CbAlertService.prototype.trytoParse = function (string) {
        try {
            return JSON.parse(string);
        }
        catch (error) {
            return {};
        }
    };
    CbAlertService.prototype.createAlert = function (message, type) {
        if (type === void 0) { type = 'error'; }
        return btoa(JSON.stringify({
            message: message,
            type: type,
            date: new Date().getTime(),
        }));
    };
    CbAlertService.prototype.getAlert = function () {
        var alert = this.cbAuthURLService.getAllURLParams().alert;
        if (!alert) {
            return;
        }
        var stored = this.cbStorage.get(alert);
        var isAlertDone = alert && stored;
        if (isAlertDone) {
            return;
        }
        this.cbStorage.save(alert, 'used');
        return this.trytoParse(atob(alert));
    };
    CbAlertService.ngInjectableDef = i0.defineInjectable({ factory: function CbAlertService_Factory() { return new CbAlertService(i0.inject(i1.CbStorage), i0.inject(i2.CbAuthURLService)); }, token: CbAlertService, providedIn: "root" });
    return CbAlertService;
}());
export { CbAlertService };
