import { CbFullstoryService } from './cb-fullstory.service';
import * as i0 from "@angular/core";
import * as i1 from "./cb-fullstory.service";
var ConfirmationAlertData = /** @class */ (function () {
    function ConfirmationAlertData(cbFullstoryService) {
        this.cbFullstoryService = cbFullstoryService;
        this.resendVerificationStatus = 0;
        this.resendVerificationMessage = '';
        this.isLoading = false;
    }
    ConfirmationAlertData.prototype.init = function () {
        this.resendVerificationStatus = 0;
        this.resendVerificationMessage = '';
        this.isLoading = false;
    };
    ConfirmationAlertData.prototype.setSuccess = function (successMessage) {
        this.resendVerificationStatus = 1;
        this.resendVerificationMessage = successMessage;
    };
    ConfirmationAlertData.prototype.setError = function (errorMessage) {
        this.cbFullstoryService.fsError(errorMessage);
        this.resendVerificationStatus = -1;
        this.resendVerificationMessage = errorMessage;
    };
    ConfirmationAlertData.prototype.setLoadingStatus = function (status) {
        this.isLoading = status;
    };
    ConfirmationAlertData.ngInjectableDef = i0.defineInjectable({ factory: function ConfirmationAlertData_Factory() { return new ConfirmationAlertData(i0.inject(i1.CbFullstoryService)); }, token: ConfirmationAlertData, providedIn: "root" });
    return ConfirmationAlertData;
}());
export { ConfirmationAlertData };
