import { CbAuthURLService } from './cb-auth-url.service';
import { CbBannerService } from './cb-banner.service';
import { LogFactory } from './cb-debug';
import { cdn } from './cb-envars';
import { CLIENTS_METADATA } from './cb-login-banner.metadata';
import * as i0 from "@angular/core";
import * as i1 from "./cb-auth-url.service";
import * as i2 from "./cb-banner.service";
var log = LogFactory('CbLoginBannerService', false);
export var defaultLogoURL = 'https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/thelinuxfoundation-color.svg';
var CbLoginBannerService = /** @class */ (function () {
    function CbLoginBannerService(cbAuthURLService, cbBannerService) {
        this.cbAuthURLService = cbAuthURLService;
        this.cbBannerService = cbBannerService;
        this.bannerType = 'default';
        this.appName = '';
        this.clientId = '';
        // info: General used by several apps
        this.project = '';
        // Cvent
        this.eventName = '';
        this.eventImages = [];
        // LF Registration
        this.zoomID = '';
        this.id = '';
        this.registrationType = '';
        this.isRegistration = false;
        this.isLoading = true;
        this.displayDefault = false;
        this.appImages = [];
        this.appMetadata = null;
        this.bottomImages = [];
        this.isAppLogoActive = true;
        this.isProjectLogoActive = false;
        this.withAppLogotoLeft = false;
        this.isMeeting = false;
        this.meetingName = '';
        this.meetingDescription = '';
        this.meetingVisibility = 'public';
        this.meetingID = '';
        this.meetingType = '';
        this.meetingDate = '';
        this.inviteID = '';
        this.registrantName = '';
        this.meetingError = false;
        this.initValues();
    }
    CbLoginBannerService.prototype.getPersistentParamsInURL = function () {
        log('entered getPersistentParamsInURL');
        var urlParams = this.cbAuthURLService.getAllURLParams();
        var decodedParams = this.cbAuthURLService.getDecodedToken();
        var returnToParams = this.cbAuthURLService.getParamsFromReturnTo();
        var auth0params = this.cbAuthURLService.getAuth0ConfigParams();
        var fragments = this.cbAuthURLService.getValuesFromFragments(urlParams.fragment);
        this.appName =
            urlParams.clientName ||
                auth0params.title ||
                decodedParams.clientName ||
                returnToParams.clientName ||
                urlParams.client_name ||
                decodedParams.client_name ||
                returnToParams.client_name ||
                auth0params.app ||
                urlParams.app ||
                decodedParams.app ||
                returnToParams.app ||
                fragments.clientName ||
                '';
        this.clientId =
            urlParams.clientId ||
                decodedParams.clientId ||
                returnToParams.clientId ||
                urlParams.client_id ||
                decodedParams.client_id ||
                returnToParams.client_id ||
                '';
        if (this.appName && Array.isArray(this.appName)) {
            this.appName = this.appName[0];
        }
        this.setAppMetadata();
        this.zoomID =
            auth0params.zoomID ||
                urlParams.zoomID ||
                returnToParams.zoomID ||
                auth0params.zoom_id ||
                urlParams.zoom_id ||
                returnToParams.zoom_id;
        this.id = auth0params.id || urlParams.id || returnToParams.id;
        if (this.id && Array.isArray(this.id)) {
            this.id = this.id[0];
        }
        this.registrationType =
            auth0params.registrationType ||
                auth0params.registration_type ||
                urlParams.registrationType ||
                urlParams.registration_type ||
                returnToParams.registrationType ||
                returnToParams.registration_type;
        if (this.registrationType && Array.isArray(this.registrationType)) {
            this.registrationType = this.registrationType[0];
        }
        var projectSlugFromMetadata = (this.appMetadata && this.appMetadata.projectSlug) || '';
        this.project = projectSlugFromMetadata || auth0params.project || urlParams.project || returnToParams.project;
        if (this.project && Array.isArray(this.project)) {
            this.project = this.project[0];
        }
        this.eventName =
            auth0params.eventName ||
                urlParams.eventName ||
                returnToParams.eventName ||
                auth0params.event_name ||
                urlParams.event_name ||
                returnToParams.event_name;
        if (this.eventName && Array.isArray(this.eventName)) {
            this.eventName = this.eventName[0];
        }
        this.setMeetingInfo(urlParams, fragments, returnToParams);
        return {
            appName: this.appName,
            eventName: this.eventName,
            project: this.project,
            id: this.id,
            registrationType: this.registrationType,
            zoomID: this.zoomID,
            clientId: this.clientId,
            meetingName: this.meetingName,
            meetingDescription: this.meetingDescription,
            meetingVisibility: this.meetingVisibility,
            meetingID: this.meetingID,
            meetingType: this.meetingType,
            meetingDate: this.meetingDate,
            inviteID: this.inviteID,
            registrantName: this.registrantName,
            meetingError: this.meetingError,
        };
    };
    CbLoginBannerService.prototype.initValues = function () {
        log('entered int values');
        this.getPersistentParamsInURL();
        this.isRegistration = this.appName.toLowerCase().includes('lf registration');
        // this.isMeeting = this.appName.toLowerCase().includes('lfx meeting');
        log('data ? ', {
            appName: this.appName,
            project: this.project,
            eventName: this.eventName,
            isRegistration: this.isRegistration,
            id: this.id,
            registrationType: this.registrationType,
            zoomID: this.zoomID,
            clientId: this.clientId,
            meetingName: this.meetingName,
            meetingDescription: this.meetingDescription,
            meetingVisibility: this.meetingVisibility,
            meetingID: this.meetingID,
            meetingType: this.meetingType,
            meetingDate: this.meetingDate,
            inviteID: this.inviteID,
            registrantName: this.registrantName,
            meetingError: this.meetingError,
        });
        // if (this.isMeeting) {
        //   return;
        // }
        if (this.isRegistration) {
            var RegistrationIDFound = this.id && this.registrationType;
            if (RegistrationIDFound) {
                // this.setRegistrationLogos();
                this.setBottomImages();
                return;
            }
            // info: Registration ID is not found
            this.isRegistration = false;
            this.setProjectLogo();
            return;
        }
        if (this.eventName) {
            this.setEventImage();
            return;
        }
        if (this.appName) {
            this.setAppLogoURL();
            return;
        }
        if (this.project) {
            this.setProjectLogo();
            return;
        }
        this.setDefaultBanner();
    };
    CbLoginBannerService.prototype.setAppMetadata = function () {
        var medatada = CLIENTS_METADATA[this.appName];
        if (medatada) {
            this.appMetadata = medatada;
        }
    };
    CbLoginBannerService.prototype.setRegistrationLogos = function () { };
    CbLoginBannerService.prototype.setBottomImages = function () {
        var images = this.getProjectLogoURL();
        var defaultImage = {
            hide: true,
            URL: defaultLogoURL,
        };
        if (images && images.length === 0) {
            images = [defaultImage];
        }
        this.bottomImages = images.slice();
        return images;
    };
    CbLoginBannerService.prototype.setDefaultBanner = function () {
        log('entered setDefaultBanner');
        this.eventName = '';
        this.appImages = [];
        this.isRegistration = false;
        this.displayDefault = true;
    };
    CbLoginBannerService.prototype.setEventImage = function () {
        log('entered setEventImage');
        var eventImages = this.cbBannerService.GetCventImageURL(this.eventName) || [];
        this.eventImages = this.cbBannerService.GetPossibleBanners(eventImages);
        log('cvent images ? ', { images: this.eventImages });
        if (!this.eventImages || !this.eventImages.length) {
            this.setDefaultBanner();
        }
    };
    CbLoginBannerService.prototype.removeEventImage = function (index) {
        log('entered removeEventImage');
        this.eventImages.splice(index, 1);
        if (!this.eventImages.length) {
            this.setDefaultBanner();
        }
    };
    CbLoginBannerService.prototype.displayEventImage = function (banner) {
        log('entered displayEventImage', { banner: banner });
        // todo: once we have 1 loaded , hide others
        banner.hide = false;
    };
    CbLoginBannerService.prototype.getAppLogoURL = function () {
        log('entered getAppLogoURL');
        var appName = this.appName.toLowerCase();
        log('app name', { appName: appName });
        var imageURL = '';
        var logoCss = '';
        if (appName.includes('jira')) {
            imageURL = cdn + '/assets/applogos/jira.svg';
            this.withAppLogotoLeft = true;
        }
        else if (appName.includes('confluence')) {
            imageURL = cdn + '/assets/applogos/confluence.svg';
            this.withAppLogotoLeft = true;
        }
        else if (appName.includes('wiki')) {
            imageURL = cdn + '/assets/applogos/confluence.svg';
            // imageURL = cdn + '/assets/applogos/wiki.svg';
            this.withAppLogotoLeft = true;
        }
        else if (appName.includes('jenkins')) {
            imageURL = cdn + '/assets/applogos/jenkins.svg';
            this.withAppLogotoLeft = true;
        }
        else if (appName.includes('nexus3')) {
            imageURL = cdn + '/assets/applogos/nexus3.svg';
            this.withAppLogotoLeft = true;
        }
        else {
            var appImage = CLIENTS_METADATA[this.appName];
            if (appImage && appImage.logoURL) {
                imageURL = cdn + "/assets/applogos/" + appImage.logoURL;
                logoCss = appImage.logoCss || '';
            }
        }
        log(' image ? ', { imageURL: imageURL });
        if (!imageURL) {
            return null;
        }
        return {
            hide: true,
            URL: imageURL,
            logoCss: logoCss,
        };
    };
    CbLoginBannerService.prototype.getProjectLogoURL = function () {
        log('entered getProjectLogoURL', { project: this.project });
        if (!this.project) {
            return [];
        }
        var projectName = this.project.toLowerCase();
        var buckets = [
            "https://lf-master-project-logos-prod.s3.amazonaws.com/" + projectName + ".svg",
            "https://lf-login-platform-assets-production.s3.amazonaws.com/project/" + projectName + ".svg",
        ];
        var images = buckets.map(function (item) {
            return {
                hide: false,
                URL: item,
            };
        });
        log('images ? ', { images: images });
        return images;
    };
    CbLoginBannerService.prototype.setAppLogoURL = function () {
        log('entered setAppLogoURL');
        var appImage = this.getAppLogoURL();
        log('appImage ', { appImage: appImage });
        if (appImage) {
            this.appImages = [appImage];
            this.setBottomImages();
            return;
        }
        this.isAppLogoActive = false;
        this.isProjectLogoActive = true;
        this.setProjectLogo();
    };
    CbLoginBannerService.prototype.removeAppImage = function (index) {
        log('entered removeAppImage', { index: index });
        this.appImages.splice(index, 1);
        if (!this.appImages.length) {
            this.setDefaultBanner();
            return;
        }
    };
    CbLoginBannerService.prototype.displayAppImage = function (image) {
        log('enterd displayAppImage', { image: image });
        image.hide = false;
    };
    CbLoginBannerService.prototype.removePossibleImage = function (index, list) {
        log('entered removePossibleImage', { index: index });
        list.splice(index, 1);
        if (list.length === 0) {
            list = [
                {
                    hide: false,
                    URL: defaultLogoURL,
                },
            ];
        }
        // if (!list.length) {
        //   this.setDefaultBanner();
        //   return;
        // }
    };
    CbLoginBannerService.prototype.displayPossibleImage = function (image, list) {
        log('enterd displayPossibleImage', { image: image });
        // const isDefaultImage = list.findIndex(item => item.URL === image.URL) === list.length;
        var isOneActive = list.find(function (item) { return item.hide === false; });
        log('isOneActive ? ', { isOneActive: isOneActive });
        if (!isOneActive) {
            image.hide = false;
            return;
        }
        var isOneActiveDefault = isOneActive && isOneActive.URL === defaultLogoURL;
        log(' isOneActiveDefault ? ', { isOneActiveDefault: isOneActiveDefault });
        if (isOneActiveDefault) {
            list.forEach(function (item) {
                item.hide = true;
            });
            image.hide = false;
        }
    };
    CbLoginBannerService.prototype.setProjectLogo = function () {
        log('enterd setProjectLogo');
        var projectImages = this.getProjectLogoURL();
        log('projectImages ? ', { projectImages: projectImages });
        this.appImages = projectImages;
        if (!projectImages.length) {
            this.setDefaultBanner();
        }
    };
    CbLoginBannerService.prototype.setMeetingInfo = function (urlParams, fragments, returnToParams) {
        if (returnToParams.fragment) {
            fragments = this.cbAuthURLService.getValuesFromFragments(returnToParams.fragment);
        }
        log('meeting values ? ', { urlParams: urlParams, fragments: fragments, returnToParams: returnToParams });
        this.meetingID = urlParams.meetingID || fragments.meetingID || returnToParams.meetingID;
        if (urlParams.topic || fragments.topic || returnToParams.topic) {
            this.meetingName = urlParams.topic || fragments.topic || returnToParams.topic;
            this.meetingDescription = urlParams.agenda || fragments.agenda || returnToParams.agenda;
            this.meetingVisibility = urlParams.visibility || fragments.visibility || returnToParams.visibility;
            this.meetingType = urlParams.recurring || fragments.recurring || returnToParams.recurring;
            this.meetingDate = urlParams.start_time || fragments.start_time || returnToParams.start_time;
            this.inviteID = urlParams.inviteID || fragments.inviteID || returnToParams.inviteID;
            this.registrantName = urlParams.firstName
                ? urlParams.firstName + " " + urlParams.lastName
                : fragments.firstName
                    ? fragments.firstName + " " + fragments.lastName
                    : returnToParams.firstName + " " + returnToParams.lastName;
        }
        if (urlParams.error || fragments.error || returnToParams.error) {
            this.meetingError = true;
        }
    };
    CbLoginBannerService.ngInjectableDef = i0.defineInjectable({ factory: function CbLoginBannerService_Factory() { return new CbLoginBannerService(i0.inject(i1.CbAuthURLService), i0.inject(i2.CbBannerService)); }, token: CbLoginBannerService, providedIn: "root" });
    return CbLoginBannerService;
}());
export { CbLoginBannerService };
