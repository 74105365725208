import * as tslib_1 from "tslib";
import { LogFactory } from './../../services/cb-debug';
import { OnInit } from '@angular/core';
import { to } from 'await-to-js';
import { CbGroupioService } from '../../services/cb-groupio.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbMyProfileService } from '../../services/cb-myprofile.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
var log = LogFactory('GroupioSelectEmailSendComponent');
var GroupioSelectEmailSendComponent = /** @class */ (function () {
    function GroupioSelectEmailSendComponent(cbWebApi, cbAuthURLService, cbGroupioService, cbMyProfileService, cbFullstoryService) {
        this.cbWebApi = cbWebApi;
        this.cbAuthURLService = cbAuthURLService;
        this.cbGroupioService = cbGroupioService;
        this.cbMyProfileService = cbMyProfileService;
        this.cbFullstoryService = cbFullstoryService;
        this.isLoading = false;
        this.emailSent = false;
        this.errorMessage = '';
        this.email = '';
    }
    GroupioSelectEmailSendComponent.prototype.ngOnInit = function () {
        var email = this.cbAuthURLService.getAllURLParams().email;
        this.email = email;
        this.cbMyProfileService.displayMyProfileURL();
    };
    GroupioSelectEmailSendComponent.prototype.loadingStart = function () {
        this.isLoading = true;
        this.emailSent = false;
        this.errorMessage = '';
    };
    GroupioSelectEmailSendComponent.prototype.loadingEnd = function () {
        this.isLoading = false;
    };
    GroupioSelectEmailSendComponent.prototype.resendEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, email, token, state, returnTo, payload, errResponse, error, defaultErrorMessage;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loadingStart();
                        _a = this.cbAuthURLService.getAllURLParams(), email = _a.email, token = _a.token, state = _a.state, returnTo = _a.returnTo;
                        payload = {
                            email: email,
                            token: token,
                            state: state,
                            returnTo: returnTo,
                        };
                        log('payload', { payload: payload });
                        return [4 /*yield*/, to(this.cbGroupioService.sendVerificationEmail(payload).toPromise())];
                    case 1:
                        errResponse = (_b.sent())[0];
                        if (errResponse) {
                            error = errResponse && errResponse.error && errResponse.error.error;
                            defaultErrorMessage = 'There was a problem. Please try again';
                            this.errorMessage = error.message || defaultErrorMessage;
                            this.cbFullstoryService.fsError(this.errorMessage);
                            this.loadingEnd();
                            return [2 /*return*/];
                        }
                        this.emailSent = true;
                        this.loadingEnd();
                        return [2 /*return*/];
                }
            });
        });
    };
    GroupioSelectEmailSendComponent.prototype.signOut = function () {
        this.cbWebApi.returnToAuthorizePage();
    };
    return GroupioSelectEmailSendComponent;
}());
export { GroupioSelectEmailSendComponent };
