import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { to } from 'await-to-js';
import { utcToZonedTime } from 'date-fns-tz';
import { CbRegistrationService } from '../../services/cb-registration.service';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';
import { LogFactory } from '../../services/cb-debug';
var log = LogFactory('CbTopBannerRegistrationComponent', false);
var CbTopBannerRegistrationComponent = /** @class */ (function () {
    function CbTopBannerRegistrationComponent(cbRegistrationService, cbLoginBannerService) {
        this.cbRegistrationService = cbRegistrationService;
        this.cbLoginBannerService = cbLoginBannerService;
        this.type = '';
        this.date = '';
        this.title = '';
        this.description = "";
        this.speakers = "";
        this.zonedDate = undefined;
        this.timezoneFriendlyName = '';
        this.showEllipsis = true;
    }
    CbTopBannerRegistrationComponent.prototype.ngOnInit = function () { };
    CbTopBannerRegistrationComponent.prototype.ngAfterViewInit = function () {
        this.setRegistrationValues();
    };
    CbTopBannerRegistrationComponent.prototype.setRegistrationValues = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var registrationType, id, _a, err, registration, date, timeZone;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        registrationType = this.cbLoginBannerService.registrationType;
                        id = this.cbLoginBannerService.id;
                        return [4 /*yield*/, to(this.cbRegistrationService.GetRegistration(id, registrationType).toPromise())];
                    case 1:
                        _a = _b.sent(), err = _a[0], registration = _a[1];
                        log('err , registration', { err: err, registration: registration });
                        this.type = registrationType;
                        this.title = registration.topic;
                        this.description = registration.agenda;
                        this.date = this.cbRegistrationService.GetTime(registration);
                        this.timezoneFriendlyName = this.cbRegistrationService.GetTimezoneFriendlyName(registration.timezone);
                        date = new Date(this.date);
                        timeZone = registration.timezone;
                        this.zonedDate = utcToZonedTime(date, timeZone);
                        return [2 /*return*/];
                }
            });
        });
    };
    return CbTopBannerRegistrationComponent;
}());
export { CbTopBannerRegistrationComponent };
