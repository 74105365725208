/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/cb-pipes/safeHtml.pipe";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component.ngfactory";
import * as i4 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component";
import * as i5 from "../../shared/cb-ui/cb-alert/cb-alert.component.ngfactory";
import * as i6 from "../../shared/cb-ui/cb-alert/cb-alert.component";
import * as i7 from "@angular/common";
import * as i8 from "./email-verification.component";
import * as i9 from "../../services/cb-webapi";
import * as i10 from "@angular/router";
import * as i11 from "../../services/cb-auth-url.service";
import * as i12 from "../../services/cb-state.service";
import * as i13 from "../../services/cb-backend.service";
import * as i14 from "../../services/cb-groupio.service";
import * as i15 from "../../services/cb-http-errors.service";
import * as i16 from "../../services/cb-fullstory.service";
var styles_EmailVerificationComponent = [];
var RenderType_EmailVerificationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailVerificationComponent, data: {} });
export { RenderType_EmailVerificationComponent as RenderType_EmailVerificationComponent };
function View_EmailVerificationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "actions cb-title-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "primary-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(4, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.pageMessage)); _ck(_v, 3, 0, currVal_1); }); }
export function View_EmailVerificationComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.SafeHtmlPipe, [i2.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "cb-loading-page", [["loadingMessage", "Please wait, we are verifying your email"]], [[8, "hidden", 0]], null, null, i3.View_CbLoadingPageComponent_0, i3.RenderType_CbLoadingPageComponent)), i0.ɵdid(2, 49152, null, 0, i4.CbLoadingPageComponent, [], { loadingMessage: [0, "loadingMessage"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "columns is-flex is-centered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "column is-two-fifths is-four-fifths-mobile"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "cb-alert", [], null, null, null, i5.View_CbAlertComponent_0, i5.RenderType_CbAlertComponent)), i0.ɵdid(7, 49152, null, 0, i6.CbAlertComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(8, 0, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailVerificationComponent_1)), i0.ɵdid(10, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "Please wait, we are verifying your email"; _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.typeMessage; _ck(_v, 7, 0, currVal_3); var currVal_5 = _co.pageMessage; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.isLoading; _ck(_v, 3, 0, currVal_2); var currVal_4 = _co.message; _ck(_v, 8, 0, currVal_4); }); }
export function View_EmailVerificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-email-verification", [], null, null, null, View_EmailVerificationComponent_0, RenderType_EmailVerificationComponent)), i0.ɵdid(1, 114688, null, 0, i8.EmailVerificationComponent, [i9.CbWebApi, i10.Router, i11.CbAuthURLService, i12.CbStateService, i13.CbBackendService, i14.CbGroupioService, i15.CbHttpErrorsService, i16.CbFullstoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailVerificationComponentNgFactory = i0.ɵccf("cb-email-verification", i8.EmailVerificationComponent, View_EmailVerificationComponent_Host_0, {}, {}, []);
export { EmailVerificationComponentNgFactory as EmailVerificationComponentNgFactory };
