import { Injectable } from '@angular/core';
import { CbAuthURLService } from './cb-auth-url.service';
import { CbStorage } from './cb-storage';
import { LogFactory } from './cb-debug';

const log = LogFactory('CbStateService');

const STATE_KEY = 'lf-state';

@Injectable({ providedIn: 'root' })
export class CbStateService {
  constructor(private cbAuthURLService: CbAuthURLService, private cbStorage: CbStorage) {}

  saveExpectedState(state = '') {
    this.cbStorage.save(STATE_KEY, state);
  }

  removeState() {
    this.cbStorage.remove(STATE_KEY);
    return true;
  }

  markStateAsUsed() {
    const state = this.cbAuthURLService.getState();
    this.cbStorage.save(`lf*${state}`, 'true');
    return true;
  }

  wasStatedUsed() {
    log('entered wasStatedUsed');

    const { state } = this.cbAuthURLService.getAllURLParams();
    log(' state ? ', { state });

    return this.cbStorage.get(`lf*${state}`);
  }

  // @info: Auth0 redirection feature requires, user continues using the same Browser
  // due some cookies internal validations,
  // To avoid any auth0 error, we can Save the state and validate it and the end of the User flow
  // by isCurrentStateValid()
  saveCurrentState() {
    log('entered saveCurrentState');

    const { state } = this.cbAuthURLService.getAllURLParams();
    log(' state ? ', { state });
    if (!state) {
      return false;
    }

    // Verify if it was used before
    const used = this.wasStatedUsed();
    log('used? ', { used });
    if (used) {
      return false;
    }

    log('saving saveExpectedState', { state });
    this.saveExpectedState(state);
    return true;
  }
  /**
   * Auth0 Rule is redirecting the user to /process-continue Page
   * which is capturing the one time valid State (this.saveCurrentState())
   * isCurrentStateValid assure that auth0 will be able use the current state
   * Because on error auth0's server (domain eg. linuxfoundation.auth0.com/continue? ...)
   * will display `unauthorized` blank page
   */
  isCurrentStateValid() {
    log('entered isCurrentStateValid');
    const { state } = this.cbAuthURLService.getAllURLParams();
    log('state ', { state });

    if (!state) {
      return false;
    }

    // Verify if it was used before
    const used = this.wasStatedUsed();
    log('used? ', { used });
    if (used) {
      return false;
    }

    const expectedState = this.getExpectedState();
    log('expectedState ', { expectedState });

    const isValid = expectedState === state;
    log('is valid ? ', { expectedState, state, isValid });

    return isValid;
  }

  getExpectedState() {
    return this.cbStorage.get(STATE_KEY);
  }

  removeCurrentState() {
    this.cbStorage.remove(STATE_KEY);
    return true;
  }
}
