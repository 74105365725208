import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CbLoginBannerService } from '../../services/cb-login-banner.service';

@Component({
  selector: 'cb-top-banner',
  templateUrl: 'cb-top-banner.component.html',
  styleUrls: ['./cb-top-banner.component.scss'],
})
export class CbTopBannerComponent implements OnInit {
  @Output() public hideLogin: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public cbLoginBannerService: CbLoginBannerService) {}

  ngOnInit() {}

  public onHideLogin() {
    this.hideLogin.emit(true);
  }
}
