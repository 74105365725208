import { Component, Input } from '@angular/core';

export type AlertType = '' | 'info' | 'warning' | 'error' | 'success';

@Component({
  selector: 'cb-alert',
  templateUrl: './cb-alert.component.html',
  styleUrls: ['./cb-alert.component.scss'],
})
export class CbAlertComponent {
  @Input() type: AlertType = 'info';
}
