<div *ngIf="title" class="to-banner-registration columns is-centered my-1">
  <div class="column pb-0">
    <div class="banner-registration-wrap font-open-sans has-text-white p-15">
      <div class="reg-top-title font-11px has-text-lfgrey-light mb-1 is-uppercase">{{ type }} REGISTRATION</div>
      <div *ngIf="date" class="reg-date font-12px bold is-uppercase">
        {{ zonedDate | date: 'EEEE MMMM d y, h:mm a' }}
        <ng-container *ngIf="timezoneFriendlyName"> in {{ timezoneFriendlyName }} </ng-container>
      </div>
      <div *ngIf="title" class="reg-title font-20px py-05 bold-600">
        {{ title }}
      </div>
      <ng-container *ngIf="description">
        <div
          *ngIf="showEllipsis; else noEllipsis"
          class="reg-description font-14px"
          style="height: 3rem;"
          ellipsis="... Show more"
          #ell="ellipsis"
          (ellipsis-click-more)="showEllipsis = false"
          [ellipsis-content]="description"
        ></div>
        <ng-template #noEllipsis>
          <div class="reg-description font-14px">
            {{ description }}
            <a (click)="showEllipsis = true" class="ngx-ellipsis-more">
              Show less
            </a>
          </div>
        </ng-template>
      </ng-container>
      <div *ngIf="speakers" class="reg-speakers font-16px py-1">
        {{ speakers }}
      </div>
    </div>
  </div>
</div>
