<div [hidden]="formLoading">
  <div class="columns is-flex is-centered">
    <div class="column is-6">
      <cb-alert *ngIf="formSubmitted && resetPasswordFailed" type="error">
        {{ failedMessage }}
      </cb-alert>

      <div class="columns">
        <div class="column">
          <div class="is-capitalized has-text-lfblack font-18px bold has-text-centered">
            Forgot your password?
          </div>
        </div>
      </div>

      <form [formGroup]="form">
        <div class="cb-title-group">
          <div class="secondary-text">
            Enter your username or email address and we will send your a link to reset your password.
          </div>
        </div>
        <cb-input
          autofocus
          iconName="user"
          label="Email or Username"
          formControlName="usernameEmail"
          [errors]="fieldErrors"
        ></cb-input>

        <div *ngIf="errorAccountIncomplete" class="cb-separator-2rem"></div>

        <div *ngIf="errorAccountIncomplete" class="has-text-centered has-text-danger">
          You can't reset your password because you haven't created an LFID. <br />
          Please <a (click)="finishRegistration()">Click here</a> to create your LF username.
        </div>

        <div class="cb-separator-2rem"></div>
        <div class="actions">
          <cb-button
            buttonType="submit"
            [isLoading]="!captchaToken"
            type="is-info px-3"
            [disabled]="!form.valid || !captchaToken"
            (click)="submit()"
          >
            Reset Password
          </cb-button>

          <a class="cb-link gray cancel" cbNavigateTo="/cb/login">Back</a>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="formLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>
