import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationAlertData } from '../../../services/confirmation-alert-data';
import { CbLoginConfig } from '../../../services/cb-login.config';
import { CbWebApi } from 'src/app/lib/services/cb-webapi';

const NO_ACTION = 0;
const SUCCESS = 1;
const ERROR = -1;

@Component({
  selector: 'cb-confirmation',
  templateUrl: './cb-confirmation.component.html',
})
export class CbConfirmationComponent {
  @Input() headerTitle = 'header title';
  @Input() confirmationTitle = 'Confirmation title';
  @Input() confirmationMessage = 'Confirmation message';
  @Input() buttonText = '';
  @Input() linkText = 'Link text';
  @Input() linkRouterPath = '/cb/login';
  @Input() alertStatus = NO_ACTION;
  @Input() alertMessage = '';
  @Input() showContent = true;
  @Input() linkRedirect = false;
  @Input() showHeader = true;
  @Input() displayCancelLink = false;
  @Input() cancelLinkText = 'cancel';
  @Input() showReturnLink = true;

  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();

  NO_ACTION: number;
  SUCCESS: number;
  ERROR: number;
  isSsoStaticApp = false;

  constructor(
    public confirmationAlertData: ConfirmationAlertData,
    private cbWebApi: CbWebApi,
    private loginConfig: CbLoginConfig
  ) {
    this.isSsoStaticApp = this.loginConfig.cbSsoStaticApp;
    this.NO_ACTION = NO_ACTION;
    this.SUCCESS = SUCCESS;
    this.ERROR = ERROR;
  }

  getRoutePath() {
    return this.linkRouterPath;
  }

  actionButtonClicked() {
    this.confirmationAlertData.resendVerificationStatus = this.NO_ACTION;
    this.buttonClicked.emit();
  }

  cancel() {
    this.cbWebApi.returnToAuthorizePage();
    return;
  }
}

export const ALERT_STATUS = {
  NO_ACTION,
  SUCCESS,
  ERROR,
};
