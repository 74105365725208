import { to } from 'await-to-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { AuthError } from '../../models/cb-auth.models';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbBackendService } from '../../services/cb-backend.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { CbSocialLoginService } from '../../services/cb-social-login.service';
import { CbWebApi } from '../../services/cb-webapi';
import { regexPattern } from '../../shared/custom-validations/custom-validations';
import { SocialUsernameComponentContent } from './social-username-content.component';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './social-username.component.html',
  styleUrls: ['./social-username.component.scss'],
})
export class SocialUsernameComponent implements OnInit, OnDestroy {
  form: FormGroup;
  username: FormControl;
  destroy$ = new Subject();
  formLoading = false;
  error: AuthError | boolean = false;
  fragment: any = null;
  errorMessage = 'An error occurred!';

  pageContent = new SocialUsernameComponentContent();
  ssoStaticParamsFromAuth0Rule: any;
  intervalID = null;
  loadingMessage = 'We are processing your request, please wait';
  isLocal = environment.isLocal;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cbSocialLoginService: CbSocialLoginService,
    private cbWebApi: CbWebApi,
    private cbAuthURLService: CbAuthURLService,
    private cbFullstoryService: CbFullstoryService,
    private cbBackendService: CbBackendService
  ) {}

  ngOnInit(): void {
    this.createForm();

    if (this.isLocal) {
      // INFO: avoid validate auth0 redirection state in development
      return;
    }

    this.cbWebApi.verifyUserCanContinue();
  }

  createForm() {
    this.form = this.fb.group({
      username: [
        '',
        [
          Validators.required,
          Validators.pattern(regexPattern.username),
          Validators.minLength(3),
          Validators.maxLength(60),
        ],
      ],
    });
    this.username = this.form.get('username') as FormControl;

    this.username.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
      const lowercasevalue = (value && value.toLowerCase()) || '';
      if (lowercasevalue !== value) {
        this.username.setValue(lowercasevalue);
      }
    });
  }

  getReturnValuefromAuth0Rule() {
    const dataFromURL = this.route.snapshot.fragment;
    const [, returnValue] = dataFromURL.split('&return=');
    return returnValue;
  }

  validateTokenAndState() {
    if (this.isLocal) {
      // INFO: avoid validate auth0 redirection state in development
      // we can use this to validate Error messages
      return {
        token: '',
        state: '',
        validSub: '',
      };
    }

    const { token, state } = this.cbAuthURLService.getAllURLParams();

    const validSub = this.cbSocialLoginService.getSubFromToken(token);
    if (!validSub) {
      this.cbWebApi.goToErrorPage('Invalid_token');
      return;
    }

    return { token, state, validSub };
  }

  submit() {
    this.formLoading = true;
    this.error = false;

    const { token, state, validSub } = this.validateTokenAndState();

    if (!this.username.value) {
      this.handleFormErrors('username');
      return;
    }

    if (!this.form.valid) {
      return;
    }

    const body = { username: this.username.value, userId: validSub, token };

    this.cbSocialLoginService
      .createUserWithUsername(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          if (response.error) {
            return this.handleError(response.error.error);
          }
          this.cbFullstoryService.sendEvent('create_account', { username: this.username.value });
          this.cbFullstoryService.sendEvent('successfully_authenticated', { username: this.username.value });
          this.loadingMessage = 'We are setting up your profile. Please wait.';
          this.startPollingUser(response.email, response.newToken, state);
        },
        (error) => this.handleError(error.error.error)
      );
  }

  async startPollingUser(email, token, state, counter = 0) {
    counter++;
    const maxIntents = 30;
    const [err, response] = await to(this.cbBackendService.getUser(email, token).toPromise());
    const userExists = (response && response.data) || false;
    if (userExists || counter > maxIntents || err) {
      this.redirectToAuth0AppOnSuccess(token, state);
      return;
    }
    setTimeout(() => {
      this.startPollingUser(email, token, state, counter);
    }, 1000);
  }

  redirectToAuth0AppOnSuccess(token, state) {
    const extras = {
      isSignup: true,
    };

    setTimeout(() => {
      this.cbWebApi.continueToAuth0(token, state, extras);
    }, 1000);
  }

  cancel() {
    this.cbWebApi.returnToAuthorizePage();
    return;
  }

  clearErrors() {
    this.error = false;
  }

  handleError(error: Error) {
    this.formLoading = false;
    this.error = true;
    this.errorMessage = (error && error.message) || this.pageContent.errorMessages['default'];

    const emailTaken = 'email already taken';
    if (this.errorMessage.toLowerCase().includes(emailTaken)) {
      this.handleEmailTakenError();
      return;
    }

    return false;
  }

  // INFO: Validate if email is already taken by another LFID
  handleEmailTakenError() {
    this.errorMessage = this.pageContent.errorMessages['emailTaken'];

    this.form.disable();
  }

  handleFormErrors(type) {
    this.error = true;
    this.formLoading = false;
    this.errorMessage = this.pageContent.errorMessages[type];
    return false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
