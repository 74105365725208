import { stage } from './cb-envars';
// const stage = STAGE || 'development';
const enableEnvs = ['development', 'dev'];
const IS_DEBUG_ENABLED = enableEnvs.includes(stage);

export function debug(text, value: any = '', prefix = '') {
  if (!IS_DEBUG_ENABLED) {
    return;
  }

  if (typeof text !== 'string') {
    value = text;
    text = '(notext)';
  }

  text = `(${prefix}) ${text}`;

  const date = new Date().toISOString();

  if (!value) {
    console.log('... ' + text);
    return;
  }
  let logValue = '';
  logValue = JSON.stringify(value, null, 2);
  // logValue = JSON.parse(JSON.stringify(value));

  if (value instanceof Error) {
    console.log(text, value);
    return;
  }
  if (value.error instanceof Error) {
    console.log(text, value.error);
    return;
  }
  if (value.err instanceof Error) {
    console.log(text, value.err);
    return;
  }

  console.group('%c' + date + '-' + text, 'color: yellow');
  console.log('... ' + text, logValue);
  console.groupEnd();
}

export const LogFactory = (prefix, enable = true) => (text: any = '', value: any = '') => {
  if (!enable) {
    return;
  }
  return debug(text, value, prefix);
};

export const info = (text: any, value: any = '', prefix = '') => debug(text, value, prefix);
