import { Component, OnInit } from '@angular/core';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';

import { LogFactory } from '../../services/cb-debug';
const log = LogFactory('CbTopBannerCventComponent', false);
@Component({
  selector: 'cb-top-banner-cvent',
  templateUrl: 'cb-top-banner-cvent.component.html',
})
export class CbTopBannerCventComponent implements OnInit {
  constructor(public cbLoginBannerService: CbLoginBannerService) {}

  ngOnInit() {
    log('entered nginint');
  }

  removeBanner(index) {
    this.cbLoginBannerService.removeEventImage(index);
  }

  displayBanner(banner: any) {
    this.cbLoginBannerService.displayEventImage(banner);
  }
}
