import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { CbLoginConfig } from './cb-login.config';
import { CbAuthURLService } from './cb-auth-url.service';
import { stringify } from 'querystring';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./cb-login.config";
import * as i4 from "./cb-auth-url.service";
var log = LogFactory('CbGroupioService');
var CbGroupioService = /** @class */ (function () {
    function CbGroupioService(httpService, http, loginConfig, cbAuthURLService) {
        this.httpService = httpService;
        this.http = http;
        this.loginConfig = loginConfig;
        this.cbAuthURLService = cbAuthURLService;
        this.baseRoute = this.loginConfig.cbBackend + "/user";
    }
    CbGroupioService.prototype.getGroupID = function () {
        var queryParams = this.cbAuthURLService.getQueryparams() || {};
        var id = queryParams.group_id;
        if (id) {
            return id;
        }
        // *info: sso static page
        var group_id = (this.cbAuthURLService.getReturnToQueryParams() || {}).group_id;
        return group_id;
    };
    CbGroupioService.prototype.isLogin = function () {
        if (!this.getGroupID()) {
            return false;
        }
        var queryParams = this.cbAuthURLService.getQueryparams();
        var groupAccessType = queryParams.group_access_type;
        if (groupAccessType === 'login') {
            return true;
        }
        return false;
    };
    CbGroupioService.prototype.isJoin = function () {
        if (!this.getGroupID()) {
            return false;
        }
        var queryParams = this.cbAuthURLService.getQueryparams();
        var groupAccessType = queryParams.group_access_type;
        if (groupAccessType === 'register') {
            return true;
        }
        return false;
    };
    CbGroupioService.prototype.getPageParentGroupTitle = function () {
        if (!this.getGroupID()) {
            return null;
        }
        return this.getParentTitleForGroupio();
    };
    CbGroupioService.prototype.getGroupSubGroupNames = function () {
        var queryParams = this.cbAuthURLService.getQueryparams();
        var groupName = queryParams.group_name;
        if (groupName) {
            return groupName.split('+');
        }
        // *info sso static page
        var group_name = this.cbAuthURLService.getReturnToQueryParams().group_name;
        return group_name ? group_name.split('+') : [];
    };
    CbGroupioService.prototype.getParentTitleForGroupio = function () {
        var parentGroupName = this.getGroupSubGroupNames()[0];
        return parentGroupName;
    };
    CbGroupioService.prototype.getPageTitle = function () {
        if (!this.getGroupID()) {
            return null;
        }
        var _a = this.getGroupSubGroupNames(), subGroupName = _a[1];
        return subGroupName || 'Main';
    };
    CbGroupioService.prototype.groupTitleTemplate = function (type, groupName) {
        if (type === void 0) { type = 'Log In'; }
        if (groupName === void 0) { groupName = ''; }
        return "<div class=\"group-title\">" + type + " to <span class=\"group-name\">" + groupName + "</span> group</div>";
    };
    CbGroupioService.prototype.getPageTitleForLogin = function () {
        var _a = this.getGroupSubGroupNames(), subGroupName = _a[1];
        if (!subGroupName) {
            return "Main group";
        }
        return this.groupTitleTemplate('Log In', subGroupName);
    };
    CbGroupioService.prototype.getPageTitleForJoin = function () {
        var _a = this.getGroupSubGroupNames(), subGroupName = _a[1];
        if (!subGroupName) {
            return "Join to Main Group";
        }
        return this.groupTitleTemplate('Join', subGroupName);
    };
    CbGroupioService.prototype.returnToGroupPage = function () {
        var groupURL = this.cbAuthURLService.getGroupURL();
        if (!groupURL) {
            return false;
        }
        window.location.href = groupURL;
        return true;
    };
    CbGroupioService.prototype.ContinueWIthNewEmail = function (ruleToken, email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subscriber, invite;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        log('ContinueGroupsIOflowWithEmail', { email: email });
                        return [4 /*yield*/, this.GetEmailSubscription(ruleToken, email).toPromise()];
                    case 1:
                        subscriber = _a.sent();
                        log('subscriber ?', { subscriber: subscriber });
                        if (!subscriber) return [3 /*break*/, 3];
                        // @info : save email and continue login
                        return [4 /*yield*/, this.AddUserEmail(ruleToken, email).toPromise()];
                    case 2:
                        // @info : save email and continue login
                        _a.sent();
                        return [2 /*return*/, 'continue'];
                    case 3: return [4 /*yield*/, this.InviteEmail(ruleToken, email).toPromise()];
                    case 4:
                        invite = _a.sent();
                        log('invite ?', { invite: invite });
                        return [2 /*return*/, 'invite'];
                }
            });
        });
    };
    CbGroupioService.prototype.sendVerificationEmail = function (payload) {
        var route = this.baseRoute + "/send-verify-email-link";
        var requestBody = tslib_1.__assign({}, payload, { type: 'groups_io' });
        return this.httpService.post(route, { requestBody: requestBody });
    };
    CbGroupioService.prototype.GetEmailSubscription = function (ruleToken, email) {
        var q = stringify({ email: email, token: ruleToken });
        var route = this.baseRoute + "/groups/email-subscription?" + q;
        return this.http.get(route).pipe(catchError(function (response) { return throwError(response.error); }));
    };
    CbGroupioService.prototype.GetUserSubscriptions = function (ruleToken) {
        var q = stringify({ token: ruleToken });
        var route = this.baseRoute + "/groups/user-subscriptions?" + q;
        return this.http.get(route).pipe(catchError(function (response) { return throwError(response.error); }));
    };
    CbGroupioService.prototype.GetUserEmails = function (ruleToken) {
        var q = stringify({ token: ruleToken });
        var route = this.baseRoute + "/groups/user-emails?" + q;
        return this.http.get(route).pipe(catchError(function (response) { return throwError(response.error); }));
    };
    CbGroupioService.prototype.InviteEmail = function (ruleToken, email) {
        var payload = {
            token: ruleToken,
            email: email,
        };
        var URL = this.baseRoute + "/groups/invite";
        return this.http.post(URL, payload);
    };
    CbGroupioService.prototype.AddUserEmail = function (ruleToken, email) {
        var payload = {
            token: ruleToken,
            email: email,
        };
        var URL = this.baseRoute + "/groups/add-user-email";
        return this.http.post(URL, payload);
    };
    CbGroupioService.ngInjectableDef = i0.defineInjectable({ factory: function CbGroupioService_Factory() { return new CbGroupioService(i0.inject(i1.HttpService), i0.inject(i2.HttpClient), i0.inject(i3.CbLoginConfig), i0.inject(i4.CbAuthURLService)); }, token: CbGroupioService, providedIn: "root" });
    return CbGroupioService;
}());
export { CbGroupioService };
