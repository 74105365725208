/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../cb-alert/cb-alert.component.ngfactory";
import * as i2 from "../cb-alert/cb-alert.component";
import * as i3 from "../cb-button/cb-button.component.ngfactory";
import * as i4 from "../cb-button/cb-button.component";
import * as i5 from "../../../directives/cb-navigate-to.directive";
import * as i6 from "../../../services/cb-auth.service";
import * as i7 from "@angular/common";
import * as i8 from "../cb-warning-page/cb-warning-page.component.ngfactory";
import * as i9 from "../cb-warning-page/cb-warning-page.component";
import * as i10 from "../../cb-pipes/safeHtml.pipe";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./cb-confirmation.component";
import * as i13 from "../../../services/confirmation-alert-data";
import * as i14 from "../../../services/cb-webapi";
import * as i15 from "../../../services/cb-login.config";
var styles_CbConfirmationComponent = [];
var RenderType_CbConfirmationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CbConfirmationComponent, data: {} });
export { RenderType_CbConfirmationComponent as RenderType_CbConfirmationComponent };
function View_CbConfirmationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "cb-alert", [["type", "success"]], null, null, null, i1.View_CbAlertComponent_0, i1.RenderType_CbAlertComponent)), i0.ɵdid(1, 49152, null, 0, i2.CbAlertComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "success"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.confirmationAlertData.resendVerificationMessage; _ck(_v, 2, 0, currVal_1); }); }
function View_CbConfirmationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "cb-alert", [["type", "error"]], null, null, null, i1.View_CbAlertComponent_0, i1.RenderType_CbAlertComponent)), i0.ɵdid(1, 49152, null, 0, i2.CbAlertComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = "error"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.confirmationAlertData.resendVerificationMessage; _ck(_v, 2, 0, currVal_1); }); }
function View_CbConfirmationComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "cb-button", [["type", "is-info px-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.actionButtonClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CbButtonComponent_0, i3.RenderType_CbButtonComponent)), i0.ɵdid(1, 49152, null, 0, i4.CbButtonComponent, [], { type: [0, "type"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "is-info px-3"; var currVal_1 = _co.confirmationAlertData.isLoading; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonText; _ck(_v, 2, 0, currVal_2); }); }
function View_CbConfirmationComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "cb-link gray cancel nolinkredirect"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i5.CbNavigateToDirective, [i0.ElementRef, i6.CbAuthService], { cbNavigateTo: [0, "cbNavigateTo"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.getRoutePath(), ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.linkText; _ck(_v, 2, 0, currVal_1); }); }
function View_CbConfirmationComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "cb-link gray cancel linkredirect"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.getRoutePath(), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.linkText; _ck(_v, 1, 0, currVal_1); }); }
function View_CbConfirmationComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_7)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_8)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.linkRedirect; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.linkRedirect; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CbConfirmationComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "cb-title-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "primary-text"], ["data-cy", "page-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "secondary-text"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "cb-separator-2rem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_5)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_6)), i0.ɵdid(11, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.buttonText; _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.showReturnLink && !_co.isSsoStaticApp); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmationTitle; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 0), _co.confirmationMessage)); _ck(_v, 4, 0, currVal_1); }); }
function View_CbConfirmationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_2)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_3)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_4)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.confirmationAlertData.resendVerificationStatus == _co.SUCCESS); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.confirmationAlertData.resendVerificationStatus == _co.ERROR); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showContent; _ck(_v, 6, 0, currVal_2); }, null); }
function View_CbConfirmationComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-warning-page", [], null, null, null, i8.View_CbWarningPageComponent_0, i8.RenderType_CbWarningPageComponent)), i0.ɵdid(1, 49152, null, 0, i9.CbWarningPageComponent, [], { warningMessage: [0, "warningMessage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.confirmationAlertData.resendVerificationMessage, ""); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CbConfirmationComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i10.SafeHtmlPipe, [i11.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "columns is-flex-mobile is-centered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "column is-6"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbConfirmationComponent_9)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showContent; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.showContent; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_CbConfirmationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-confirmation", [], null, null, null, View_CbConfirmationComponent_0, RenderType_CbConfirmationComponent)), i0.ɵdid(1, 49152, null, 0, i12.CbConfirmationComponent, [i13.ConfirmationAlertData, i14.CbWebApi, i15.CbLoginConfig], null, null)], null, null); }
var CbConfirmationComponentNgFactory = i0.ɵccf("cb-confirmation", i12.CbConfirmationComponent, View_CbConfirmationComponent_Host_0, { headerTitle: "headerTitle", confirmationTitle: "confirmationTitle", confirmationMessage: "confirmationMessage", buttonText: "buttonText", linkText: "linkText", linkRouterPath: "linkRouterPath", alertStatus: "alertStatus", alertMessage: "alertMessage", showContent: "showContent", linkRedirect: "linkRedirect", showHeader: "showHeader", displayCancelLink: "displayCancelLink", cancelLinkText: "cancelLinkText", showReturnLink: "showReturnLink" }, { buttonClicked: "buttonClicked" }, []);
export { CbConfirmationComponentNgFactory as CbConfirmationComponentNgFactory };
