import { Component, Input } from '@angular/core';

@Component({
  selector: 'cb-loading-page',
  templateUrl: './cb-loading-page.component.html',
  styleUrls: ['./cb-loading-page.component.scss']
})

export class CbLoadingPageComponent {
  @Input() loadingMessage = 'Loading ...';
}
