/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-top-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../cb-top-banner-zoom-banner/cb-top-banner-zoom-banner.component.ngfactory";
import * as i3 from "../cb-top-banner-zoom-banner/cb-top-banner-zoom-banner.component";
import * as i4 from "../../services/cb-login-banner.service";
import * as i5 from "../cb-top-banner-cvent/cb-top-banner-cvent.component.ngfactory";
import * as i6 from "../cb-top-banner-cvent/cb-top-banner-cvent.component";
import * as i7 from "../cb-top-banner-app/cb-top-banner-app.component.ngfactory";
import * as i8 from "../cb-top-banner-app/cb-top-banner-app.component";
import * as i9 from "../lfx-meetings/lfx-meetings.component.ngfactory";
import * as i10 from "../lfx-meetings/lfx-meetings.component";
import * as i11 from "../../services/cb-auth.service";
import * as i12 from "@angular/common";
import * as i13 from "./cb-top-banner.component";
var styles_CbTopBannerComponent = [i0.styles];
var RenderType_CbTopBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbTopBannerComponent, data: {} });
export { RenderType_CbTopBannerComponent as RenderType_CbTopBannerComponent };
function View_CbTopBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-top-banner-zoom-banner", [], null, null, null, i2.View_CbTopBannerZoomBannerComponent_0, i2.RenderType_CbTopBannerZoomBannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.CbTopBannerZoomBannerComponent, [i4.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CbTopBannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-top-banner-cvent", [], null, null, null, i5.View_CbTopBannerCventComponent_0, i5.RenderType_CbTopBannerCventComponent)), i1.ɵdid(1, 114688, null, 0, i6.CbTopBannerCventComponent, [i4.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CbTopBannerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-top-banner-app", [], null, null, null, i7.View_CbTopBannerAppComponent_0, i7.RenderType_CbTopBannerAppComponent)), i1.ɵdid(1, 114688, null, 0, i8.CbTopBannerAppComponent, [i4.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CbTopBannerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lfx-meetings", [], null, [[null, "hideLogin"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hideLogin" === en)) {
        var pd_0 = (_co.onHideLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_LFXMeetingsComponent_0, i9.RenderType_LFXMeetingsComponent)), i1.ɵdid(1, 114688, null, 0, i10.LFXMeetingsComponent, [i4.CbLoginBannerService, i11.CbAuthService], null, { hideLogin: "hideLogin" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CbTopBannerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "top-banner-default columns is-centered my-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "column has-text-centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "figure", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/thelinuxfoundation-color.svg"], ["style", "width: 300px;"]], null, null, null, null, null))], null, null); }
export function View_CbTopBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerComponent_2)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerComponent_3)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerComponent_4)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerComponent_5)), i1.ɵdid(9, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cbLoginBannerService.isRegistration; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.cbLoginBannerService.eventImages.length; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.cbLoginBannerService.appImages.length; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.cbLoginBannerService.isMeeting; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.cbLoginBannerService.displayDefault; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_CbTopBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-top-banner", [], null, null, null, View_CbTopBannerComponent_0, RenderType_CbTopBannerComponent)), i1.ɵdid(1, 114688, null, 0, i13.CbTopBannerComponent, [i4.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbTopBannerComponentNgFactory = i1.ɵccf("cb-top-banner", i13.CbTopBannerComponent, View_CbTopBannerComponent_Host_0, {}, { hideLogin: "hideLogin" }, []);
export { CbTopBannerComponentNgFactory as CbTopBannerComponentNgFactory };
