import { EventEmitter, OnInit } from '@angular/core';
import { CbAuthService } from '../../services/cb-auth.service';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';
var LFXMeetingsComponent = /** @class */ (function () {
    function LFXMeetingsComponent(cbLoginBannerService, authService) {
        this.cbLoginBannerService = cbLoginBannerService;
        this.authService = authService;
        this.hideLogin = new EventEmitter(true);
        this.meetingName = '';
        this.meetingDescription = '';
        this.meetingVisibility = '';
        this.meetingID = '';
        this.meetingType = '';
        this.meetingDate = '';
        this.inviteID = '';
        this.registrantName = '';
        this.notCurrentUser = false;
        this.meetingError = false;
        this.meetingName = this.cbLoginBannerService.meetingName;
        this.meetingDescription = this.cbLoginBannerService.meetingDescription;
        this.meetingVisibility = this.cbLoginBannerService.meetingVisibility;
        this.meetingID = this.cbLoginBannerService.meetingID;
        this.meetingType = this.cbLoginBannerService.meetingType;
        this.meetingDate = this.cbLoginBannerService.meetingDate;
        this.inviteID = this.cbLoginBannerService.inviteID;
        this.registrantName = this.cbLoginBannerService.registrantName;
        this.meetingError = this.cbLoginBannerService.meetingError;
    }
    LFXMeetingsComponent.prototype.ngOnInit = function () {
        if (!this.meetingError && this.meetingVisibility === 'private' && !this.inviteID) {
            this.notCurrentUser = true;
            this.hideLogin.emit(true);
        }
    };
    Object.defineProperty(LFXMeetingsComponent.prototype, "registrantFirstName", {
        get: function () {
            return this.registrantName && this.registrantName.split(' ')[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LFXMeetingsComponent.prototype, "showPersonalizedInviteInfo", {
        get: function () {
            return this.inviteID && !this.notCurrentUser && this.registrantName !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LFXMeetingsComponent.prototype, "showLoginText", {
        get: function () {
            return !this.inviteID && this.meetingVisibility === 'public' && this.meetingType !== 'Past Meeting';
        },
        enumerable: true,
        configurable: true
    });
    LFXMeetingsComponent.prototype.changeCurrentUser = function () {
        // If meeting visibility is private, hide the login section
        if (this.meetingVisibility === 'private') {
            this.notCurrentUser = true;
            this.hideLogin.emit(true);
        }
        else {
            // If the event is public, redirect the user back to the standalone app,
            // which will redirect them back here without the invite info
            var params = new URLSearchParams(window.location.search.slice(1));
            var redirectURI = params.get('redirect_uri').replace('/auth', '');
            window.location.href = redirectURI + "/meeting/" + this.meetingID;
        }
    };
    return LFXMeetingsComponent;
}());
export { LFXMeetingsComponent };
