import { Injectable } from '@angular/core';
import { CbLoginConfig } from './cb-login.config';
import { CbAuthService, ClientMetadata, Dict } from './cb-auth.service';
import { ActivatedRoute } from '@angular/router';
import { parse } from 'querystring';
// import { LogFactory } from './cb-debug';

// Possible values in URL (in queryParams or Fragments)
const URL_PARAMS = {
  displayBanner: 'display_banner',
  token: 'token',
  email: 'email',
  state: 'state',
  ['return']: 'return',
  clientId: 'clientId',
  clientName: 'clientName',
  logo: 'logo',
};

const patterns = {
  URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
};

const COMMUNITY_BRIDGE_TITLE = 'CommunityBridge';
const COMMUNITY_BRIDGE_FAVICON = '/assets/favicons/cb-favicon.png';
const COMMUNITY_BRIDGE_DEFAULT_LOGO_BLUE = '/assets/cb-assets/cb-logo-blue.svg';
const COMMUNITY_BRIDGE_DEFAULT_LOGO_WHITE = '/assets/cb-assets/cb-logo-white.svg';

const LINUX_FOUNDATION_TITLE = 'The Linux Foundation';
const LINUX_FOUNDATION_FAVICON = '/assets/favicons/lf-favicon.png';
const LINUX_FOUNDATION_DEFAULT_LOGO_BLUE = '/assets/cb-assets/lf-logo-blue.png';
const LINUX_FOUNDATION_DEFAULT_LOGO_WHITE = '/assets/cb-assets/lf-logo-blue.png';
// const log = LogFactory('CbClientParametersService');

@Injectable({ providedIn: 'root' })
export class CbClientParametersService {
  clientId = '';
  clientName = COMMUNITY_BRIDGE_TITLE;
  clientLogo = this.cbLoginConfig.cbDefaultLogo;
  clientLogoLink = '';
  isSidebarDisabled: any = false;
  bradingUpdated = false;
  isCbApp = false;

  constructor(
    private cbAuthService: CbAuthService,
    private route: ActivatedRoute,
    private cbLoginConfig: CbLoginConfig
  ) {
    // if (this.cbLoginConfig.cbAuthCentralizedLogin) {
    //   this.setClientBrandFromAuth0Config();
    // }
    // if (this.cbLoginConfig.cbSsoStaticApp) {
    //   this.clientBrandInSsoStaticApp();
    // }
    // if (this.cbAuthService.isErrorFlow) {
    //   this.clientLogoLink = '';
    // }
  }

  clientBrandInSsoStaticApp() {
    // log('entered clientBrandInSsoStaticApp');
    // From Fragments:
    // Send create account email Flow
    // Add social username flow
    this.setValuesFromFragments();
    // From queryParams:
    // Verify Account flow
    // change Password flow
    this.setValuesFromQueryParams();
  }

  setClientBrandFromAuth0Config() {
    // log('entered setClientBrandFromAuth0Config');

    const { clientID = '', dict = {} as Dict, clientMetadata = {} as ClientMetadata } =
      this.cbAuthService.getConfigFromAuth0Environment() || {};

    const { title = '', logo = '' } = dict.signin;

    this.setAppBranding(clientMetadata);

    this.setClientParams({
      clientId: clientID,
      clientName: clientMetadata.app_name || title,
      clientLogo: clientMetadata.app_logo || logo,
      clientLogoLink: this.cbAuthService.getLogoLinkInAuth0Page(),
    });
  }

  getThemeByClient(clientName): ClientMetadata {
    // log('entered getThemeByClient');

    const themeKey = '_themes';
    const themes = (window && window[themeKey]) || null;
    if (!clientName || !themes || !themes[clientName]) {
      return {} as ClientMetadata;
    }

    return themes[clientName];
  }

  setValuesFromFragments() {
    // log('entered setValuesFromFragments');
    const fragmentFromAngular = this.route.snapshot.fragment || '';
    if (!fragmentFromAngular) {
      return false;
    }

    const [params, returnURL] = fragmentFromAngular.split(`&${URL_PARAMS.return}=`);
    const clientLogoLink = returnURL ? `https://${this.cbLoginConfig.domain}/${decodeURIComponent(returnURL)}` : '';

    if (!params) {
      return false;
    }

    const fragmentParams = parse(params);
    const clientMetadata = this.getThemeByClient(fragmentParams[URL_PARAMS.clientName]);

    const clientId = (fragmentParams[URL_PARAMS.clientId] as string) || '';
    const clientName = clientMetadata.app_name || (fragmentParams[URL_PARAMS.clientName] as string) || '';
    const clientLogo = clientMetadata.app_logo || (fragmentParams[URL_PARAMS.logo] as string) || '';

    this.setAppBranding(clientMetadata);
    this.setClientParams({ clientId, clientName, clientLogo, clientLogoLink });

    return true;
  }

  setValuesFromQueryParams() {
    // log('entered setValuesFromQueryParams');

    const params = this.route.snapshot.queryParams || '';

    if (!params) {
      return false;
    }

    const returnValue = params[URL_PARAMS.return];
    const clientLogoLink = returnValue ? `https://${this.cbLoginConfig.domain}/${decodeURIComponent(returnValue)}` : '';

    const clientMetadata = this.getThemeByClient(params[URL_PARAMS.clientName]);

    const clientId = (params[URL_PARAMS.clientId] as string) || '';
    const clientName = clientMetadata.app_name || (params[URL_PARAMS.clientName] as string) || '';
    const clientLogo = clientMetadata.app_logo || (params[URL_PARAMS.logo] as string) || '';

    this.setAppBranding(clientMetadata);
    this.setClientParams({ clientId, clientName, clientLogo, clientLogoLink });
  }

  isSmallScreen() {
    const value = window.matchMedia && window.matchMedia('(max-width: 768px)').matches;
    // log('entered isSmallScreen', { value });
    return value;
  }

  setAppBranding(clientMetadata: ClientMetadata) {
    // log('entered setAppBranding', { clientMetadata });

    if (this.bradingUpdated) {
      // log(' ... branding updated');
      return;
    }

    this.bradingUpdated = true;
    // @info: Apps are LF by default - only CB is there are explictly true
    this.isCbApp = clientMetadata.cb_app === true;
    this.isSidebarDisabled = clientMetadata.display_banner !== true;

    if (this.isSmallScreen()) {
      this.isSidebarDisabled = true;
    }

    let clientLogo;
    if (this.isCbApp) {
      clientLogo = this.isSidebarDisabled ? COMMUNITY_BRIDGE_DEFAULT_LOGO_BLUE : COMMUNITY_BRIDGE_DEFAULT_LOGO_WHITE;
    } else {
      clientLogo = this.isSidebarDisabled ? LINUX_FOUNDATION_DEFAULT_LOGO_BLUE : LINUX_FOUNDATION_DEFAULT_LOGO_WHITE;
    }
    this.setClientParams({ clientLogo });

    document.title = this.isCbApp ? COMMUNITY_BRIDGE_TITLE : LINUX_FOUNDATION_TITLE;
    this.setFavicon(this.isCbApp ? COMMUNITY_BRIDGE_FAVICON : LINUX_FOUNDATION_FAVICON);
  }

  setFavicon(src = '') {
    // log('entered setFavicon', { src });

    const link = (document.querySelector("link[rel*='icon']") as any) || (document.createElement('link') as any);
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = this.cbLoginConfig.cbCDN + src;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  setClientParams(config: Partial<CbClientParametersService>) {
    // log('entered setClientParams', { config });

    if (config.clientId) {
      this.clientId = config.clientId;
    }
    if (config.clientName) {
      this.clientName = config.clientName;
    }

    if (config.clientLogo) {
      const hasCDN = patterns.URL.test(config.clientLogo);
      if (hasCDN) {
        this.clientLogo = config.clientLogo;
      } else {
        this.clientLogo = this.cbLoginConfig.cbCDN + config.clientLogo;
      }
    }

    if (config.clientLogoLink) {
      this.clientLogoLink = config.clientLogoLink;
    }
  }
}
