import { CbFullstoryService } from './../../services/cb-fullstory.service';
import { Component, OnInit } from '@angular/core';
import { CbWebApi } from '../../services/cb-webapi';

@Component({
  selector: 'cb-unauthorize',
  templateUrl: './error-email-verification.component.html',
})
export class CbErrorEmailVerificationComponent implements OnInit {
  constructor(private cbWebApi: CbWebApi, private cbFullstoryService: CbFullstoryService) {}

  ngOnInit(): void {
    this.cbFullstoryService.fsError('Error email verification');
  }

  gotoLogin() {
    this.cbWebApi.returnToAuthorizePage();
  }
}
