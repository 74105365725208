/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component.ngfactory";
import * as i2 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component";
import * as i3 from "./process-continue.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/cb-auth-url.service";
import * as i6 from "../../services/cb-state.service";
import * as i7 from "../../services/cb-webapi";
import * as i8 from "../../services/cb-storage";
import * as i9 from "../../services/cb-ga.service";
import * as i10 from "../../services/cb-login-banner.service";
var styles_CbProcessContinueComponent = [];
var RenderType_CbProcessContinueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CbProcessContinueComponent, data: {} });
export { RenderType_CbProcessContinueComponent as RenderType_CbProcessContinueComponent };
export function View_CbProcessContinueComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-loading-page", [["loadingMessage", "Please wait, we are processing your session"]], [[8, "hidden", 0]], null, null, i1.View_CbLoadingPageComponent_0, i1.RenderType_CbLoadingPageComponent)), i0.ɵdid(1, 49152, null, 0, i2.CbLoadingPageComponent, [], { loadingMessage: [0, "loadingMessage"] }, null)], function (_ck, _v) { var currVal_1 = "Please wait, we are processing your session"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 0, 0, currVal_0); }); }
export function View_CbProcessContinueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-process-continue", [], null, null, null, View_CbProcessContinueComponent_0, RenderType_CbProcessContinueComponent)), i0.ɵdid(1, 114688, null, 0, i3.CbProcessContinueComponent, [i4.Router, i5.CbAuthURLService, i6.CbStateService, i7.CbWebApi, i8.CbStorage, i9.CbGaService, i10.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbProcessContinueComponentNgFactory = i0.ɵccf("cb-process-continue", i3.CbProcessContinueComponent, View_CbProcessContinueComponent_Host_0, {}, {}, []);
export { CbProcessContinueComponentNgFactory as CbProcessContinueComponentNgFactory };
