import * as tslib_1 from "tslib";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { to } from 'await-to-js';
import { CbGroupioService } from '../../services/cb-groupio.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbStateService } from '../../services/cb-state.service';
import { CustomValidators } from '../../shared/custom-validations/custom-validations';
import { CbHttpErrorsService } from '../../services/cb-http-errors.service';
import { CbFullstoryService } from './../../services/cb-fullstory.service';
import { LogFactory } from '../../services/cb-debug';
import { CbMyProfileService } from '../../services/cb-myprofile.service';
var log = LogFactory('SelectEmailComponent');
var SelectEmailComponent = /** @class */ (function () {
    function SelectEmailComponent(fb, cbGroupioService, cbWebApi, router, cbAuthURLService, cbStateService, cbHttpErrorsService, cbFullstoryService, cbMyProfileService) {
        this.fb = fb;
        this.cbGroupioService = cbGroupioService;
        this.cbWebApi = cbWebApi;
        this.router = router;
        this.cbAuthURLService = cbAuthURLService;
        this.cbStateService = cbStateService;
        this.cbHttpErrorsService = cbHttpErrorsService;
        this.cbFullstoryService = cbFullstoryService;
        this.cbMyProfileService = cbMyProfileService;
        this.errorMessage = '';
        this.groupName = '';
        this.groupURL = '';
        this.accessType = '';
        this.userEmail = '';
        this.userEmailSubscriptions = [];
        this.userAllowedEmails = [];
        this.isLogin = false;
        this.isJoin = false;
        this.isSubscriber = false;
        this.decodedToken = {};
        this.ruleToken = '';
        this.ruleState = '';
        this.authorizeURL = '';
        this.isLoading = true;
        this.displayPrimaryEmailSection = true;
        this.displayAlternativeSecion = false;
        this.displayNewEmailSection = false;
        this.displayAlternateLink = false;
        this.displayClaimButton = false;
        this.claimMessage = false;
        this.claimType = 'error';
        this.claimURL = '';
        this.claimedEmail = '';
        this.message = {
            errorVerificationEmail: 'There was a problem sending the verification email. Please try again',
            errorSubscriberEndpoint: 'There was a problem checking your email, Please try later.',
            errorSubscriberNotFound: function (email) { return "The email address " + email + " is not currently a member of this group.\n      Please add the correct address to your LFID"; },
        };
        this.destroy$ = new Subject();
    }
    SelectEmailComponent.prototype.formLoadingStart = function () {
        log('entered formLoadingStart');
        this.isLoading = true;
        this.errorMessage = '';
    };
    SelectEmailComponent.prototype.formLoadingEnd = function () {
        log('entered formLoadingEnd');
        this.isLoading = false;
    };
    SelectEmailComponent.prototype.setErrorMessage = function (message) {
        if (message === void 0) { message = ''; }
        this.errorMessage = message;
        this.formLoadingEnd();
    };
    SelectEmailComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allowed, _a, token, state, authorizeURL, _b, groupURL, groupName, groupAccessType, email, username;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.cbMyProfileService.displayMyProfileURL();
                        this.createForm();
                        allowed = this.cbWebApi.verifyUserCanContinue();
                        if (!allowed) {
                            return [2 /*return*/];
                        }
                        _a = this.cbAuthURLService.getAllURLParams(), token = _a.token, state = _a.state, authorizeURL = _a.authorizeURL;
                        _b = this.cbAuthURLService.getDecodedToken(), groupURL = _b.groupURL, groupName = _b.groupName, groupAccessType = _b.groupAccessType, email = _b.email, username = _b.username;
                        this.isLogin = groupAccessType === 'login';
                        this.isJoin = groupAccessType === 'register';
                        this.groupName = groupName;
                        this.groupURL = groupURL;
                        this.userEmail = email;
                        this.ruleToken = token;
                        this.ruleState = state;
                        this.authorizeURL = authorizeURL;
                        return [4 /*yield*/, this.loadPageDependiencies()];
                    case 1:
                        _c.sent();
                        this.cbFullstoryService.identify(username, { email: email, username: username });
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectEmailComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            allowedEmail: '',
            subscribedEmail: '',
            newEmail: ['', [CustomValidators.OptionalEmail]],
        });
        this.allowedEmailControl = this.form.get('allowedEmail');
        this.subscribedEmailControl = this.form.get('subscribedEmail');
        this.newEmailControl = this.form.get('newEmail');
    };
    SelectEmailComponent.prototype.setEmailDropdownListener = function () {
        var _this = this;
        if (this.userEmailSubscriptions.length) {
            this.subscribedEmailControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(function (value) {
                if (value) {
                    _this.useEmailFromDropdown(value);
                }
            });
        }
        if (this.userAllowedEmails.length) {
            this.allowedEmailControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(function (value) {
                if (value) {
                    _this.useEmailFromDropdown(value);
                }
            });
        }
    };
    SelectEmailComponent.prototype.GetPrimaryEmailSubscription = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subscriber;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cbGroupioService.GetEmailSubscription(this.ruleToken, this.userEmail).toPromise()];
                    case 1:
                        subscriber = _a.sent();
                        log('subscriber ? ', { subscriber: subscriber });
                        this.isSubscriber = subscriber ? true : false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectEmailComponent.prototype.getUserSubscriptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err, emails;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, to(this.cbGroupioService.GetUserSubscriptions(this.ruleToken).toPromise())];
                    case 1:
                        _a = _b.sent(), err = _a[0], emails = _a[1];
                        if (err) {
                            log('Error getting emails ', { err: err });
                            this.handlerErrors(err.error);
                            return [2 /*return*/];
                        }
                        this.userEmailSubscriptions = emails || [];
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectEmailComponent.prototype.getUserAllowedEmails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err, emails;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, to(this.cbGroupioService.GetUserEmails(this.ruleToken).toPromise())];
                    case 1:
                        _a = _b.sent(), err = _a[0], emails = _a[1];
                        if (err) {
                            log('Error getting emails ', { err: err });
                            this.handlerErrors(err.error);
                            return [2 /*return*/];
                        }
                        this.userAllowedEmails = emails || [];
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectEmailComponent.prototype.handlerErrors = function (error) {
        log('entered handlerErrors', { error: error });
        if (error.code === 'invalid_token') {
            this.setErrorMessage('Your session is not valid, Please sign out and try again.');
            return;
        }
        this.setErrorMessage('There was a problem. Try again later');
    };
    SelectEmailComponent.prototype.setPrimaryEmailSubscription = function () {
        if (this.userEmailSubscriptions.length === 1 && this.userEmailSubscriptions.includes(this.userEmail)) {
            this.isSubscriber = true;
            this.userEmailSubscriptions = [];
        }
    };
    SelectEmailComponent.prototype.excludeUsedEmails = function (emails) {
        var _this = this;
        return emails.filter(function (email) { return !_this.userEmailSubscriptions.includes(email) && email !== _this.userEmail; });
    };
    SelectEmailComponent.prototype.addPrimaryEmail = function (emails) {
        var collection = emails.slice();
        var addPrimary = this.userEmailSubscriptions.length &&
            !this.userEmailSubscriptions.includes(this.userEmail) &&
            !collection.includes(this.userEmail);
        if (addPrimary) {
            collection.unshift(this.userEmail);
        }
        return collection;
    };
    SelectEmailComponent.prototype.processUserAllowedEmails = function () {
        this.setPrimaryEmailSubscription();
        var emails = this.userAllowedEmails.slice();
        emails = this.excludeUsedEmails(emails);
        emails = this.addPrimaryEmail(emails);
        this.userAllowedEmails = emails.slice();
        this.displayAlternateLink = !!this.userAllowedEmails.length;
    };
    SelectEmailComponent.prototype.loadPageDependiencies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deps, err;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deps = [this.getUserSubscriptions(), this.getUserAllowedEmails()];
                        return [4 /*yield*/, to(Promise.all(deps))];
                    case 1:
                        err = (_a.sent())[0];
                        if (err) {
                            log('err ? ', { err: err });
                            return [2 /*return*/];
                        }
                        this.processUserAllowedEmails();
                        if (this.userEmailSubscriptions.length) {
                            this.displayPrimaryEmailSection = false;
                        }
                        this.setEmailDropdownListener();
                        this.formLoadingEnd();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    SelectEmailComponent.prototype.displayAlternativeEmails = function () {
        this.displayAlternativeSecion = !this.displayAlternativeSecion;
        this.displayNewEmailSection = false;
    };
    SelectEmailComponent.prototype.displayNewEmail = function () {
        this.displayNewEmailSection = !this.displayNewEmailSection;
        this.displayAlternativeSecion = false;
    };
    SelectEmailComponent.prototype.clearEmailsControl = function () {
        if (this.allowedEmailControl.value) {
            this.allowedEmailControl.setValue('');
        }
        if (this.subscribedEmailControl.value) {
            this.subscribedEmailControl.setValue('');
        }
    };
    SelectEmailComponent.prototype.ContinueIFSubscriber = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subscriber;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cbGroupioService.GetEmailSubscription(this.ruleToken, email).toPromise()];
                    case 1:
                        subscriber = _a.sent();
                        log(' subscriber ? ', { subscriber: subscriber });
                        if (subscriber) {
                            this.continue(email);
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    SelectEmailComponent.prototype.InviteEmail = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var invite, queryParams, nextPage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.cbGroupioService.InviteEmail(this.ruleToken, email).toPromise()];
                    case 1:
                        invite = _a.sent();
                        log('Invite ?', { invite: invite });
                        queryParams = {
                            email: email,
                            state: this.ruleState,
                            token: this.ruleToken,
                            groupName: this.groupName,
                            groupURL: this.groupURL,
                            returnTo: this.authorizeURL,
                        };
                        log('queryParams ? ', queryParams);
                        nextPage = "/cb/groupio-confirm-invite";
                        this.router.navigate([nextPage], { queryParams: queryParams, skipLocationChange: false });
                        return [2 /*return*/, true];
                }
            });
        });
    };
    SelectEmailComponent.prototype.ContinueFlowWithEmail = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var succeed, inviteSent;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ContinueIFSubscriber(email)];
                    case 1:
                        succeed = _a.sent();
                        if (succeed) {
                            return [2 /*return*/, true];
                        }
                        return [4 /*yield*/, this.InviteEmail(email)];
                    case 2:
                        inviteSent = _a.sent();
                        if (inviteSent) {
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    SelectEmailComponent.prototype.usePrimaryEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, _a, err, succeed, message;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.userEmail) {
                            log('Error on using primary email');
                            return [2 /*return*/, false];
                        }
                        email = this.userEmail;
                        this.formLoadingStart();
                        return [4 /*yield*/, to(this.ContinueFlowWithEmail(email))];
                    case 1:
                        _a = _b.sent(), err = _a[0], succeed = _a[1];
                        if (err) {
                            message = this.cbHttpErrorsService.GetErrorMessage(err);
                            this.setErrorMessage(message);
                            return [2 /*return*/];
                        }
                        if (succeed) {
                            return [2 /*return*/];
                        }
                        this.setErrorMessage("It wasn't possible to continue with your email " + email + ", try a different Email");
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectEmailComponent.prototype.useEmailFromDropdown = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err, succeed, message;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!email) {
                            log('Error using dropdown emails');
                            return [2 /*return*/, false];
                        }
                        this.formLoadingStart();
                        return [4 /*yield*/, to(this.ContinueFlowWithEmail(email))];
                    case 1:
                        _a = _b.sent(), err = _a[0], succeed = _a[1];
                        if (err) {
                            message = this.GetErrorMessage(err) || 'There was a problem using your email. Try again later';
                            this.setErrorMessage(message);
                            return [2 /*return*/];
                        }
                        if (succeed) {
                            return [2 /*return*/];
                        }
                        this.setErrorMessage("It wasn't possible to continue with your email " + email + ", try a different Email");
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectEmailComponent.prototype.continue = function (selectedEmail) {
        if (selectedEmail === void 0) { selectedEmail = ''; }
        this.formLoadingStart();
        var token = this.cbAuthURLService.getToken();
        var state = this.cbAuthURLService.getState();
        var extraParams = {
            selectedEmail: selectedEmail,
        };
        this.cbFullstoryService.sendEvent('successfully_authenticated', { email: selectedEmail });
        this.cbWebApi.continueToAuth0(token, state, extraParams);
    };
    SelectEmailComponent.prototype.useNewEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, succeed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = this.newEmailControl.value;
                        if (!email) {
                            return [2 /*return*/];
                        }
                        if (this.form.invalid) {
                            return [2 /*return*/];
                        }
                        this.formLoadingStart();
                        if (this.userEmailSubscriptions.includes(email) || this.userAllowedEmails.includes(email)) {
                            this.ContinueFlowWithEmail(email);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.checkIFWeCanContinue(email)];
                    case 1:
                        succeed = _a.sent();
                        if (!succeed) {
                            return [2 /*return*/];
                        }
                        this.sendVerificationEmail();
                        return [2 /*return*/];
                }
            });
        });
    };
    // todo: Valid if user must claim
    SelectEmailComponent.prototype.checkIFWeCanContinue = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var errorResponse, err, isClaim;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, to(this.cbGroupioService.GetEmailSubscription(this.ruleToken, email).toPromise())];
                    case 1:
                        errorResponse = (_a.sent())[0];
                        if (errorResponse && errorResponse.error) {
                            err = errorResponse.error;
                            if (err.code === 'invalid_token') {
                                this.handlerErrors(err);
                                return [2 /*return*/];
                            }
                            log('err ? ', { err: err });
                            isClaim = err.code === 'email_exists';
                            if (isClaim) {
                                this.setClaimMessage(email);
                            }
                            else {
                                this.setErrorMessage('There was an error, Try again later');
                            }
                            this.formLoadingEnd();
                            return [2 /*return*/];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    SelectEmailComponent.prototype.setClaimMessage = function (email) {
        this.newEmailControl.setValue('');
        this.displayNewEmailSection = false;
        this.claimedEmail = email;
        this.displayClaimButton = true;
        this.claimMessage = true;
    };
    SelectEmailComponent.prototype.claimEmail = function () {
        this.formLoadingStart();
        window.location.href = 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/523';
        return true;
    };
    SelectEmailComponent.prototype.setClaimSent = function (claim) {
        var claimURL = claim._links.web;
        this.claimURL = claimURL;
        this.claimMessage = false;
        this.displayClaimButton = false;
        this.displayNewEmailSection = false;
    };
    // todo: handle this in httpService
    SelectEmailComponent.prototype.GetErrorMessage = function (errorResponse) {
        if (errorResponse === void 0) { errorResponse = {}; }
        var _a = (errorResponse || {}).error, errorWrapper = _a === void 0 ? {} : _a;
        var _b = (errorWrapper || {}).error, error = _b === void 0 ? {} : _b;
        var _c = error || {}, _d = _c.code, code = _d === void 0 ? '' : _d, _e = _c.message, message = _e === void 0 ? '' : _e;
        return message || code || '';
    };
    SelectEmailComponent.prototype.sendVerificationEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, returnTo, state, email, payload, err, message, ok;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.formLoadingStart();
                        token = this.cbAuthURLService.getToken();
                        returnTo = this.cbAuthURLService.getReturnPath();
                        state = this.cbAuthURLService.getState();
                        email = this.newEmailControl.value;
                        payload = {
                            state: state,
                            token: token,
                            email: email,
                            returnTo: returnTo,
                        };
                        log('payload ?', { payload: payload });
                        return [4 /*yield*/, to(this.cbGroupioService.sendVerificationEmail(payload).toPromise())];
                    case 1:
                        err = (_a.sent())[0];
                        if (err) {
                            message = this.GetErrorMessage(err);
                            this.setErrorMessage(message || this.message.errorVerificationEmail);
                            return [2 /*return*/];
                        }
                        ok = this.cbStateService.saveCurrentState();
                        if (!ok) {
                            this.cbWebApi.returnToAuthorizePage();
                            return [2 /*return*/];
                        }
                        this.redirectToSendLinkPage(payload);
                        this.formLoadingEnd();
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectEmailComponent.prototype.redirectToSendLinkPage = function (queryParams) {
        if (queryParams === void 0) { queryParams = {}; }
        this.router.navigate(['/cb/groupio-select-email-send'], { queryParams: queryParams });
    };
    SelectEmailComponent.prototype.signOut = function () {
        this.formLoadingStart();
        this.cbWebApi.returnToAuthorizePage();
    };
    SelectEmailComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    return SelectEmailComponent;
}());
export { SelectEmailComponent };
