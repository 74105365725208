import { Component, OnInit, Input } from '@angular/core';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';

import { LogFactory } from '../../services/cb-debug';
const log = LogFactory('CbTopBannerZoomBannerComponent');

@Component({
  selector: 'cb-top-banner-zoom-banner',
  templateUrl: 'cb-top-banner-zoom-banner.component.html',
})
export class CbTopBannerZoomBannerComponent implements OnInit {
  @Input() zoomID = '';
  @Input() id = '';

  possibleImages = [];
  isImageLoading = true;
  imageExists = true;

  constructor(public cbLoginBannerService: CbLoginBannerService) {}

  ngOnInit() {
    log('entered nginint');
    this.setBanner();
  }

  setBanner() {
    log('entered setBanner');

    const zoomBannerName = this.cbLoginBannerService.zoomID;

    if (!zoomBannerName) {
      this.imageExists = false;
      this.isImageLoading = false;
      return;
    }

    const bannerURL = `https://lf-login-platform-assets-production.s3.amazonaws.com/webinar/${zoomBannerName}.png`;

    const possibleImages = [
      {
        hide: true,
        URL: bannerURL,
      },
    ];

    log('possibleImages ? ', { possibleImages });

    this.possibleImages = possibleImages;
  }

  removeBanner(index: number = 0) {
    this.possibleImages.splice(index, 1);
    if (!this.possibleImages.length) {
      this.isImageLoading = false;
      this.imageExists = false;
    }
  }

  displayBanner(banner: any) {
    if (!this.isImageLoading) {
      return;
    }

    this.isImageLoading = true;
    this.imageExists = true;
    banner.hide = false;
  }
}
