import { CbLoginBannerService } from './../../services/cb-login-banner.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CbStateService } from '../../services/cb-state.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbStorage } from '../../services/cb-storage';
import { CbWebApi } from '../../services/cb-webapi';
import { CbGaService } from '../../services/cb-ga.service';

import { LogFactory } from '../../services/cb-debug';
const log = LogFactory('MainLayoutComponent', false);

const PROCESS_CONTINUE_TYPES = {
  CVENT: 'cvent',
  GROUPSIO: 'groupsio',
  ADD_USERNAME: 'add_username',
  LOGIN_ERROR: 'login_error',
};

interface ProcessContinueToken {
  continueType: string;
}

@Component({
  selector: 'cb-process-continue',
  templateUrl: './process-continue.component.html',
})
export class CbProcessContinueComponent implements OnInit {
  isLoading = true;

  constructor(
    private router: Router,
    private cbAuthURLService: CbAuthURLService,
    private cbStateService: CbStateService,
    private cbWebApi: CbWebApi,
    private cbStorage: CbStorage,
    private cbGaService: CbGaService,
    private cbLoginBannerService: CbLoginBannerService
  ) {}

  ngOnInit(): void {
    // @info: help to verify the same browser will continue used
    const ok = this.cbStateService.saveCurrentState();
    if (!ok) {
      this.cbWebApi.returnToAuthorizePage();
      return;
    }

    const { returnTo, _ga, ...qs } = this.cbAuthURLService.getAllURLParams();
    let gaValue = _ga;

    if (!gaValue) {
      gaValue = this.cbGaService.getGAParamFromReturnTo();
    }

    const alwaysInURLParams = this.cbLoginBannerService.getPersistentParamsInURL();

    const queryParams = {
      ...alwaysInURLParams,
      ...qs,
      _ga: gaValue,
      // info: Keep always returnTo at the end
      returnTo,
    };

    const decoded = this.cbAuthURLService.getDecodedToken() as ProcessContinueToken;

    log(' decoded, queryParams', { decoded, queryParams });

    this.cbStorage.save('SAFE_RETURN', returnTo);

    if (!decoded) {
      console.error('Invalid Token');
      this.cbWebApi.goToErrorPage('Invalid Token');
      return;
    }

    const continueType = decoded.continueType;
    if (!continueType) {
      console.error('Invalid Token');
      this.cbWebApi.goToErrorPage('Invalid Token');
      return;
    }

    switch (continueType) {
      case PROCESS_CONTINUE_TYPES.CVENT:
        this.router.navigate(['/cb/cvent-select-user-type'], { queryParams });
        break;
      case PROCESS_CONTINUE_TYPES.GROUPSIO:
        this.router.navigate(['/cb/select-email'], { queryParams });
        break;
      case PROCESS_CONTINUE_TYPES.ADD_USERNAME:
        this.router.navigate(['/cb/social-username'], { queryParams });
        break;
      case PROCESS_CONTINUE_TYPES.LOGIN_ERROR:
        this.cbWebApi.returnToAuthorizePage();
        break;

      default:
        // display error case
        this.cbWebApi.returnToAuthorizePage();
        break;
    }
  }
}
