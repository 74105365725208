import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { stringify } from 'querystring';
import { to } from 'await-to-js';
import { CbLoginConfig } from './cb-login.config';
import { CbStateService } from './cb-state.service';
import { CbStorage } from './cb-storage';
import { CbAuthURLService } from './cb-auth-url.service';
import { CbFullstoryService } from './cb-fullstory.service';
import { info, LogFactory } from './cb-debug';
import { CbAlertService } from './cb-alert.service';
import { removeUnnededParams } from '../shared/features';
import * as i0 from "@angular/core";
import * as i1 from "./cb-login.config";
import * as i2 from "./cb-state.service";
import * as i3 from "./cb-storage";
import * as i4 from "./cb-auth-url.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common/http";
import * as i7 from "./cb-fullstory.service";
import * as i8 from "./cb-alert.service";
var log = LogFactory('CbWebApi', false);
var CbWebApi = /** @class */ (function () {
    function CbWebApi(cbLoginConfig, cbStateService, cbStorage, cbAuthURLService, router, httpClient, cbFullstoryService, cbAlertService) {
        this.cbLoginConfig = cbLoginConfig;
        this.cbStateService = cbStateService;
        this.cbStorage = cbStorage;
        this.cbAuthURLService = cbAuthURLService;
        this.router = router;
        this.httpClient = httpClient;
        this.cbFullstoryService = cbFullstoryService;
        this.cbAlertService = cbAlertService;
        log('_auth0Config', window['_auth0Config']);
        log('cbLoginConfig', this.cbLoginConfig);
    }
    // @info: Verify if the current /login URL is valid
    // If not, it will generate and redirect to a valid authorize URL
    CbWebApi.prototype.VerifyStateChallenge = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, route, err;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        log('entered VerifyStateChallenge');
                        if (!this.cbLoginConfig.cbAuthCentralizedLogin) {
                            return [2 /*return*/];
                        }
                        state = this.cbAuthURLService.getAllURLParams().state;
                        route = "https://" + this.cbLoginConfig.domain + "/usernamepassword/challenge";
                        return [4 /*yield*/, to(this.httpClient.post(route, { state: state }).toPromise())];
                    case 1:
                        err = (_a.sent())[0];
                        if (err) {
                            console.warn('No found a valid login URL');
                            this.returnToAuthorizePage();
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    CbWebApi.prototype.getExtraParamsString = function (extra) {
        if (!extra) {
            return '';
        }
        if (typeof extra === 'string') {
            return extra;
        }
        if (typeof extra === 'object') {
            return '&' + stringify(extra);
        }
    };
    CbWebApi.prototype.getAuthorizeURL = function (params) {
        if (params === void 0) { params = null; }
        var returnTo = this.cbAuthURLService.getReturnPath(params);
        if (!returnTo) {
            return '';
        }
        return "https://" + this.cbLoginConfig.domain + "/" + returnTo;
    };
    CbWebApi.prototype.logoutByIframe = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var logoutURL = "https://" + _this.cbLoginConfig.domain + "/v2/logout";
            var iframe = document.createElement('iframe');
            iframe.src = logoutURL;
            iframe.style.display = 'none';
            iframe.onload = function () {
                resolve(true);
            };
            iframe.onerror = function () {
                console.log('finish by error');
                resolve(true);
            };
            document.body.appendChild(iframe);
            // INFO: force finish iframe loading if needed
            setTimeout(function () {
                console.log('finish by timeout');
                resolve(true);
            }, 3000);
        });
    };
    CbWebApi.prototype.returnToAuthorizePage = function (params) {
        if (params === void 0) { params = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var authorizeURL;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        authorizeURL = removeUnnededParams(this.getAuthorizeURL(params));
                        if (!authorizeURL) {
                            this.cbFullstoryService.fsError('authorize URL not found');
                            // @info: Fallback page
                            this.goToUnauthorizePage();
                            return [2 /*return*/, false];
                        }
                        this.cbStateService.removeCurrentState();
                        this.cbStateService.markStateAsUsed();
                        setTimeout(function () {
                            // console.log('Forcing new Login UI after 10s');
                            _this.redirectUserTo(authorizeURL);
                        }, 10000);
                        return [4 /*yield*/, to(this.logoutByIframe())];
                    case 1:
                        _a.sent();
                        console.log('Refreshing Login UI after logout');
                        this.redirectUserTo(authorizeURL);
                        return [2 /*return*/];
                }
            });
        });
    };
    CbWebApi.prototype.returnToAuth0BySafeReturn = function () {
        info('entered returnToAuth0BySafeReturn');
        var path = this.cbStorage.get('SAFE_RETURN');
        if (!path) {
            return;
        }
        var validParts = ['samlp/', 'authorize?'];
        var isValidURL = function () { return validParts.some(function (key) { return path.includes(key); }); };
        while (!isValidURL()) {
            path = decodeURIComponent(path);
        }
        path = "https://" + this.cbLoginConfig.domain + "/" + path;
        this.redirectUserTo(path);
        return;
    };
    CbWebApi.prototype.continueToAuth0 = function (token, state, extras) {
        if (extras === void 0) { extras = ''; }
        info('entered continueToAuth0');
        var extraParams = this.getExtraParamsString(extras);
        var continuePart = this.cbAuthURLService.getContinuePath(state, token);
        var continueURL = "https://" + this.cbLoginConfig.domain + "/" + continuePart + extraParams;
        // Verify the continue is in the same browser
        var isValid = this.cbStateService.isCurrentStateValid();
        if (!isValid) {
            var alert_1 = this.cbAlertService.createAlert('To Continue, Sign In with your LFID, otherwise create a new LFID.');
            this.returnToAuthorizePage({ alert: alert_1 });
            return;
        }
        this.cbStateService.removeCurrentState();
        // Continue as normal
        this.redirectUserTo(continueURL);
        return true;
    };
    CbWebApi.prototype.redirectUserTo = function (URL) {
        if (URL === void 0) { URL = ''; }
        info('entered redirectUserTo', { URL: URL });
        window.location.href = URL;
        return true;
    };
    CbWebApi.prototype.verifyUserCanContinue = function () {
        info('entered verifyUserCanContinue');
        var isValid = this.cbStateService.isCurrentStateValid();
        if (!isValid) {
            this.returnToAuthorizePage();
            return false;
        }
        return true;
    };
    CbWebApi.prototype.isValidStaticPageAccessByAuth0 = function () {
        var _a = this.cbAuthURLService.getAllURLParams(), state = _a.state, token = _a.token;
        if (!state) {
            return false;
        }
        if (!token) {
            return false;
        }
        return true;
    };
    CbWebApi.prototype.goToErrorEmailVerificationPage = function () {
        var queryParams = this.cbAuthURLService.getAllURLParams();
        this.cbFullstoryService.fsError('Error on email verification');
        this.router.navigate(['/cb/error-email-verification'], { queryParams: queryParams });
        return true;
    };
    CbWebApi.prototype.goToUnauthorizePage = function () {
        info('entered goToUnauthorizePage');
        var queryParams = this.cbAuthURLService.getAllURLParams();
        this.router.navigate(['/cb/unauthorize'], { queryParams: queryParams });
        return true;
    };
    CbWebApi.prototype.goToErrorPage = function (error, description) {
        if (error === void 0) { error = ''; }
        if (description === void 0) { description = ''; }
        var queryParams = this.cbAuthURLService.getAllURLParams();
        var query = stringify(tslib_1.__assign({ error: error, error_description: description || '' }, queryParams));
        this.cbFullstoryService.fsError(description);
        this.router.navigateByUrl("/cb/error-page?" + query);
        return true;
    };
    CbWebApi.ngInjectableDef = i0.defineInjectable({ factory: function CbWebApi_Factory() { return new CbWebApi(i0.inject(i1.CbLoginConfig), i0.inject(i2.CbStateService), i0.inject(i3.CbStorage), i0.inject(i4.CbAuthURLService), i0.inject(i5.Router), i0.inject(i6.HttpClient), i0.inject(i7.CbFullstoryService), i0.inject(i8.CbAlertService)); }, token: CbWebApi, providedIn: "root" });
    return CbWebApi;
}());
export { CbWebApi };
