import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { to } from 'await-to-js';
import { CbBackendService } from '../../services/cb-backend.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbStateService } from '../../services/cb-state.service';
import { LogFactory } from '../../services/cb-debug';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { isExpired } from './verify-account.features';

const log = LogFactory('VerifyAccountComponent');
@Component({
  templateUrl: './verify-account.component.html',
})
export class VerifyAccountComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  userToken: string;
  userEmail: string;
  state: string;
  isEmailVerified = false;
  error: boolean;
  errorMessage: any;
  isLoading = true;
  authorizeURL = '';
  loadingMessage = 'We are verifying your account';
  intervalID = null;

  constructor(
    private cbBackendService: CbBackendService,
    private cbAuthURLService: CbAuthURLService,
    private cbStateService: CbStateService,
    private cbWebApi: CbWebApi,
    private cbFullstoryService: CbFullstoryService
  ) {}

  ngOnInit(): void {
    const { token, email, state, authorizeURL } = this.cbAuthURLService.getAllURLParams();
    if (!token || !state) {
      this.cbWebApi.goToErrorPage('Invalid Access');
      return;
    }

    const expired = isExpired(token);

    if (expired) {
      this.error = true;
      this.errorMessage = 'Your token has expired.';
      this.isLoading = false;
      setTimeout(() => {
        this.gotoLogin();
      }, 5000);

      return;
    }

    this.userToken = token;
    this.userEmail = email;
    this.state = state;
    this.authorizeURL = authorizeURL;

    this.sendEventVerifyAccount();
    this.verifyAccount();
  }

  sendEventVerifyAccount() {
    const { email } = this.cbAuthURLService.getAllURLParams();

    const payload = {
      email,
    };
    this.cbFullstoryService.sendEvent('verify_account', payload);
  }

  shouldMarkAsVerified() {
    // Verify the continue is in the same browser
    const expectedState = this.cbStateService.getExpectedState();
    const userCanContinue = expectedState && expectedState === this.state;
    if (userCanContinue) {
      return false;
    }

    return true;
  }

  gerUserInfo() {
    return this.cbBackendService.getUser(this.userEmail, this.userToken).toPromise();
  }

  startPollingUser(token) {
    let counter = 0;

    this.intervalID = setInterval(async () => {
      const [err, response] = await to(this.gerUserInfo());
      const userExists = (response && response.data) || false;
      if (userExists || counter > 25 || err) {
        this.continueToAuth0(token, this.state);
        clearInterval(this.intervalID);
        return;
      }

      counter++;
    }, 2000);
  }

  async startPollingUserV2(token, counter = 0) {
    counter++;
    const [err, response] = await to(this.gerUserInfo());
    const userExists = (response && response.data) || false;
    if (userExists || counter > 25 || err) {
      this.continueToAuth0(token, this.state);
      return;
    }
    this.startPollingUserV2(token, counter);
  }

  verifyAccount() {
    const options = {
      state: this.state,
      verifyAccount: this.shouldMarkAsVerified(),
      return: this.authorizeURL,
    };
    const { email } = this.cbAuthURLService.getAllURLParams();

    this.cbBackendService
      .verifyAccount(this.userToken, options)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.cbFullstoryService.sendEvent('successfully_authenticated', { email });
          // *info: Allow Salesforce finish the user creating
          this.loadingMessage = 'We are setting up your profile, Please wait few seconds.';
          // todo: delay only if user can continue
          this.startPollingUserV2(response.newToken);
        },
        ({ error, message }) => {
          log(' Error ', { error, message });

          this.error = true;
          this.isLoading = false;
          this.errorMessage = (error && error.error && error.error.message) || message;
          setTimeout(() => {
            this.gotoLogin();
          }, 1000);
          return;
        }
      );
  }

  continueToAuth0(token, state) {
    const extras = {
      isSignup: true,
    };

    setTimeout(() => {
      this.cbWebApi.continueToAuth0(token, state, extras);
    }, 1000);
  }

  gotoLogin() {
    this.cbWebApi.returnToAuthorizePage();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
