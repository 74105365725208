import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { to } from 'await-to-js';

import { CbGroupioService } from '../../services/cb-groupio.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbStateService } from '../../services/cb-state.service';
import { CustomValidators } from '../../shared/custom-validations/custom-validations';
import { CbHttpErrorsService } from '../../services/cb-http-errors.service';
import { CbFullstoryService } from './../../services/cb-fullstory.service';

import { LogFactory } from '../../services/cb-debug';
import { CbMyProfileService } from '../../services/cb-myprofile.service';
const log = LogFactory('SelectEmailComponent');

interface ISelectEmailToken {
  username: string;
  email: string;
  groupsioflow: boolean;
  emails: string[];
  groupID: string;
  groupName: string;
  groupURL: string;
  groupAccessType: string;
  isSubscriber: boolean;
  sub: string;
  iat: string;
  exp: number;
  aud: string;
  iss: string;
}

@Component({
  selector: 'cb-select-email',
  templateUrl: './select-email.component.html',
  styleUrls: ['./select-email.component.scss'],
})
export class SelectEmailComponent implements OnInit, OnDestroy {
  form: FormGroup;
  conditionControl: FormControl;
  newEmailControl: FormControl;
  allowedEmailControl: FormControl;
  subscribedEmailControl: FormControl;
  errorMessage = '';
  groupName = '';
  groupURL = '';
  accessType = '';
  userEmail = '';
  userEmailSubscriptions = [];
  userAllowedEmails = [];
  isLogin = false;
  isJoin = false;
  isSubscriber = false;
  decodedToken = {} as ISelectEmailToken;
  ruleToken = '';
  ruleState = '';
  authorizeURL = '';
  isLoading = true;

  displayPrimaryEmailSection = true;
  displayAlternativeSecion = false;
  displayNewEmailSection = false;
  displayAlternateLink = false;
  displayClaimButton = false;
  claimMessage = false;
  claimType = 'error';
  claimURL = '';
  claimedEmail = '';

  message = {
    errorVerificationEmail: 'There was a problem sending the verification email. Please try again',
    errorSubscriberEndpoint: 'There was a problem checking your email, Please try later.',
    errorSubscriberNotFound: (email) => `The email address ${email} is not currently a member of this group.
      Please add the correct address to your LFID`,
  };

  destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private cbGroupioService: CbGroupioService,
    private cbWebApi: CbWebApi,
    private router: Router,
    private cbAuthURLService: CbAuthURLService,
    private cbStateService: CbStateService,
    private cbHttpErrorsService: CbHttpErrorsService,
    private cbFullstoryService: CbFullstoryService,
    private cbMyProfileService: CbMyProfileService
  ) {}

  formLoadingStart() {
    log('entered formLoadingStart');
    this.isLoading = true;
    this.errorMessage = '';
  }

  formLoadingEnd() {
    log('entered formLoadingEnd');
    this.isLoading = false;
  }

  setErrorMessage(message = '') {
    this.errorMessage = message;
    this.formLoadingEnd();
  }

  async ngOnInit() {
    this.cbMyProfileService.displayMyProfileURL();

    this.createForm();
    const allowed = this.cbWebApi.verifyUserCanContinue();
    if (!allowed) {
      return;
    }

    const { token, state, authorizeURL } = this.cbAuthURLService.getAllURLParams();
    const {
      groupURL,
      groupName,
      groupAccessType,
      email,
      username,
    } = this.cbAuthURLService.getDecodedToken() as ISelectEmailToken;

    this.isLogin = groupAccessType === 'login';
    this.isJoin = groupAccessType === 'register';
    this.groupName = groupName;
    this.groupURL = groupURL;
    this.userEmail = email;
    this.ruleToken = token;
    this.ruleState = state;
    this.authorizeURL = authorizeURL;

    await this.loadPageDependiencies();
    this.cbFullstoryService.identify(username, { email, username });
  }

  createForm() {
    this.form = this.fb.group({
      allowedEmail: '',
      subscribedEmail: '',
      newEmail: ['', [CustomValidators.OptionalEmail]],
    });

    this.allowedEmailControl = this.form.get('allowedEmail') as FormControl;
    this.subscribedEmailControl = this.form.get('subscribedEmail') as FormControl;

    this.newEmailControl = this.form.get('newEmail') as FormControl;
  }

  setEmailDropdownListener() {
    if (this.userEmailSubscriptions.length) {
      this.subscribedEmailControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        if (value) {
          this.useEmailFromDropdown(value);
        }
      });
    }
    if (this.userAllowedEmails.length) {
      this.allowedEmailControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        if (value) {
          this.useEmailFromDropdown(value);
        }
      });
    }
  }

  async GetPrimaryEmailSubscription() {
    const subscriber = await this.cbGroupioService.GetEmailSubscription(this.ruleToken, this.userEmail).toPromise();

    log('subscriber ? ', { subscriber });

    this.isSubscriber = subscriber ? true : false;
  }

  async getUserSubscriptions() {
    // @info: Get only emails which are already subscribed
    let err;
    let emails;

    [err, emails] = await to(this.cbGroupioService.GetUserSubscriptions(this.ruleToken).toPromise());
    if (err) {
      log('Error getting emails ', { err });
      this.handlerErrors(err.error);
      return;
    }

    this.userEmailSubscriptions = emails || [];
  }
  async getUserAllowedEmails() {
    // @info: Get all possible emails user have registered to use in Join flow
    let err;
    let emails;

    [err, emails] = await to(this.cbGroupioService.GetUserEmails(this.ruleToken).toPromise());
    if (err) {
      log('Error getting emails ', { err });
      this.handlerErrors(err.error);
      return;
    }

    this.userAllowedEmails = emails || [];
  }

  handlerErrors(error) {
    log('entered handlerErrors', { error });
    if (error.code === 'invalid_token') {
      this.setErrorMessage('Your session is not valid, Please sign out and try again.');
      return;
    }

    this.setErrorMessage('There was a problem. Try again later');
  }

  setPrimaryEmailSubscription() {
    if (this.userEmailSubscriptions.length === 1 && this.userEmailSubscriptions.includes(this.userEmail)) {
      this.isSubscriber = true;
      this.userEmailSubscriptions = [];
    }
  }

  excludeUsedEmails(emails) {
    return emails.filter((email) => !this.userEmailSubscriptions.includes(email) && email !== this.userEmail);
  }

  addPrimaryEmail(emails) {
    const collection = [...emails];
    const addPrimary =
      this.userEmailSubscriptions.length &&
      !this.userEmailSubscriptions.includes(this.userEmail) &&
      !collection.includes(this.userEmail);

    if (addPrimary) {
      collection.unshift(this.userEmail);
    }

    return collection;
  }

  processUserAllowedEmails() {
    this.setPrimaryEmailSubscription();

    let emails = [...this.userAllowedEmails];

    emails = this.excludeUsedEmails(emails);
    emails = this.addPrimaryEmail(emails);

    this.userAllowedEmails = [...emails];

    this.displayAlternateLink = !!this.userAllowedEmails.length;
  }

  async loadPageDependiencies() {
    const deps = [this.getUserSubscriptions(), this.getUserAllowedEmails()];

    const [err] = await to(Promise.all(deps));
    if (err) {
      log('err ? ', { err });
      return;
    }

    this.processUserAllowedEmails();

    if (this.userEmailSubscriptions.length) {
      this.displayPrimaryEmailSection = false;
    }

    this.setEmailDropdownListener();
    this.formLoadingEnd();
    return true;
  }

  displayAlternativeEmails() {
    this.displayAlternativeSecion = !this.displayAlternativeSecion;
    this.displayNewEmailSection = false;
  }

  displayNewEmail() {
    this.displayNewEmailSection = !this.displayNewEmailSection;
    this.displayAlternativeSecion = false;
  }

  clearEmailsControl() {
    if (this.allowedEmailControl.value) {
      this.allowedEmailControl.setValue('');
    }
    if (this.subscribedEmailControl.value) {
      this.subscribedEmailControl.setValue('');
    }
  }

  async ContinueIFSubscriber(email) {
    const subscriber = await this.cbGroupioService.GetEmailSubscription(this.ruleToken, email).toPromise();

    log(' subscriber ? ', { subscriber });

    if (subscriber) {
      this.continue(email);
      return true;
    }

    return false;
  }

  async InviteEmail(email) {
    const invite = await this.cbGroupioService.InviteEmail(this.ruleToken, email).toPromise();

    log('Invite ?', { invite });

    const queryParams = {
      email,
      state: this.ruleState,
      token: this.ruleToken,
      groupName: this.groupName,
      groupURL: this.groupURL,
      returnTo: this.authorizeURL,
    };

    log('queryParams ? ', queryParams);

    const nextPage = `/cb/groupio-confirm-invite`;
    this.router.navigate([nextPage], { queryParams, skipLocationChange: false });

    return true;
  }

  async ContinueFlowWithEmail(email) {
    const succeed = await this.ContinueIFSubscriber(email);

    if (succeed) {
      return true;
    }

    // If No , invite , redirect to Invite sent page
    const inviteSent = await this.InviteEmail(email);

    if (inviteSent) {
      return true;
    }

    return false;
  }

  async usePrimaryEmail() {
    if (!this.userEmail) {
      log('Error on using primary email');
      return false;
    }
    const email = this.userEmail;

    this.formLoadingStart();

    const [err, succeed] = await to(this.ContinueFlowWithEmail(email));
    if (err) {
      const message = this.cbHttpErrorsService.GetErrorMessage(err);
      this.setErrorMessage(message);
      return;
    }

    if (succeed) {
      return;
    }

    this.setErrorMessage(`It wasn't possible to continue with your email ${email}, try a different Email`);
  }

  async useEmailFromDropdown(email) {
    if (!email) {
      log('Error using dropdown emails');
      return false;
    }

    this.formLoadingStart();

    const [err, succeed] = await to(this.ContinueFlowWithEmail(email));
    if (err) {
      const message = this.GetErrorMessage(err) || 'There was a problem using your email. Try again later';
      this.setErrorMessage(message);
      return;
    }

    if (succeed) {
      return;
    }
    this.setErrorMessage(`It wasn't possible to continue with your email ${email}, try a different Email`);
  }

  continue(selectedEmail = '') {
    this.formLoadingStart();

    const token = this.cbAuthURLService.getToken();
    const state = this.cbAuthURLService.getState();

    const extraParams = {
      selectedEmail,
    };

    this.cbFullstoryService.sendEvent('successfully_authenticated', { email: selectedEmail });
    this.cbWebApi.continueToAuth0(token, state, extraParams);
  }

  async useNewEmail() {
    const email = this.newEmailControl.value;
    if (!email) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    this.formLoadingStart();

    if (this.userEmailSubscriptions.includes(email) || this.userAllowedEmails.includes(email)) {
      this.ContinueFlowWithEmail(email);
      return;
    }

    // todo: Valid if user must claim
    const succeed = await this.checkIFWeCanContinue(email);

    if (!succeed) {
      return;
    }

    this.sendVerificationEmail();
  }

  // todo: Valid if user must claim
  async checkIFWeCanContinue(email) {
    let errorResponse: any;

    [errorResponse] = await to(this.cbGroupioService.GetEmailSubscription(this.ruleToken, email).toPromise());
    if (errorResponse && errorResponse.error) {
      const err = errorResponse.error;

      if (err.code === 'invalid_token') {
        this.handlerErrors(err);
        return;
      }

      log('err ? ', { err });
      const isClaim = err.code === 'email_exists';

      if (isClaim) {
        this.setClaimMessage(email);
      } else {
        this.setErrorMessage('There was an error, Try again later');
      }

      this.formLoadingEnd();
      return;
    }

    return true;
  }

  setClaimMessage(email) {
    this.newEmailControl.setValue('');
    this.displayNewEmailSection = false;

    this.claimedEmail = email;
    this.displayClaimButton = true;
    this.claimMessage = true;
  }

  claimEmail() {
    this.formLoadingStart();
    window.location.href = 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/523';
    return true;
  }

  setClaimSent(claim) {
    const claimURL = claim._links.web;

    this.claimURL = claimURL;
    this.claimMessage = false;
    this.displayClaimButton = false;
    this.displayNewEmailSection = false;
  }

  // todo: handle this in httpService
  GetErrorMessage(errorResponse: any = {}) {
    const { error: errorWrapper = {} } = errorResponse || ({} as any);
    const { error = {} } = errorWrapper || {};
    const { code = '', message = '' } = error || {};

    return message || code || '';
  }

  async sendVerificationEmail() {
    this.formLoadingStart();

    const token = this.cbAuthURLService.getToken();
    const returnTo = this.cbAuthURLService.getReturnPath();
    const state = this.cbAuthURLService.getState();
    const email = this.newEmailControl.value;

    const payload = {
      state,
      token,
      email,
      returnTo,
    };

    log('payload ?', { payload });

    const [err] = await to(this.cbGroupioService.sendVerificationEmail(payload).toPromise());
    if (err) {
      const message = this.GetErrorMessage(err);
      this.setErrorMessage(message || this.message.errorVerificationEmail);
      return;
    }
    const ok = this.cbStateService.saveCurrentState();
    if (!ok) {
      this.cbWebApi.returnToAuthorizePage();
      return;
    }

    this.redirectToSendLinkPage(payload);
    this.formLoadingEnd();
  }

  redirectToSendLinkPage(queryParams = {}) {
    this.router.navigate(['/cb/groupio-select-email-send'], { queryParams });
  }

  signOut() {
    this.formLoadingStart();
    this.cbWebApi.returnToAuthorizePage();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
