import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { stringify } from 'querystring';
import { to } from 'await-to-js';

import { CbLoginConfig } from './cb-login.config';
import { CbStateService } from './cb-state.service';
import { CbStorage } from './cb-storage';
import { CbAuthURLService } from './cb-auth-url.service';
import { CbFullstoryService } from './cb-fullstory.service';

import { info, LogFactory } from './cb-debug';
import { CbAlertService } from './cb-alert.service';
import { removeUnnededParams } from '../shared/features';
const log = LogFactory('CbWebApi', false);

@Injectable({ providedIn: 'root' })
export class CbWebApi {
  constructor(
    private cbLoginConfig: CbLoginConfig,
    private cbStateService: CbStateService,
    private cbStorage: CbStorage,
    private cbAuthURLService: CbAuthURLService,
    private router: Router,
    private httpClient: HttpClient,
    private cbFullstoryService: CbFullstoryService,
    private cbAlertService: CbAlertService
  ) {
    log('_auth0Config', window['_auth0Config']);
    log('cbLoginConfig', this.cbLoginConfig);
  }

  // @info: Verify if the current /login URL is valid
  // If not, it will generate and redirect to a valid authorize URL
  async VerifyStateChallenge() {
    log('entered VerifyStateChallenge');

    if (!this.cbLoginConfig.cbAuthCentralizedLogin) {
      return;
    }

    const { state } = this.cbAuthURLService.getAllURLParams();

    const route = `https://${this.cbLoginConfig.domain}/usernamepassword/challenge`;
    const [err] = await to(this.httpClient.post(route, { state }).toPromise());

    if (err) {
      console.warn('No found a valid login URL');
      this.returnToAuthorizePage();
      return false;
    }

    return true;
  }

  getExtraParamsString(extra: any) {
    if (!extra) {
      return '';
    }

    if (typeof extra === 'string') {
      return extra;
    }

    if (typeof extra === 'object') {
      return '&' + stringify(extra);
    }
  }

  getAuthorizeURL(params = null) {
    const returnTo = this.cbAuthURLService.getReturnPath(params);
    if (!returnTo) {
      return '';
    }

    return `https://${this.cbLoginConfig.domain}/${returnTo}`;
  }

  logoutByIframe() {
    return new Promise((resolve) => {
      const logoutURL = `https://${this.cbLoginConfig.domain}/v2/logout`;
      const iframe = document.createElement('iframe');
      iframe.src = logoutURL;
      iframe.style.display = 'none';
      iframe.onload = () => {
        resolve(true);
      };

      iframe.onerror = () => {
        console.log('finish by error');
        resolve(true);
      };
      document.body.appendChild(iframe);

      // INFO: force finish iframe loading if needed
      setTimeout(() => {
        console.log('finish by timeout');
        resolve(true);
      }, 3000);
    });
  }

  async returnToAuthorizePage(params = null) {
    const authorizeURL = removeUnnededParams(this.getAuthorizeURL(params));
    if (!authorizeURL) {
      this.cbFullstoryService.fsError('authorize URL not found');
      // @info: Fallback page
      this.goToUnauthorizePage();
      return false;
    }

    this.cbStateService.removeCurrentState();
    this.cbStateService.markStateAsUsed();

    setTimeout(() => {
      // console.log('Forcing new Login UI after 10s');
      this.redirectUserTo(authorizeURL);
    }, 10000);

    await to(this.logoutByIframe());

    console.log('Refreshing Login UI after logout');
    this.redirectUserTo(authorizeURL);
  }

  returnToAuth0BySafeReturn() {
    info('entered returnToAuth0BySafeReturn');

    let path = this.cbStorage.get('SAFE_RETURN');

    if (!path) {
      return;
    }

    const validParts = ['samlp/', 'authorize?'];
    const isValidURL = () => validParts.some((key) => path.includes(key));

    while (!isValidURL()) {
      path = decodeURIComponent(path);
    }

    path = `https://${this.cbLoginConfig.domain}/${path}`;

    this.redirectUserTo(path);

    return;
  }

  continueToAuth0(token, state, extras: any = '') {
    info('entered continueToAuth0');
    const extraParams = this.getExtraParamsString(extras);
    const continuePart = this.cbAuthURLService.getContinuePath(state, token);
    const continueURL = `https://${this.cbLoginConfig.domain}/${continuePart}${extraParams}`;

    // Verify the continue is in the same browser
    const isValid = this.cbStateService.isCurrentStateValid();
    if (!isValid) {
      const alert = this.cbAlertService.createAlert(
        'To Continue, Sign In with your LFID, otherwise create a new LFID.'
      );
      this.returnToAuthorizePage({ alert });
      return;
    }

    this.cbStateService.removeCurrentState();
    // Continue as normal
    this.redirectUserTo(continueURL);
    return true;
  }

  redirectUserTo(URL = '') {
    info('entered redirectUserTo', { URL });
    window.location.href = URL;
    return true;
  }

  verifyUserCanContinue() {
    info('entered verifyUserCanContinue');
    const isValid = this.cbStateService.isCurrentStateValid();
    if (!isValid) {
      this.returnToAuthorizePage();
      return false;
    }

    return true;
  }

  isValidStaticPageAccessByAuth0() {
    const { state, token } = this.cbAuthURLService.getAllURLParams();
    if (!state) {
      return false;
    }
    if (!token) {
      return false;
    }

    return true;
  }

  goToErrorEmailVerificationPage() {
    const queryParams = this.cbAuthURLService.getAllURLParams();
    this.cbFullstoryService.fsError('Error on email verification');
    this.router.navigate(['/cb/error-email-verification'], { queryParams });
    return true;
  }

  goToUnauthorizePage() {
    info('entered goToUnauthorizePage');
    const queryParams = this.cbAuthURLService.getAllURLParams();
    this.router.navigate(['/cb/unauthorize'], { queryParams });
    return true;
  }

  goToErrorPage(error = '', description = '') {
    const queryParams = this.cbAuthURLService.getAllURLParams();

    const query = stringify({
      error,
      error_description: description || '',
      ...queryParams,
    });
    this.cbFullstoryService.fsError(description);
    this.router.navigateByUrl(`/cb/error-page?${query}`);
    return true;
  }
}
