<!-- <div class="cb-separator-2rem"></div>
<div class="cb-row">
  <cb-logo></cb-logo>
</div> -->

<div class="cb-title-group">
  <div class="cb-separator-5rem"></div>
  <br />
  <div class="cb-separator-5rem"></div>
  <div class="primary-text">
    <div style="font-size: 2rem;">
      We were unable to continue with your session
    </div>

    <p style="width: 600px; margin: 0 auto; margin-top: 1rem; font-weight: normal;">
      We were regrettably unable to continue with your session. It was likely due to your browser being closed before
      you completed your login.
    </p>

    <p style="width: 600px; margin: 3rem auto 2rem auto; text-align: left; padding-left: 1rem;">
      Please follow these steps to continue with your session:
    </p>
    <ol style="width: 600px; margin: 0 auto 3rem; text-align: left; font-weight: normal;">
      <li style="margin: 0.75rem 0;">
        Proceed to the <a class="cb-link skyblue" (click)="gotoLogin()">Login</a> page and log In with your username and
        password.
      </li>
      <li style="margin: 0.75rem 0;">
        Please use the same browser window, and avoid bookmarking the URL as it is one-time use only.
      </li>
    </ol>

    <div class="cb-separator-2rem"></div>
  </div>
</div>
