<div class="control" [ngClass]="{ error: control.dirty && control.invalid }">
  <!-- <label for="">{{ label }}</label> -->
  <textarea
    id="{{ formControlName }}"
    rows="{{ rowSize }}"
    maxlength="{{ maxLength }}"
    class="textarea"
    #textAreaElement
    [placeholder]="label"
    (ngModelChange)="onKeyup($event)"
    [ngModel]="textValue"
    [ngClass]="{ 'ng-valid': control.valid, 'ng-invalid': control.invalid, 'ng-dirty': control.dirty }"
  >
  </textarea>
  <div class="error-message" [ngClass]="{ active: control.dirty && control.invalid }">
    {{ getErrorMessage(control.errors) }}
  </div>
</div>
