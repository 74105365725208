import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CbAuthService } from './cb-auth.service';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { CbLoginConfig } from './cb-login.config';

@Injectable()
export class CbAuthGuard implements CanActivate {
  whiteList = ['/cb/change-password'];

  constructor(private cbAuthService: CbAuthService, private router: Router, private loginConfig: CbLoginConfig) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const param = 'redirectTo';
    const redirectTo = route.queryParams[param] || '/';

    if (this.loginConfig.cbAuthCentralizedLogin) {
      return of(true);
    }

    if (this.loginConfig.cbSsoStaticApp) {
      const allowedPages = [
        '/cb/process-continue',
        '/cb/error-email-verification',
        '/cb/unauthorize',
        '/cb/select-email',
        '/cb/groupio-confirm-invite',
        '/cb/groupio-select-email-send',
        '/cb/email-verification',
        '/cb/cvent-select-user-type',
        '/cb/social-username',
        '/cb/change-password',
        '/cb/create-account-email',
        '/cb/verify-account',
        '/cb/error-page',
        '/cb/help',
      ];

      const validPage = allowedPages.some((allowedPage) => {
        return state.url.includes(allowedPage);
      });
      if (validPage) {
        this.cbAuthService.notifyCbActive(true);
      }

      return validPage;
    }

    return this.cbAuthService.isUserSessionValid().pipe(
      map((isSessionValid) => {
        if (this.isWhiteListedUrl(state)) {
          return true;
        }

        const allowCBLoginAccess = !isSessionValid;

        if (allowCBLoginAccess) {
          this.cbAuthService.saveRedirectTo(redirectTo);
          this.cbAuthService.notifyCbActive(true);
        }

        if (this.router.routerState.snapshot.url === '' && isSessionValid) {
          this.cbAuthService.navigateTo('/');
        }

        return allowCBLoginAccess;
      })
    );
  }

  isWhiteListedUrl(state: RouterStateSnapshot) {
    const url = state.url;
    const baseUrl = url.split('?')[0];

    if (this.whiteList.includes(baseUrl)) {
      this.cbAuthService.notifyCbActive(true);
      return true;
    }

    return false;
  }
}
