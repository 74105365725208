export class CbCreateAccountPageContent {
  sidebar = '';
  title = 'Create a Linux Foundation Account';
  emailLabel = 'Email address';
  passwordLabel = 'Password';
  usernameLabel = 'Username';
  firstNameLabel = 'First name';
  lastNameLabel = 'Last name';
  loginButtonText = 'Login';
  terms = `By creating an account, you agree to the CommunityBridge <a class="cb-link skyblue"
  tabindex="-1"
  href="https://communitybridge.org/platform-use-agreement/" target="_blank">
  Platform Use Agreement
  </a> and all terms incorporated therein, including the <a
  tabindex="-1" class="cb-link skyblue" href="https://www.linuxfoundation.org/terms/" target="_blank">
Service-Specific Use Terms</a>, the Acceptable Use Policy and the <a
tabindex="-1" class="cb-link skyblue" href="https://www.linuxfoundation.org/privacy/"
target="_blank">Privacy Policy</a>.
  <br /><br />
  Linux Foundation ID (LFID) is a free account that identifies you and securely provides access to
  web sites of The Linux Foundation, our projects, and our service providers.
  <br/><br/>
  `;
  termError = 'You must consent to continue registration.';
  createAccountButtonText = 'Create Account';
  headerText = 'Already have an account ? ';
  lfidInfo =
    'Linux Foundation ID (LFID) is a free account that identifies you and securely provides ' +
    'access to web sites of The Linux Foundation, our projects and our service providers.';

  usernameErrors = {
    required: 'Username is a required field.',
    minlength: 'Username should be at least 3 characters long.',
    maxlength: 'Username should be at less than 60 characters long.',
    noStartWithNumber: 'Username should not start with a number.',
    pattern: 'Username should be alphanumeric and in lowercase.',
  };

  firstNameErrors = {
    required: 'First name is a required field.',
    whitespace: 'First name is a required field.',
    minlength: 'First name should be at least 1 characters long.',
    pattern: 'First name is invalid.',
  };

  lastNameErrors = {
    required: 'Last name is a required field.',
    whitespace: 'Last name is a required field.',
    minlength: 'Last name should be at least 1 characters long.',
    pattern: 'Last name is invalid.',
  };

  emailErrors = {
    required: 'Email is a required field.',
    email: 'Invalid email format.',
    pattern: 'Invalid email format.',
  };

  passwordErrors = {
    required: 'Password is a required field.',
    pattern: 'Must have at least 8 characters, including upper, lower, numeric, and special characters.',
  };

  constructor(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }

  overwriteContent(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }
}
