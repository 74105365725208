import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IServiceRequest } from '../models/service-desk.models';
import { CbLoginConfig } from './cb-login.config';

@Injectable()
export class ServiceDeskService {
  constructor(private http: HttpClient, private loginConfig: CbLoginConfig) {}

  sendRequest(request: IServiceRequest) {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = {
      description: `Name: ${request.name}\nEmail: ${request.email}\n\n\nDescription: ${request.description}`,
      captchaToken: request.captchaToken,
    };

    return this.http.post<any>(`${this.loginConfig.cbBackend}/service-desk/request`, body, options);
  }
}
