import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
var log = LogFactory('CbStorage', false);
var CbStorage = /** @class */ (function () {
    function CbStorage() {
    }
    CbStorage.prototype.save = function (key, value) {
        if (key === void 0) { key = ''; }
        if (value === void 0) { value = ''; }
        // @info: FF doesn't handle localStorage per domain always , usually per tab
        // Issue : https://bugzilla.mozilla.org/show_bug.cgi?id=1453699
        // Implementing cookies fallback
        if (document) {
            var d = new Date();
            d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
            var expires = 'expires=' + d.toUTCString();
            document.cookie = key + "=" + value + ";" + expires + ";path=/";
        }
    };
    CbStorage.prototype.remove = function (key) {
        if (key === void 0) { key = ''; }
        if (!key) {
            return;
        }
        if (document) {
            document.cookie = key + "=;expires = Thu, 01 Jan 1970 00:00:00 UTC;path=/";
        }
    };
    CbStorage.prototype.get = function (key) {
        if (key === void 0) { key = ''; }
        log('entered get ', { key: key });
        // Implementing cookies fallback
        return this.getFromCookieStorage(key);
    };
    CbStorage.prototype.getFromCookieStorage = function (key) {
        log('entered getFromCookieStorage', { key: key });
        // Implementing cookies fallback
        if (!document) {
            return null;
        }
        var cookies = document.cookie || '';
        var cookiesObj = cookies.split(';').reduce(function (acc, item) {
            if (!item) {
                return acc;
            }
            var _a = item.split('='), i = _a[0], value = _a[1];
            acc[i.trim()] = (value && value.trim()) || '';
            return acc;
        }, {});
        return cookiesObj[key];
    };
    CbStorage.ngInjectableDef = i0.defineInjectable({ factory: function CbStorage_Factory() { return new CbStorage(); }, token: CbStorage, providedIn: "root" });
    return CbStorage;
}());
export { CbStorage };
