import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CbBackendService } from '../../services/cb-backend.service';
import { ConfirmationAlertData } from '../../services/confirmation-alert-data';
import { CbAuthService } from '../../services/cb-auth.service';
import { CbRecaptchaService } from '../../services/cb-recpatcha.service';
import { CbLoginConfig } from '../../services/cb-login.config';

@Component({
  selector: 'cb-reset-password-confirmation',
  templateUrl: './confirmation.component.html',
})
export class ResetPasswordConfirmationComponent implements OnDestroy, OnInit {
  destroy$ = new Subject();
  // Parameters
  userEmail = this.activatedRoute.snapshot.queryParams.email || '';
  maskedEmail = this.activatedRoute.snapshot.queryParams.maskedEmail || '';

  // Page content details
  headerTitle = 'Already have an account ?';
  confirmationTitle = 'Email has been sent.';
  confirmationMessage = `A reset link has been sent to ${this.maskedEmail ? this.maskedEmail : 'your email on file.'}.`;
  buttonText = 'Did not Receive Link';
  linkText = 'Back';
  linkRouterPath = '/cb/login';

  // Alert parameters
  resendSuccessMessage = 'New reset email has been sent to you!';

  private subscription: Subscription;
  captchaToken = '';

  constructor(
    private cbBackendService: CbBackendService,
    private cbLoginConfig: CbLoginConfig,
    private confirmationAlertData: ConfirmationAlertData,
    private activatedRoute: ActivatedRoute,
    private cbRecaptchaService: CbRecaptchaService,
    private cbAuthService: CbAuthService
  ) {
    this.confirmationAlertData.init();
  }

  resendResetPasswordEmail = () => {
    this.confirmationAlertData.setLoadingStatus(true);

    let returnValue = '';

    if (this.cbLoginConfig.cbAuthCentralizedLogin) {
      returnValue = this.cbAuthService.persistConfigParamsForReturn();
    }

    this.cbBackendService
      .resetPassword(this.userEmail, this.captchaToken, returnValue)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.refreshToken())
      )
      .subscribe(
        () => {
          this.confirmationAlertData.setSuccess(this.resendSuccessMessage);
          this.confirmationAlertData.setLoadingStatus(false);
        },
        (err) => {
          this.confirmationAlertData.setError(err.error.error.message);
          this.confirmationAlertData.setLoadingStatus(false);
        }
      );
  };

  ngOnInit(): void {
    if (!this.userEmail) {
      this.cbAuthService.navigateTo('/');
    }
    this.refreshToken();
  }

  refreshToken() {
    this.subscription = this.cbRecaptchaService.getCaptchaToken('resetPassword').subscribe((data) => {
      this.captchaToken = data;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
