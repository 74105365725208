import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { to } from 'await-to-js';
import { CbGroupioService } from '../../services/cb-groupio.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbMyProfileService } from '../../services/cb-myprofile.service';
import { LogFactory } from './../../services/cb-debug';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
var log = LogFactory('GroupioConfirmInviteComponent');
var GroupioConfirmInviteComponent = /** @class */ (function () {
    function GroupioConfirmInviteComponent(cbGroupService, cbAuthURLService, cbWebApi, cbMyProfileService, cbFullstoryService) {
        this.cbGroupService = cbGroupService;
        this.cbAuthURLService = cbAuthURLService;
        this.cbWebApi = cbWebApi;
        this.cbMyProfileService = cbMyProfileService;
        this.cbFullstoryService = cbFullstoryService;
        this.groupURL = '';
        this.email = '';
        this.groupName = '';
        this.isLoading = false;
        this.errorMessage = '';
    }
    GroupioConfirmInviteComponent.prototype.pageLoading = function () {
        this.isLoading = true;
    };
    GroupioConfirmInviteComponent.prototype.pageLoadingEnd = function () {
        this.isLoading = false;
    };
    GroupioConfirmInviteComponent.prototype.setErrorMessage = function (message) {
        this.errorMessage = message;
        this.pageLoadingEnd();
    };
    GroupioConfirmInviteComponent.prototype.ngOnInit = function () {
        var _a = this.cbAuthURLService.getAllURLParams(), email = _a.email, groupName = _a.groupName, groupURL = _a.groupURL;
        this.email = email;
        this.groupName = groupName;
        this.groupURL = groupURL;
        this.cbMyProfileService.displayMyProfileURL();
    };
    GroupioConfirmInviteComponent.prototype.goToLoginPage = function () {
        this.cbGroupService.returnToGroupPage();
    };
    GroupioConfirmInviteComponent.prototype.continue = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, email, state, token, _b, err, subscriber;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.pageLoading();
                        _a = this.cbAuthURLService.getAllURLParams(), email = _a.email, state = _a.state, token = _a.token;
                        log(' email, state, token', { email: email, state: state, token: token });
                        return [4 /*yield*/, to(this.cbGroupService.GetEmailSubscription(token, email).toPromise())];
                    case 1:
                        _b = _c.sent(), err = _b[0], subscriber = _b[1];
                        if (err) {
                            this.cbFullstoryService.fsError('There was a problem processing, Please refresh your page or try sign out.');
                            this.setErrorMessage('There was a problem processing, Please refresh your page or try sign out.');
                            return [2 /*return*/];
                        }
                        log('subscriber? ', { subscriber: subscriber });
                        if (!subscriber) {
                            this.cbFullstoryService.fsError('No subscription found');
                            this.setErrorMessage('No subscription found, Please Accept your invitation and try again.');
                            return [2 /*return*/];
                        }
                        this.cbFullstoryService.sendEvent('successfully_authenticated', { email: email });
                        this.cbWebApi.continueToAuth0(token, state, { selectedEmail: email });
                        return [2 /*return*/];
                }
            });
        });
    };
    GroupioConfirmInviteComponent.prototype.signOut = function () {
        this.pageLoading();
        this.cbWebApi.returnToAuthorizePage();
    };
    return GroupioConfirmInviteComponent;
}());
export { GroupioConfirmInviteComponent };
