import { Injectable } from '@angular/core';
import { CbLoginPageContent } from '../components/login/login-content-page';
import { CbCreateAccountPageContent } from '../components/create-account/create-account-content';
import { CbAuthServiceErrorMessage } from './cb-auth-error-messages';
import { CbCreateAccountEmailPageContent } from '../components/create-account-email/create-account-email-content';

export type ContentFactoryType =
  | CbLoginPageContent
  | CbCreateAccountPageContent
  | CbCreateAccountEmailPageContent
  | CbAuthServiceErrorMessage
  | any;

/**
 * Assets uploaded in S3: https://cb-login-controller-assets-dev.s3.amazonaws.com/
 */
@Injectable({ providedIn: 'root' })
export class CbLoginCustomConfig {
  domain?: string;
  clientId?: string;
  clientName?: string;
  cbBackend: string;
  cbSidebarContent?: string;
  debug?: boolean;
  cbBackgroundImage?: string;
  cbBorderImage?: string;
  cbLogoLink?: string;
  cbWarningTime?: number;
  cbResponseType?: string;
  cbAuthCentralizedLogin?: boolean;
  cbSkipLocalChanges?: boolean;
  cbVerifyAccount?: boolean;
  cbSsoStaticApp?: boolean;
  cbRecaptchaSiteKey?: string;
  cbDefaultLogo: string;
  cbDefaultLogoNoSidebar: string;
  cbCDN?: string;
  cbContentFactory?: () => { [key: string]: ContentFactoryType };
}
