import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CbJoinNowService {
  GetJoinnowCopy() {
    if (this.isRiscvProject()) {
      return true;
    }

    return false;
  }

  isRiscvProject() {
    // @todo: Update using joinnnow_project_name=<name>
    // once this is needed for all the projects
    const URL = window.location.href || '';
    return URL.toLowerCase().includes('risc-v');
  }
}
