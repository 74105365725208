import { LogFactory } from './cb-debug';
import { Injectable } from '@angular/core';

const log = LogFactory('CbHttpErrorsService');

@Injectable({ providedIn: 'root' })
export class CbHttpErrorsService {
  GetErrorMessage(errorResponse: any = {}) {
    log('errorResponse ?', { errorResponse });
    let innerError;

    innerError = this.GetError(errorResponse);
    if (typeof innerError === 'string') {
      return innerError;
    }

    innerError = this.GetError(innerError);
    if (typeof innerError === 'string') {
      return innerError;
    }

    innerError = this.GetError(innerError);
    if (typeof innerError === 'string') {
      return innerError;
    }

    log(' three ? ', { innerError });
    return 'There was a problem, Try again later';
  }
  GetErrorCode(errorResponse: any = {}) {
    log('errorResponse ?', { errorResponse });
    let innerError;

    innerError = this.GetErrorCodeInner(errorResponse);
    if (typeof innerError === 'string') {
      return innerError;
    }

    innerError = this.GetErrorCodeInner(innerError);
    if (typeof innerError === 'string') {
      return innerError;
    }

    innerError = this.GetErrorCodeInner(innerError);
    if (typeof innerError === 'string') {
      return innerError;
    }

    log(' three ? ', { innerError });
    return 'There was a problem, Try again later';
  }

  GetError(possibleError: any) {
    log('entered GetError', { possibleError });

    if (typeof possibleError === 'string') {
      return possibleError;
    }
    const { error, code, message, Message, Code, errorMessage } = possibleError || ({} as any);
    if (typeof error === 'object') {
      return error;
    }

    return Message || Code || errorMessage || message || code || error;
  }
  GetErrorCodeInner(possibleError: any) {
    log('entered GetError', { possibleError });

    if (typeof possibleError === 'string') {
      return possibleError;
    }
    const { error, code, Code } = possibleError || ({} as any);
    if (typeof error === 'object') {
      return error;
    }

    return Code || code || error;
  }
}
