import * as i0 from "@angular/core";
var CbTrainingAppService = /** @class */ (function () {
    function CbTrainingAppService() {
    }
    CbTrainingAppService.prototype.IsTrainingCheckoutFlow = function () {
        var URL = document.location.href || '';
        if (!URL.includes('returnTo')) {
            return false;
        }
        if (!URL.includes('purchasableId')) {
            return false;
        }
        if (!URL.includes('orders')) {
            return false;
        }
        return true;
    };
    CbTrainingAppService.prototype.getDefaultRelayFromCheckoutFlow = function () {
        var currentRelayState = window['_auth0Config'].extraParams.RelayState;
        var defaultPath = 'learn/dashboard';
        var defaultRelay = currentRelayState.replace(/orders\?cart=\[\{.+\}\]/g, defaultPath);
        return defaultRelay;
    };
    CbTrainingAppService.prototype.HandleOverwritesByApp = function () {
        if (!this.IsTrainingCheckoutFlow()) {
            return {};
        }
        return {
            RelayState: this.getDefaultRelayFromCheckoutFlow(),
        };
    };
    CbTrainingAppService.ngInjectableDef = i0.defineInjectable({ factory: function CbTrainingAppService_Factory() { return new CbTrainingAppService(); }, token: CbTrainingAppService, providedIn: "root" });
    return CbTrainingAppService;
}());
export { CbTrainingAppService };
