<div [hidden]="!isLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>
<div [hidden]="isLoading">
  <div class="columns is-flex is-centered">
    <div class="column is-9">
      <!-- <div class="columns">
        <div class="column">
          <div class="title has-text-info is-size-4 has-text-centered">
            Register
          </div>
        </div>
      </div> -->

      <div class="columns is-marginless pb-1">
        <div class="column">
          <div class="has-text-lfblack font-18px bold has-text-centered">
            REGISTER
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column has-text-centered">
          <div class="column">
            Email used for your registration is
          </div>

          <div class="control has-text-centered">
            <div class="field">
              <div class="select">
                <select [(ngModel)]="selectedEmail">
                  <option *ngFor="let email of userEmails" [value]="email">
                    {{ email }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="columns">
        <div class="column has-text-centered">
          <div class="option-title">Myself</div>
          <cb-button type="is-info is-rounded is-size-7 px-3" data-cy="btn-me" (click)="registerMyself()">
            Register / Modify
          </cb-button>
        </div>

        <div class="is-divider-vertical"></div>

        <div class="column has-text-centered">
          <div class="option-title">
            Someone Else
            <span
              class="tooltip has-tooltip-right has-tooltip-multiline has-tooltip-arrow"
              data-tooltip="Select this option if you are registering another individual or a group of individuals."
            >
              <cb-icon type="fas" name="question-circle"></cb-icon>
            </span>
          </div>
          <cb-button type="is-info is-rounded is-size-7 px-3" data-cy="btn-else" (click)="registerSomeoneElse()">
            Register / Modify
          </cb-button>
        </div>
      </div>

      <br />

      <div *ngIf="errorMessage" class="columns">
        <div class="column has-text-centered has-text-danger">
          {{ errorMessage }}
        </div>
      </div>

      <div class="cb-secondary-text cb-text-center cvent-copy">
        You are responsible for ensuring that any individual whose personal data you provide (i) is aware that you have
        provided such information to us for purposes of registration for this event and (ii) agrees to Linux
        Foundation's
        <a target="_blank" href="https://www.linuxfoundation.org/privacy/" class="cb-link skyblue">
          Privacy Policy.
        </a>
      </div>
    </div>
  </div>
</div>
