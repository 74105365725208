export const CB_LOGIN_PAGE = '/cb/login';

export const CB_LOGIN_REDIRECTION_KEY = 'key';

export class CbLoginPageContent {
  sidebar = '';
  title = 'CommunityBridge Login';
  usernameLabel = 'Email or Username';
  PasswordLabel = 'Password';
  forgotPasswordLabel = 'Forgot Password?';
  loginButtonLabel = 'Sign In';
  headerText = "Don't have an account?";
  createAccountButtonLabel = 'Create Account';
  googleButtonLabel = 'Login with Google';
  githubButtonLabel = 'Login with GitHub';

  // @info: Messages/ alerts
  emailVerified = 'Your email has been verified.';
  passwordReset = 'Password has been updated.';

  // @info: variable name from auth0 response
  // tslint:disable-next-line: variable-name
  access_denied = 'Incorrect username or password.';
  // anomalyDetected = 'Please refresh the browser and sign in again.';

  usernameErrors = {
    required: 'Email or Username is a required field',
    whitespace: 'Email or Username is a required field',
    username: 'Invalid Username',
    email: 'Invalid Email',
  };

  passwordErrors = {
    required: 'Password is a required field',
  };

  constructor(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }
  overwriteContent(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }
}
