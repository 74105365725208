import * as i0 from "@angular/core";
var CbJoinNowService = /** @class */ (function () {
    function CbJoinNowService() {
    }
    CbJoinNowService.prototype.GetJoinnowCopy = function () {
        if (this.isRiscvProject()) {
            return true;
        }
        return false;
    };
    CbJoinNowService.prototype.isRiscvProject = function () {
        // @todo: Update using joinnnow_project_name=<name>
        // once this is needed for all the projects
        var URL = window.location.href || '';
        return URL.toLowerCase().includes('risc-v');
    };
    CbJoinNowService.ngInjectableDef = i0.defineInjectable({ factory: function CbJoinNowService_Factory() { return new CbJoinNowService(); }, token: CbJoinNowService, providedIn: "root" });
    return CbJoinNowService;
}());
export { CbJoinNowService };
