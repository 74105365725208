import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { to } from 'await-to-js';
import { CbMyProfileService } from './../../services/cb-myprofile.service';
import { CbBackendService } from './../../services/cb-backend.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { LogFactory } from '../../services/cb-debug';
var log = LogFactory('CbCventUserTypeComponent');
var CbCventUserTypeComponent = /** @class */ (function () {
    function CbCventUserTypeComponent(cbAuthURLService, cbBackendService, cbWebApi, cbMyProfileService, cbFullstoryService) {
        this.cbAuthURLService = cbAuthURLService;
        this.cbBackendService = cbBackendService;
        this.cbWebApi = cbWebApi;
        this.cbMyProfileService = cbMyProfileService;
        this.cbFullstoryService = cbFullstoryService;
        this.userEmails = [];
        this.selectedEmail = '';
        this.isLoading = true;
        this.errorMessage = '';
        // document.title = 'Linux Foundation - Cvent';
    }
    CbCventUserTypeComponent.prototype.ngOnInit = function () {
        var canContinue = this.cbWebApi.verifyUserCanContinue();
        if (!canContinue) {
            // * user will be redirected to authorize page
            return;
        }
        this.cbMyProfileService.displayMyProfileURL();
        this.setUserEmails();
        this.setInitialValues();
    };
    CbCventUserTypeComponent.prototype.setInitialValues = function () {
        var decoded = this.cbAuthURLService.getDecodedToken();
        this.selectedEmail = decoded.email;
        this.userEmails = [decoded.email];
    };
    CbCventUserTypeComponent.prototype.setUserEmails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, _a, err, emails;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        log('entered setUserEmails');
                        token = this.cbAuthURLService.getAllURLParams().token;
                        return [4 /*yield*/, to(this.cbBackendService.getUserEmails(token).toPromise())];
                    case 1:
                        _a = _b.sent(), err = _a[0], emails = _a[1];
                        this.loadingEnd();
                        if (err || !emails || !emails.length) {
                            // todo: maybe alert to the user
                            return [2 /*return*/];
                        }
                        this.userEmails = emails;
                        return [2 /*return*/];
                }
            });
        });
    };
    CbCventUserTypeComponent.prototype.registerMyself = function () {
        this.continueWithType('no');
    };
    CbCventUserTypeComponent.prototype.registerSomeoneElse = function () {
        this.continueWithType('yes');
    };
    CbCventUserTypeComponent.prototype.loading = function () {
        this.errorMessage = '';
        this.isLoading = true;
    };
    CbCventUserTypeComponent.prototype.loadingEnd = function () {
        this.isLoading = false;
    };
    CbCventUserTypeComponent.prototype.setErrorMessage = function (message) {
        if (message === void 0) { message = 'There was a problem, try again later'; }
        this.isLoading = false;
        this.errorMessage = message;
    };
    CbCventUserTypeComponent.prototype.continueWithType = function (type) {
        if (type === void 0) { type = 'no'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, token, state, selectedEmail, _b, err, response, extraParam;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        log('entered continueWithType');
                        if (this.isLoading) {
                            return [2 /*return*/];
                        }
                        this.loading();
                        _a = this.cbAuthURLService.getAllURLParams(), token = _a.token, state = _a.state;
                        selectedEmail = this.selectedEmail;
                        return [4 /*yield*/, to(this.cbBackendService.createEmailToken(token, selectedEmail).toPromise())];
                    case 1:
                        _b = _c.sent(), err = _b[0], response = _b[1];
                        if (err) {
                            this.isLoading = false;
                            this.cbFullstoryService.fsError('There was a problem with the email you selected.');
                            return [2 /*return*/, this.setErrorMessage('There was a problem with the email you selected. Please Try sign out and Sign In again or Contact us to help you.')];
                        }
                        extraParam = {
                            cventType: type,
                        };
                        this.cbFullstoryService.sendEvent('successfully_authenticated', { email: this.selectedEmail });
                        this.cbWebApi.continueToAuth0(response.token, state, extraParam);
                        return [2 /*return*/];
                }
            });
        });
    };
    CbCventUserTypeComponent.prototype.signOut = function () {
        this.cbWebApi.returnToAuthorizePage();
    };
    return CbCventUserTypeComponent;
}());
export { CbCventUserTypeComponent };
