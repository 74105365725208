import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { to } from 'await-to-js';
import { CbBackendService } from '../../services/cb-backend.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbStateService } from '../../services/cb-state.service';
import { LogFactory } from '../../services/cb-debug';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { isExpired } from './verify-account.features';
var log = LogFactory('VerifyAccountComponent');
var VerifyAccountComponent = /** @class */ (function () {
    function VerifyAccountComponent(cbBackendService, cbAuthURLService, cbStateService, cbWebApi, cbFullstoryService) {
        this.cbBackendService = cbBackendService;
        this.cbAuthURLService = cbAuthURLService;
        this.cbStateService = cbStateService;
        this.cbWebApi = cbWebApi;
        this.cbFullstoryService = cbFullstoryService;
        this.destroy$ = new Subject();
        this.isEmailVerified = false;
        this.isLoading = true;
        this.authorizeURL = '';
        this.loadingMessage = 'We are verifying your account';
        this.intervalID = null;
    }
    VerifyAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a = this.cbAuthURLService.getAllURLParams(), token = _a.token, email = _a.email, state = _a.state, authorizeURL = _a.authorizeURL;
        if (!token || !state) {
            this.cbWebApi.goToErrorPage('Invalid Access');
            return;
        }
        var expired = isExpired(token);
        if (expired) {
            this.error = true;
            this.errorMessage = 'Your token has expired.';
            this.isLoading = false;
            setTimeout(function () {
                _this.gotoLogin();
            }, 5000);
            return;
        }
        this.userToken = token;
        this.userEmail = email;
        this.state = state;
        this.authorizeURL = authorizeURL;
        this.sendEventVerifyAccount();
        this.verifyAccount();
    };
    VerifyAccountComponent.prototype.sendEventVerifyAccount = function () {
        var email = this.cbAuthURLService.getAllURLParams().email;
        var payload = {
            email: email,
        };
        this.cbFullstoryService.sendEvent('verify_account', payload);
    };
    VerifyAccountComponent.prototype.shouldMarkAsVerified = function () {
        // Verify the continue is in the same browser
        var expectedState = this.cbStateService.getExpectedState();
        var userCanContinue = expectedState && expectedState === this.state;
        if (userCanContinue) {
            return false;
        }
        return true;
    };
    VerifyAccountComponent.prototype.gerUserInfo = function () {
        return this.cbBackendService.getUser(this.userEmail, this.userToken).toPromise();
    };
    VerifyAccountComponent.prototype.startPollingUser = function (token) {
        var _this = this;
        var counter = 0;
        this.intervalID = setInterval(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, err, response, userExists;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, to(this.gerUserInfo())];
                    case 1:
                        _a = _b.sent(), err = _a[0], response = _a[1];
                        userExists = (response && response.data) || false;
                        if (userExists || counter > 25 || err) {
                            this.continueToAuth0(token, this.state);
                            clearInterval(this.intervalID);
                            return [2 /*return*/];
                        }
                        counter++;
                        return [2 /*return*/];
                }
            });
        }); }, 2000);
    };
    VerifyAccountComponent.prototype.startPollingUserV2 = function (token, counter) {
        if (counter === void 0) { counter = 0; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err, response, userExists;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        counter++;
                        return [4 /*yield*/, to(this.gerUserInfo())];
                    case 1:
                        _a = _b.sent(), err = _a[0], response = _a[1];
                        userExists = (response && response.data) || false;
                        if (userExists || counter > 25 || err) {
                            this.continueToAuth0(token, this.state);
                            return [2 /*return*/];
                        }
                        this.startPollingUserV2(token, counter);
                        return [2 /*return*/];
                }
            });
        });
    };
    VerifyAccountComponent.prototype.verifyAccount = function () {
        var _this = this;
        var options = {
            state: this.state,
            verifyAccount: this.shouldMarkAsVerified(),
            return: this.authorizeURL,
        };
        var email = this.cbAuthURLService.getAllURLParams().email;
        this.cbBackendService
            .verifyAccount(this.userToken, options)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (response) {
            _this.cbFullstoryService.sendEvent('successfully_authenticated', { email: email });
            // *info: Allow Salesforce finish the user creating
            _this.loadingMessage = 'We are setting up your profile, Please wait few seconds.';
            // todo: delay only if user can continue
            _this.startPollingUserV2(response.newToken);
        }, function (_a) {
            var error = _a.error, message = _a.message;
            log(' Error ', { error: error, message: message });
            _this.error = true;
            _this.isLoading = false;
            _this.errorMessage = (error && error.error && error.error.message) || message;
            setTimeout(function () {
                _this.gotoLogin();
            }, 1000);
            return;
        });
    };
    VerifyAccountComponent.prototype.continueToAuth0 = function (token, state) {
        var _this = this;
        var extras = {
            isSignup: true,
        };
        setTimeout(function () {
            _this.cbWebApi.continueToAuth0(token, state, extras);
        }, 1000);
    };
    VerifyAccountComponent.prototype.gotoLogin = function () {
        this.cbWebApi.returnToAuthorizePage();
    };
    VerifyAccountComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    return VerifyAccountComponent;
}());
export { VerifyAccountComponent };
