import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbNavigateToDirective } from './cb-navigate-to.directive';

const DIRECTIVES = [CbNavigateToDirective];

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  exports: [...DIRECTIVES],
  providers: [],
})
export class CbDirectivesModule {}
