import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faGithub, faGoogle, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faPlay,
  faQuestionCircle,
  fas,
} from '@fortawesome/free-solid-svg-icons';

import { CbIconComponent } from './cb-icon.component';

// * Currently using v0.3.0 (compatible with angular V6)
// * https://github.com/FortAwesome/angular-fontawesome/tree/0.3.0
// *
// * Current icons installed:
//  <cb-icon name="check-circle"></cb-icon>
//  <cb-icon name="exclamation-circle"></cb-icon>
//  <cb-icon name="question-circle"></cb-icon>
//  <cb-icon type="fab" name="google"></cb-icon>
//  <cb-icon type="fab" name="github"></cb-icon>

@NgModule({
  declarations: [CbIconComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [CbIconComponent],
  providers: [],
})
export class CbIconModule {
  constructor() {
    library.add(fas);
    library.add(faCheckCircle);
    library.add(faQuestionCircle);
    library.add(faExclamationCircle);
    library.add(faGithub);
    library.add(faGoogle);
    library.add(faLinkedinIn);
    library.add(faFacebookF);
    library.add(faPlay);
    library.add(faExclamationTriangle);
  }
}
