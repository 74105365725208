import { CbAuthURLService } from 'src/app/lib/services/cb-auth-url.service';
import { Injectable } from '@angular/core';

import { parse } from 'querystring';

import { LogFactory } from './cb-debug';
const log = LogFactory('CbGaService');

@Injectable({ providedIn: 'root' })
export class CbGaService {
  constructor(private cbAuthURLService: CbAuthURLService) {}

  getParamsFromURL(URL = '') {
    log('entered getParamsFromURL', { URL });

    if (!URL) {
      return {};
    }

    const [, params] = URL.split('?');

    if (!params) {
      return {};
    }

    const queryParams = parse(params);

    log('queryParams ? ', { queryParams });

    return queryParams;
  }

  getGAParamFromReturnTo() {
    log('entered getGAParamFromReturnTo');

    const returnPath = this.cbAuthURLService.getReturnPath();
    if (!returnPath) {
      return '';
    }

    const params = this.getParamsFromURL(returnPath);

    const ga = (params._ga as string) || '';

    return ga;
  }
}
