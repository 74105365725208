import { NG_VALUE_ACCESSOR, FormControl, ControlContainer, } from '@angular/forms';
import { OnInit, forwardRef, ElementRef, EventEmitter, OnDestroy, } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
export var CB_INPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line: no-use-before-declare
    useExisting: forwardRef(function () { return CbInputComponent; }),
    multi: true,
};
var CbInputComponent = /** @class */ (function () {
    function CbInputComponent(controlContainer, platformId) {
        this.controlContainer = controlContainer;
        this.platformId = platformId;
        this.inputElement = null;
        this.formControlName = '';
        this.label = 'label';
        this.type = 'text';
        this.iconType = 'fas';
        this.iconName = 'envelope';
        this.linkForgot = false;
        this.forgotLinkLabel = '';
        this.placeholder = '';
        this.required = false;
        this.validOnEmpty = false;
        this.errors = [];
        this.cbInputBlur = new EventEmitter();
        this.control = new FormControl();
        this.inputValue = '';
        this.isAutofocus = false;
        this.hasError = false;
        this.inputValue$ = new Subject();
        this.addErrorListener();
    }
    Object.defineProperty(CbInputComponent.prototype, "autofocus", {
        set: function (condition) {
            this.isAutofocus = condition !== false;
        },
        enumerable: true,
        configurable: true
    });
    CbInputComponent.prototype.ngOnInit = function () {
        if (this.controlContainer && this.controlContainer.control) {
            this.control = this.controlContainer.control.get(this.formControlName);
        }
    };
    CbInputComponent.prototype.notifyOnErrors = function (value) {
        this.hasError = false;
        this.inputValue$.next(value);
    };
    CbInputComponent.prototype.addErrorListener = function () {
        var _this = this;
        this.inputValue$.pipe(debounceTime(300)).subscribe(function () {
            _this.setHasError();
        });
    };
    CbInputComponent.prototype.setHasError = function () {
        this.control.updateValueAndValidity();
        this.hasError = (this.control.dirty && this.control.invalid) || (this.formGroupRef && this.formGroupRef.invalid);
    };
    // @info: focus is not supported for ios mobile , and it is breaking the mobile version
    // ngAfterViewInit() {
    //   if (this.isAutofocus) {
    //     this.setFocus();
    //   }
    // }
    CbInputComponent.prototype.setFocus = function () {
        if (isPlatformBrowser(this.platformId)) {
            this.inputElement.nativeElement.focus();
        }
    };
    CbInputComponent.prototype.onBlur = function (elementRef) {
        this.cbInputBlur.emit(elementRef);
    };
    CbInputComponent.prototype.onKeyup = function (value) {
        this.propagateChanges(value);
    };
    CbInputComponent.prototype.onEnter = function (event) {
        var value = event.target.value;
        this.control.markAsDirty();
        this.notifyOnErrors(value);
    };
    CbInputComponent.prototype.onClick = function (value) {
        if (this.type === 'checkbox') {
            this.inputValue = value ? 'checked' : 'unchecked';
            this.propagateChanges(value);
        }
    };
    CbInputComponent.prototype.getErrorMessage = function (errors) {
        if (!errors) {
            return;
        }
        var firstKey = Object.keys(errors)[0];
        return (this.errors && this.errors[firstKey]) || firstKey;
    };
    CbInputComponent.prototype.writeValue = function (obj) {
        this.inputValue = obj || '';
        if (this.validOnEmpty) {
            this.handleEmptyValue();
        }
    };
    CbInputComponent.prototype.handleEmptyValue = function () {
        if (!this.inputValue) {
            this.inputElement.nativeElement.value = '';
            this.setHasError();
        }
    };
    CbInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChanges = fn;
    };
    CbInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    CbInputComponent.prototype.setDisabledState = function () { };
    CbInputComponent.prototype.ngOnDestroy = function () {
        this.inputValue$.complete();
    };
    return CbInputComponent;
}());
export { CbInputComponent };
