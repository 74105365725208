import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CbBackendService } from '../../services/cb-backend.service';
import { EmailResponse } from '../../models/http.models';
import { CbAuthService } from '../../services/cb-auth.service';
import { CbRecaptchaService } from '../../services/cb-recpatcha.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';

@Component({
  selector: 'cb-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnDestroy, OnInit {
  form: FormGroup;
  usernameEmail: FormControl;
  destroy$ = new Subject();
  formSubmitted = false;
  failedMessage = '';
  resetPasswordFailed = false;
  resetPasswordSuccess = false;
  formLoading = false;
  captchaToken = null;
  captchaInterval: any;

  fieldErrors = {
    required: 'Email or username is a required field.',
    username: 'Invalid Username',
    email: 'Invalid Email',
  };

  errorAccountIncomplete = false;
  errorAccountIncompleteProvider = '';

  constructor(
    private fb: FormBuilder,
    private cbBackendService: CbBackendService,
    private cbRecaptchaService: CbRecaptchaService,
    private cbAuthURLService: CbAuthURLService,
    private cbAuthService: CbAuthService,
    private cbFullstoryService: CbFullstoryService
  ) {
    this.form = this.fb.group({
      usernameEmail: ['', [Validators.required]],
    });

    this.usernameEmail = this.form.get('usernameEmail') as FormControl;
  }

  ngOnInit(): void {
    this.fetchRecaptchaToken();
    this.captchaInterval = setInterval(() => {
      this.fetchRecaptchaToken();
    }, 120000);
  }

  fetchRecaptchaToken() {
    this.cbRecaptchaService
      .getCaptchaToken('resetPassword')
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.captchaToken = data;
      });
  }

  submit() {
    this.formLoading = true;
    this.formSubmitted = true;
    this.resetPasswordSuccess = false;
    this.resetPasswordFailed = false;
    if (this.form.valid) {
      this.resetPasswordFailed = false;
      this.resetPasswordSuccess = false;

      const returnValue = this.cbAuthURLService.getReturnPath();

      this.cbBackendService
        .resetPassword(this.usernameEmail.value, this.captchaToken, returnValue)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: EmailResponse) => {
            let queryParams = {};

            if (response) {
              queryParams = {
                email: this.usernameEmail.value,
                maskedEmail: response.email,
              };
            } else {
              queryParams = {
                email: this.usernameEmail.value,
              };
            }

            this.sendForgotPasswordEvent();

            this.cbAuthService.navigateTo('/cb/reset-password-confirmation', { queryParams });
          },
          (err) => {
            this.formLoading = false;
            this.failedMessage = err.error.error.message;
            this.resetPasswordFailed = true;
            this.fetchRecaptchaToken();
            this.cbFullstoryService.fsError(this.failedMessage);
            this.handlerError(err.error.error);
          }
        );
    }
  }

  handlerError(error: ResetPasswordError) {
    if (!error) {
      return;
    }

    if (error.code === 'create_account_incomplete') {
      const [provider] = error.details && error.details.user_id.split('|');
      this.errorAccountIncomplete = true;
      this.errorAccountIncompleteProvider = provider;
      this.clearTopError();
    }
  }

  clearTopError() {
    this.failedMessage = '';
    this.resetPasswordFailed = false;
  }

  finishRegistration() {
    this.cbAuthService.socialLogin(this.errorAccountIncompleteProvider).pipe(takeUntil(this.destroy$)).subscribe();
  }

  sendForgotPasswordEvent() {
    const userID = this.usernameEmail.value;
    const isEmail = userID.includes('@');
    const payload = {
      username: !isEmail ? userID : '',
      email: isEmail ? userID : '',
    };
    this.cbFullstoryService.sendEvent('forgot_password', payload);
  }

  ngOnDestroy() {
    clearInterval(this.captchaInterval);
    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface ResetPasswordError {
  status: string;
  code: string;
  message: string;
  details?: ResetPasswordErrorDetails;
}

interface ResetPasswordErrorDetails {
  user_id: string;
  email: string;
}
