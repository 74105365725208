import { Component, OnInit } from '@angular/core';
import { CbWebApi } from '../../services/cb-webapi';
import { CbFullstoryService } from '../../services/cb-fullstory.service';

@Component({
  selector: 'cb-unauthorize',
  templateUrl: './error-unauthorize.component.html',
})
export class CbUnauthorizeComponent implements OnInit {
  constructor(private cbWebApi: CbWebApi, private cbFullstoryService: CbFullstoryService) {}

  ngOnInit(): void {
    this.cbFullstoryService.fsError('Error unauthorize page');
  }

  gotoLogin() {
    this.cbWebApi.returnToAuthorizePage();
  }
}
