import { stringify } from 'querystring';
import { stage } from './cb-envars';
import { HttpClient } from '@angular/common/http';
import { LogFactory } from './cb-debug';
import { GetTimeZoneFriendlyNameByName } from './cb-registration.timezones';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var log = LogFactory('CbRegistrationService', false);
function GetRegistrationAPI() {
    switch (stage) {
        case 'prod':
        case 'production':
            return 'https://1m192jbree.execute-api.us-east-2.amazonaws.com/prod';
        default:
            // TODO: dev is pending to be re-created
            return 'https://1m192jbree.execute-api.us-east-2.amazonaws.com/prod';
    }
}
var CbRegistrationService = /** @class */ (function () {
    function CbRegistrationService(http) {
        this.http = http;
        this.API = GetRegistrationAPI();
    }
    CbRegistrationService.prototype.GetRegistration = function (id, type) {
        log('entered GetRegistration', { id: id, type: type });
        var q = stringify({ pageType: type });
        if (!id) {
            throw new Error('id not found');
        }
        var URL = this.API + "/registration/" + encodeURIComponent(id) + "?" + q;
        log('url ? ', { URL: URL });
        return this.http.get(URL);
    };
    CbRegistrationService.prototype.GetTime = function (registration) {
        var startTime = '';
        if (!registration) {
            return '';
        }
        startTime = registration.start_time;
        if (startTime) {
            return startTime;
        }
        return this.GetNextStartTime(registration.occurrences || []);
    };
    CbRegistrationService.prototype.GetNextStartTime = function (occurrences) {
        if (occurrences === void 0) { occurrences = []; }
        if (!occurrences.length) {
            return '';
        }
        return occurrences[0].start_time || '';
    };
    CbRegistrationService.prototype.GetTimezoneFriendlyName = function (timezone) {
        if (timezone === void 0) { timezone = ''; }
        return GetTimeZoneFriendlyNameByName(timezone);
    };
    CbRegistrationService.ngInjectableDef = i0.defineInjectable({ factory: function CbRegistrationService_Factory() { return new CbRegistrationService(i0.inject(i1.HttpClient)); }, token: CbRegistrationService, providedIn: "root" });
    return CbRegistrationService;
}());
export { CbRegistrationService };
