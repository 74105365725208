import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { CbAuthService } from './cb-auth.service';

@Injectable()
export class CbAuthDeactivateGuard implements CanDeactivate<any> {
  constructor(private cbAuthService: CbAuthService, private router: Router) {}

  canDeactivate() {
    // nextState?: RouterStateSnapshot // currentState: RouterStateSnapshot, // currentRoute: ActivatedRouteSnapshot, // component: any,
    this.cbAuthService.notifyCbActive(false);
    return true;
  }
}
