<div class="columns is-flex-mobile is-centered">
  <div class="column is-two-thirds is-four-fifths-mobile">
    <div class="actions">
      <cb-icon name="exclamation-circle" type="fas" size="9x" class="exclamationmark"> </cb-icon>

      <div class="cb-separator-2rem warning-title">
        {{ warningMessage }}
      </div>
    </div>
  </div>
</div>
