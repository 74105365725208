let appStage = 'development';
try {
  appStage = STAGE;
} catch (error) {
  console.log('no stage', { error });
}
if (appStage === 'dev') {
  appStage = 'development';
}
export const stage = appStage;

let appUseHash = false;
try {
  appUseHash = USE_HASH;
} catch (error) {
  console.log('no appUseHash', { error });
}
export const useHash = appUseHash;

export const debug = false;

let appCentralizedLogin = false;
try {
  appCentralizedLogin = CENTRALIZED_LOGIN;
} catch (error) {
  console.log('no appCentralizedLogin', { error });
}
export const centralizedLogin = appCentralizedLogin;

let appAuth0Domain = '';
try {
  appAuth0Domain = AUTH0_DOMAIN;
} catch (error) {
  console.log('no appCentralizedLogin', { error });
}
export const auth0Domain = appAuth0Domain;

let appAuth0ClientID = '';
try {
  appAuth0ClientID = AUTH0_CLIENT_ID;
} catch (error) {
  console.log('no AUTH0_CLIENT_ID', { error });
}
export const auth0ClientID = appAuth0ClientID;

let appAPI_URL = '';
try {
  appAPI_URL = API_URL;
} catch (error) {
  console.log('no appAPI_URL', { error });
}
export const apiURL = appAPI_URL;

let appSSO_STATIC_APP = false;
try {
  appSSO_STATIC_APP = SSO_STATIC_APP;
} catch (error) {
  console.log('no SSO_STATIC_APP', { error });
}
export const ssoStaticApp = appSSO_STATIC_APP;

let appRECAPCHA = '';
try {
  appRECAPCHA = RECAPCHA;
} catch (error) {
  console.log('no RECAPCHA', { error });
}
export const recapcha = appRECAPCHA;

let appCDN = '';
try {
  appCDN = CDN;
} catch (error) {
  console.log('no appCDN', { error });
}
export const cdn = appCDN;
