<div class="columns is-flex-mobile is-centered">
  <div class="column is-one-third is-four-fifths-mobile">
    <div class="actions">
      <div class="loader"></div>
      <div class="has-text-centered">
        {{ loadingMessage }}
      </div>
    </div>
  </div>
</div>
