import { Component, OnInit } from '@angular/core';
import { to } from 'await-to-js';

import { CbMyProfileService } from './../../services/cb-myprofile.service';
import { CbBackendService } from './../../services/cb-backend.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';

import { LogFactory } from '../../services/cb-debug';
const log = LogFactory('CbCventUserTypeComponent');

@Component({
  selector: 'cb-cvent-user-type',
  templateUrl: './cvent-user-type.component.html',
  styleUrls: ['./cvent-user-type.component.scss'],
})
export class CbCventUserTypeComponent implements OnInit {
  userEmails: string[] = [];
  selectedEmail = '';
  isLoading = true;
  errorMessage = '';

  constructor(
    private cbAuthURLService: CbAuthURLService,
    private cbBackendService: CbBackendService,
    private cbWebApi: CbWebApi,
    private cbMyProfileService: CbMyProfileService,
    private cbFullstoryService: CbFullstoryService
  ) {
    // document.title = 'Linux Foundation - Cvent';
  }

  ngOnInit() {
    const canContinue = this.cbWebApi.verifyUserCanContinue();
    if (!canContinue) {
      // * user will be redirected to authorize page
      return;
    }

    this.cbMyProfileService.displayMyProfileURL();

    this.setUserEmails();
    this.setInitialValues();
  }

  setInitialValues() {
    const decoded = this.cbAuthURLService.getDecodedToken();

    this.selectedEmail = decoded.email;
    this.userEmails = [decoded.email];
  }

  async setUserEmails() {
    log('entered setUserEmails');
    const { token } = this.cbAuthURLService.getAllURLParams();
    const [err, emails] = await to(this.cbBackendService.getUserEmails(token).toPromise());

    this.loadingEnd();

    if (err || !emails || !(emails as string[]).length) {
      // todo: maybe alert to the user
      return;
    }
    this.userEmails = emails as any;
  }

  registerMyself() {
    this.continueWithType('no');
  }

  registerSomeoneElse() {
    this.continueWithType('yes');
  }

  loading() {
    this.errorMessage = '';
    this.isLoading = true;
  }

  loadingEnd() {
    this.isLoading = false;
  }
  setErrorMessage(message = 'There was a problem, try again later') {
    this.isLoading = false;
    this.errorMessage = message;
  }

  async continueWithType(type: string = 'no') {
    log('entered continueWithType');

    if (this.isLoading) {
      return;
    }

    this.loading();

    const { token, state } = this.cbAuthURLService.getAllURLParams();

    const selectedEmail = this.selectedEmail;

    const [err, response] = await to(this.cbBackendService.createEmailToken(token, selectedEmail).toPromise());

    if (err) {
      this.isLoading = false;
      this.cbFullstoryService.fsError('There was a problem with the email you selected.');
      return this.setErrorMessage(
        'There was a problem with the email you selected. Please Try sign out and Sign In again or Contact us to help you.'
      );
    }

    const extraParam = {
      cventType: type,
    };

    this.cbFullstoryService.sendEvent('successfully_authenticated', { email: this.selectedEmail });
    this.cbWebApi.continueToAuth0(response.token, state, extraParam);
  }

  signOut() {
    this.cbWebApi.returnToAuthorizePage();
  }
}
