/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./request-confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/cb-ui/cb-confirmation/cb-confirmation.component.ngfactory";
import * as i3 from "../../shared/cb-ui/cb-confirmation/cb-confirmation.component";
import * as i4 from "../../services/confirmation-alert-data";
import * as i5 from "../../services/cb-webapi";
import * as i6 from "../../services/cb-login.config";
import * as i7 from "./request-confirmation.component";
import * as i8 from "@angular/router";
import * as i9 from "../../services/cb-auth.service";
var styles_RequestConfirmationComponent = [i0.styles];
var RenderType_RequestConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RequestConfirmationComponent, data: {} });
export { RenderType_RequestConfirmationComponent as RenderType_RequestConfirmationComponent };
export function View_RequestConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-confirmation", [], null, null, null, i2.View_CbConfirmationComponent_0, i2.RenderType_CbConfirmationComponent)), i1.ɵdid(1, 49152, null, 0, i3.CbConfirmationComponent, [i4.ConfirmationAlertData, i5.CbWebApi, i6.CbLoginConfig], { headerTitle: [0, "headerTitle"], confirmationTitle: [1, "confirmationTitle"], confirmationMessage: [2, "confirmationMessage"], linkText: [3, "linkText"], linkRouterPath: [4, "linkRouterPath"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.headerTitle, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.confirmationTitle, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.confirmationMessage, ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.linkText, ""); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.linkRouterPath, ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_RequestConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_RequestConfirmationComponent_0, RenderType_RequestConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i7.RequestConfirmationComponent, [i8.ActivatedRoute, i9.CbAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RequestConfirmationComponentNgFactory = i1.ɵccf("ng-component", i7.RequestConfirmationComponent, View_RequestConfirmationComponent_Host_0, {}, {}, []);
export { RequestConfirmationComponentNgFactory as RequestConfirmationComponentNgFactory };
