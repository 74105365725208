import { Injectable } from '@angular/core';
import { WebAuth } from 'auth0-js';
import { CbLoginConfig } from './cb-login.config';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { CbToken, CBTokenUser, CBStoredToken } from '../models/cb-auth.models';
import { switchMap, map, filter, take, delay } from 'rxjs/operators';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { stringify } from 'querystring';
import { LogFactory } from './cb-debug';

const log = LogFactory('CbAuthService', false);

const OPENID_CONNECT_SCOPES = 'openid profile email';
const REALM = 'Username-Password-Authentication';
const STORAGE_KEY = 'cbtoken';
const STORAGE_REDIRECT_KEY = 'cb-redirectTo';
const QUERY_KYES = {
  token: 'token',
  return: 'return',
  email: 'email',
  state: 'state',
  clientName: 'clientName',
};
export interface CBWarningExpiryTime {
  active: boolean;
  expiresIn?: number;
}

@Injectable({ providedIn: 'root' })
export class CbAuthService {
  // Allow track the current user session, like form.valuesChanges (ReactiveForm Module)
  cbSession$: BehaviorSubject<CBStoredToken | undefined> = new BehaviorSubject(undefined);
  user$: BehaviorSubject<CBTokenUser | undefined> = new BehaviorSubject(undefined);
  isCBActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isCBActive = false;
  cbWarningExpiryTime$: BehaviorSubject<CBWarningExpiryTime> = new BehaviorSubject({ active: false });
  returnURL = '';

  /**
   * Error Page Flow:
   * If there is any error, user will be redirected to /cb/error-page?error-info......
   * also this is an dead End so we won't provide a return option
   *
   * On auth0 error:
   * ---> User redirection ---> Error Page -> Help Page -> User Send Error info within the Help request
   *
   * - Logo Link no required
   * - back link in help page no required
   * - Other pages must be restricted
   *
   */
  isErrorFlow = false;

  // @todo: pass storage as dependency
  private storage = localStorage;
  // @todo: pass window.document as dependency
  private documentLocation = document.location;

  private auth0: WebAuth;

  currentFragments: any;

  constructor(private loginConfig: CbLoginConfig, private router: Router, private activatedRoute: ActivatedRoute) {
    if (this.loginConfig.cbSsoStaticApp) {
      this.loginConfig.cbSkipLocalChanges = true;
    }

    this.initializeAuth0Library();
    this.syncStoreToken();
    this.addExpiryTimeListener();
  }

  serializeToQueryParams(obj, prefix: string = '') {
    const str = [];
    let p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? this.serializeToQueryParams(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
    return str.join('&');
  }

  goToErrorPage(errorInfo: { error: string; message: string; extras?: any }) {
    let errorData = {
      error: (errorInfo && errorInfo.error) || 'unknown Error',
      error_description: (errorInfo && errorInfo.message) || 'Please contact support for additional help.',
    };

    if (errorInfo.extras) {
      errorData = {
        ...errorData,
        ...errorInfo.extras,
      };
    }

    this.router.navigateByUrl(`/cb/error-page?${this.serializeToQueryParams(errorData)}`);
    return;
  }

  navigateTo(path = '', extras: NavigationExtras = {}) {
    const skipLocationChange = this.loginConfig.cbAuthCentralizedLogin || this.loginConfig.cbSkipLocalChanges;
    this.router.navigate([path], { ...extras, skipLocationChange });
  }

  notifyCbActive(value: boolean) {
    if (value === this.isCBActive) {
      return;
    }
    this.isCBActive = value;
    this.isCBActive$.next(value);
  }

  initializeAuth0Library() {
    try {
      // Angular Environment eg.LFX
      // Config is provided by CbLoginConfig
      let authOptions = this.getAuth0ConfigFromApp();

      // Auth0 Tenant Universal Login
      // Config is provided by  window[auth0Key]
      if (this.loginConfig.cbAuthCentralizedLogin) {
        authOptions = this.getAuth0ConfigFromAuth0();
      }

      this.auth0 = new WebAuth(authOptions);
    } catch (error) {
      console.error('There was an error initiating cb-login module');
    }
  }

  getAuth0ConfigFromApp() {
    return {
      clientID: this.loginConfig.clientId,
      clientName: this.loginConfig.clientName,
      domain: this.loginConfig.domain,
      responseType: this.loginConfig.cbResponseType,
      redirectUri: this.getCallbackRedirection(),
      scope: OPENID_CONNECT_SCOPES,
    };
  }

  getAuth0ConfigFromAuth0(): any {
    const config = this.getConfigFromAuth0Environment();
    if (!config) {
      return {};
    }

    const responseType =
      (config.internalOptions || ({} as any)).response_type || (config.callbackOnLocationHash ? 'token' : 'code');
    const commonParams = {
      clientID: config.clientID,
      clientName: config.dict.signin.title,
      domain: config.auth0Domain,
      responseType,
      overrides: {
        __tenant: config.auth0Tenant,
        __token_issuer: config.authorizationServer.issuer,
      },
    };

    return Object.assign(commonParams, config.internalOptions);
  }

  getConfigFromAuth0Environment(): Auth0Config {
    log('entered getConfigFromAuth0Environment');
    const auth0Key = '_auth0Config';
    const config = window[auth0Key];
    return config;
  }

  generateReturnFromAuth0Config(config: Auth0Config) {
    if (!config) {
      return '';
    }
    let returnPath = 'authorize';
    const extraParams: ExtraParams = config.extraParams || ({} as ExtraParams);
    const internalOptions = config.internalOptions || ({} as InternalOptions);
    if ((extraParams.protocol || internalOptions.protocol || '') === 'samlp') {
      returnPath = `samlp/${config.clientID}`;
    }

    const queryParams = {
      client_id: config.clientID,
    };

    Object.keys(config.extraParams).forEach((key) => {
      queryParams[key] = config.extraParams[key];
    });

    const returnQuery = stringify(queryParams);
    const returnURL = encodeURIComponent(`${returnPath}?${returnQuery}`);

    return returnURL;
  }

  persistConfigParamsForReturn() {
    const config = this.getConfigFromAuth0Environment();
    return this.generateReturnFromAuth0Config(config);
  }

  getLogoLinkInAuth0Page() {
    log('entered getLogoLinkInAuth0Page');
    return this.getClientDomainFromAuth0Config();
  }

  getClientDomainFromAuth0Config() {
    log('entered getClientDomainFromAuth0Config');
    if (this.returnURL) {
      return this.returnURL;
    }

    const config = this.getConfigFromAuth0Environment();
    if (!config) {
      return '';
    }

    const url = this.parseUrl(config.callbackURL);
    this.returnURL = url.origin;

    return this.returnURL;
  }

  parseUrl(url: string = '') {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    const { host, hostname, pathname, port, protocol, search, hash } = a;
    const origin = `${protocol}//${hostname}${port.length ? `:${port}` : ''}`;
    return { origin, host, hostname, pathname, port, protocol, search, hash };
  }

  getValuesFromFragments(frags: string) {
    return (
      (frags &&
        frags.split('&').reduce((acc, item) => {
          const [key, value] = item.split('=');
          acc[key] = value;
          return { ...acc };
        }, {})) ||
      {}
    );
  }

  getDataFromFragment(key: string = '') {
    this.currentFragments = this.fragmentToObject();

    if (key) {
      return this.currentFragments[key];
    }

    return this.currentFragments;
  }

  fragmentToObject() {
    const fragments = this.activatedRoute.snapshot.fragment || '';
    const [extra, returnURL] = fragments.split('&return=');
    const frags = this.getValuesFromFragments(extra);
    if (returnURL) {
      frags[QUERY_KYES.return] = returnURL;
    }
    return frags;
  }

  setDefaultReturnURL(returnURL: string) {
    this.returnURL = returnURL;
  }

  getReturnURLInSSOStaticApp() {
    if (this.returnURL) {
      return this.returnURL;
    }

    const returnURL = this.getReturnURLFromFragments() || this.getReturnURLFromQueryParam();
    if (!returnURL) {
      return '';
    }

    this.returnURL = `https://${this.loginConfig.domain}/${decodeURIComponent(returnURL)}`;

    return this.returnURL;
  }

  getReturnURLFromFragments() {
    const fragments = this.getDataFromFragment();
    return fragments[QUERY_KYES.return];
  }

  getReturnURLFromQueryParam() {
    return this.activatedRoute.snapshot.queryParams.return;
  }

  getCallbackRedirection() {
    return `${this.documentLocation.origin}/auth`;
  }

  getToHomePage() {
    return `${this.documentLocation.origin}`;
  }

  goToLogInPage(redirectTo: string = '/') {
    this.navigateTo('/cb/login', { queryParams: { redirectTo } });
  }

  saveRedirectTo(redirectTo: string = '/') {
    this.storage.setItem(STORAGE_REDIRECT_KEY, redirectTo);
  }

  clearRedirectTo() {
    this.storage.removeItem(STORAGE_REDIRECT_KEY);
    return true;
  }

  redirectTo() {
    const redirectToUrl = this.storage.getItem(STORAGE_REDIRECT_KEY) || '/';
    this.clearRedirectTo();
    this.navigateTo(redirectToUrl);
  }

  syncStoreToken() {
    this.getSavedCbToken().subscribe((cbStoredToken) => this.notifyUserSession$(cbStoredToken));
  }

  isUserSessionValid(): Observable<boolean> {
    return this.getSavedCbToken().pipe(
      switchMap((cBStoredToken) => {
        const invalid = of(false);
        const valid = of(true);

        if (!cBStoredToken) {
          return invalid;
        }

        // Verify expiresAt
        const token = cBStoredToken.token;
        if (token.expiresAt.getTime() < new Date().getTime()) {
          this.logout().subscribe();
          return invalid;
        }

        return valid;
      })
    );
  }

  getSavedCbToken(): Observable<CBStoredToken | undefined> {
    return new Observable((observer) => {
      const cbStoredToken = this.storage.getItem(STORAGE_KEY);

      if (!cbStoredToken) {
        observer.next(undefined);
        observer.complete();
        return;
      }

      const { user, token } = JSON.parse(cbStoredToken);
      const cbToken = {
        user,
        token: {
          ...token,
          expiresAt: new Date(token.expiresAt),
        },
      };

      observer.next(cbToken);
      observer.complete();
    });
  }

  getUserInfo(): Observable<CBTokenUser> {
    return this.getSavedCbToken().pipe(map((storedToken) => (storedToken && storedToken.user) || undefined));
  }

  login(username: string, password: string): Observable<boolean> {
    return new Observable((observer) => {
      this.auth0.login({ username, password, realm: REALM }, (error: any) => {
        if (error) {
          observer.error(error);
          observer.complete();
          return;
        }
      });
    });
  }

  socialLogin(connectionName): Observable<any> {
    return new Observable((observer) => {
      this.auth0.authorize({ connection: connectionName }, (error) => observer.error(error));
    });
  }

  // @info: redirect by default to Login page
  // It requires Auth0 app suport this page as Allowed logout URLs
  logout(redirectTo = '/cb/login'): Observable<boolean> {
    const returnTo = this.getToHomePage() + redirectTo;

    return new Observable((observer) => {
      this.auth0.logout({ returnTo });
      this.storage.removeItem(STORAGE_KEY);
      this.notifyUserSession$(undefined);
      this.notifyWarning();
      observer.next(true);
      observer.complete();
    });
  }

  saveToken(hash: string): Observable<boolean> {
    return this.parseHash(hash).pipe(
      switchMap((decodedToken) =>
        this.parseToken(decodedToken).pipe(
          map((userInfo) => {
            const userSession = { token: decodedToken, user: userInfo };
            this.notifyUserSession$(userSession);
            return this.saveInStorage(userSession);
          })
        )
      )
    );
  }

  notifyWarning(active = false, expiresIn = 0) {
    this.cbWarningExpiryTime$.next({ expiresIn, active });
  }

  addExpiryTimeListener(): void {
    this.cbSession$
      .pipe(
        filter((value) => !!value),
        take(1)
      )
      .subscribe((userSession) => {
        const WARNING_TIME = this.loginConfig.cbWarningTime;
        const expiryTime = userSession.token.expiresAt;
        const timeUntilExpiry = expiryTime.getTime() - new Date().getTime();
        const timeUntilWarning = timeUntilExpiry - WARNING_TIME;
        const isInWarningTime = timeUntilExpiry <= WARNING_TIME;

        if (isInWarningTime) {
          this.notifyWarning(true, timeUntilExpiry);

          if (timeUntilExpiry > 0) {
            of(true)
              .pipe(delay(timeUntilExpiry))
              .subscribe(() => this.addExpiryTimeListener());
          }

          return;
        }

        this.notifyWarning(false, timeUntilExpiry);
        of(true)
          .pipe(delay(timeUntilWarning))
          .subscribe(() => this.addExpiryTimeListener());
      });
  }

  renewAuthToken(): Observable<boolean> {
    return new Observable((observer) => {
      this.auth0.checkSession({}, (error, decodedHash) => {
        if (error) {
          observer.error(new Error('Failed to load token.'));
          observer.complete();
          return;
        }

        const userSession = {
          token: {
            ...decodedHash,
            expiresAt: this.getExpiresAt(),
          },
          user: {
            ...decodedHash.idTokenPayload,
          },
        };

        // @info: not necessary in token Object
        delete userSession.token.idTokenPayload;

        this.notifyUserSession$(userSession);

        const WARNING_TIME = this.loginConfig.cbWarningTime;
        const timeUntilExpiry = userSession.token.expiresAt.getTime() - new Date().getTime();
        const timeUntilWarning = timeUntilExpiry - WARNING_TIME;
        this.notifyWarning(false, timeUntilWarning);

        this.addExpiryTimeListener();
        this.saveInStorage(userSession);

        observer.next(true);
        observer.complete();
      });
    });
  }

  notifyUserSession$(value: CBStoredToken | undefined) {
    this.cbSession$.next(value);
    this.user$.next((value && value.user) || undefined);
  }

  private getExpiresAt() {
    // @info: 10h is the time assign in auth0 app
    const expireTime = 36000000;
    return new Date(new Date().getTime() + expireTime);
  }

  private saveInStorage(object: any): boolean {
    this.storage.setItem(STORAGE_KEY, JSON.stringify(object));
    return true;
  }

  private parseHash(hash: string): Observable<CbToken> {
    return new Observable((observer) => {
      this.auth0.parseHash({ hash }, (err: any, authResult: any) => {
        if (err) {
          observer.error(err);
        }

        const cbToken = {
          ...authResult,
          expiresAt: this.getExpiresAt(),
        };

        observer.next(cbToken);
        observer.complete();
      });
    });
  }

  private parseToken(decodedToken: any): Observable<CBTokenUser> {
    return new Observable((observer) => {
      this.auth0.client.userInfo(decodedToken.accessToken, (err: any, user: any) => {
        if (err) {
          observer.error(err);
        }
        observer.next(user);
        observer.complete();
      });
    });
  }
}

export interface Signin {
  title?: string;
  logo?: string;
}

export interface Dict {
  signin: Signin;
}

export interface ExtraParams {
  protocol: string;
  response_type: string;
  response_mode: string;
  scope: string;
  nonce: string;
  auth0Client: string;
  _csrf: string;
  _intstate: string;
  state: string;
}

export interface InternalOptions {
  protocol: string;
  response_type: string;
  response_mode: string;
  scope: string;
  nonce: string;
  auth0Client: string;
  _csrf: string;
  _intstate: string;
  state: string;
}

export interface AuthorizationServer {
  url: string;
  issuer: string;
}

export interface Colors {
  primary: string;
  page_background: string;
}

export interface ClientMetadata {
  // @info: Control email template ,etc
  cb_app: boolean;
  // @info: control Layout design
  display_banner: boolean;
  app_name: string;
  app_logo: string;
}

export interface Auth0Config {
  clientMetadata?: ClientMetadata;
  assetsUrl: string;
  auth0Domain: string;
  auth0Tenant: string;
  clientConfigurationBaseUrl: string;
  callbackOnLocationHash: boolean;
  callbackURL: string;
  cdn: string;
  clientID: string;
  dict: Dict;
  extraParams: ExtraParams;
  internalOptions: InternalOptions;
  widgetUrl: string;
  isThirdPartyClient: boolean;
  authorizationServer: AuthorizationServer;
  colors: Colors;
}
