import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { stage } from './cb-envars';

import { LogFactory } from './cb-debug';
const log = LogFactory('CbMyProfileService', false);

// const appStage = STAGE || 'development';
function GetStage() {
  log('entered GetStage', { stage });

  switch (stage) {
    case 'development':
    case 'dev':
      return 'dev';
    case 'production':
    case 'prod':
      return 'prod';
    case 'stage':
    case 'staging':
      return 'staging';
    case 'test':
      return 'test';
    default:
      return 'dev';
  }
}

const innerStage = GetStage();

const ManageProfileMap = {
  prod: 'https://myprofile.lfx.linuxfoundation.org/',
  default: `https://myprofile.${innerStage}.platform.linuxfoundation.org/`,
};

const MyProfileURL = ManageProfileMap[innerStage] || ManageProfileMap.default;

@Injectable({ providedIn: 'root' })
export class CbMyProfileService {
  URL = MyProfileURL;
  myProfileURLdisplayed$ = new BehaviorSubject<boolean>(false);

  displayMyProfileURL() {
    log('entered displayMyProfileURL');
    this.myProfileURLdisplayed$.next(true);
  }
}
