import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CbAuthService } from '../services/cb-auth.service';

@Directive({
  selector: '[cbNavigateTo]',
})
export class CbNavigateToDirective {
  @Input() cbNavigateTo = '';
  constructor(private el: ElementRef, private cbAuthService: CbAuthService) {}

  @HostListener('click') onClick() {
    this.cbAuthService.navigateTo(this.cbNavigateTo);
  }
}
