import * as i0 from "@angular/core";
/**
 * Assets uploaded in S3: https://cb-login-controller-assets-dev.s3.amazonaws.com/
 */
var CbLoginCustomConfig = /** @class */ (function () {
    function CbLoginCustomConfig() {
    }
    CbLoginCustomConfig.ngInjectableDef = i0.defineInjectable({ factory: function CbLoginCustomConfig_Factory() { return new CbLoginCustomConfig(); }, token: CbLoginCustomConfig, providedIn: "root" });
    return CbLoginCustomConfig;
}());
export { CbLoginCustomConfig };
