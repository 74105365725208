import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CbBackendService } from '../../services/cb-backend.service';
import { ConfirmationAlertData } from '../../services/confirmation-alert-data';
import { CbLoginConfig } from '../../services/cb-login.config';
import { CbCreateAccountEmailPageContent } from './create-account-email-content';
import { CbStateService } from '../../services/cb-state.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { removeUnnededParams } from '../../shared/features';

const QUERY_KYES = {
  token: 'token',
  return: 'return',
  email: 'email',
  state: 'state',
};

@Component({
  templateUrl: './create-account-email.component.html',
  styleUrls: ['./create-account-email.component.scss'],
})
export class CreateAccountEmailComponent implements OnInit, OnDestroy {
  pageContent = new CbCreateAccountEmailPageContent();
  destroy$ = new Subject();
  userEmail: string;
  userToken: string;
  isLoading = false;
  isVerifing: string;
  isEmailVerified = false;
  isSSoStaticApp = false;

  constructor(
    private loginConfig: CbLoginConfig,
    private cbBackendService: CbBackendService,
    private confirmationAlertData: ConfirmationAlertData,
    private cbWebApi: CbWebApi,
    private router: Router,
    private cbStateService: CbStateService,
    private activatedRoute: ActivatedRoute,
    private cbFullstoryService: CbFullstoryService
  ) {
    this.isSSoStaticApp = this.loginConfig.cbSsoStaticApp;
  }

  ngOnInit(): void {
    this.setClientAppValues();

    this.isLoading = true;
    this.confirmationAlertData.init();
    this.initResendSection();
  }

  setClientAppValues() {
    const fragments = this.getDataFromFragment();
    this.userToken = fragments[QUERY_KYES.token];
    this.userEmail = fragments[QUERY_KYES.email];

    const state = this.activatedRoute.snapshot.queryParams[QUERY_KYES.state];

    if (!this.userEmail || !state || !this.userToken) {
      this.handleUnauthorizedAccessInSsoStaticApp();
      return;
    }
    this.setReturnURL();
    // save current state to verify it in cb/verify-page
    this.cbStateService.saveExpectedState(state);
  }

  getDataFromFragment() {
    const fragments = this.activatedRoute.snapshot.fragment || '';
    const [extra, returnURL] = fragments.split('&return=');
    const frags = this.getValuesFromFragments(extra);
    frags[QUERY_KYES.return] = returnURL;
    return frags;
  }

  getValuesFromFragments(frags: string) {
    return (
      (frags &&
        frags.split('&').reduce((acc, item) => {
          const [key, value] = item.split('=');
          acc[key] = value;
          return { ...acc };
        }, {})) ||
      {}
    );
  }

  handleUnauthorizedAccessInSsoStaticApp() {
    const error = 'invalid_access';
    this.cbFullstoryService.fsError('handle Unauthorized Access In Sso Static App');
    this.router.navigateByUrl(`/cb/error-page?error=${error}`);
  }

  setReturnURL() {
    const returnPath = this.getDataFromFragment()[QUERY_KYES.return];

    if (returnPath) {
      this.pageContent.linkRedirect = true;

      const auth0Domain = this.loginConfig.domain;
      const returnUrl = `https://${auth0Domain}/${returnPath}`;

      this.pageContent.linkRouterPath = returnUrl;
    }
  }

  initResendSection() {
    this.cbBackendService
      .canResendVerification(this.userEmail, this.userToken)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          if (response.code === 'ldap_user_verified') {
            this.continueToAuth0(response.user.newToken);
            return;
          }

          this.isLoading = false;

          const showSuccessAlert = !this.loginConfig.cbSsoStaticApp;
          this.resendVerificationEmail(showSuccessAlert);
        },
        (err) => {
          this.isLoading = false;

          if (err.error.error.code === 'validation_first_verification_email_sent') {
            this.pageContent.showContent = true;
            this.confirmationAlertData.setSuccess('Email verification is already sent');
            return;
          }

          this.confirmationAlertData.setError(err.error.error.message);
          this.pageContent.showContent = false;

          setTimeout(() => {
            this.cbWebApi.returnToAuthorizePage();
          }, 1000);
        }
      );
  }

  displayUserVerifiedMessage() {
    this.pageContent.showContent = false;
    this.isLoading = false;
    this.confirmationAlertData.setError('User already verified.');
  }

  continueToAuth0(token) {
    const state = this.activatedRoute.snapshot.queryParams[QUERY_KYES.state];
    return this.cbWebApi.continueToAuth0(token, state);
  }

  removeUnnededParams(url = '') {
    if (!url) {
      return;
    }

    let newUrl;

    newUrl = decodeURIComponent(url);
    newUrl = removeUnnededParams(newUrl);
    newUrl = encodeURIComponent(newUrl);
    return newUrl;
  }

  resendVerificationEmail = (showSuccessAlert = true) => {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const fragments = this.getDataFromFragment();
    const options = {
      state: queryParams[QUERY_KYES.state],
      token: fragments[QUERY_KYES.token],
      return: this.removeUnnededParams(fragments[QUERY_KYES.return]),
    };

    this.cbBackendService
      .resendVerficationEmail(this.userEmail, options)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          if (showSuccessAlert) {
            this.confirmationAlertData.setSuccess(this.pageContent.resendSuccessMessage);
          }
        },
        (err) => {
          const { error: { error: { code = '', message = '' } = {} } = {} } = err;
          if (code === 'user-verified') {
            this.isEmailVerified = true;
          }

          this.confirmationAlertData.setError(message);
        }
      );
  };

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
