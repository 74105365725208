/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-warning-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../cb-icon/cb-icon.component.ngfactory";
import * as i3 from "../cb-icon/cb-icon.component";
import * as i4 from "./cb-warning-page.component";
var styles_CbWarningPageComponent = [i0.styles];
var RenderType_CbWarningPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbWarningPageComponent, data: {} });
export { RenderType_CbWarningPageComponent as RenderType_CbWarningPageComponent };
export function View_CbWarningPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "columns is-flex-mobile is-centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "column is-two-thirds is-four-fifths-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "cb-icon", [["class", "exclamationmark"], ["name", "exclamation-circle"], ["size", "9x"], ["type", "fas"]], null, null, null, i2.View_CbIconComponent_0, i2.RenderType_CbIconComponent)), i1.ɵdid(4, 49152, null, 0, i3.CbIconComponent, [], { type: [0, "type"], name: [1, "name"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "cb-separator-2rem warning-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "fas"; var currVal_1 = "exclamation-circle"; var currVal_2 = "9x"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.warningMessage; _ck(_v, 6, 0, currVal_3); }); }
export function View_CbWarningPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-warning-page", [], null, null, null, View_CbWarningPageComponent_0, RenderType_CbWarningPageComponent)), i1.ɵdid(1, 49152, null, 0, i4.CbWarningPageComponent, [], null, null)], null, null); }
var CbWarningPageComponentNgFactory = i1.ɵccf("cb-warning-page", i4.CbWarningPageComponent, View_CbWarningPageComponent_Host_0, { warningMessage: "warningMessage" }, {}, []);
export { CbWarningPageComponentNgFactory as CbWarningPageComponentNgFactory };
