import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CbBackendService } from '../../services/cb-backend.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomValidators, regexPattern } from '../../shared/custom-validations/custom-validations';
import { CbLoginConfig } from '../../services/cb-login.config';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from 'src/app/lib/services/cb-auth-url.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { isExpired } from '../verify-account/verify-account.features';
import { to } from 'await-to-js';

@Component({
  selector: 'cb-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnDestroy, OnInit {
  token = '';
  changePasswordFailed = false;
  changePasswordSuccess = false;
  failedMessage = '';
  formSubmitted = false;
  form: FormGroup;
  password: FormControl;
  confirmPassword: FormControl;
  destroy$ = new Subject();
  formLoading = true;
  loginPath = '/cb/login';
  displayCloseWindowMessage = false;
  isExpired = false;
  pageLoading = true;

  passwordErrors = {
    required: 'Password is required.',
    pattern: 'Must have at least 8 characters, including upper, lower, numeric, and special characters.',
  };

  confirmPasswordErrors = {
    required: 'Confirm password is required.',
    pattern: 'Must have at least 8 characters, including upper, lower, numeric, and special characters.',
    passwordMatch: 'Passwords are not identical.',
  };

  constructor(
    private fb: FormBuilder,
    private cbBackendService: CbBackendService,
    private cbAuthURLService: CbAuthURLService,
    public cbloginConfig: CbLoginConfig,
    private cbWebApi: CbWebApi,
    private cbFullstoryService: CbFullstoryService
  ) {}

  async ngOnInit() {
    const { token } = this.cbAuthURLService.getAllURLParams();

    const expired = isExpired(token);
    if (expired) {
      this.isExpired = true;
      this.formLoading = false;
      this.pageLoading = false;
      return;
    }

    const [err] = await to(this.cbBackendService.checkResetPasswordToken(token).toPromise());
    if (err) {
      this.isExpired = true;
      this.formLoading = false;
      this.pageLoading = false;

      return;
    }

    this.formLoading = false;
    this.pageLoading = false;

    this.token = token;

    this.createForm();
  }

  createForm() {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required, Validators.pattern(regexPattern.strongPassword)]],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: CustomValidators.passwordMatcher }
    );

    this.password = this.form.get('password') as FormControl;
    this.confirmPassword = this.form.get('confirmPassword') as FormControl;
  }

  submit() {
    this.formLoading = true;
    this.formSubmitted = true;
    if (this.form.valid) {
      this.changePasswordFailed = false;
      this.cbBackendService
        .changePassword(this.password.value, this.token)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.handleChangePasswordSucceed();
          },
          (err) => {
            this.formLoading = false;
            this.failedMessage = err.error.error.message;
            this.changePasswordFailed = true;
            this.cbFullstoryService.fsError(this.failedMessage);
          }
        );
    }
  }

  handleChangePasswordSucceed() {
    const { authorizeURL } = this.cbAuthURLService.getAllURLParams();

    this.formLoading = false;
    this.changePasswordSuccess = true;

    const canContinue = authorizeURL ? true : false;
    if (canContinue) {
      this.cbWebApi.returnToAuthorizePage();
      return;
    }

    this.displayCloseWindowMessage = true;
  }

  cancel() {
    this.cbWebApi.returnToAuthorizePage();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
