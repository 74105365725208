var CbButtonComponent = /** @class */ (function () {
    function CbButtonComponent() {
        this.isDisabled = false;
        this.id = '';
        this.type = 'is-info';
        this.style = '';
        this.isLoading = false;
        this.size = '';
        this.buttonType = 'button';
    }
    Object.defineProperty(CbButtonComponent.prototype, "disabled", {
        get: function () {
            return this.isDisabled;
        },
        set: function (conditional) {
            this.isDisabled = conditional !== false;
        },
        enumerable: true,
        configurable: true
    });
    return CbButtonComponent;
}());
export { CbButtonComponent };
