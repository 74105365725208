<button
  type="{{ buttonType }}"
  id="{{ id }}"
  class="button {{ type }} {{ style }} font-source-sans-pro bold-600"
  [ngClass]="{ 'is-loading': isLoading }"
  [class.disabled]="disabled"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</button>
