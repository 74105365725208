import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import * as querystring from 'querystring';

import { LogFactory } from './cb-debug';
import { CbAuthURLService } from './cb-auth-url.service';

const log = LogFactory('CbFullstoryService', false);

const LIB_FS = (window as any).FS;
let identified = false;

export interface FullStoryIdentity {
  username?: string;
  email?: string;
  displayName?: string; // user fullname
  sessionid?: string;
}

@Injectable({ providedIn: 'root' })
export class CbFullstoryService {
  sessionid = '';

  constructor(private cbAuthURLService: CbAuthURLService) {
    this.setSessionId();
  }

  getSessionidFromReturnTo(): string {
    const { authorizeURL = '' } = this.cbAuthURLService.getAllURLParams() || {};

    if (!authorizeURL) {
      return '';
    }

    const [, search = ''] = authorizeURL.split('?');
    if (!search) {
      return '';
    }

    const params = querystring.parse(search) || {};

    return (params.sessionid as string) || '';
  }

  setSessionId() {
    log('entered setSessionId');
    const params = this.cbAuthURLService.getAllURLParams() || {};

    if (params.sessionid) {
      this.sessionid = params.sessionid;
      log('sessionid', { sessionid: params.sessionid });
      return;
    }

    const sessionid = this.getSessionidFromReturnTo();
    if (sessionid) {
      this.sessionid = sessionid;
      log('sessionid', { sessionid });
      return;
    }

    log('creating sessionid');

    this.sessionid = uuidv4();
    log('sessionid', { sessionid: this.sessionid });

    return true;
  }
  identify(uid = '', data: FullStoryIdentity) {
    const userVars = {
      ...data,
      sessionid: this.sessionid,
    };

    log('FS identity', { uid, userVars, identified });

    if (identified) {
      this.setUserVars(userVars);
      return;
    }

    if (!uid) {
      this.setUserVars(userVars);
      return;
    }

    const isAuth0User = uid.includes('auth0|') || !uid.includes('|');
    log('FS identity', { isAuth0User });

    if (!isAuth0User) {
      this.setUserVars(userVars);
      return;
    }

    const innerUID = uid.replace('auth0|', '');
    log(' set identity', { innerUID, userVars });

    LIB_FS.identify(innerUID, userVars);
    identified = true;
  }

  setUserVars(data = {}) {
    log('entered setUserVars', { data });
    const userVars = {
      ...data,
      sessionid: this.sessionid,
    };

    LIB_FS.setUserVars(userVars);
  }

  initFullstory() {
    log('entered initFullstory');

    const params = this.cbAuthURLService.getAllURLParams();
    const decoded = this.cbAuthURLService.getDecodedToken();

    const usernameFromSub = decoded.sub ? decoded.sub.replace('auth0|', '') : '';
    const uid = decoded.username || params.username || usernameFromSub;
    const firstName = decoded.firstName || decoded.given_name || params.firstName || params.given_name || '';
    const lastName = decoded.lastName || decoded.family_name || params.lastName || params.lastName || '';
    const email = decoded.email || params.email;
    const username = decoded.username || params.username;
    const displayName = `${firstName} ${lastName}`;

    const data: FullStoryIdentity = {
      email,
      username,
      displayName: displayName.trim(),
      sessionid: this.sessionid,
    };
    this.identify(uid, data);
  }

  sendEvent(eventName = '', payload = {}) {
    if (!eventName) {
      return;
    }

    const data = {
      ...payload,
      sessionid: this.sessionid,
    };

    log('sendEvent data ? ', { eventName, data });

    LIB_FS.event(eventName, payload);
  }

  fsLog(message) {
    log('entered fsLog', { message });
    if (!message) {
      return;
    }

    LIB_FS.log(message);
  }

  fsError(message) {
    log('entered fsError', { message });
    if (!message) {
      return;
    }
    // *info: disabled , no needed by now
    // LIB_FS.log('error', message);
  }
}
