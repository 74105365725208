import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  ControlContainer,
  ValidationErrors,
} from '@angular/forms';
import {
  Component,
  Input,
  OnInit,
  forwardRef,
  Optional,
  Host,
  SkipSelf,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
  Inject,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export const CB_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => CbInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-input',
  templateUrl: './cb-input.component.html',
  styleUrls: ['./cb-input.component.scss'],
  providers: [CB_INPUT_VALUE_ACCESSOR],
})
export class CbInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('inputElement') inputElement: ElementRef | null = null;
  @Input() formControlName = '';
  @Input() label = 'label';
  @Input() type = 'text';
  @Input() iconType = 'fas';
  @Input() iconName = 'envelope';
  @Input() linkForgot = false;
  @Input() forgotLinkLabel = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() validOnEmpty = false;
  @Input() errors = [];
  @Input() formGroupRef;
  @Input() set autofocus(condition: boolean) {
    this.isAutofocus = condition !== false;
  }

  @Output() cbInputBlur: EventEmitter<any> = new EventEmitter();

  control: FormControl = new FormControl();
  inputValue = '';
  isAutofocus = false;
  hasError = false;
  inputValue$ = new Subject();

  propagateChanges: (value) => {};
  onTouched: () => {};

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.addErrorListener();
  }

  ngOnInit() {
    if (this.controlContainer && this.controlContainer.control) {
      this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
    }
  }

  notifyOnErrors(value: string) {
    this.hasError = false;
    this.inputValue$.next(value);
  }

  addErrorListener() {
    this.inputValue$.pipe(debounceTime(300)).subscribe(() => {
      this.setHasError();
    });
  }

  setHasError() {
    this.control.updateValueAndValidity();
    this.hasError = (this.control.dirty && this.control.invalid) || (this.formGroupRef && this.formGroupRef.invalid);
  }

  // @info: focus is not supported for ios mobile , and it is breaking the mobile version
  // ngAfterViewInit() {
  //   if (this.isAutofocus) {
  //     this.setFocus();
  //   }
  // }

  setFocus() {
    if (isPlatformBrowser(this.platformId)) {
      this.inputElement.nativeElement.focus();
    }
  }

  onBlur(elementRef: HTMLElement) {
    this.cbInputBlur.emit(elementRef);
  }

  onKeyup(value: string) {
    this.propagateChanges(value);
  }

  onEnter(event) {
    const value = event.target.value;

    this.control.markAsDirty();
    this.notifyOnErrors(value);
  }

  onClick(value) {
    if (this.type === 'checkbox') {
      this.inputValue = value ? 'checked' : 'unchecked';
      this.propagateChanges(value);
    }
  }

  getErrorMessage(errors: ValidationErrors | null) {
    if (!errors) {
      return;
    }

    const firstKey = Object.keys(errors)[0];
    return (this.errors && this.errors[firstKey]) || firstKey;
  }

  writeValue(obj: any): void {
    this.inputValue = obj || '';

    if (this.validOnEmpty) {
      this.handleEmptyValue();
    }
  }

  handleEmptyValue() {
    if (!this.inputValue) {
      this.inputElement.nativeElement.value = '';
      this.setHasError();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(): void {}

  ngOnDestroy(): void {
    this.inputValue$.complete();
  }
}
