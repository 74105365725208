import { cdn } from '../services/cb-envars';

// export const LF_WEBSITE_CLIENT_ID = 'w2Pz2FRkM0SnJ05V36xL80fADWpS8GUF';

export const brandingPerClient = [
  {
    // name: 'LF My Profile', // -> for testing in dev stage
    name: 'LF Website',
    backgroundUrl: cdn + '/assets/backgrounds/LF_WEBSITE.png',
  },
];

export function getClientBranding(clientName = '') {
  return brandingPerClient.find((branding) => branding.name === clientName);
}

export function getClientName() {
  const search = new URLSearchParams(window.location.search);

  let clientName = '';

  clientName = search.get('clientName');
  if (clientName) {
    return clientName;
  }

  clientName =
    (window['_auth0Config'] &&
      window['_auth0Config'].dict &&
      window['_auth0Config'].dict.signin &&
      window['_auth0Config'].dict.signin.title) ||
    '';
  return clientName;
}

export function getCurrentBranding() {
  const clientName = getClientName();
  const branding = getClientBranding(clientName);

  return branding;
}
