<div class="columns is-flex-mobile is-centered">
  <div class="column is-6">
    <div class="" *ngIf="showContent">
      <cb-alert *ngIf="confirmationAlertData.resendVerificationStatus == SUCCESS" type="success">
        {{ confirmationAlertData.resendVerificationMessage }}
      </cb-alert>
      <cb-alert *ngIf="confirmationAlertData.resendVerificationStatus == ERROR" type="error">
        {{ confirmationAlertData.resendVerificationMessage }}
      </cb-alert>

      <div class="actions" *ngIf="showContent">
        <div class="cb-title-group">
          <div class="primary-text" data-cy="page-title">
            {{ confirmationTitle }}
          </div>
          <div class="secondary-text" [innerHTML]="confirmationMessage | safeHtml"></div>
        </div>
        <div class="cb-separator-2rem"></div>
        <div class="actions">
          <cb-button
            *ngIf="buttonText"
            type="is-info px-3"
            (click)="actionButtonClicked()"
            [disabled]="confirmationAlertData.isLoading"
          >
            {{ buttonText }}
          </cb-button>

          <ng-container *ngIf="showReturnLink && !isSsoStaticApp">
            <a *ngIf="!linkRedirect" class="cb-link gray cancel nolinkredirect" cbNavigateTo="{{ getRoutePath() }}">{{
              linkText
            }}</a>

            <a *ngIf="linkRedirect" class="cb-link gray cancel linkredirect" href="{{ getRoutePath() }}">{{
              linkText
            }}</a>
          </ng-container>
        </div>
      </div>
    </div>

    <cb-warning-page *ngIf="!showContent" warningMessage="{{ confirmationAlertData.resendVerificationMessage }}">
    </cb-warning-page>
  </div>
</div>
