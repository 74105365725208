import * as tslib_1 from "tslib";
import { map, switchMap, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { HttpService } from '../services/http.service';
import { CbLoginConfig } from './cb-login.config';
import { CreateAccountUiErrorMessages, DATA_PATH, CbAuthServiceErrorMessage } from './cb-auth-error-messages';
import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
import * as i1 from "./cb-login.config";
import * as i2 from "./http.service";
var log = LogFactory('CbBackendService', false);
var CbBackendService = /** @class */ (function () {
    function CbBackendService(loginConfig, httpService) {
        this.loginConfig = loginConfig;
        this.httpService = httpService;
        this.errorMessages = new CbAuthServiceErrorMessage();
    }
    CbBackendService.prototype.getUser = function (email, token) {
        if (token === void 0) { token = ''; }
        var route = this.loginConfig.cbBackend;
        var queryParams = [
            { name: 'email', value: email },
            { name: 'token', value: token },
        ];
        return this.httpService.get(route + "/user/check-user", { queryParams: queryParams }, token);
    };
    CbBackendService.prototype.getUserEmails = function (token) {
        log('entered getUserEmails', { token: token });
        var route = this.loginConfig.cbBackend;
        return this.httpService.get(route + "/user/emails", {}, token);
    };
    CbBackendService.prototype.createEmailToken = function (token, email) {
        log('entered createEmailToken', { token: token, email: email });
        var route = this.loginConfig.cbBackend;
        var requestBody = {
            email: email,
            token: token,
        };
        return this.httpService.post(route + "/user/create-email-token", { requestBody: requestBody }, token);
    };
    CbBackendService.prototype.resetPassword = function (usernameEmail, captchaToken, returnValue) {
        if (returnValue === void 0) { returnValue = ''; }
        var route = this.loginConfig.cbBackend;
        var requestBody = {
            usernameEmail: usernameEmail,
            captchaToken: captchaToken,
            returnTo: returnValue ? encodeURIComponent(returnValue) : '',
        };
        return this.httpService
            .post(route + '/user/reset-password', { requestBody: requestBody })
            .pipe(map(function (response) { return response; }));
    };
    CbBackendService.prototype.changePassword = function (password, token) {
        var route = this.loginConfig.cbBackend;
        return this.httpService
            .post(route + '/user/change-password', {
            queryParams: [
                { name: 'password', value: password },
                { name: 'token', value: token },
            ],
        })
            .pipe(map(function (response) { return response; }));
    };
    CbBackendService.prototype.checkResetPasswordToken = function (token) {
        var route = this.loginConfig.cbBackend;
        return this.httpService
            .post(route + '/user/check-reset-password', {
            queryParams: [{ name: 'token', value: token }],
        })
            .pipe(map(function (response) { return response; }));
    };
    CbBackendService.prototype.resendVerficationEmail = function (email, options) {
        var route = this.loginConfig.cbBackend;
        var queryParams = [{ name: 'email', value: email }];
        if (options.state) {
            var decodedState = decodeURIComponent(options.state);
            queryParams.push({ name: 'state', value: decodedState });
        }
        if (options.return) {
            var decodedReturn = decodeURIComponent(options.return);
            queryParams.push({ name: 'return', value: decodedReturn });
        }
        return this.httpService
            .post(route + '/user/resend-verification-email', { queryParams: queryParams })
            .pipe(map(function (response) { return response; }));
    };
    CbBackendService.prototype.verifyAccount = function (token, options) {
        var route = this.loginConfig.cbBackend;
        var queryParams = [{ name: 'token', value: token }];
        var optionkeys = Object.keys(options);
        optionkeys.forEach(function (optionKey) {
            queryParams.push({ name: optionKey, value: options[optionKey] });
        });
        return this.httpService
            .post(route + '/user/verify-account', { queryParams: queryParams })
            .pipe(map(function (response) { return response; }));
    };
    CbBackendService.prototype.canResendVerification = function (email, token) {
        var route = this.loginConfig.cbBackend;
        var queryParams = [{ name: 'email', value: email }];
        if (token) {
            queryParams.push({ name: 'token', value: token });
        }
        if (this.loginConfig.cbSsoStaticApp) {
            queryParams.push({ name: 'ssoStaticApp', value: 'true' });
        }
        return this.httpService
            .get(route + '/user/can-resend-verification', { queryParams: queryParams })
            .pipe(map(function (response) { return response; }));
    };
    CbBackendService.prototype.markEmailAsVerified = function (token) {
        var base = this.loginConfig.cbBackend;
        var route = base + "/user/mark-as-verified";
        var requestBody = {
            token: token,
        };
        return this.httpService.post(route, { requestBody: requestBody });
    };
    CbBackendService.prototype.createAccount = function (user) {
        var cbBackend = this.loginConfig.cbBackend;
        var accountUrl = cbBackend + "/user/accounts";
        var userBody = tslib_1.__assign({}, user);
        return this.httpService.post(accountUrl, { requestBody: userBody }).pipe(switchMap(function (response) {
            if (response.error) {
                return throwError(response.error);
            }
            return of(response);
        }), catchError(function (response) {
            return throwError(response.error);
        }));
    };
    CbBackendService.prototype.handleCreateAccountError = function (error) {
        var err = error.code ? error : error.error;
        if (err.code === 'conflict_error') {
            return this.handleCreateAccount409Error();
        }
        return this.handleCreateAccount400Error(error);
    };
    CbBackendService.prototype.handleCreateAccount409Error = function () {
        return throwError({
            errorField: 'username',
            errorMessage: CreateAccountUiErrorMessages.ACCOUNT_EXISTS,
        });
    };
    CbBackendService.prototype.handleCreateAccount400Error = function (error) {
        var _a = error.details, errorDetails = _a === void 0 ? { dataPath: '' } : _a;
        var uiError = {
            errorField: '',
            errorMessage: CreateAccountUiErrorMessages.GENERIC,
        };
        switch (errorDetails.dataPath) {
            case DATA_PATH.USERNAME:
                uiError = {
                    errorField: 'username',
                    errorMessage: this.errorMessages.username,
                };
                break;
            case DATA_PATH.EMAIL:
                uiError = {
                    errorField: 'email',
                    errorMessage: this.errorMessages.email,
                };
                break;
            case DATA_PATH.PASSWORD:
                uiError = {
                    errorField: 'password',
                    errorMessage: this.errorMessages.password,
                };
                break;
            case DATA_PATH.FIRST_NAME:
                uiError = {
                    errorField: 'firstName',
                    errorMessage: this.errorMessages.firstName,
                };
                break;
            case DATA_PATH.LAST_NAME:
                uiError = {
                    errorField: 'lastName',
                    errorMessage: this.errorMessages.lastName,
                };
                break;
            case DATA_PATH.IS_AGREEMENT_ACCEPTED:
                uiError = {
                    errorField: 'isAgreementAccepted',
                    errorMessage: this.errorMessages.isAgreementAccepted,
                };
                break;
            default:
                uiError = {
                    errorField: '',
                    errorMessage: this.errorMessages.generic,
                };
        }
        return throwError(uiError);
    };
    CbBackendService.ngInjectableDef = i0.defineInjectable({ factory: function CbBackendService_Factory() { return new CbBackendService(i0.inject(i1.CbLoginConfig), i0.inject(i2.HttpService)); }, token: CbBackendService, providedIn: "root" });
    return CbBackendService;
}());
export { CbBackendService };
