import { Injectable } from '@angular/core';

export interface ErrorData {
  client_id: string;
  error: string;
  error_description: string;
  tracking?: string;
  connection?: string;
  lang?: string;
  username?: string;
  client_name?: string;
}

@Injectable({ providedIn: 'root' })
export class CbErrorService {
  errorData: ErrorData;

  setErrorData(config: any) {
    if (!config) {
      this.errorData = {} as any;
      return;
    }
    this.errorData = { ...config } as ErrorData;
  }

  getErrorInformation() {
    const ErrorMessageHeader = '\n\n Error Information: \n\n';
    const errorDataKeys = Object.keys(this.errorData);
    let ErrorMessageBody = '';

    if (!errorDataKeys.length) {
      ErrorMessageBody = 'No error information provided.';
    } else {
      ErrorMessageBody = errorDataKeys.reduce((acc, key) => {
        const row = `\n ${key}: ${this.errorData[key]}`;
        return acc + row;
      }, '');
    }

    return ErrorMessageHeader + ErrorMessageBody;
  }
}
