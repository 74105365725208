/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component.ngfactory";
import * as i2 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component";
import * as i3 from "../../shared/cb-ui/cb-button/cb-button.component.ngfactory";
import * as i4 from "../../shared/cb-ui/cb-button/cb-button.component";
import * as i5 from "@angular/common";
import * as i6 from "./verify-account.component";
import * as i7 from "../../services/cb-backend.service";
import * as i8 from "../../services/cb-auth-url.service";
import * as i9 from "../../services/cb-state.service";
import * as i10 from "../../services/cb-webapi";
import * as i11 from "../../services/cb-fullstory.service";
var styles_VerifyAccountComponent = [];
var RenderType_VerifyAccountComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VerifyAccountComponent, data: {} });
export { RenderType_VerifyAccountComponent as RenderType_VerifyAccountComponent };
function View_VerifyAccountComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-loading-page", [], null, null, null, i1.View_CbLoadingPageComponent_0, i1.RenderType_CbLoadingPageComponent)), i0.ɵdid(1, 49152, null, 0, i2.CbLoadingPageComponent, [], { loadingMessage: [0, "loadingMessage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingMessage; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VerifyAccountComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "cb-title-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "primary-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Your email is already verified. "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "cb-separator-2rem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "cb-button", [["type", "is-info is-rounded"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CbButtonComponent_0, i3.RenderType_CbButtonComponent)), i0.ɵdid(5, 49152, null, 0, i4.CbButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, [" Go back to Login Page "]))], function (_ck, _v) { var currVal_0 = "is-info is-rounded"; _ck(_v, 5, 0, currVal_0); }, null); }
function View_VerifyAccountComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "cb-title-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "primary-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "cb-separator-2rem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "cb-button", [["type", "is-info is-rounded"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CbButtonComponent_0, i3.RenderType_CbButtonComponent)), i0.ɵdid(5, 49152, null, 0, i4.CbButtonComponent, [], { type: [0, "type"] }, null), (_l()(), i0.ɵted(-1, 0, [" Go back to Login Page "]))], function (_ck, _v) { var currVal_1 = "is-info is-rounded"; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 2, 0, currVal_0); }); }
export function View_VerifyAccountComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_VerifyAccountComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "columns is-flex is-centered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "column is-two-fifths is-four-fifths-mobile"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VerifyAccountComponent_2)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VerifyAccountComponent_3)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isEmailVerified; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.error; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_VerifyAccountComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_VerifyAccountComponent_0, RenderType_VerifyAccountComponent)), i0.ɵdid(1, 245760, null, 0, i6.VerifyAccountComponent, [i7.CbBackendService, i8.CbAuthURLService, i9.CbStateService, i10.CbWebApi, i11.CbFullstoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifyAccountComponentNgFactory = i0.ɵccf("ng-component", i6.VerifyAccountComponent, View_VerifyAccountComponent_Host_0, {}, {}, []);
export { VerifyAccountComponentNgFactory as VerifyAccountComponentNgFactory };
