var _a;
import { CbLoginConfig } from './cb-login.config';
import { CbAuthService } from './cb-auth.service';
import { ActivatedRoute } from '@angular/router';
import { parse } from 'querystring';
import * as i0 from "@angular/core";
import * as i1 from "./cb-auth.service";
import * as i2 from "@angular/router";
import * as i3 from "./cb-login.config";
// import { LogFactory } from './cb-debug';
// Possible values in URL (in queryParams or Fragments)
var URL_PARAMS = (_a = {
        displayBanner: 'display_banner',
        token: 'token',
        email: 'email',
        state: 'state'
    },
    _a['return'] = 'return',
    _a.clientId = 'clientId',
    _a.clientName = 'clientName',
    _a.logo = 'logo',
    _a);
var patterns = {
    URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
};
var COMMUNITY_BRIDGE_TITLE = 'CommunityBridge';
var COMMUNITY_BRIDGE_FAVICON = '/assets/favicons/cb-favicon.png';
var COMMUNITY_BRIDGE_DEFAULT_LOGO_BLUE = '/assets/cb-assets/cb-logo-blue.svg';
var COMMUNITY_BRIDGE_DEFAULT_LOGO_WHITE = '/assets/cb-assets/cb-logo-white.svg';
var LINUX_FOUNDATION_TITLE = 'The Linux Foundation';
var LINUX_FOUNDATION_FAVICON = '/assets/favicons/lf-favicon.png';
var LINUX_FOUNDATION_DEFAULT_LOGO_BLUE = '/assets/cb-assets/lf-logo-blue.png';
var LINUX_FOUNDATION_DEFAULT_LOGO_WHITE = '/assets/cb-assets/lf-logo-blue.png';
// const log = LogFactory('CbClientParametersService');
var CbClientParametersService = /** @class */ (function () {
    function CbClientParametersService(cbAuthService, route, cbLoginConfig) {
        this.cbAuthService = cbAuthService;
        this.route = route;
        this.cbLoginConfig = cbLoginConfig;
        this.clientId = '';
        this.clientName = COMMUNITY_BRIDGE_TITLE;
        this.clientLogo = this.cbLoginConfig.cbDefaultLogo;
        this.clientLogoLink = '';
        this.isSidebarDisabled = false;
        this.bradingUpdated = false;
        this.isCbApp = false;
        // if (this.cbLoginConfig.cbAuthCentralizedLogin) {
        //   this.setClientBrandFromAuth0Config();
        // }
        // if (this.cbLoginConfig.cbSsoStaticApp) {
        //   this.clientBrandInSsoStaticApp();
        // }
        // if (this.cbAuthService.isErrorFlow) {
        //   this.clientLogoLink = '';
        // }
    }
    CbClientParametersService.prototype.clientBrandInSsoStaticApp = function () {
        // log('entered clientBrandInSsoStaticApp');
        // From Fragments:
        // Send create account email Flow
        // Add social username flow
        this.setValuesFromFragments();
        // From queryParams:
        // Verify Account flow
        // change Password flow
        this.setValuesFromQueryParams();
    };
    CbClientParametersService.prototype.setClientBrandFromAuth0Config = function () {
        // log('entered setClientBrandFromAuth0Config');
        var _a = this.cbAuthService.getConfigFromAuth0Environment() || {}, _b = _a.clientID, clientID = _b === void 0 ? '' : _b, _c = _a.dict, dict = _c === void 0 ? {} : _c, _d = _a.clientMetadata, clientMetadata = _d === void 0 ? {} : _d;
        var _e = dict.signin, _f = _e.title, title = _f === void 0 ? '' : _f, _g = _e.logo, logo = _g === void 0 ? '' : _g;
        this.setAppBranding(clientMetadata);
        this.setClientParams({
            clientId: clientID,
            clientName: clientMetadata.app_name || title,
            clientLogo: clientMetadata.app_logo || logo,
            clientLogoLink: this.cbAuthService.getLogoLinkInAuth0Page(),
        });
    };
    CbClientParametersService.prototype.getThemeByClient = function (clientName) {
        // log('entered getThemeByClient');
        var themeKey = '_themes';
        var themes = (window && window[themeKey]) || null;
        if (!clientName || !themes || !themes[clientName]) {
            return {};
        }
        return themes[clientName];
    };
    CbClientParametersService.prototype.setValuesFromFragments = function () {
        // log('entered setValuesFromFragments');
        var fragmentFromAngular = this.route.snapshot.fragment || '';
        if (!fragmentFromAngular) {
            return false;
        }
        var _a = fragmentFromAngular.split("&" + URL_PARAMS.return + "="), params = _a[0], returnURL = _a[1];
        var clientLogoLink = returnURL ? "https://" + this.cbLoginConfig.domain + "/" + decodeURIComponent(returnURL) : '';
        if (!params) {
            return false;
        }
        var fragmentParams = parse(params);
        var clientMetadata = this.getThemeByClient(fragmentParams[URL_PARAMS.clientName]);
        var clientId = fragmentParams[URL_PARAMS.clientId] || '';
        var clientName = clientMetadata.app_name || fragmentParams[URL_PARAMS.clientName] || '';
        var clientLogo = clientMetadata.app_logo || fragmentParams[URL_PARAMS.logo] || '';
        this.setAppBranding(clientMetadata);
        this.setClientParams({ clientId: clientId, clientName: clientName, clientLogo: clientLogo, clientLogoLink: clientLogoLink });
        return true;
    };
    CbClientParametersService.prototype.setValuesFromQueryParams = function () {
        // log('entered setValuesFromQueryParams');
        var params = this.route.snapshot.queryParams || '';
        if (!params) {
            return false;
        }
        var returnValue = params[URL_PARAMS.return];
        var clientLogoLink = returnValue ? "https://" + this.cbLoginConfig.domain + "/" + decodeURIComponent(returnValue) : '';
        var clientMetadata = this.getThemeByClient(params[URL_PARAMS.clientName]);
        var clientId = params[URL_PARAMS.clientId] || '';
        var clientName = clientMetadata.app_name || params[URL_PARAMS.clientName] || '';
        var clientLogo = clientMetadata.app_logo || params[URL_PARAMS.logo] || '';
        this.setAppBranding(clientMetadata);
        this.setClientParams({ clientId: clientId, clientName: clientName, clientLogo: clientLogo, clientLogoLink: clientLogoLink });
    };
    CbClientParametersService.prototype.isSmallScreen = function () {
        var value = window.matchMedia && window.matchMedia('(max-width: 768px)').matches;
        // log('entered isSmallScreen', { value });
        return value;
    };
    CbClientParametersService.prototype.setAppBranding = function (clientMetadata) {
        // log('entered setAppBranding', { clientMetadata });
        if (this.bradingUpdated) {
            // log(' ... branding updated');
            return;
        }
        this.bradingUpdated = true;
        // @info: Apps are LF by default - only CB is there are explictly true
        this.isCbApp = clientMetadata.cb_app === true;
        this.isSidebarDisabled = clientMetadata.display_banner !== true;
        if (this.isSmallScreen()) {
            this.isSidebarDisabled = true;
        }
        var clientLogo;
        if (this.isCbApp) {
            clientLogo = this.isSidebarDisabled ? COMMUNITY_BRIDGE_DEFAULT_LOGO_BLUE : COMMUNITY_BRIDGE_DEFAULT_LOGO_WHITE;
        }
        else {
            clientLogo = this.isSidebarDisabled ? LINUX_FOUNDATION_DEFAULT_LOGO_BLUE : LINUX_FOUNDATION_DEFAULT_LOGO_WHITE;
        }
        this.setClientParams({ clientLogo: clientLogo });
        document.title = this.isCbApp ? COMMUNITY_BRIDGE_TITLE : LINUX_FOUNDATION_TITLE;
        this.setFavicon(this.isCbApp ? COMMUNITY_BRIDGE_FAVICON : LINUX_FOUNDATION_FAVICON);
    };
    CbClientParametersService.prototype.setFavicon = function (src) {
        // log('entered setFavicon', { src });
        if (src === void 0) { src = ''; }
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.cbLoginConfig.cbCDN + src;
        document.getElementsByTagName('head')[0].appendChild(link);
    };
    CbClientParametersService.prototype.setClientParams = function (config) {
        // log('entered setClientParams', { config });
        if (config.clientId) {
            this.clientId = config.clientId;
        }
        if (config.clientName) {
            this.clientName = config.clientName;
        }
        if (config.clientLogo) {
            var hasCDN = patterns.URL.test(config.clientLogo);
            if (hasCDN) {
                this.clientLogo = config.clientLogo;
            }
            else {
                this.clientLogo = this.cbLoginConfig.cbCDN + config.clientLogo;
            }
        }
        if (config.clientLogoLink) {
            this.clientLogoLink = config.clientLogoLink;
        }
    };
    CbClientParametersService.ngInjectableDef = i0.defineInjectable({ factory: function CbClientParametersService_Factory() { return new CbClientParametersService(i0.inject(i1.CbAuthService), i0.inject(i2.ActivatedRoute), i0.inject(i3.CbLoginConfig)); }, token: CbClientParametersService, providedIn: "root" });
    return CbClientParametersService;
}());
export { CbClientParametersService };
