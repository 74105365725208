<div [hidden]="formLoading">
  <div class="columns is-flex is-centered">
    <div class="column is-6">
      <form class="form" [formGroup]="form" (ngSubmit)="submit()">
        <cb-alert *ngIf="pageContent.redirectionMessage" type="success">
          {{ pageContent.redirectionMessage }}
        </cb-alert>

        <cb-alert *ngIf="error" type="error">
          {{ errorMessage }}
        </cb-alert>

        <div class="columns is-marginless pb-1">
          <div class="column">
            <div class="has-text-lfblack font-18px bold has-text-centered">
              Create your LFID
            </div>
          </div>
        </div>

        <div class="field">
          <cb-input
            label="{{ pageContent.usernameLabel }}"
            tabindex="1"
            iconName="user"
            formControlName="username"
            (click)="clearErrors()"
            [errors]="pageContent.usernameErrors"
          ></cb-input>
        </div>

        <div class="columns is-centered is-flex is-size-7 has-text-centered">
          <div class="column">
            By continuing, you agree to the creation of an LFID in accordance with our
            <a class="cb-link skyblue" href="https://www.linuxfoundation.org/privacy/" target="_blank">
              Privacy Policy</a
            >
            and
            <a class="cb-link skyblue" href="https://www.linuxfoundation.org/terms/" target="_blank"> Terms of Use.</a>
          </div>
        </div>

        <div class="actions cb-separator">
          <cb-button tabindex="3" buttonType="submit" type="is-info px-3" data-cy="btn-submit" [disabled]="!form.valid">
            {{ pageContent.submitButtonLabel }}
          </cb-button>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="formLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>
