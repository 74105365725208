/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../cb-top-banner-registration/cb-top-banner-registration.component.ngfactory";
import * as i3 from "../cb-top-banner-registration/cb-top-banner-registration.component";
import * as i4 from "../../services/cb-registration.service";
import * as i5 from "../../services/cb-login-banner.service";
import * as i6 from "./cb-top-banner-zoom-banner.component";
var styles_CbTopBannerZoomBannerComponent = [];
var RenderType_CbTopBannerZoomBannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CbTopBannerZoomBannerComponent, data: {} });
export { RenderType_CbTopBannerZoomBannerComponent as RenderType_CbTopBannerZoomBannerComponent };
function View_CbTopBannerZoomBannerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "figure", [["class", ""]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "is-hidden": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["class", "img-banner"]], [[8, "src", 4]], [[null, "error"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.removeBanner(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.displayBanner(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_0 = ""; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.hide); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.URL; _ck(_v, 4, 0, currVal_2); }); }
function View_CbTopBannerZoomBannerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "banner-zoom columns is-centered my-1 my-0-mobile"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "column has-text-centered pb-0 p-0-mobile"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbTopBannerZoomBannerComponent_2)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.possibleImages; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CbTopBannerZoomBannerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbTopBannerZoomBannerComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "cb-top-banner-registration", [], null, null, null, i2.View_CbTopBannerRegistrationComponent_0, i2.RenderType_CbTopBannerRegistrationComponent)), i0.ɵdid(4, 4308992, null, 0, i3.CbTopBannerRegistrationComponent, [i4.CbRegistrationService, i5.CbLoginBannerService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageExists; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageExists; _ck(_v, 2, 0, currVal_1); }); }
export function View_CbTopBannerZoomBannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-top-banner-zoom-banner", [], null, null, null, View_CbTopBannerZoomBannerComponent_0, RenderType_CbTopBannerZoomBannerComponent)), i0.ɵdid(1, 114688, null, 0, i6.CbTopBannerZoomBannerComponent, [i5.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbTopBannerZoomBannerComponentNgFactory = i0.ɵccf("cb-top-banner-zoom-banner", i6.CbTopBannerZoomBannerComponent, View_CbTopBannerZoomBannerComponent_Host_0, { zoomID: "zoomID", id: "id" }, {}, []);
export { CbTopBannerZoomBannerComponentNgFactory as CbTopBannerZoomBannerComponentNgFactory };
