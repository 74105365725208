export function removeUnnededParams(url = '') {
  const unneded = ['signup', 'undefined'];
  if (!url) {
    return '';
  }

  let newUrl: any = url;
  newUrl = newUrl.split('&').map((x) => x.split('='));
  newUrl = newUrl.filter((x) => !unneded.includes(x[0]));
  newUrl = newUrl.map((x) => x.join('=')).join('&');
  return newUrl;
}
