/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./cb-button.component";
var styles_CbButtonComponent = [i0.styles];
var RenderType_CbButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbButtonComponent, data: {} });
export { RenderType_CbButtonComponent as RenderType_CbButtonComponent };
export function View_CbButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [], [[8, "type", 0], [8, "id", 0], [2, "disabled", null], [8, "disabled", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "is-loading": 0 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵinlineInterpolate(2, "button ", _co.type, " ", _co.style, " font-source-sans-pro bold-600"); var currVal_5 = _ck(_v, 2, 0, _co.isLoading); _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.buttonType, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.id, ""); var currVal_2 = _co.disabled; var currVal_3 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_CbButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-button", [], null, null, null, View_CbButtonComponent_0, RenderType_CbButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.CbButtonComponent, [], null, null)], null, null); }
var CbButtonComponentNgFactory = i1.ɵccf("cb-button", i3.CbButtonComponent, View_CbButtonComponent_Host_0, { id: "id", type: "type", style: "style", isLoading: "isLoading", size: "size", buttonType: "buttonType", disabled: "disabled" }, {}, ["*"]);
export { CbButtonComponentNgFactory as CbButtonComponentNgFactory };
