<cb-loading-page *ngIf="isLoading" loadingMessage="Please wait, we are processing your request"> </cb-loading-page>

<cb-confirmation
  *ngIf="!isLoading"
  headerTitle="{{ pageContent.headerTitle }}"
  confirmationTitle="{{ pageContent.confirmationTitle }}"
  confirmationMessage="{{ pageContent.confirmationMessage }}"
  buttonText="{{ pageContent.buttonText }}"
  linkText="{{ pageContent.linkText }}"
  linkRouterPath="{{ pageContent.linkRouterPath }}"
  cancelLinkText="Go to Login page"
  [displayCancelLink]="true"
  [linkRedirect]="pageContent.linkRedirect"
  [showHeader]="!isSSoStaticApp"
  [showReturnLink]="!isSSoStaticApp"
  [showContent]="pageContent.showContent && !isEmailVerified"
  (buttonClicked)="resendVerificationEmail()"
>
</cb-confirmation>
