import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { getCurrentBranding } from '../../features/branding';

import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbBannerService } from '../../services/cb-banner.service';
import { CbClientParametersService } from '../../services/cb-client-parameters.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { CbGroupioService } from '../../services/cb-groupio.service';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';
import { CbLoginConfig } from '../../services/cb-login.config';
import { CbMyProfileService } from '../../services/cb-myprofile.service';
import { CbWebApi } from '../../services/cb-webapi';

@Component({
  selector: 'cb-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent implements OnInit {
  showLogin = true;
  year = new Date().getFullYear();
  isSidebarDisabled = false;
  banners = [];
  appClassName = '';
  defaultBannerActive = false;
  isSmallBanner = false;
  displaySignOut = false;
  isLoading = false;

  myProfileURL = '';
  displayOptions = '';
  myProfileURLdisplayed$ = this.cbMyProfileService.myProfileURLdisplayed$;
  displaySettings = false;

  displaySecondaryBar = false;
  projectName = '';
  groupName = '';
  display2Logos = false;
  clientBranding = null;
  clientBackground = '';

  constructor(
    private cbWebApi: CbWebApi,
    private cbLoginConfig: CbLoginConfig,
    private cbClientParametersService: CbClientParametersService,
    private cbBannerService: CbBannerService,
    private cbAuthURLService: CbAuthURLService,
    private cbFullstoryService: CbFullstoryService,
    private cbMyProfileService: CbMyProfileService,
    private cbGroupioService: CbGroupioService,
    public cbLoginBannerService: CbLoginBannerService
  ) {
    // document.title = 'Linux Foundation';
    this.cbWebApi.VerifyStateChallenge();
    this.setDefaultValues();
    this.setSignOut();

    this.myProfileURL = this.cbMyProfileService.URL;

    this.cbFullstoryService.initFullstory();
    this.display2Logos = this.cbLoginBannerService.withAppLogotoLeft;
  }

  ngOnInit() {
    this.handleProfileURL();
    this.handleSecondaryBar();
    this.setBackground();
  }
  setBackground() {
    this.clientBranding = getCurrentBranding();
    const { backgroundUrl = '' } = this.clientBranding || {};
    this.clientBackground = backgroundUrl || '';
  }

  handleProfileURL() {
    this.cbMyProfileService.myProfileURLdisplayed$.subscribe((value) => {
      if (!this.displaySettings) {
        this.displaySettings = !!value;
      }
    });
  }

  setSignOut() {
    const isStatic = this.cbLoginConfig.cbSsoStaticApp;

    if (!isStatic) {
      return;
    }

    const { authorizeURL } = this.cbAuthURLService.getAllURLParams();
    this.displaySignOut = !!authorizeURL;

    if (!this.displaySettings) {
      this.displaySettings = !!authorizeURL;
    }
  }

  setDefaultValues() {
    this.isSidebarDisabled = this.cbClientParametersService.isSidebarDisabled;
    this.appClassName = this.cbBannerService.GetAppClassName();
    // this.banners = this.cbBannerService.GetBanners();
    this.defaultBannerActive = this.setDefaultBanner();
  }

  setDefaultBanner() {
    if (!this.banners || this.banners.length === 0) {
      this.defaultBannerActive = true;
      return true;
    }

    return false;
  }

  handleSecondaryBar() {
    this.groupName = this.cbGroupioService.getPageTitle();
    this.projectName = this.cbGroupioService.getPageParentGroupTitle();

    if (this.projectName) {
      this.displaySecondaryBar = true;
    }
  }

  signOut() {
    this.isLoading = true;
    this.cbWebApi.returnToAuthorizePage();
  }

  displayBanner(banner: any) {
    // todo: once we have 1 loaded , hide others
    banner.hide = false;
    this.isSmallBanner = this.cbBannerService.isSmaller(banner);
  }

  removeBanner(index: number) {
    this.banners.splice(index, 1);
    this.defaultBannerActive = this.setDefaultBanner();
  }
}
