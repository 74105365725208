<div class="bottom-banner page-logo columns is-centered">
  <div class="column has-text-centered">
    <ng-container *ngFor="let image of cbLoginBannerService.bottomImages; let index = index">
      <figure class="p-image" [ngClass]="{ 'is-hidden': image.hide }">
        <img
          [src]="image.URL"
          (error)="removePossibleImage(index, cbLoginBannerService.bottomImages)"
          (load)="displayPossibleImage(image)"
        />
      </figure>
    </ng-container>
  </div>
</div>
