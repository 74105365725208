
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbButtonComponent } from './cb-button/cb-button.component';
import { CbLoaderComponent } from './cb-loader/cb-loader.component';
import { CbAlertComponent } from './cb-alert/cb-alert.component';
import { CbInputComponent } from './cb-input/cb-input.component';
import { CBTextAreaComponent } from './cb-textarea/cb-textarea.component';
import { CbCheckboxComponent } from './cb-checkbox/cb-checkbox.component';
import { CbConfirmationComponent } from './cb-confirmation/cb-confirmation.component';
import { CbLoadingPageComponent } from './cb-loading-page/cb-loading-page.component';
import { CbWarningPageComponent } from './cb-warning-page/cb-warning-page.component';
import { RouterModule } from '@angular/router';
import { CbIconModule } from './cb-icon/cb-icon.module';
import { CbDirectivesModule } from '../../directives/cb-directives.module';
import { CbLogoComponent } from './cb-logo/cb-logo.component';
import { CbPipesModule } from '../cb-pipes/cb-pipes.module';
import { CbRadiobuttonComponent } from './cb-radio/cb-radio.component';

const UiComponents = [
  CbButtonComponent,
  CbLoaderComponent,
  CbAlertComponent,
  CbInputComponent,
  CBTextAreaComponent,
  CbCheckboxComponent,
  CbConfirmationComponent,
  CbLoadingPageComponent,
  CbWarningPageComponent,
  CbLogoComponent,
  CbRadiobuttonComponent,
];

@NgModule({
  declarations: [
    ...UiComponents,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CbIconModule,
    CbDirectivesModule,
    CbPipesModule
  ],
  exports: [
    ...UiComponents,
    CbIconModule
  ],
  providers: [],
})
export class CbUIModule { }
