import { Injectable } from '@angular/core';
import { CbFullstoryService } from './cb-fullstory.service';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationAlertData {
  resendVerificationStatus = 0;
  resendVerificationMessage = '';
  isLoading = false;

  constructor(private cbFullstoryService: CbFullstoryService) {}

  init() {
    this.resendVerificationStatus = 0;
    this.resendVerificationMessage = '';
    this.isLoading = false;
  }

  setSuccess(successMessage) {
    this.resendVerificationStatus = 1;
    this.resendVerificationMessage = successMessage;
  }

  setError(errorMessage) {
    this.cbFullstoryService.fsError(errorMessage);
    this.resendVerificationStatus = -1;
    this.resendVerificationMessage = errorMessage;
  }

  setLoadingStatus(status) {
    this.isLoading = status;
  }
}
