import { Injectable } from '@angular/core';
import { CbAuthURLService } from './cb-auth-url.service';
import { CbStorage } from './cb-storage';

@Injectable({ providedIn: 'root' })
export class CbAlertService {
  constructor(private cbStorage: CbStorage, private cbAuthURLService: CbAuthURLService) {}

  trytoParse(string) {
    try {
      return JSON.parse(string);
    } catch (error) {
      return {};
    }
  }

  createAlert(message, type = 'error') {
    return btoa(
      JSON.stringify({
        message,
        type,
        date: new Date().getTime(),
      })
    );
  }

  getAlert(): any {
    const { alert } = this.cbAuthURLService.getAllURLParams();
    if (!alert) {
      return;
    }
    const stored = this.cbStorage.get(alert);
    const isAlertDone = alert && stored;
    if (isAlertDone) {
      return;
    }

    this.cbStorage.save(alert, 'used');
    return this.trytoParse(atob(alert));
  }
}
