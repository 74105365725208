import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
var log = LogFactory('CbHttpErrorsService');
var CbHttpErrorsService = /** @class */ (function () {
    function CbHttpErrorsService() {
    }
    CbHttpErrorsService.prototype.GetErrorMessage = function (errorResponse) {
        if (errorResponse === void 0) { errorResponse = {}; }
        log('errorResponse ?', { errorResponse: errorResponse });
        var innerError;
        innerError = this.GetError(errorResponse);
        if (typeof innerError === 'string') {
            return innerError;
        }
        innerError = this.GetError(innerError);
        if (typeof innerError === 'string') {
            return innerError;
        }
        innerError = this.GetError(innerError);
        if (typeof innerError === 'string') {
            return innerError;
        }
        log(' three ? ', { innerError: innerError });
        return 'There was a problem, Try again later';
    };
    CbHttpErrorsService.prototype.GetErrorCode = function (errorResponse) {
        if (errorResponse === void 0) { errorResponse = {}; }
        log('errorResponse ?', { errorResponse: errorResponse });
        var innerError;
        innerError = this.GetErrorCodeInner(errorResponse);
        if (typeof innerError === 'string') {
            return innerError;
        }
        innerError = this.GetErrorCodeInner(innerError);
        if (typeof innerError === 'string') {
            return innerError;
        }
        innerError = this.GetErrorCodeInner(innerError);
        if (typeof innerError === 'string') {
            return innerError;
        }
        log(' three ? ', { innerError: innerError });
        return 'There was a problem, Try again later';
    };
    CbHttpErrorsService.prototype.GetError = function (possibleError) {
        log('entered GetError', { possibleError: possibleError });
        if (typeof possibleError === 'string') {
            return possibleError;
        }
        var _a = possibleError || {}, error = _a.error, code = _a.code, message = _a.message, Message = _a.Message, Code = _a.Code, errorMessage = _a.errorMessage;
        if (typeof error === 'object') {
            return error;
        }
        return Message || Code || errorMessage || message || code || error;
    };
    CbHttpErrorsService.prototype.GetErrorCodeInner = function (possibleError) {
        log('entered GetError', { possibleError: possibleError });
        if (typeof possibleError === 'string') {
            return possibleError;
        }
        var _a = possibleError || {}, error = _a.error, code = _a.code, Code = _a.Code;
        if (typeof error === 'object') {
            return error;
        }
        return Code || code || error;
    };
    CbHttpErrorsService.ngInjectableDef = i0.defineInjectable({ factory: function CbHttpErrorsService_Factory() { return new CbHttpErrorsService(); }, token: CbHttpErrorsService, providedIn: "root" });
    return CbHttpErrorsService;
}());
export { CbHttpErrorsService };
