import { LogFactory } from './cb-debug';
import { Injectable } from '@angular/core';

const log = LogFactory('CbStorage', false);

@Injectable({ providedIn: 'root' })
export class CbStorage {
  save(key = '', value = '') {
    // @info: FF doesn't handle localStorage per domain always , usually per tab
    // Issue : https://bugzilla.mozilla.org/show_bug.cgi?id=1453699
    // Implementing cookies fallback
    if (document) {
      const d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + d.toUTCString();
      document.cookie = `${key}=${value};${expires};path=/`;
    }
  }

  remove(key = '') {
    if (!key) {
      return;
    }

    if (document) {
      document.cookie = `${key}=;expires = Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
    }
  }

  get(key = '') {
    log('entered get ', { key });

    // Implementing cookies fallback
    return this.getFromCookieStorage(key);
  }

  getFromCookieStorage(key) {
    log('entered getFromCookieStorage', { key });

    // Implementing cookies fallback
    if (!document) {
      return null;
    }

    const cookies = document.cookie || '';
    const cookiesObj = cookies.split(';').reduce((acc, item) => {
      if (!item) {
        return acc;
      }

      const [i, value] = item.split('=');
      acc[i.trim()] = (value && value.trim()) || '';
      return acc;
    }, {});

    return cookiesObj[key];
  }
}
