import { Observable } from 'rxjs';
import { stringify } from 'querystring';
import { Injectable } from '@angular/core';

import { stage } from './cb-envars';
import { HttpClient } from '@angular/common/http';

import { LogFactory } from './cb-debug';
import { GetTimeZoneFriendlyNameByName } from './cb-registration.timezones';
const log = LogFactory('CbRegistrationService', false);

function GetRegistrationAPI() {
  switch (stage) {
    case 'prod':
    case 'production':
      return 'https://1m192jbree.execute-api.us-east-2.amazonaws.com/prod';
    default:
      // TODO: dev is pending to be re-created
      return 'https://1m192jbree.execute-api.us-east-2.amazonaws.com/prod';
  }
}

@Injectable({
  providedIn: 'root',
})
export class CbRegistrationService {
  API = GetRegistrationAPI();

  constructor(private http: HttpClient) {}

  GetRegistration(id, type): Observable<any> {
    log('entered GetRegistration', { id, type });

    const q = stringify({ pageType: type });

    if (!id) {
      throw new Error('id not found');
    }

    const URL = `${this.API}/registration/${encodeURIComponent(id)}?${q}`;

    log('url ? ', { URL });

    return this.http.get(URL);
  }
  GetTime(registration) {
    let startTime = '';
    if (!registration) {
      return '';
    }

    startTime = registration.start_time;

    if (startTime) {
      return startTime;
    }

    return this.GetNextStartTime(registration.occurrences || []);
  }
  GetNextStartTime(occurrences = []) {
    if (!occurrences.length) {
      return '';
    }

    return occurrences[0].start_time || '';
  }

  GetTimezoneFriendlyName(timezone = '') {
    return GetTimeZoneFriendlyNameByName(timezone);
  }
}
