/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./cb-bottom-banner.component";
import * as i3 from "../../services/cb-login-banner.service";
var styles_CbBottomBannerComponent = [];
var RenderType_CbBottomBannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CbBottomBannerComponent, data: {} });
export { RenderType_CbBottomBannerComponent as RenderType_CbBottomBannerComponent };
function View_CbBottomBannerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "figure", [["class", "p-image"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "is-hidden": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "error"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.removePossibleImage(_v.context.index, _co.cbLoginBannerService.bottomImages) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.displayPossibleImage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_0 = "p-image"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.hide); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.URL; _ck(_v, 4, 0, currVal_2); }); }
export function View_CbBottomBannerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "bottom-banner page-logo columns is-centered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "column has-text-centered"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CbBottomBannerComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cbLoginBannerService.bottomImages; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CbBottomBannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-bottom-banner", [], null, null, null, View_CbBottomBannerComponent_0, RenderType_CbBottomBannerComponent)), i0.ɵdid(1, 114688, null, 0, i2.CbBottomBannerComponent, [i3.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbBottomBannerComponentNgFactory = i0.ɵccf("cb-bottom-banner", i2.CbBottomBannerComponent, View_CbBottomBannerComponent_Host_0, {}, {}, []);
export { CbBottomBannerComponentNgFactory as CbBottomBannerComponentNgFactory };
