export class CbCreateAccountEmailPageContent {
  // Page content details
  headerTitle = 'Already have an account ?';
  confirmationTitle = 'Verify your account.';
  confirmationMessage = `
  An email was sent to the email address on file, please click the link in the message to verify your account.
  <br />
  <br/>
  `;
  buttonText = 'Resend Email';
  linkText = 'Return to Login';
  linkRouterPath = '/cb/login';
  showContent = true;
  linkRedirect = false;

  // Alert parameters
  resendSuccessMessage = 'New verification email has been sent to you!';

  constructor(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }

  overwriteContent(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }
}
