<div [hidden]="formLoading">
  <div class="columns is-flex is-centered">
    <div class="column is-8">
      <form autocomplete="off" (ngSubmit)="submit()" [formGroup]="form">
        <cb-alert *ngIf="recaptchTokenError" type="error">
          There was a problem loading the form. Please, refresh your page or contact us to help you.
        </cb-alert>

        <cb-alert *ngIf="errorMessage" type="error">
          {{ errorMessage }}
        </cb-alert>

        <div class="columns is-marginless">
          <div class="column">
            <div class="has-text-lfblack font-18px bold has-text-centered">
              CREATE YOUR ACCOUNT
            </div>
          </div>
        </div>

        <div class="ca-top-content columns is-centered is-flex is-size-7 has-text-centered mt-1 has-text-lfblack">
          <div class="column">
            <div class="subtitle-p1 bold">
              Create your LFID today – it’s quick and easy. An LFID gives you access to:
            </div>
            <ul class="subtitle-list">
              <li>
                The LFX platform — a digital toolkit to manage your open source projects, contributions, and open source
                community profile
              </li>
              <li>
                Single sign-on across LF events, training, and other programs
              </li>
              <li>Information about our latest open source and industry research</li>
            </ul>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="field">
              <cb-input
                type="text"
                iconName="user"
                autofocus
                label="{{ cbCreateAccountContent.firstNameLabel }}"
                [errors]="cbCreateAccountContent.firstNameErrors"
                formControlName="firstName"
              ></cb-input>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <cb-input
                label="{{ cbCreateAccountContent.lastNameLabel }}"
                type="text"
                iconName="user"
                [errors]="cbCreateAccountContent.lastNameErrors"
                formControlName="lastName"
              ></cb-input>
            </div>
          </div>
        </div>

        <div class="field">
          <cb-input
            label="{{ cbCreateAccountContent.emailLabel }}"
            type="email"
            iconName="envelope"
            [errors]="cbCreateAccountContent.emailErrors"
            formControlName="email"
          ></cb-input>
        </div>

        <div class="columns">
          <div class="column">
            <div class="field">
              <cb-input
                label="{{ cbCreateAccountContent.usernameLabel }}"
                type="text"
                iconName="user"
                [errors]="cbCreateAccountContent.usernameErrors"
                formControlName="username"
              ></cb-input>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <cb-input
                label="{{ cbCreateAccountContent.passwordLabel }}"
                type="password"
                iconName="lock"
                [errors]="cbCreateAccountContent.passwordErrors"
                formControlName="password"
              ></cb-input>
            </div>
          </div>
        </div>

        <div class="columns is-centered is-flex has-text-lfblack has-text-centered mt-1 font-14px">
          <div class="column">
            By continuing, you agree to the creation of an LFID in accordance with our
            <a class="cb-link skyblue" href="https://www.linuxfoundation.org/privacy/" target="_blank">
              Privacy Policy</a
            >
            and
            <a class="cb-link skyblue" href="https://www.linuxfoundation.org/terms/" target="_blank"> Terms of Use.</a>
          </div>
        </div>

        <div class="cb-separator-2rem"></div>

        <div class="actions">
          <cb-button
            data-cy="button-create-account-submit"
            tabindex="0"
            buttonType="submit"
            [disabled]="disableSubmit()"
            [isLoading]="recaptchaLoading"
            data-cy="btn-submit"
            type="is-info px-3"
          >
            Create Account
          </cb-button>

          <div class="footer-subactions">
            <a tabindex="0" class="ca-link-back cb-link cancel flex-1 center mt-0" cbNavigateTo="/cb/login">Back</a>

            <a
              tabindex="0"
              class="cb-link skyblue flex-1 center font-14px"
              target="_blank"
              href="https://lfx.linuxfoundation.org/"
            >
              Learn more
              <span class="ca-icon">
                <cb-icon name="play"></cb-icon>
              </span>
            </a>
          </div>
        </div>
      </form>

      <!-- <div class="is-divider" data-content="Or Sign In with"></div>

      <div class="social-login-options">
        <cb-button type="is-rounded p-05 is-button-icon-rounded " (click)="googlLogin()">
          <cb-icon type="fab" name="google" size="2x"></cb-icon>
        </cb-button>
        <cb-button type="is-rounded p-05 is-button-icon-rounded " (click)="githubLogin()">
          <cb-icon type="fab" name="github" size="2x"></cb-icon>
        </cb-button>
        <cb-button type="is-rounded p-05 is-button-icon-rounded " (click)="linkedinLogin()">
          <cb-icon type="fab" name="linkedin-in" size="2x"></cb-icon>
        </cb-button>
        <cb-button type="is-rounded p-05 is-button-icon-rounded " (click)="facebook()">
          <cb-icon type="fab" name="facebook-f" size="2x"></cb-icon>
        </cb-button>
      </div> -->
    </div>
  </div>

  <!-- <div class="cb-row">
    <div class="cb-content">

    </div>
  </div> -->
</div>

<div *ngIf="formLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>
