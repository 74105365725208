export interface ClientMetadata {
  logoURL?: string;
  contactURL?: string;
  faqURL?: string;
  faqText?: string;
  projectSlug?: string;
  jsdURL?: string;
  logoCss?: string;
}

// INFO: this list is built using client names from auth0
export const CLIENTS_METADATA: { [key: string]: ClientMetadata } = {
  'AGL Confluence': {
    projectSlug: 'agl',
  },
  'AGL Jenkins': {
    projectSlug: 'agl',
  },
  'AGL Jira': {
    projectSlug: 'agl',
  },
  'ASWF Confluence': {
    projectSlug: 'aswf',
  },
  'ASWF Jira': {
    projectSlug: 'aswf',
  },
  'Account Setup': null,
  'Acumos Jenkins Sandbox': {
    projectSlug: 'acumos',
  },
  'Acumos Jenkins': {
    projectSlug: 'acumos',
  },
  'Acumos Jira': {
    projectSlug: 'acumos',
  },
  'Acumos Nexus3': {
    projectSlug: 'acumos',
  },
  'Acumos Wiki': {
    projectSlug: 'acumos',
  },
  'Akraino Dashboard': {
    projectSlug: 'akraino',
  },
  'Akraino Jenkins Sandbox': {
    projectSlug: 'akraino',
  },
  'Akraino Jenkins': {
    projectSlug: 'akraino',
  },
  'Akraino Jira': {
    projectSlug: 'akraino',
  },
  'Akraino Nexus3': {
    projectSlug: 'akraino',
  },
  'Akraino Wiki': {
    projectSlug: 'akraino',
  },
  'Bevy CNCF': {
    projectSlug: 'cncf',
  },
  'Bevy LF Networking': {
    projectSlug: 'lfn',
  },
  'Bevy Riscv': {
    projectSlug: 'risc-v-international',
  },
  'CB Funding': {
    logoURL: 'lfx-crowdfunding-color.svg',
  },
  'CB People': {
    logoURL: 'lfx-mentorship-color.svg',
  },
  'CB Security': {
    logoURL: 'lfx-security-color.svg',
  },
  'Crowd.dev': {
    jsdURL: 'https://jira.linuxfoundation.org/plugins/servlet/desk/portal/4/create/693',
  },
  'LF Security': {
    logoURL: 'lfx-security-color.svg',
  },
  'LF Training Website': {
    logoURL: 'LF-Training-Website.png',
    logoCss: 'is-width-200',
  },
  'LF MyAccount': null,
  CNCF: {
    projectSlug: 'cncf',
  },
  Cvent: {
    contactURL: 'https://events.linuxfoundation.org/about/contact/',
  },
  'EdgeX Foundry Wiki': {
    projectSlug: 'edgex',
  },
  'EdgeX Nexus3': {
    projectSlug: 'edgex',
  },
  'Edgex Foundry Jenkins Sandbox': {
    projectSlug: 'edgex',
  },
  'Edgex Foundry Jenkins': {
    projectSlug: 'edgex',
  },
  'Expensify Fundspring': null,
  'FD.io Jira': {
    projectSlug: 'fdio',
  },
  'FDIO Jenkins Sandbox': {
    projectSlug: 'fdio',
  },
  'FDIO Jenkins': {
    projectSlug: 'fdio',
  },
  'Groups.io': null,
  'Hyperledger Jira': {
    projectSlug: 'hyp',
  },
  'Hyperledger Members Confluence': {
    projectSlug: 'hyp',
  },
  'Hyperledger Wiki': {
    projectSlug: 'hyp',
  },
  'ITX Swagger': null,
  'LEGAL JIRA': null,
  'LF AI Wiki': {
    projectSlug: 'lf-ai-foundation',
  },
  'LF Admin Console': null,
  'LFX Project Control Center': {
    logoURL: 'lfx-pcc-color.svg',
    jsdURL: 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/358',
  },
  'LF CLA': {
    logoURL: 'lfx-easy-cla-color.svg',
  },
  'LF Confluence': null,
  'LF DA': {
    logoURL: 'lfx-insights-color.svg',
    jsdURL: 'https://jira.linuxfoundation.org/plugins/servlet/desk/portal/4/create/226',
  },
  'LF Docs Wiki': null,
  'LF Edge Jenkins': {
    projectSlug: 'lfedge',
  },
  'LF Edge Wiki': {
    projectSlug: 'lfedge',
  },
  'LF Energy Confluence': {
    projectSlug: 'lfenergy',
  },
  'LF Forums': null,
  'LF IT Automation - Beta': null,
  'LF IT Jenkins Sandbox': null,
  'LF IT Jenkins': null,
  'LF IT Tools': null,
  'LF JIRA': null,
  'LF Join Form': null,
  'LF Matrix': null,
  'LF My Profile': {
    logoURL: 'lfx-individual-dashboard-color.svg',
  },
  'LF Website': {
    logoURL: 'LF-Website.svg',
  },
  'LF Networking Wiki': {
    projectSlug: 'lfn',
  },
  'LF NexusIQ': null,
  'LF Platform Apps': null,
  'LF Registration': null,
  'LFID User Management': null,
  'LFN JIRA': {
    projectSlug: 'lfn',
  },
  LFX: {
    logoURL: 'LFX.svg',
  },
  'Learner UI': null,
  'Link an Account': null,
  'ODIM Wiki': null,
  'ODL Jenkins Sandbox': {
    projectSlug: 'odl',
  },
  'ODL Jenkins': {
    projectSlug: 'odl',
  },
  'ODL Nexus3': {
    projectSlug: 'odl',
  },
  'ODPI Artifactory': {
    projectSlug: 'odpi',
  },
  'ONAP Jenkins Sandbox': {
    projectSlug: 'onap',
  },
  'ONAP Jenkins': {
    projectSlug: 'onap',
  },
  'ONAP Jira': {
    projectSlug: 'onap',
  },
  'ONAP Nexus3': {
    projectSlug: 'onap',
  },
  'ONAP Wiki': {
    projectSlug: 'onap',
  },
  'OPNFV Jenkins Sandbox': {
    projectSlug: 'opnfv',
  },
  'OPNFV Jenkins': {
    projectSlug: 'opnfv',
  },
  'OPNFV Jira': {
    projectSlug: 'opnfv',
  },
  'OPNFV Wiki': {
    projectSlug: 'opnfv',
  },
  'ORAN JIRA': {
    projectSlug: 'oran',
  },
  'ORAN Jenkins Sandbox': {
    projectSlug: 'oran',
  },
  'ORAN Jenkins': {
    projectSlug: 'oran',
  },
  'ORAN Nexus3': {
    projectSlug: 'oran',
  },
  'ORAN Wiki': {
    projectSlug: 'oran',
  },
  'Open-O Confluence': {
    projectSlug: 'open-o',
  },
  'OpenDaylight Confluence': {
    projectSlug: 'odl',
  },
  'OpenDaylight Jira': {
    projectSlug: 'odl',
  },
  'PNDA JIRA': {
    projectSlug: 'pnda',
  },
  'PNDA Wiki': {
    projectSlug: 'pnda',
  },
  'Project Control Center': {
    logoURL: 'lfx-pcc-color.svg',
    jsdURL: 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/358',
  },
  'RISC-V Jira': {
    projectSlug: 'risc-v-international',
  },
  'RISC-V Wiki': {
    projectSlug: 'risc-v-international',
  },
  'TARS Wiki': {
    projectSlug: 'tars',
  },
  'TOIP Confluence': {
    projectSlug: 'toip',
  },
  'Test OIC App 1': null,
  'Test OIC App 2': null,
  'Test SAML App 1': null,
  'Test SAML App 2': null,
  'Token Helper CLI': null,
  'Training Portal': {
    faqURL: 'https://training.linuxfoundation.org/about/faqs/',
    faqText: 'Training + Certification FAQ',
    contactURL: 'https://training.linuxfoundation.org/about/contact-us/',
    jsdURL: 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/15/create/329',
    logoURL: 'LF-Training-Website.png',
    logoCss: 'is-width-200',
  },
  'Training Site': null,
  'Tungsten Fabric Jira': {
    projectSlug: 'tungsten',
  },
  'Tungsten Fabric Wiki': {
    projectSlug: 'tungsten',
  },
  'ZOWE Confluence': {
    projectSlug: 'zowe',
  },
  'ZOWE Jenkins Sandbox': {
    projectSlug: 'zowe',
  },
  'ZOWE Jenkins': {
    projectSlug: 'zowe',
  },
};
