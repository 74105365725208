/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../cb-icon/cb-icon.component.ngfactory";
import * as i3 from "../cb-icon/cb-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "./cb-alert.component";
var styles_CbAlertComponent = [i0.styles];
var RenderType_CbAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbAlertComponent, data: {} });
export { RenderType_CbAlertComponent as RenderType_CbAlertComponent };
function View_CbAlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-icon", [["name", "check-circle"]], null, null, null, i2.View_CbIconComponent_0, i2.RenderType_CbIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.CbIconComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "check-circle"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CbAlertComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-icon", [["name", "exclamation-circle"]], null, null, null, i2.View_CbIconComponent_0, i2.RenderType_CbIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.CbIconComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "exclamation-circle"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CbAlertComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-icon", [["name", "question-circle"]], null, null, null, i2.View_CbIconComponent_0, i2.RenderType_CbIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.CbIconComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "question-circle"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CbAlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbAlertComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbAlertComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbAlertComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.type === "success"); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.type === "error"); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.type === "warning"); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "alert ", _co.type, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_CbAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-alert", [], null, null, null, View_CbAlertComponent_0, RenderType_CbAlertComponent)), i1.ɵdid(1, 49152, null, 0, i5.CbAlertComponent, [], null, null)], null, null); }
var CbAlertComponentNgFactory = i1.ɵccf("cb-alert", i5.CbAlertComponent, View_CbAlertComponent_Host_0, { type: "type" }, {}, ["*"]);
export { CbAlertComponentNgFactory as CbAlertComponentNgFactory };
