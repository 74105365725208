import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelper = new JwtHelperService();
const twentyfourHours = 24 * 60 * 60;

export function isExpired(token, validHours = twentyfourHours) {
  const decoded = jwtHelper.decodeToken(token);
  const { iat, exp } = decoded;
  const now = new Date();
  const iatTime = new Date((iat + validHours) * 1000);
  const expTime = new Date(exp * 1000);

  if (exp) {
    return now.getTime() > expTime.getTime();
  }
  if (iat) {
    return now.getTime() > iatTime.getTime();
  }

  return false;
}
