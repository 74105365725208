<div class="columns is-flex is-centered">
  <div class="column is-half is-four-fifths-mobile">
    <div class="cb-title-group">
      <div class="primary-text">
        {{ errorName }}
      </div>
      <div class="cb-separator-2rem"></div>

      <div class="">
        {{ errorDescription }}
      </div>
    </div>
    <div class="cb-separator-2rem"></div>
    <div class="cb-text-center">
      <cb-button *ngIf="safeReturnURL && !isAccessDenied" type="is-info is-rounded" (click)="gotoLogin()">
        Try going back to Login Page
      </cb-button>
      <br />
      <br />
      <a *ngIf="!isAccessDenied" class="cb-link skyblue" cbNavigateTo="/cb/help">Get Help</a>
      <a *ngIf="isAccessDenied" [href]="requestAccess" class="cb-link skyblue">Request Access</a>
    </div>
  </div>
</div>
