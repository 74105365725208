export class SocialUsernameComponentContent {
  redirectionMessage = '';
  usernameLabel = 'Username';
  submitButtonLabel = 'Create Account';
  title = 'Create your linux Foundation Login';
  usernameErrors = {
    required: 'Username is a required.',
    minlength: 'Username should be at least 3 characters long.',
    maxlength: 'Username should be at less than 60 characters long.',
    pattern: 'Username should be lowercase alphanumeric chars.',
  };
  errorCodes: {
    emailTaken: 'email already taken';
  };
  errorMessages = {
    default: 'An error occurred!. Please try sign out and sign in again.',
    session: 'Session has expired',
    terms: 'Please accept the Terms and Conditions',
    username: 'Please enter your username',
    emailTaken: `There was an error or conflict checking to see if your email is already registered. If you have an existing username, you may log out and log back in with a password, then link this account from Individual Dashboard, or, contact support for assistance.`,
  };
  termsAndConditionsText = `I agree to the CommunityBridge <a href="https://www.linuxfoundation.org/terms/"
    target="_blank">Platform Use</a> Agreement and terms incorporated therein,
    <a href="https://www.linuxfoundation.org/terms/" target="_blank">Service-Specific Use Terms
    </a>  including the Acceptable Use Policy and the
    <a href="https://www.linuxfoundation.org/privacy/" target="_blank">Privacy Policy</a>`;
  terms = `By creating an account, you agree to the CommunityBridge <a class="cb-link skyblue" tabindex="-1"
    href="https://communitybridge.org/platform-use-agreement/" target="_blank"> Platform Use Agreement</a>
    and all terms incorporated therein, including the
    <a tabindex="-1" class="cb-link skyblue" href="https://www.linuxfoundation.org/terms/" target="_blank">
    Service-Specific Use Terms</a>, the Acceptable Use Policy and the <a tabindex="-1" class="cb-link skyblue"
    href="https://www.linuxfoundation.org/privacy/" target="_blank">Privacy Policy</a>.
    <br/><br/>
    Linux Foundation ID (LFID) is a free account that identifies you and securely provides access to
    web sites of The Linux Foundation, our projects, and our service providers.
    <br/><br/>
    `;

  constructor(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }
  overwriteContent(options: any = {}) {
    if (options) {
      Object.assign(this, { ...options });
    }
  }
}
