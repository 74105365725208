/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error-unauthorize.component";
import * as i2 from "../../services/cb-webapi";
import * as i3 from "../../services/cb-fullstory.service";
var styles_CbUnauthorizeComponent = [];
var RenderType_CbUnauthorizeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CbUnauthorizeComponent, data: {} });
export { RenderType_CbUnauthorizeComponent as RenderType_CbUnauthorizeComponent };
export function View_CbUnauthorizeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "div", [["class", "cb-title-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "cb-separator-5rem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "cb-separator-5rem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 15, "div", [["class", "primary-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["style", "font-size: 2rem;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" We were unable to continue with your session "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "p", [["style", "width: 600px; margin: 0 auto; margin-top: 1rem; font-weight: normal;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" We were regrettably unable to continue with your session. It was likely due to your browser being closed before you completed your login. "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [["style", "width: 600px; margin: 3rem auto 2rem auto; text-align: left; padding-left: 1rem;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Please follow these steps to continue with your session: "])), (_l()(), i0.ɵeld(11, 0, null, null, 7, "ol", [["style", "width: 600px; margin: 0 auto 3rem; text-align: left; font-weight: normal;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 4, "li", [["style", "margin: 0.75rem 0;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Proceed to the "])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "a", [["class", "cb-link skyblue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Login"])), (_l()(), i0.ɵted(-1, null, [" page and log In with your username and password. "])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "li", [["style", "margin: 0.75rem 0;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Please use the same browser window, and avoid bookmarking the URL as it is one-time use only. "])), (_l()(), i0.ɵeld(19, 0, null, null, 0, "div", [["class", "cb-separator-2rem"]], null, null, null, null, null))], null, null); }
export function View_CbUnauthorizeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cb-unauthorize", [], null, null, null, View_CbUnauthorizeComponent_0, RenderType_CbUnauthorizeComponent)), i0.ɵdid(1, 114688, null, 0, i1.CbUnauthorizeComponent, [i2.CbWebApi, i3.CbFullstoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbUnauthorizeComponentNgFactory = i0.ɵccf("cb-unauthorize", i1.CbUnauthorizeComponent, View_CbUnauthorizeComponent_Host_0, {}, {}, []);
export { CbUnauthorizeComponentNgFactory as CbUnauthorizeComponentNgFactory };
