import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { CbLoginConfig } from './cb-login.config';
import { CbAuthURLService } from './cb-auth-url.service';
import { stringify } from 'querystring';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { LogFactory } from './cb-debug';
const log = LogFactory('CbGroupioService');

@Injectable({ providedIn: 'root' })
export class CbGroupioService {
  baseRoute = `${this.loginConfig.cbBackend}/user`;

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private loginConfig: CbLoginConfig,
    private cbAuthURLService: CbAuthURLService
  ) {}

  getGroupID() {
    const queryParams = (this.cbAuthURLService.getQueryparams() as GroupioQueryParams) || ({} as any);
    const id = queryParams.group_id;

    if (id) {
      return id;
    }

    // *info: sso static page
    const { group_id } = this.cbAuthURLService.getReturnToQueryParams() || ({} as any);
    return group_id;
  }

  isLogin() {
    if (!this.getGroupID()) {
      return false;
    }

    const queryParams = this.cbAuthURLService.getQueryparams() as GroupioQueryParams;
    const groupAccessType = queryParams.group_access_type;

    if (groupAccessType === 'login') {
      return true;
    }

    return false;
  }

  isJoin() {
    if (!this.getGroupID()) {
      return false;
    }

    const queryParams = this.cbAuthURLService.getQueryparams() as GroupioQueryParams;
    const groupAccessType = queryParams.group_access_type;

    if (groupAccessType === 'register') {
      return true;
    }

    return false;
  }

  getPageParentGroupTitle() {
    if (!this.getGroupID()) {
      return null;
    }

    return this.getParentTitleForGroupio();
  }

  getGroupSubGroupNames(): any[] {
    const queryParams = this.cbAuthURLService.getQueryparams() as GroupioQueryParams;
    const groupName = queryParams.group_name;

    if (groupName) {
      return groupName.split('+');
    }

    // *info sso static page
    const { group_name } = this.cbAuthURLService.getReturnToQueryParams() as any;
    return group_name ? group_name.split('+') : [];
  }

  getParentTitleForGroupio() {
    const [parentGroupName] = this.getGroupSubGroupNames();
    return parentGroupName;
  }

  getPageTitle() {
    if (!this.getGroupID()) {
      return null;
    }

    const [, subGroupName] = this.getGroupSubGroupNames();

    return subGroupName || 'Main';
  }

  groupTitleTemplate(type = 'Log In', groupName = '') {
    return `<div class="group-title">${type} to <span class="group-name">${groupName}</span> group</div>`;
  }

  getPageTitleForLogin() {
    const [, subGroupName] = this.getGroupSubGroupNames();
    if (!subGroupName) {
      return `Main group`;
    }
    return this.groupTitleTemplate('Log In', subGroupName);
  }

  getPageTitleForJoin() {
    const [, subGroupName] = this.getGroupSubGroupNames();
    if (!subGroupName) {
      return `Join to Main Group`;
    }
    return this.groupTitleTemplate('Join', subGroupName);
  }

  returnToGroupPage() {
    const groupURL = this.cbAuthURLService.getGroupURL();
    if (!groupURL) {
      return false;
    }

    window.location.href = groupURL;
    return true;
  }

  async ContinueWIthNewEmail(ruleToken, email) {
    log('ContinueGroupsIOflowWithEmail', { email });

    const subscriber = await this.GetEmailSubscription(ruleToken, email).toPromise();
    log('subscriber ?', { subscriber });

    if (subscriber) {
      // @info : save email and continue login
      await this.AddUserEmail(ruleToken, email).toPromise();
      return 'continue';
    }

    // Invite email
    const invite = await this.InviteEmail(ruleToken, email).toPromise();
    log('invite ?', { invite });

    return 'invite';
  }

  sendVerificationEmail(payload: SendVerificationEmailPayload) {
    const route = `${this.baseRoute}/send-verify-email-link`;
    const requestBody = {
      ...payload,
      type: 'groups_io',
    };
    return this.httpService.post(route, { requestBody });
  }

  GetEmailSubscription(ruleToken, email) {
    const q = stringify({ email, token: ruleToken });
    const route = `${this.baseRoute}/groups/email-subscription?${q}`;
    return this.http.get<Subscription>(route).pipe(catchError((response) => throwError(response.error)));
  }

  GetUserSubscriptions(ruleToken) {
    const q = stringify({ token: ruleToken });
    const route = `${this.baseRoute}/groups/user-subscriptions?${q}`;
    return this.http.get<string[]>(route).pipe(catchError((response) => throwError(response.error)));
  }

  GetUserEmails(ruleToken) {
    const q = stringify({ token: ruleToken });
    const route = `${this.baseRoute}/groups/user-emails?${q}`;
    return this.http.get<string[]>(route).pipe(catchError((response) => throwError(response.error)));
  }

  InviteEmail(ruleToken, email) {
    const payload = {
      token: ruleToken,
      email,
    };
    const URL = `${this.baseRoute}/groups/invite`;
    return this.http.post<GroupInviteResponse>(URL, payload);
  }

  AddUserEmail(ruleToken, email) {
    const payload = {
      token: ruleToken,
      email,
    };

    const URL = `${this.baseRoute}/groups/add-user-email`;
    return this.http.post<boolean>(URL, payload);
  }
}

export interface GroupInviteResponse {
  Email: string;
  MailinglistGPID: string;
  Message?: string;
  Result: string;
}

export interface MailingList {
  Domain: string;
  MailingListGPID: string;
  Name: string;
}

export interface Subscription {
  Email: string;
  MailingList: MailingList;
  Name: string;
  SubscriberGPID: string;
}

export interface GroupioQueryParams {
  group_id: string;
  group_access_type: string;
  group_name: string;
}

export interface CheckSubscriberPayload {
  token: string;
  selectedEmail: string;
}

export interface SendVerificationEmailPayload {
  email: string;
  token: string;
  state: string;
  returnTo?: string;
}
