import { LogFactory } from './../../services/cb-debug';
import { Component, OnInit } from '@angular/core';
import { to } from 'await-to-js';

import { CbGroupioService } from '../../services/cb-groupio.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbMyProfileService } from '../../services/cb-myprofile.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';

const log = LogFactory('GroupioSelectEmailSendComponent');
@Component({
  selector: 'cb-groupio-select-email-send',
  templateUrl: './groupio-select-email-send.component.html',
})
export class GroupioSelectEmailSendComponent implements OnInit {
  isLoading = false;
  emailSent = false;
  errorMessage = '';
  email = '';

  constructor(
    private cbWebApi: CbWebApi,
    private cbAuthURLService: CbAuthURLService,
    private cbGroupioService: CbGroupioService,
    private cbMyProfileService: CbMyProfileService,
    private cbFullstoryService: CbFullstoryService
  ) {}

  ngOnInit() {
    const { email } = this.cbAuthURLService.getAllURLParams();
    this.email = email;

    this.cbMyProfileService.displayMyProfileURL();
  }

  loadingStart() {
    this.isLoading = true;
    this.emailSent = false;
    this.errorMessage = '';
  }

  loadingEnd() {
    this.isLoading = false;
  }

  async resendEmail() {
    this.loadingStart();

    const { email, token, state, returnTo } = this.cbAuthURLService.getAllURLParams();

    const payload = {
      email,
      token,
      state,
      returnTo,
    };

    log('payload', { payload });

    let errResponse: any;

    [errResponse] = await to(this.cbGroupioService.sendVerificationEmail(payload).toPromise());
    if (errResponse) {
      const error = errResponse && errResponse.error && errResponse.error.error;
      const defaultErrorMessage = 'There was a problem. Please try again';

      this.errorMessage = error.message || defaultErrorMessage;
      this.cbFullstoryService.fsError(this.errorMessage);
      this.loadingEnd();
      return;
    }

    this.emailSent = true;
    this.loadingEnd();
  }

  signOut() {
    this.cbWebApi.returnToAuthorizePage();
  }
}
