import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { to } from 'await-to-js';
import { CbHttpErrorsService } from './../../services/cb-http-errors.service';
import { CbGroupioService } from './../../services/cb-groupio.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbStateService } from '../../services/cb-state.service';
import { CbBackendService } from '../../services/cb-backend.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { LogFactory } from '../../services/cb-debug';
var log = LogFactory('EmailVerificationComponent');
var EmailVerificationComponent = /** @class */ (function () {
    function EmailVerificationComponent(cbWebApi, router, cbAuthURLService, cbStateService, cbBackendService, cbGroupioService, cbHttpErrorsService, cbFullstoryService) {
        this.cbWebApi = cbWebApi;
        this.router = router;
        this.cbAuthURLService = cbAuthURLService;
        this.cbStateService = cbStateService;
        this.cbBackendService = cbBackendService;
        this.cbGroupioService = cbGroupioService;
        this.cbHttpErrorsService = cbHttpErrorsService;
        this.cbFullstoryService = cbFullstoryService;
        this.isLoading = true;
        this.typeMessage = '';
        this.message = '';
        this.pageTitle = '';
        this.pageMessage = '';
        this.displaySignOut = false;
    }
    EmailVerificationComponent.prototype.ngOnInit = function () {
        this.verifyEmail();
    };
    EmailVerificationComponent.prototype.setPageMessage = function (message, type) {
        if (type === void 0) { type = 'error'; }
        this.typeMessage = type;
        this.message = message;
        this.isLoading = false;
        this.displaySignOut = true;
    };
    EmailVerificationComponent.prototype.clearMessage = function () {
        this.typeMessage = '';
        this.message = '';
    };
    EmailVerificationComponent.prototype.loadingStart = function () {
        this.isLoading = true;
    };
    EmailVerificationComponent.prototype.loadingEnd = function () {
        this.isLoading = false;
    };
    EmailVerificationComponent.prototype.verifyEmail = function () {
        var decodedToken = this.cbAuthURLService.getDecodedToken();
        var type = decodedToken.type;
        switch (type) {
            case 'groups_io':
                this.HandleVerifyEmailGroupsio(decodedToken);
                break;
            case 'user_service':
                this.HandleVerifyAlternativeUserEmail(decodedToken);
                break;
            default:
                this.setPageMessage('Verification email not implemented!', 'error');
                break;
        }
    };
    EmailVerificationComponent.prototype.HandleVerifyEmailGroupsio = function (decodedToken) {
        log('entered HandleVerifyEmailGroupsio');
        var isValid = this.cbStateService.isCurrentStateValid();
        log('isValid ? ', { isValid: isValid });
        if (!isValid) {
            this.cbWebApi.returnToAuthorizePage();
            return;
        }
        if (!decodedToken.groupsioflow) {
            this.setPageMessage('Invalid Token!', 'error');
            return;
        }
        this.verifyForGroupsIOFlow();
        return;
    };
    EmailVerificationComponent.prototype.signOut = function () {
        this.loadingStart();
        this.cbWebApi.returnToAuthorizePage();
    };
    EmailVerificationComponent.prototype.verifyForGroupsIOFlow = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, token, state, returnTo, _b, groupsioEmail, groupName, groupURL, _c, err, type, message, code, extraParams, queryParams, nextPage;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        log('entered verifyForGroupsIOFlow');
                        _a = this.cbAuthURLService.getAllURLParams(), token = _a.token, state = _a.state, returnTo = _a.returnTo;
                        _b = this.cbAuthURLService.getDecodedToken(), groupsioEmail = _b.groupsioEmail, groupName = _b.groupName, groupURL = _b.groupURL;
                        return [4 /*yield*/, to(this.cbGroupioService.ContinueWIthNewEmail(token, groupsioEmail))];
                    case 1:
                        _c = _d.sent(), err = _c[0], type = _c[1];
                        if (err) {
                            log('error in request', { err: err });
                            message = this.cbHttpErrorsService.GetErrorMessage(err);
                            code = this.cbHttpErrorsService.GetErrorCode(err);
                            if (code === 'lfid_exist') {
                                message = "This email address is associated with another LF Profile. To use it, please go back and login using that LF Profile.";
                            }
                            this.cbFullstoryService.fsError(message);
                            this.setPageMessage(message);
                            return [2 /*return*/];
                        }
                        if (type === 'continue') {
                            extraParams = {
                                selectedEmail: groupsioEmail,
                            };
                            this.cbWebApi.continueToAuth0(token, state, extraParams);
                            return [2 /*return*/];
                        }
                        if (type === 'invite') {
                            queryParams = {
                                email: groupsioEmail,
                                state: state,
                                token: token,
                                groupName: groupName,
                                groupURL: groupURL,
                                returnTo: returnTo,
                            };
                            log('queryParams ? ', queryParams);
                            nextPage = "/cb/groupio-confirm-invite";
                            this.router.navigate([nextPage], { queryParams: queryParams });
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EmailVerificationComponent.prototype.HandleVerifyAlternativeUserEmail = function (decodedToken) {
        var _this = this;
        var token = this.cbAuthURLService.getToken();
        this.cbBackendService.markEmailAsVerified(token).subscribe(function () {
            var email = decodedToken.email;
            _this.pageTitle = 'Your email is verified';
            _this.pageMessage = "Your email " + email + " is verified. <br/> You can close this window.";
            _this.loadingEnd();
        }, function () {
            _this.setPageMessage('There was a problem verifying your email', 'error');
            _this.cbFullstoryService.fsError('There was a problem verifying your email');
        });
    };
    return EmailVerificationComponent;
}());
export { EmailVerificationComponent };
