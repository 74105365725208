<div class="lfx-meetings-container" [class.error]="meetingError">
  <div class="title-container">
    <h3>MEETING REGISTRATION</h3>
    <div class="meeting-type" [class.past]="meetingType === 'Past Meeting'" *ngIf="!meetingError">
      <h4>{{ meetingType }}</h4>
    </div>
  </div>
  <div class="meeting-error" *ngIf="meetingError">
    <cb-icon type="fas" name="exclamation-triangle" size="3x"></cb-icon>
    <p>Sorry, this meeting invite has expired or is invalid.</p>
    <p>Login to LFX Individual Dashboard to view all meetings available to you.</p>
  </div>
  <div class="meeting" *ngIf="!meetingError">
    <div class="date-container">
      <span>{{ meetingDate }}</span>
    </div>
    <h1>{{ meetingName }}</h1>
    <p>{{ meetingDescription || '' }}</p>
    <p *ngIf="showPersonalizedInviteInfo">
      This is a personalized invitation for {{ registrantName }}.
      <a (click)="changeCurrentUser()">Not {{ registrantFirstName }}?</a>
    </p>
    <p *ngIf="showLoginText" class="login-text">
      Please log in or create an account to register to attend the meeting.
    </p>
    <div class="alert-message error" *ngIf="meetingVisibility === 'private' && (!inviteID || notCurrentUser)">
      <cb-icon type="fas" name="exclamation-triangle" size="2x"></cb-icon>
      <p>
        This meeting invitation is private. If you are supposed to be invited, please contact the meeting organizer to
        receive your personalized link.
      </p>
    </div>
    <div class="alert-message past" *ngIf="meetingType === 'Past Meeting'">
      <cb-icon type="fas" name="exclamation-triangle" size="2x"></cb-icon>
      <p>
        This meeting has ended. You can login to LFX Individual Dashboard below to view the recording and/or transcript
        if available.
      </p>
    </div>
  </div>
</div>
