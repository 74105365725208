import { CbAuthURLService } from './cb-auth-url.service';
import { CbStorage } from './cb-storage';
import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
import * as i1 from "./cb-auth-url.service";
import * as i2 from "./cb-storage";
var log = LogFactory('CbStateService');
var STATE_KEY = 'lf-state';
var CbStateService = /** @class */ (function () {
    function CbStateService(cbAuthURLService, cbStorage) {
        this.cbAuthURLService = cbAuthURLService;
        this.cbStorage = cbStorage;
    }
    CbStateService.prototype.saveExpectedState = function (state) {
        if (state === void 0) { state = ''; }
        this.cbStorage.save(STATE_KEY, state);
    };
    CbStateService.prototype.removeState = function () {
        this.cbStorage.remove(STATE_KEY);
        return true;
    };
    CbStateService.prototype.markStateAsUsed = function () {
        var state = this.cbAuthURLService.getState();
        this.cbStorage.save("lf*" + state, 'true');
        return true;
    };
    CbStateService.prototype.wasStatedUsed = function () {
        log('entered wasStatedUsed');
        var state = this.cbAuthURLService.getAllURLParams().state;
        log(' state ? ', { state: state });
        return this.cbStorage.get("lf*" + state);
    };
    // @info: Auth0 redirection feature requires, user continues using the same Browser
    // due some cookies internal validations,
    // To avoid any auth0 error, we can Save the state and validate it and the end of the User flow
    // by isCurrentStateValid()
    CbStateService.prototype.saveCurrentState = function () {
        log('entered saveCurrentState');
        var state = this.cbAuthURLService.getAllURLParams().state;
        log(' state ? ', { state: state });
        if (!state) {
            return false;
        }
        // Verify if it was used before
        var used = this.wasStatedUsed();
        log('used? ', { used: used });
        if (used) {
            return false;
        }
        log('saving saveExpectedState', { state: state });
        this.saveExpectedState(state);
        return true;
    };
    /**
     * Auth0 Rule is redirecting the user to /process-continue Page
     * which is capturing the one time valid State (this.saveCurrentState())
     * isCurrentStateValid assure that auth0 will be able use the current state
     * Because on error auth0's server (domain eg. linuxfoundation.auth0.com/continue? ...)
     * will display `unauthorized` blank page
     */
    CbStateService.prototype.isCurrentStateValid = function () {
        log('entered isCurrentStateValid');
        var state = this.cbAuthURLService.getAllURLParams().state;
        log('state ', { state: state });
        if (!state) {
            return false;
        }
        // Verify if it was used before
        var used = this.wasStatedUsed();
        log('used? ', { used: used });
        if (used) {
            return false;
        }
        var expectedState = this.getExpectedState();
        log('expectedState ', { expectedState: expectedState });
        var isValid = expectedState === state;
        log('is valid ? ', { expectedState: expectedState, state: state, isValid: isValid });
        return isValid;
    };
    CbStateService.prototype.getExpectedState = function () {
        return this.cbStorage.get(STATE_KEY);
    };
    CbStateService.prototype.removeCurrentState = function () {
        this.cbStorage.remove(STATE_KEY);
        return true;
    };
    CbStateService.ngInjectableDef = i0.defineInjectable({ factory: function CbStateService_Factory() { return new CbStateService(i0.inject(i1.CbAuthURLService), i0.inject(i2.CbStorage)); }, token: CbStateService, providedIn: "root" });
    return CbStateService;
}());
export { CbStateService };
