import { NG_VALUE_ACCESSOR, FormControl, ControlContainer, ValidationErrors } from '@angular/forms';
import { Component, Input, OnInit, forwardRef, Optional, Host, SkipSelf, ElementRef, ViewChild } from '@angular/core';

export const CB_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => CBTextAreaComponent),
  multi: true,
};

@Component({
  selector: 'cb-textarea',
  templateUrl: './cb-textarea.component.html',
  styleUrls: ['./cb-textarea.component.scss'],
  providers: [CB_INPUT_VALUE_ACCESSOR],
})
export class CBTextAreaComponent implements OnInit {
  @ViewChild('inputElement') inputElement: ElementRef | null = null;
  @Input() formControlName = '';
  @Input() label = 'label';
  @Input() rowSize = 8;
  @Input() maxLength = 32700;
  @Input() errors = [];

  control: FormControl = new FormControl();
  textValue = '';

  propagateChanges: (value) => {};
  onTouched: () => {};

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.controlContainer && this.controlContainer.control) {
      this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
    }
  }

  getErrorMessage(errors: ValidationErrors | null) {
    if (!errors) {
      return;
    }

    const firstKey = Object.keys(errors)[0];
    // const getError = errors[firstKey];
    return (this.errors && this.errors[firstKey]) || firstKey;
  }

  onKeyup(value: string) {
    this.propagateChanges(value);
  }

  writeValue(obj: any): void {
    this.textValue = obj || '';
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(): void {}
}
