/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-top-banner-registration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-ellipsis";
import * as i4 from "./cb-top-banner-registration.component";
import * as i5 from "../../services/cb-registration.service";
import * as i6 from "../../services/cb-login-banner.service";
var styles_CbTopBannerRegistrationComponent = [i0.styles];
var RenderType_CbTopBannerRegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbTopBannerRegistrationComponent, data: {} });
export { RenderType_CbTopBannerRegistrationComponent as RenderType_CbTopBannerRegistrationComponent };
function View_CbTopBannerRegistrationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" in ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timezoneFriendlyName; _ck(_v, 1, 0, currVal_0); }); }
function View_CbTopBannerRegistrationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "reg-date font-12px bold is-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerRegistrationComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.timezoneFriendlyName; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.zonedDate, "EEEE MMMM d y, h:mm a")); _ck(_v, 1, 0, currVal_0); }); }
function View_CbTopBannerRegistrationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "reg-title font-20px py-05 bold-600"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_CbTopBannerRegistrationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "reg-description font-14px"], ["ellipsis", "... Show more"], ["style", "height: 3rem;"]], null, [[null, "ellipsis-click-more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ellipsis-click-more" === en)) {
        var pd_0 = ((_co.showEllipsis = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4866048, [["ell", 4]], 0, i3.EllipsisDirective, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.PLATFORM_ID], { ellipsisCharacters: [0, "ellipsisCharacters"], ellipsisContent: [1, "ellipsisContent"] }, { moreClickEmitter: "ellipsis-click-more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "... Show more"; var currVal_1 = _co.description; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CbTopBannerRegistrationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "reg-description font-14px"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "ngx-ellipsis-more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showEllipsis = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Show less "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 1, 0, currVal_0); }); }
function View_CbTopBannerRegistrationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerRegistrationComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noEllipsis", 2]], null, 0, null, View_CbTopBannerRegistrationComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showEllipsis; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CbTopBannerRegistrationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "reg-speakers font-16px py-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.speakers; _ck(_v, 1, 0, currVal_0); }); }
function View_CbTopBannerRegistrationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "to-banner-registration columns is-centered my-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "column pb-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "banner-registration-wrap font-open-sans has-text-white p-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "reg-top-title font-11px has-text-lfgrey-light mb-1 is-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " REGISTRATION"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerRegistrationComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerRegistrationComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerRegistrationComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerRegistrationComponent_8)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.date; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.title; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.description; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.speakers; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 4, 0, currVal_0); }); }
export function View_CbTopBannerRegistrationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CbTopBannerRegistrationComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CbTopBannerRegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-top-banner-registration", [], null, null, null, View_CbTopBannerRegistrationComponent_0, RenderType_CbTopBannerRegistrationComponent)), i1.ɵdid(1, 4308992, null, 0, i4.CbTopBannerRegistrationComponent, [i5.CbRegistrationService, i6.CbLoginBannerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbTopBannerRegistrationComponentNgFactory = i1.ɵccf("cb-top-banner-registration", i4.CbTopBannerRegistrationComponent, View_CbTopBannerRegistrationComponent_Host_0, {}, {}, []);
export { CbTopBannerRegistrationComponentNgFactory as CbTopBannerRegistrationComponentNgFactory };
