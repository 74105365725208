import { BehaviorSubject } from 'rxjs';
import { stage } from './cb-envars';
import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
var log = LogFactory('CbMyProfileService', false);
// const appStage = STAGE || 'development';
function GetStage() {
    log('entered GetStage', { stage: stage });
    switch (stage) {
        case 'development':
        case 'dev':
            return 'dev';
        case 'production':
        case 'prod':
            return 'prod';
        case 'stage':
        case 'staging':
            return 'staging';
        case 'test':
            return 'test';
        default:
            return 'dev';
    }
}
var innerStage = GetStage();
var ManageProfileMap = {
    prod: 'https://myprofile.lfx.linuxfoundation.org/',
    default: "https://myprofile." + innerStage + ".platform.linuxfoundation.org/",
};
var MyProfileURL = ManageProfileMap[innerStage] || ManageProfileMap.default;
var CbMyProfileService = /** @class */ (function () {
    function CbMyProfileService() {
        this.URL = MyProfileURL;
        this.myProfileURLdisplayed$ = new BehaviorSubject(false);
    }
    CbMyProfileService.prototype.displayMyProfileURL = function () {
        log('entered displayMyProfileURL');
        this.myProfileURLdisplayed$.next(true);
    };
    CbMyProfileService.ngInjectableDef = i0.defineInjectable({ factory: function CbMyProfileService_Factory() { return new CbMyProfileService(); }, token: CbMyProfileService, providedIn: "root" });
    return CbMyProfileService;
}());
export { CbMyProfileService };
