import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { parse, stringify } from 'querystring';

import { ExtraParams, InternalOptions } from './cb-auth.service';
import { CbTrainingAppService } from './cb-training-app.service';

// import { LogFactory } from './cb-debug';
// const log = LogFactory('CbAuthURLService');

const QUERY_KEYS = {
  token: 'token',
  return: 'return',
  returnTo: 'returnTo',
  email: 'email',
  state: 'state',
  groupURL: 'groupURL',
};

@Injectable({ providedIn: 'root' })
export class CbAuthURLService {
  fragments = undefined;
  queryParamsFromURL = null;

  constructor(private activatedRoute: ActivatedRoute, private cbTrainingAppService: CbTrainingAppService) {
    this.getDataFromFragment();
  }

  getQueryparams() {
    const queryParamsKeys = Object.keys(this.activatedRoute.snapshot.queryParams);
    if (queryParamsKeys.length) {
      return this.activatedRoute.snapshot.queryParams;
    }

    return this.getQueryParamsFromURL();
  }

  getQueryParamsFromURL() {
    const search = window.location.search || '';
    if (!search) {
      return null;
    }

    let returnTo = '';
    let searchWithParams = search;

    if (search.includes('&return=')) {
      [searchWithParams, returnTo] = searchWithParams.split('&return=');
    } else if (search.includes('&returnTo=')) {
      [searchWithParams, returnTo] = searchWithParams.split('&returnTo=');
    }

    searchWithParams = searchWithParams.replace('?', '');

    this.queryParamsFromURL = parse(searchWithParams);
    this.queryParamsFromURL['return'] = returnTo;
    this.queryParamsFromURL['returnTo'] = returnTo;

    return this.queryParamsFromURL;
  }

  getValuesFromFragments(frags: string) {
    return (
      (frags &&
        frags.split('&').reduce((acc, item) => {
          const [key, value] = item.split('=');
          acc[key] = value;
          return { ...acc };
        }, {})) ||
      {}
    );
  }

  getDataFromFragment() {
    const fragments = this.activatedRoute.snapshot.fragment || window.location.hash || '';

    let extra = fragments;
    let returnURL = '';
    if (fragments.includes('&return=')) {
      [extra, returnURL] = fragments.split('&return=');
    } else if (fragments.includes('&returnTo=')) {
      [extra, returnURL] = fragments.split('&returnTo=');
    }

    extra = extra.replace('#', '');

    const frags = parse(extra);
    frags[QUERY_KEYS.return] = returnURL;

    this.fragments = frags;

    return frags;
  }

  getParamsFromReturnTo() {
    // log('entered getParamsFromReturnTo');

    const returnPath = this.getReturnPath();
    if (!returnPath) {
      return {};
    }

    const [, params] = returnPath.split('?');
    const paramObject = parse(params);

    return paramObject;
  }

  getAllURLParams() {
    const queryParmas = this.getQueryParamsFromURL() || {};
    const fragments = this.getDataFromFragment() || {};
    const params = {
      ...queryParmas,
      ...fragments,
    };

    // @info: cleanup return -> returnTo
    if (params.return) {
      params.returnTo = params.return;
      delete params.return;
    }

    // @info: authorizeURL is a better name than return or returnTo
    params.authorizeURL = params.returnTo || '';
    if (params.authorizeURL) {
      params.authorizeURL = decodeURIComponent(params.authorizeURL);
    }

    return params;
  }

  getEmailFromFragment() {
    return this.fragments[QUERY_KEYS.email];
  }

  getState() {
    return this.activatedRoute.snapshot.queryParams.state;
  }

  getAuth0ConfigParams(): any {
    // log('entered getConfigFromAuth0Environment');
    const auth0Key = '_auth0Config';
    const config = window[auth0Key] || {};
    return this.flatObject(config) || {};
  }

  flatObject(obj = {}, collection = {}) {
    if (!obj) {
      return {};
    }

    if (typeof obj !== 'object') {
      return collection;
    }

    const props = Object.keys(obj) || [];
    props.forEach((key) => {
      const innerValue = obj[key];
      if (typeof innerValue === 'object') {
        return this.flatObject(innerValue, collection);
      }
      collection[key] = innerValue;
    });

    return collection;
  }

  getToken() {
    const { token } = this.getAllURLParams();
    return token || '';
  }

  getDecodedToken() {
    const jwt = new JwtHelperService();
    const token = this.getToken();

    if (!token) {
      return {};
    }

    const decoded = jwt.decodeToken(token) || {};

    return decoded;
  }

  getReturnToQueryParams() {
    const returnPath = this.getReturnPath();

    if (!returnPath) {
      return {};
    }

    // pageType: authorize or samlp
    let [, params] = returnPath.split('?');

    if (!params) {
      params = '';
    }

    return parse(params) || {};
  }

  getContinuePath(state, token) {
    // log('entered getContinuePath');

    const page = 'continue';
    const returnPath = this.getReturnPath();

    if (!returnPath) {
      throw new Error('There is not return Path to process');
    }

    let continueParams = {};

    // pageType: authorize or samlp
    let [, params] = returnPath.split('?');

    if (!params) {
      params = '';
    }

    const paramObject = parse(params);
    // log(' paramObject ?', { paramObject });

    // @info:
    // Due we are doing a second or third redirection , context.request.query is being cleaned up
    // In order to keep auth0 rules working, we need to manuall pass the neccesary Params
    const onlyInclude = [
      // Groupsio
      'group_id',
      'group_access_type',
      'group_name',
      'group_url',

      // Cvent
      'eventName',
      'event_name',
      // Joinnow
      'project',
      // Registration zoom
      'zoomID',
      'zoom_id',

      // todo: remove! , get it form the returnTo URL
      'registrationType',
      'registration_type',

      'id',
      // Default values
      'app',
      'title',

      'clientName',
      'client_name',
      // to generate next redirection
      '_ga',
      'e',
      'RelayState',
      'client_id',
      'protocol',
      'redirect_uri',
      'response_type',
      'nonce',
    ];

    continueParams = Object.keys(paramObject).reduce((acc, key) => {
      if (onlyInclude.includes(key) && !acc[key] && key !== 'undefined') {
        acc[key] = paramObject[key] || '';
      }
      return acc;
    }, {});

    // log('continueParams ? ', { continueParams });

    const queryParams = stringify({
      state,
      token,
      ...continueParams,
    });

    // log('queryParams ? ', { queryParams });

    const continuePath = `${page}?${queryParams}`;

    // log('continue Path ?', { continuePath });

    return continuePath;
  }

  HandleOverwritesByApps(queryParams) {
    const trainingAppOverwrites = this.cbTrainingAppService.HandleOverwritesByApp() || {};

    return {
      ...queryParams,
      ...trainingAppOverwrites,
    };
  }

  // @info: Generate a valid authorize URL
  // providing (always): client_id, response_type and redirect_uri
  generateAuthorizeURLInAuth0Server() {
    const auth0Key = '_auth0Config';
    const config = window[auth0Key];

    if (!config) {
      return '';
    }

    let returnPath = 'authorize';
    const extraParams: ExtraParams = config.extraParams || ({} as ExtraParams);
    const internalOptions = config.internalOptions || ({} as InternalOptions);

    if ((extraParams.protocol || internalOptions.protocol || '') === 'samlp') {
      returnPath = `samlp/${config.clientID}`;
    }

    let queryParams = {};

    if (!returnPath.includes('samlp')) {
      queryParams = {
        client_id: config.clientID,
      };
    }

    Object.keys(config.extraParams).forEach((key) => {
      if (key && key !== 'undefined') {
        queryParams[key] = config.extraParams[key];
      }
    });

    queryParams = this.HandleOverwritesByApps(queryParams);

    if (!returnPath.includes('samlp')) {
      if (!queryParams['response_type']) {
        queryParams['response_type'] = queryParams['type'];
      }

      if (!queryParams['redirect_uri']) {
        queryParams['redirect_uri'] = queryParams['redirectURI'] || config.callbackURL || '';
      }
    }

    const returnQuery = stringify(queryParams);
    const returnURL = encodeURIComponent(`${returnPath}?${returnQuery}`);
    return returnURL;
  }

  getReturnPath(extraParams = null): string {
    const { authorizeURL } = this.getAllURLParams();
    let path = authorizeURL || this.generateAuthorizeURLInAuth0Server();

    if (!path) {
      return '';
    }

    if (path.includes('samlp')) {
      while (!path.includes('samlp/')) {
        path = decodeURIComponent(path);
      }

      // Safe Cleanup
      if (path.includes('&return=')) {
        [, path] = path.split('&return=');
      } else if (path.includes('&returnTo=')) {
        [, path] = path.split('&returnTo=');
      }

      // log('final path', { path });
      return path;
    }

    if (!path.includes('authorize')) {
      return '';
    }

    while (!path.includes('authorize?')) {
      path = decodeURIComponent(path);
    }

    // Safe Cleanup
    if (path.includes('&return=')) {
      [, path] = path.split('&return=');
    } else if (path.includes('&returnTo=')) {
      [, path] = path.split('&returnTo=');
    }

    let extraQueries;

    if (extraParams) {
      extraQueries = stringify(extraParams);
      if (extraQueries === 'undefined') {
        extraQueries = '';
      }
    }

    const finalPath = `${path}&${extraQueries}`;

    // log('final path', { finalPath });

    return finalPath;
  }

  // Used in Groups IO flow
  getGroupURL() {
    return this.fragments[QUERY_KEYS.groupURL] || '';
  }

  isSignupFirstPage() {
    const flags = ['signup', 'signUp'];

    const search = document.location.href;
    if (!search) {
      return false;
    }

    const isSignup = flags.some((flag) => {
      return search.includes(flag);
    });

    if (isSignup) {
      return true;
    }

    return false;
  }
}

export interface GroupioToken {
  sub: string;
  username: string;
  email: string; // LF User Email
  groupsioEmail?: string; // email returned after verification
  groupsioflow: boolean;
  emails: string[];
  groupName: string;
  groupURL: string;
  groupAccessType?: 'login' | 'register';
  iat: number;
  exp: number;
  aud: string;
  iss: string;
}
