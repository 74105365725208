import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { to } from 'await-to-js';

import { CbHttpErrorsService } from './../../services/cb-http-errors.service';
import { CbGroupioService } from './../../services/cb-groupio.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbAuthURLService, GroupioToken } from '../../services/cb-auth-url.service';
import { CbStateService } from '../../services/cb-state.service';
import { CbBackendService } from '../../services/cb-backend.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';

import { LogFactory } from '../../services/cb-debug';
const log = LogFactory('EmailVerificationComponent');

@Component({
  selector: 'cb-email-verification',
  templateUrl: './email-verification.component.html',
})
export class EmailVerificationComponent implements OnInit {
  isLoading = true;
  typeMessage = '';
  message = '';
  pageTitle = '';
  pageMessage = '';
  displaySignOut = false;

  constructor(
    private cbWebApi: CbWebApi,
    private router: Router,
    private cbAuthURLService: CbAuthURLService,
    private cbStateService: CbStateService,
    private cbBackendService: CbBackendService,
    private cbGroupioService: CbGroupioService,
    private cbHttpErrorsService: CbHttpErrorsService,
    private cbFullstoryService: CbFullstoryService
  ) {}

  ngOnInit(): void {
    this.verifyEmail();
  }

  setPageMessage(message, type = 'error') {
    this.typeMessage = type;
    this.message = message;
    this.isLoading = false;
    this.displaySignOut = true;
  }
  clearMessage() {
    this.typeMessage = '';
    this.message = '';
  }

  loadingStart() {
    this.isLoading = true;
  }

  loadingEnd() {
    this.isLoading = false;
  }

  verifyEmail() {
    const decodedToken = this.cbAuthURLService.getDecodedToken() as any;
    const type = decodedToken.type;

    switch (type) {
      case 'groups_io':
        this.HandleVerifyEmailGroupsio(decodedToken as GroupsioEmailToken);
        break;
      case 'user_service':
        this.HandleVerifyAlternativeUserEmail(decodedToken as AlternativeEmailToken);
        break;
      default:
        this.setPageMessage('Verification email not implemented!', 'error');
        break;
    }
  }

  HandleVerifyEmailGroupsio(decodedToken: GroupsioEmailToken) {
    log('entered HandleVerifyEmailGroupsio');

    const isValid = this.cbStateService.isCurrentStateValid();
    log('isValid ? ', { isValid });
    if (!isValid) {
      this.cbWebApi.returnToAuthorizePage();
      return;
    }

    if (!decodedToken.groupsioflow) {
      this.setPageMessage('Invalid Token!', 'error');
      return;
    }

    this.verifyForGroupsIOFlow();
    return;
  }

  signOut() {
    this.loadingStart();
    this.cbWebApi.returnToAuthorizePage();
  }

  async verifyForGroupsIOFlow() {
    log('entered verifyForGroupsIOFlow');

    const { token, state, returnTo } = this.cbAuthURLService.getAllURLParams();
    const { groupsioEmail, groupName, groupURL } = this.cbAuthURLService.getDecodedToken() as GroupioToken;

    const [err, type] = await to(this.cbGroupioService.ContinueWIthNewEmail(token, groupsioEmail));

    if (err) {
      log('error in request', { err });
      let message = this.cbHttpErrorsService.GetErrorMessage(err);
      const code = this.cbHttpErrorsService.GetErrorCode(err);
      if (code === 'lfid_exist') {
        message = `This email address is associated with another LF Profile. To use it, please go back and login using that LF Profile.`;
      }

      this.cbFullstoryService.fsError(message);
      this.setPageMessage(message);
      return;
    }

    if (type === 'continue') {
      const extraParams = {
        selectedEmail: groupsioEmail,
      };

      this.cbWebApi.continueToAuth0(token, state, extraParams);
      return;
    }

    if (type === 'invite') {
      const queryParams = {
        email: groupsioEmail,
        state,
        token,
        groupName,
        groupURL,
        returnTo,
      };

      log('queryParams ? ', queryParams);

      const nextPage = `/cb/groupio-confirm-invite`;
      this.router.navigate([nextPage], { queryParams });
      return;
    }
  }

  HandleVerifyAlternativeUserEmail(decodedToken: AlternativeEmailToken) {
    const token = this.cbAuthURLService.getToken();

    this.cbBackendService.markEmailAsVerified(token).subscribe(
      () => {
        const email = decodedToken.email;
        this.pageTitle = 'Your email is verified';
        this.pageMessage = `Your email ${email} is verified. <br/> You can close this window.`;
        this.loadingEnd();
      },
      () => {
        this.setPageMessage('There was a problem verifying your email', 'error');
        this.cbFullstoryService.fsError('There was a problem verifying your email');
      }
    );
  }
}

export interface GroupsioEmailToken {
  sub: string;
  email: string;
  username: string;
  groupsioEmail: string;
  groupsioflow: string;
  type: string;
}

export interface AlternativeEmailToken {
  username: string;
  salesforceID: string;
  email: string;
  type: string;
}
