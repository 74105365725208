<div class="top-banner-project columns is-centered my-1">
  <div *ngIf="display2Logos" class="column has-text-centered" [ngClass]="{ '2-logos': display2Logos }">
    <cb-bottom-banner></cb-bottom-banner>
  </div>
  <div class="column has-text-centered top-banner-logo-right" [ngClass]="{ 'top-banner-logo-right': display2Logos }">
    <ng-container *ngFor="let image of cbLoginBannerService.appImages; let index = index">
      <figure [ngClass]="{ 'is-hidden': image.hide }">
        <img
          [ngClass]="{
            'is-app-logo ': cbLoginBannerService.isAppLogoActive,
            'is-project-logo': cbLoginBannerService.isProjectLogoActive,
            'is-width-200': image.logoCss && image.logoCss.includes('is-width-200')
          }"
          [src]="image.URL"
          (error)="removeAppImage(index)"
          (load)="displayAppImage(image)"
        />
      </figure>
    </ng-container>
  </div>
</div>
