import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpOptionalParams, QueryParams } from '../models/cb-backend.models';
import { CbLoginBannerService } from './cb-login-banner.service';

import { LogFactory } from './cb-debug';
const log = LogFactory('HttpService', false);

export const HTTP_BAD_REQUEST = 400;
export const HTTP_CONFLICT = 409;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_NOT_FOUND = 404;

const PARAMS_REGEX = /\/-(?=\/)?/;
const PARAMS_MULTI_REGEX = /\/-(?=\/)?/g;

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public requestInProgress = false;

  constructor(private httpClient: HttpClient, private cbLoginBannerService: CbLoginBannerService) {}

  initHttpHeaders(token = '') {
    log('entered initHttpHeaders', { token });

    const { appName, clientId } = this.cbLoginBannerService.getPersistentParamsInURL();

    let headers = new HttpHeaders().set('x-clientId', clientId || '').set('x-clientName', appName || '');

    if (token) {
      headers = headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }

  serializeResourceURl(resourceUrl: string, optionalParams: HttpOptionalParams) {
    const { queryParams, urlMultiParams, urlParam } = optionalParams;

    const query = this._getQueryString(queryParams);

    resourceUrl = this._setUrlParam(resourceUrl, urlParam);

    if (urlMultiParams) {
      resourceUrl = this._setUrlMultiParams(resourceUrl, urlMultiParams);
    }

    return `${resourceUrl}${query}`;
  }

  public get<T>(resourceUrl: string, optionalParams: HttpOptionalParams = {}, token = ''): Observable<T> {
    this.requestInProgress = true;

    const httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);
    const httpHeaders = this.initHttpHeaders(token);

    return this.httpClient
      .get<T>(httpUrl, { headers: httpHeaders })
      .pipe(
        tap(() => (this.requestInProgress = false)),
        catchError((err) => this._handleError(err))
      );
  }

  public post(resourceUrl: string, optionalParams: any = {}, token = ''): Observable<any> {
    this.requestInProgress = true;
    const httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);
    const httpHeaders = this.initHttpHeaders(token);

    return this.httpClient.post(httpUrl, optionalParams.requestBody, { headers: httpHeaders }).pipe(
      tap(() => (this.requestInProgress = false)),
      catchError((err) => this._handleError(err))
    );
  }

  public put(resourceUrl: string, optionalParams: any = {}): Observable<any> {
    this.requestInProgress = true;
    const httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);
    const httpHeaders = this.initHttpHeaders();

    return this.httpClient.put(httpUrl, optionalParams.requestBody, { headers: httpHeaders }).pipe(
      tap(() => (this.requestInProgress = false)),
      catchError((err) => this._handleError(err))
    );
  }

  public isRequestInProgress() {
    return this.requestInProgress;
  }

  private _getQueryString(queryParamsList: any): string {
    if (!queryParamsList) {
      return '';
    }

    const queryString = queryParamsList
      .map(({ name, value }: QueryParams) => `${encodeURIComponent(name)}=${encodeURIComponent(value)}`)
      .join('&');
    return `?${queryString}`;
  }

  private _handleError(error: any) {
    this.requestInProgress = false;
    return throwError(error);
  }

  private _setUrlParam(resourceUrl: string, parameter: number | string) {
    if (!parameter) {
      return resourceUrl;
    }

    return resourceUrl.replace(PARAMS_REGEX, `/${parameter}`);
  }

  private _setUrlMultiParams(resourceUrl: string, parameters: string[]) {
    return resourceUrl.replace(PARAMS_MULTI_REGEX, () => `/${parameters.shift()}`);
  }
}
