import { Component, OnInit } from '@angular/core';
import { to } from 'await-to-js';

import { CbGroupioService } from '../../services/cb-groupio.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbMyProfileService } from '../../services/cb-myprofile.service';

import { LogFactory } from './../../services/cb-debug';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
const log = LogFactory('GroupioConfirmInviteComponent');

interface GroupConfirmInvitationPage {
  state: string;
  token: string;
  email: string;
  groupName: string;
  groupURL: string;
}

@Component({
  selector: 'cb-groupio-confirm-invite',
  templateUrl: './groupio-confirm-invite.component.html',
})
export class GroupioConfirmInviteComponent implements OnInit {
  groupURL = '';
  email = '';
  groupName = '';
  isLoading = false;
  errorMessage = '';

  constructor(
    private cbGroupService: CbGroupioService,
    private cbAuthURLService: CbAuthURLService,
    private cbWebApi: CbWebApi,
    private cbMyProfileService: CbMyProfileService,
    private cbFullstoryService: CbFullstoryService
  ) {}

  pageLoading() {
    this.isLoading = true;
  }
  pageLoadingEnd() {
    this.isLoading = false;
  }

  setErrorMessage(message) {
    this.errorMessage = message;
    this.pageLoadingEnd();
  }

  ngOnInit() {
    const { email, groupName, groupURL } = this.cbAuthURLService.getAllURLParams() as GroupConfirmInvitationPage;
    this.email = email;
    this.groupName = groupName;
    this.groupURL = groupURL;

    this.cbMyProfileService.displayMyProfileURL();
  }

  goToLoginPage() {
    this.cbGroupService.returnToGroupPage();
  }

  async continue() {
    this.pageLoading();
    const { email, state, token } = this.cbAuthURLService.getAllURLParams() as GroupConfirmInvitationPage;
    log(' email, state, token', { email, state, token });

    const [err, subscriber] = await to(this.cbGroupService.GetEmailSubscription(token, email).toPromise());
    if (err) {
      this.cbFullstoryService.fsError('There was a problem processing, Please refresh your page or try sign out.');
      this.setErrorMessage('There was a problem processing, Please refresh your page or try sign out.');
      return;
    }

    log('subscriber? ', { subscriber });

    if (!subscriber) {
      this.cbFullstoryService.fsError('No subscription found');
      this.setErrorMessage('No subscription found, Please Accept your invitation and try again.');
      return;
    }
    this.cbFullstoryService.sendEvent('successfully_authenticated', { email });
    this.cbWebApi.continueToAuth0(token, state, { selectedEmail: email });
  }

  signOut() {
    this.pageLoading();
    this.cbWebApi.returnToAuthorizePage();
  }
}
