<div class="" [ngClass]="{ error: hasError }">
  <!-- <div class="label-group">
    <label for="">
      <span class="required-marker" *ngIf="required">*</span>
      {{ label }}
    </label>
    <a *ngIf="linkForgot"
       class="cb-link gray forgot-link"
       tabindex="-1"
       cbNavigateTo="/cb/reset-password">{{ forgotLinkLabel }}
    </a>
  </div> -->

  <div class="control has-icons-left">
    <input
      (blur)="onBlur(inputElement)"
      type="{{ type }}"
      id="{{ formControlName }}"
      class="input"
      #inputElement
      (input)="notifyOnErrors($event.target.value)"
      (ngModelChange)="onKeyup($event)"
      [ngModel]="inputValue"
      autofocus
      [placeholder]="placeholder || label"
      (keyup.enter)="onEnter($event)"
      (click)="onClick($event.target.checked)"
      [ngClass]="{ 'ng-valid': control.valid, 'ng-invalid': control.invalid, 'ng-dirty': control.dirty }"
    />

    <span class="icon is-left">
      <cb-icon [type]="iconType" [name]="iconName"></cb-icon>
    </span>
    <div class="error-message" [ngClass]="{ active: hasError }">
      {{ getErrorMessage(control.errors) }}
    </div>
  </div>
</div>
