<!-- <div class="cb-separator-2rem mobile-hide">
  <div class="cb-row">
      <cb-logo></cb-logo>
  </div>
</div> -->
<cb-confirmation
  headerTitle="{{ headerTitle }}"
  confirmationTitle="{{ confirmationTitle }}"
  confirmationMessage="{{ confirmationMessage }}"
  buttonText="{{ buttonText }}"
  (buttonClicked)="resendResetPasswordEmail()"
  linkText="{{ linkText }}"
  linkRouterPath="{{ linkRouterPath }}"
>
</cb-confirmation>
