import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CbLoginConfig } from './cb-login.config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable()
export class CbSocialLoginService {
  jwtHelper = new JwtHelperService();
  constructor(private httpService: HttpService, private loginConfig: CbLoginConfig, private router: Router) {}
  createUserWithUsername(body: object) {
    return this.httpService.post(`${this.loginConfig.cbBackend}/user/username`, { requestBody: body }).pipe(
      switchMap((response) => {
        if (response.error) {
          return throwError(response);
        }
        return of(response);
      }),
      catchError((response) => of(response))
    );
  }

  getSubFromToken(token: string) {
    try {
      if (this.jwtHelper.isTokenExpired(token)) {
        return null;
      }
      return this.jwtHelper.decodeToken(token).sub;
    } catch (e) {
      return false;
    }
  }

  succesfulRedirect(jwt, state) {
    this.router.navigate([`${this.loginConfig.domain}/continue?token=${jwt}&state=${state}`]);
  }
}
