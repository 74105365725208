<div [hidden]="!isLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>
<div [hidden]="isLoading">
  <div class="columns is-flex is-centered">
    <div class="column is-8 mt-2">
      <form class="form" [formGroup]="form">
        <cb-alert *ngIf="errorMessage" type="error">
          <span [innerHTML]="errorMessage | safeHtml"></span>
        </cb-alert>

        <ng-container *ngIf="displayPrimaryEmailSection">
          <div class="group-titles col-10-wrap columns is-centered has-text-lfblack">
            <div class="col-10-wrap-row column is-10">
              <div class="cb-text-center">
                <ng-container *ngIf="isSubscriber">
                  The primary email <b class="default-email">{{ userEmail }}</b> associated with your LFID is
                  subscribed. Click on Proceed to list to continue.
                </ng-container>
                <ng-container *ngIf="!isSubscriber">
                  The primary email <b class="default-email">{{ userEmail }}</b> associated with your LFID is not
                  subscribed. Click on Subscribe to continue.
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="userEmailSubscriptions.length">
          <div class="cb-text-center">
            <b>To continue, choose the email address from the subscribed list</b>
          </div>
          <br />
          <div class="columns is-centered">
            <div class="column is-10">
              <div class="use-associated-emails label-group cb-text-center">
                <div class="label-group cb-text-center select is-fullwidth">
                  <select name="associated-emails" formControlName="subscribedEmail">
                    <option value="">Select an email address</option>
                    <option *ngFor="let email of userEmailSubscriptions" [value]="email">{{ email }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="group-options columns is-centered">
          <div class="column is-10 has-text-centered my-1">
            <div class="user-options">
              <a *ngIf="displayAlternateLink" class="has-text-info" (click)="displayAlternativeEmails()">
                + Select Alternate Email
              </a>
              <span *ngIf="displayAlternateLink">
                or
              </span>
              <a class="has-text-info" (click)="displayNewEmail()">
                + Add New Email
              </a>
            </div>
          </div>
        </div>

        <ng-container *ngIf="displayAlternativeSecion">
          <br />
          <div class="has-text-centered has-text-lfblack">
            <b>Select alternate email address that is not currently subscribed</b>
          </div>
          <br />
          <div class="group-select-email col-10-wrap columns is-centered">
            <div class="col-10-wrap-row column is-10">
              <div class="emails-dropdown use-associated-emails label-group cb-text-center">
                <div class="label-group cb-text-center select is-fullwidth">
                  <select name="associated-emails" formControlName="allowedEmail">
                    <option value="">Select an email address</option>
                    <option *ngFor="let email of userAllowedEmails" [value]="email">{{ email }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="displayNewEmailSection">
          <div class="has-text-centered has-text-lfblack">
            <b>Add New Email Address</b>
          </div>
          <br />
          <div class="group-add-email columns is-centered">
            <div class="column is-10">
              <div class="field">
                <cb-input
                  [errors]="emailErrors"
                  autofocus
                  iconName="envelope"
                  placeholder="Enter Email Address"
                  label="Add a new email address to your LFID"
                  formControlName="newEmail"
                >
                </cb-input>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div class="actions cb-separator">
            <cb-button
              *ngIf="
                !displayAlternativeSecion &&
                !displayNewEmailSection &&
                !displayClaimButton &&
                displayPrimaryEmailSection
              "
              id="continue-lfid-email"
              type="is-info px-3"
              data-cy="btn-confirm"
              (click)="usePrimaryEmail()"
            >
              {{ isSubscriber ? 'Proceed To List' : 'Subscribe' }}
            </cb-button>

            <cb-button
              *ngIf="displayNewEmailSection"
              id="verify-new-email"
              type="is-info px-3"
              [disabled]="form.invalid || !newEmailControl.value"
              data-cy="btn-confirm"
              (click)="useNewEmail()"
            >
              verify
            </cb-button>
          </div>
        </ng-container>

        <ng-container *ngIf="claimMessage || claimURL">
          <br />
          <div class="group-claim col-10-wrap columns is-centered">
            <div class="col-10-wrap-row column is-10 has-text-centered">
              <div *ngIf="claimMessage" class="has-text-danger">
                {{ claimedEmail }} is already associated with another account. If you would like to claim this email
                address please click on the <b class="is-capitalized">claim email</b> button, otherwise, enter another
                email address.
              </div>

              <div *ngIf="claimURL" class="has-text-success">
                Your claim request has been submitted. You can track the status of your request
                <a href="{{ claimURL }}" target="_blank" class="has-text-info">here</a>
                . Thank you.
              </div>
              <br />
              <cb-button
                *ngIf="displayClaimButton"
                id="claim-email"
                type="is-info px-3"
                data-cy="btn-confirm"
                (click)="claimEmail()"
              >
                Claim Email
              </cb-button>
            </div>
          </div>
        </ng-container>
        <br />
      </form>
    </div>
  </div>
  <div class="columns">
    <div class="column has-text-centered font-14px">
      <a class="has-text-info" [href]="groupURL"
        >Return to <span class="group-name">{{ groupName }}</span> group</a
      >
    </div>
  </div>
</div>
