import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as querystring from 'query-string';

import { CLIENTS_METADATA } from '../../services/cb-login-banner.metadata';
import { CbLoginConfig } from '../../services/cb-login.config';
import { CbAuthService } from '../../services/cb-auth.service';
import { CbErrorService } from '../../services/cb-error.service';
import { CbWebApi } from '../../services/cb-webapi';
import { CbStorage } from '../../services/cb-storage';
import { CbFullstoryService } from '../../services/cb-fullstory.service';

const DEFAULT_MESSAGE = 'Please contact support for additional help.';
const DEFAULT_NAME = 'Unknown Error';

@Component({
  selector: 'cb-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  queryParams: any;
  errorName = '';
  errorDescription = '';
  safeReturnURL = '';
  requestAccess = 'https://jira.linuxfoundation.org/plugins/servlet/theme/portal/2/create/35';
  isAccessDenied = false;

  constructor(
    private loginConfig: CbLoginConfig,
    private activatedRoute: ActivatedRoute,
    private cbAuthService: CbAuthService,
    private cbErrorService: CbErrorService,
    private cbWebApi: CbWebApi,
    private cbStorage: CbStorage,
    private cbFullstoryService: CbFullstoryService
  ) {}

  validateErrorName(errorName: string) {
    if (!errorName) {
      return false;
    }

    const regexName = /^[a-zA-Z\._]{5,40}$/g;

    return regexName.test(errorName);
  }

  validateErrorDescription(errorName: string) {
    if (!errorName) {
      return false;
    }

    const regexDescription = /^.*(?=http|https|wwww|.com|.net|\/\/).*$/g;

    return !regexDescription.test(errorName);
  }

  getErrorPageTitle(error) {
    if (!this.validateErrorName(error)) {
      return DEFAULT_NAME;
    }

    if (error === 'access_denied') {
      this.isAccessDenied = true;
      return 'Access Denied';
    }

    return error;
  }

  getErrorPageDescription(error_description) {
    return (this.validateErrorDescription(error_description) && error_description) || DEFAULT_MESSAGE;
  }

  ngOnInit(): void {
    if (!this.loginConfig.cbSsoStaticApp) {
      this.errorName = DEFAULT_NAME;
      this.errorDescription = DEFAULT_MESSAGE;
    }

    const { queryParams } = this.activatedRoute.snapshot || ({} as any);
    const { search } = window.location;

    const { error, error_description, client_name } = querystring.parse(search);
    const clientName = client_name as string;
    const { jsdURL } = CLIENTS_METADATA[clientName] || ({} as any);

    if (jsdURL) {
      this.requestAccess = jsdURL;
    }

    this.loginConfig.cbSkipLocalChanges = true;
    this.cbAuthService.isErrorFlow = true;

    this.safeReturnURL = this.cbStorage.get('SAFE_RETURN');

    this.errorName = this.getErrorPageTitle(error);
    this.errorDescription = this.getErrorPageDescription(error_description);

    this.cbErrorService.setErrorData(queryParams);
    this.cbFullstoryService.fsError('Error page');
    this.cbFullstoryService.fsError(this.errorDescription);
  }

  gotoLogin() {
    this.cbWebApi.returnToAuth0BySafeReturn();
  }
}
