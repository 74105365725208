/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-account-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component.ngfactory";
import * as i3 from "../../shared/cb-ui/cb-loading-page/cb-loading-page.component";
import * as i4 from "../../shared/cb-ui/cb-confirmation/cb-confirmation.component.ngfactory";
import * as i5 from "../../shared/cb-ui/cb-confirmation/cb-confirmation.component";
import * as i6 from "../../services/confirmation-alert-data";
import * as i7 from "../../services/cb-webapi";
import * as i8 from "../../services/cb-login.config";
import * as i9 from "@angular/common";
import * as i10 from "./create-account-email.component";
import * as i11 from "../../services/cb-backend.service";
import * as i12 from "@angular/router";
import * as i13 from "../../services/cb-state.service";
import * as i14 from "../../services/cb-fullstory.service";
var styles_CreateAccountEmailComponent = [i0.styles];
var RenderType_CreateAccountEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateAccountEmailComponent, data: {} });
export { RenderType_CreateAccountEmailComponent as RenderType_CreateAccountEmailComponent };
function View_CreateAccountEmailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-loading-page", [["loadingMessage", "Please wait, we are processing your request"]], null, null, null, i2.View_CbLoadingPageComponent_0, i2.RenderType_CbLoadingPageComponent)), i1.ɵdid(1, 49152, null, 0, i3.CbLoadingPageComponent, [], { loadingMessage: [0, "loadingMessage"] }, null)], function (_ck, _v) { var currVal_0 = "Please wait, we are processing your request"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CreateAccountEmailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cb-confirmation", [["cancelLinkText", "Go to Login page"]], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.resendVerificationEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CbConfirmationComponent_0, i4.RenderType_CbConfirmationComponent)), i1.ɵdid(1, 49152, null, 0, i5.CbConfirmationComponent, [i6.ConfirmationAlertData, i7.CbWebApi, i8.CbLoginConfig], { headerTitle: [0, "headerTitle"], confirmationTitle: [1, "confirmationTitle"], confirmationMessage: [2, "confirmationMessage"], buttonText: [3, "buttonText"], linkText: [4, "linkText"], linkRouterPath: [5, "linkRouterPath"], showContent: [6, "showContent"], linkRedirect: [7, "linkRedirect"], showHeader: [8, "showHeader"], displayCancelLink: [9, "displayCancelLink"], cancelLinkText: [10, "cancelLinkText"], showReturnLink: [11, "showReturnLink"] }, { buttonClicked: "buttonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.pageContent.headerTitle, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.pageContent.confirmationTitle, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.pageContent.confirmationMessage, ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.pageContent.buttonText, ""); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.pageContent.linkText, ""); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.pageContent.linkRouterPath, ""); var currVal_6 = (_co.pageContent.showContent && !_co.isEmailVerified); var currVal_7 = _co.pageContent.linkRedirect; var currVal_8 = !_co.isSSoStaticApp; var currVal_9 = true; var currVal_10 = "Go to Login page"; var currVal_11 = !_co.isSSoStaticApp; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_CreateAccountEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateAccountEmailComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateAccountEmailComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CreateAccountEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CreateAccountEmailComponent_0, RenderType_CreateAccountEmailComponent)), i1.ɵdid(1, 245760, null, 0, i10.CreateAccountEmailComponent, [i8.CbLoginConfig, i11.CbBackendService, i6.ConfirmationAlertData, i7.CbWebApi, i12.Router, i13.CbStateService, i12.ActivatedRoute, i14.CbFullstoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateAccountEmailComponentNgFactory = i1.ɵccf("ng-component", i10.CreateAccountEmailComponent, View_CreateAccountEmailComponent_Host_0, {}, {}, []);
export { CreateAccountEmailComponentNgFactory as CreateAccountEmailComponentNgFactory };
