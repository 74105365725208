import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class CbTrainingAppService {
  IsTrainingCheckoutFlow() {
    const URL = document.location.href || '';
    if (!URL.includes('returnTo')) {
      return false;
    }
    if (!URL.includes('purchasableId')) {
      return false;
    }
    if (!URL.includes('orders')) {
      return false;
    }

    return true;
  }

  getDefaultRelayFromCheckoutFlow() {
    const currentRelayState = window['_auth0Config'].extraParams.RelayState;
    const defaultPath = 'learn/dashboard';
    const defaultRelay = currentRelayState.replace(/orders\?cart=\[\{.+\}\]/g, defaultPath);
    return defaultRelay;
  }

  HandleOverwritesByApp() {
    if (!this.IsTrainingCheckoutFlow()) {
      return {};
    }

    return {
      RelayState: this.getDefaultRelayFromCheckoutFlow(),
    }
  }

}
