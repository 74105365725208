import { Component, OnInit } from '@angular/core';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';

@Component({
  selector: 'cb-bottom-banner',
  templateUrl: 'cb-bottom-banner.component.html',
})
export class CbBottomBannerComponent implements OnInit {
  constructor(public cbLoginBannerService: CbLoginBannerService) {}

  ngOnInit() {}

  removePossibleImage(index, list) {
    this.cbLoginBannerService.removePossibleImage(index, list);
  }

  displayPossibleImage(image) {
    image.hide = false;
    this.cbLoginBannerService.bottomImages = [image];
    // list = [image]
    // this.cbLoginBannerService.displayPossibleImage(image, list);
  }
}
