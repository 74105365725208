<div [hidden]="!isLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>

<div [hidden]="isLoading">
  <div class="columns is-flex is-centered">
    <div class="column is-three-fifths is-four-fifths-mobile">
      <cb-alert *ngIf="errorMessage" type="error">
        <span [innerHTML]="errorMessage | safeHtml"></span>
      </cb-alert>

      <div class="actions">
        <div class="cb-title-group">
          <div class="primary-text">Email Invitation Sent</div>
          <div class="">
            <br />
            We have sent an invitation to
            <b>{{ email }}</b
            >.
            <br />
            Please confirm the invitation by clicking on the link in your email in order to participate in the group.
            <br />
            <br />

            If you have already accepted.
            <a class="cb-link skyblue" data-cy="btn-login" (click)="continue()">
              Click here
            </a>
            to login to <span class="group-name">{{ groupName }}</span>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
