import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { to } from 'await-to-js';

import { ServiceDeskService } from '../../services/service-desk.service';
import { IServiceRequest } from '../../models/service-desk.models';
import { CbAuthService } from '../../services/cb-auth.service';
import { CbRecaptchaService } from '../../services/cb-recpatcha.service';
import { CbErrorService } from '../../services/cb-error.service';
import { CbLoginConfig } from '../../services/cb-login.config';

import { LogFactory } from './../../services/cb-debug';
const log = LogFactory('ServiceDeskComponent');

@Component({
  templateUrl: './service-desk.component.html',
  styleUrls: ['./service-desk.component.scss'],
})
export class ServiceDeskComponent implements OnInit, OnDestroy {
  form: FormGroup;
  destroy$ = new Subject();
  name: FormControl;
  email: FormControl;
  description: FormControl;
  formLoading = false;
  requestNotSent = false;
  captchaToken = null;
  showbackLink = true;
  captchaInterval: any;

  emailErrors = {
    required: 'Email is a required field.',
    pattern: 'Email is invalid',
  };

  nameErrors = {
    required: 'Name is a required field.',
    minlength: 'Name should be at least 5 characters long',
    maxlength: 'Name should be less than 255 characters long',
  };

  descriptionErrors = {
    required: 'Description is a required field.',
    maxlength: 'Name should be less than 255 characters long',
  };

  constructor(
    private serviceDeskService: ServiceDeskService,
    private fb: FormBuilder,
    private loginConfig: CbLoginConfig,
    private cbRecaptchaService: CbRecaptchaService,
    private cbErrorService: CbErrorService,
    private cbAuthService: CbAuthService
  ) {}

  ngOnInit(): void {
    this.fetchRecaptchaToken();
    this.captchaInterval = setInterval(() => {
      this.fetchRecaptchaToken();
    }, 120000);

    if (this.loginConfig.cbSsoStaticApp) {
      this.showbackLink = false;
    }

    this.form = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ]),
      ],
      name: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(255)])],
      description: ['', [Validators.required, Validators.maxLength(32700)]],
    });

    this.email = this.form.get('email') as FormControl;
    this.name = this.form.get('name') as FormControl;
    this.description = this.form.get('description') as FormControl;
  }

  async fetchRecaptchaToken() {
    log('entered fetchRecaptchaToken');
    const [err, token] = await to(this.cbRecaptchaService.getCaptchaToken('serviceDesk').toPromise());

    if (err) {
      log('Error fetchRecaptchaToken', { err });
      return;
    }

    this.captchaToken = token;
  }

  async submit() {
    this.formLoading = true;
    this.requestNotSent = false;

    if (!this.form.valid) {
      return;
    }

    const request: IServiceRequest = {
      name: this.name.value,
      email: this.email.value,
      description: this.description.value,
      captchaToken: this.captchaToken,
    };

    // todo: jira API has a max length,
    // todo: this is commented to avoid block user
    // if (this.cbAuthService.isErrorFlow) {
    //   request.description += this.cbErrorService.getErrorInformation();
    // }

    const [err, data] = await to(this.serviceDeskService.sendRequest(request).toPromise());
    this.formLoading = false;
    log(' response ? ', { err, data });
    if (err) {
      this.requestNotSent = true;
      this.fetchRecaptchaToken();
      return;
    }

    if (data.error) {
      this.requestNotSent = true;
      return;
    }

    this.cbAuthService.navigateTo('/cb/request-confirmation/' + data.issueKey);
  }

  goBack(): void {
    this.cbAuthService.navigateTo('/cb/login');
  }

  ngOnDestroy(): void {
    clearInterval(this.captchaInterval);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
