import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CbAuthService } from '../../services/cb-auth.service';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';

@Component({
  selector: 'app-lfx-meetings',
  templateUrl: './lfx-meetings.component.html',
  styleUrls: ['./lfx-meetings.component.scss'],
})
export class LFXMeetingsComponent implements OnInit {
  @Output() hideLogin: EventEmitter<boolean> = new EventEmitter(true);

  public meetingName = '';
  public meetingDescription = '';
  public meetingVisibility = '';
  public meetingID = '';
  public meetingType = '';
  public meetingDate = '';
  public inviteID = '';
  public registrantName = '';
  public notCurrentUser = false;
  public meetingError = false;

  constructor(private cbLoginBannerService: CbLoginBannerService, private authService: CbAuthService) {
    this.meetingName = this.cbLoginBannerService.meetingName;
    this.meetingDescription = this.cbLoginBannerService.meetingDescription;
    this.meetingVisibility = this.cbLoginBannerService.meetingVisibility;
    this.meetingID = this.cbLoginBannerService.meetingID;
    this.meetingType = this.cbLoginBannerService.meetingType;
    this.meetingDate = this.cbLoginBannerService.meetingDate;
    this.inviteID = this.cbLoginBannerService.inviteID;
    this.registrantName = this.cbLoginBannerService.registrantName;
    this.meetingError = this.cbLoginBannerService.meetingError;
  }

  ngOnInit() {
    if (!this.meetingError && this.meetingVisibility === 'private' && !this.inviteID) {
      this.notCurrentUser = true;
      this.hideLogin.emit(true);
    }
  }

  public get registrantFirstName(): string {
    return this.registrantName && this.registrantName.split(' ')[0];
  }

  public get showPersonalizedInviteInfo(): boolean {
    return this.inviteID && !this.notCurrentUser && this.registrantName !== undefined;
  }

  public get showLoginText(): boolean {
    return !this.inviteID && this.meetingVisibility === 'public' && this.meetingType !== 'Past Meeting';
  }

  public changeCurrentUser() {
    // If meeting visibility is private, hide the login section
    if (this.meetingVisibility === 'private') {
      this.notCurrentUser = true;
      this.hideLogin.emit(true);
    } else {
      // If the event is public, redirect the user back to the standalone app,
      // which will redirect them back here without the invite info
      const params = new URLSearchParams(window.location.search.slice(1));
      const redirectURI = params.get('redirect_uri').replace('/auth', '');

      window.location.href = `${redirectURI}/meeting/${this.meetingID}`;
    }
  }
}
