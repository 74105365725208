import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { cdn } from './../../services/cb-envars';
import { CbAuthService } from '../../services/cb-auth.service';
import { AuthError } from '../../models/cb-auth.models';
import { CbLoginConfig } from '../../services/cb-login.config';
import { CbLoginPageContent, CB_LOGIN_PAGE, CB_LOGIN_REDIRECTION_KEY } from './login-content-page';
import { CustomValidators } from '../../shared/custom-validations/custom-validations';
import { CbJoinNowService } from '../../services/cb-joinnow.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';

import { LogFactory } from '../../services/cb-debug';
import { CbAlertService } from '../../services/cb-alert.service';
const log = LogFactory('LoginComponent');

@Component({
  selector: 'cb-login-controller',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;
  username: FormControl;
  password: FormControl;
  destroy$ = new Subject();
  formLoading = false;
  error: AuthError | undefined = undefined;
  redirectionMessage = '';

  pageTitle = '';
  pageParentGroupTitle = '';
  joinowAppCopy = false;
  isCustomTitle = false;
  CDN = cdn;

  alertMessage = '';
  alertType = 'error';

  content: CbLoginPageContent = new CbLoginPageContent();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cbLoginConfig: CbLoginConfig,
    private cbAuthService: CbAuthService,
    private cbJoinNowService: CbJoinNowService,
    private cbAuthURLService: CbAuthURLService,
    private cbFullstoryService: CbFullstoryService,
    private cbAlertService: CbAlertService
  ) {
    this.verifyRedirectionMessage();
    this.overwriteContentFromConfig();
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      username: ['', [Validators.required, CustomValidators.noWhitespaceValidator]],
      password: ['', [Validators.required]],
    });
    this.username = this.form.get('username') as FormControl;
    this.password = this.form.get('password') as FormControl;

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.error = undefined;
    });
  }

  setDefaultLoginTitle() {
    this.pageTitle = `Sign In`;
  }

  ngOnInit() {
    this.handleErrorMessage();
    this.handlerAlert();
    this.setDefaultLoginTitle();

    this.joinowAppCopy = this.cbJoinNowService.GetJoinnowCopy();
    this.cbFullstoryService.sendEvent('login_landed');
  }

  handlerAlert() {
    const { message = '' } = this.cbAlertService.getAlert() || {};
    if (!message) {
      return;
    }
    this.alertMessage = message;
  }

  handleErrorMessage() {
    const { error_code, error_message } = this.cbAuthURLService.getAllURLParams();
    this.cbFullstoryService.fsError(error_message);

    if (error_code) {
      this.error = {
        code: error_code,
        description: error_message,
      };
      return;
    }

    this.error = null;
  }

  overwriteContentFromConfig() {
    const config = (this.cbLoginConfig.cbContentFactory && this.cbLoginConfig.cbContentFactory()) || {};
    this.content.overwriteContent(config[CB_LOGIN_PAGE]);
  }

  goToCreateAccountPage() {
    this.router.navigate(['/cb/account'], { skipLocationChange: true });
  }

  goToForgotPassword() {
    this.router.navigate(['/cb/reset-password'], { skipLocationChange: true });
  }

  submit() {
    if (window.navigator && !window.navigator.onLine) {
      this.error = {
        code: 'offline',
        description: 'No internet connection found.',
      };
      return;
    }

    if (this.form.valid) {
      this.error = undefined;
      this.formLoading = true;
      const isEmail = this.username.value.includes('@');
      const payload = {
        email: isEmail ? this.username.value : '',
        username: !isEmail ? this.username.value : '',
        method: 'form',
      };

      this.cbFullstoryService.sendEvent('login', payload);

      this.cbAuthService
        .login(this.username.value, this.password.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.formLoading = false;
          },
          (error) => {
            log('err? ', { error });
            this.error = error;
            const errorDescription = this.getErrorMessage(error);
            this.error.description = errorDescription;
            this.cbFullstoryService.fsError(errorDescription);
            this.formLoading = false;
          }
        );
    }
  }

  getErrorMessage(error) {
    const { code = '', message = '', description = '' } = error || ({} as any);
    const errorDescription = this.content[code] || description || message || '';
    const isMessageUnknownError = message && message.toLowerCase() === 'unknown error';
    const isDescriptionUnknownError = description && description.toLowerCase() === 'unknown error';
    const isUnknownError = isMessageUnknownError || isDescriptionUnknownError;
    const isAPIError = message === 'api connection error';
    // const isCodeTooManyRequest = code === 'too_many_attempts';
    // const isNameAnomalyDetected = name && name === 'AnomalyDetected';
    const isDescriptionBlockedAccount =
      description &&
      description.toLowerCase().includes('your account has been blocked after multiple consecutive login attemps');

    if (isDescriptionBlockedAccount) {
      return 'Your account has been blocked after multiple consecutive login attempts. Please contact our support team.';
    }

    if (isUnknownError || isAPIError) {
      return 'We are unable to sign you in, please try again later';
    }

    return errorDescription;
  }

  githubLogin() {
    this.formLoading = true;
    this.cbFullstoryService.sendEvent('login', { method: 'github' });
    this.cbAuthService.socialLogin('github').pipe(takeUntil(this.destroy$)).subscribe();
  }

  googlLogin() {
    this.formLoading = true;
    this.cbFullstoryService.sendEvent('login', { method: 'google-oauth2' });
    this.cbAuthService.socialLogin('google-oauth2').pipe(takeUntil(this.destroy$)).subscribe();
  }

  linkedinLogin() {
    this.formLoading = true;
    this.cbFullstoryService.sendEvent('login', { method: 'linkedin' });
    this.cbAuthService.socialLogin('linkedin').pipe(takeUntil(this.destroy$)).subscribe();
  }
  // facebook() {
  //   this.formLoading = true;
  //   this.cbFullstoryService.sendEvent('login', { method: 'facebook' });
  //   this.cbAuthService.socialLogin('facebook').pipe(takeUntil(this.destroy$)).subscribe();
  // }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  verifyRedirectionMessage() {
    const queryParam = CB_LOGIN_REDIRECTION_KEY;
    const key = this.activatedRoute.snapshot.queryParams[queryParam] || '';
    this.redirectionMessage = this.content[key];
  }
}
