import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CbAuthService } from '../../services/cb-auth.service';

@Component({
  templateUrl: './request-confirmation.component.html',
  styleUrls: ['./request-confirmation.component.scss'],
})
export class RequestConfirmationComponent implements OnInit {
  headerTitle = 'Already have an account ?';
  confirmationTitle = 'Request complete.';
  confirmationMessage =
    'Your request has been submitted to our support team,\
     thank you for your patience while we review. Our support team will reach out\
      to you via email to update you on the progress of your request.';
  linkText = 'Return to Login';
  linkRouterPath = '/cb/login';

  constructor(private route: ActivatedRoute, private cbAuthService: CbAuthService) {}

  ngOnInit(): void {
    const issueId = this.route.snapshot.params.issueId;
    if (!issueId) {
      this.cbAuthService.navigateTo('/');
    }
  }
}
