import { Injectable } from '@angular/core';
import { LogFactory } from './cb-debug';
import { CbAuthURLService } from './cb-auth-url.service';
import { stage } from './cb-envars';

const log = LogFactory('CbBannerService', false);

// @todo: replace it later
// let stage = STAGE || 'development';

// if (stage === 'dev') {
//   stage = 'development';
// }

const BUCKET_NAME = `lf-login-platform-assets-${stage}`;
const BUCKET_URL = `https://${BUCKET_NAME}.s3.amazonaws.com`;

const FOLDER = {
  JOINNOW: 'joinnow',
  CVENT: 'cvent',
  LF: 'linux',
  PROJECT: 'project',
};

const POSSIBLE_EXTENSIONS = ['.png', '.svg', '.jpg'];
const DEAULT_IMAGE_NAME = 'login/default.png';
const DEFAULT_BANNER = `https://lf-login-platform-assets-production.s3.amazonaws.com/login/default.png`;

@Injectable({ providedIn: 'root' })
export class CbBannerService {
  constructor(private cbAuthURLService: CbAuthURLService) {}

  getEventNameFromURL() {
    const { event_name, eventName } = this.cbAuthURLService.getAllURLParams();
    const name = event_name || eventName;
    log('getEventNameFromURL  name ?', { name });

    return name;
  }

  getEventNameFromReturnTo() {
    const { event_name, eventName } = this.cbAuthURLService.getParamsFromReturnTo() as any;
    const name = event_name || eventName;

    log('getEventNameFromReturnTo  name ?', { name });

    return name;
  }

  getImageFileFromName(name = '') {
    if (!name) {
      return '';
    }

    let imageFile = '';
    imageFile = decodeURIComponent(name);
    imageFile = imageFile.toLowerCase();
    imageFile = imageFile.replace(/ /gi, '-');
    imageFile = imageFile.replace(/[0-9]/gi, '');

    return imageFile;
  }
  getImageFileWithNumbersFromName(name = '') {
    if (!name) {
      return '';
    }

    const imageFile = decodeURIComponent(name).toLowerCase().replace(/ /gi, '-');

    return imageFile;
  }

  GetImagesWithExtensions(folder, name) {
    if (!folder || !name) {
      return [];
    }

    const baseURL = `${BUCKET_URL}/${folder}/${name}`;

    const images = POSSIBLE_EXTENSIONS.map((extension) => {
      return `${baseURL}${extension}`;
    });

    return images;
  }

  JoinGroupOfImages(groupA = [], groupB = []) {
    const collection = [...groupA];

    groupB.forEach((image) => {
      if (!collection.includes(image)) {
        collection.push(image);
      }
    });

    return collection;
  }

  GetPossibleImages(folderName = '', name = ''): string[] {
    const nameOne = this.getImageFileFromName(name);
    const nameTwo = this.getImageFileWithNumbersFromName(name);

    let images = this.GetImagesWithExtensions(folderName, nameOne);

    if (nameOne === nameTwo) {
      return images;
    }

    const moreImages = this.GetImagesWithExtensions(folderName, nameTwo);

    images = this.JoinGroupOfImages(images, moreImages);

    return images;
  }

  GetCventImageURL(bannerName = '') {
    log('entered GetCventImageURL', { bannerName });
    const eventName = bannerName || this.getEventNameFromURL() || this.getEventNameFromReturnTo();
    if (!eventName) {
      return null;
    }

    const folder = FOLDER.CVENT;
    let name = eventName;

    if (name.includes(',')) {
      name = name.split(',')[0];
    }

    const images = this.GetPossibleImages(folder, name);

    return images;
  }

  preloadImage(URL) {
    const img = new Image();
    img.src = URL;
  }

  GetJoinnowBanners() {
    log('entered GetJoinnowBanners');

    const { app, project } = this.cbAuthURLService.getAllURLParams();

    if (app !== 'joinnow' || !project) {
      return null;
    }

    const folder = FOLDER.JOINNOW;
    const name = project;

    const images = this.GetPossibleImages(folder, name);

    return images;
  }

  GetPossibleBanners(banners = []) {
    return banners.map((URL) => {
      this.preloadImage(URL);

      return {
        URL,
        hide: true,
      };
    });
  }

  GetProjectBanner() {
    log('entered GetProjectBanner');

    const { app, project } = this.cbAuthURLService.getAllURLParams();

    if (app !== 'registration' || !project) {
      return null;
    }

    const folder = FOLDER.PROJECT;
    const name = project;

    const images = this.GetPossibleImages(folder, name);

    return images;
  }

  GetBanners() {
    log('entered GetBanner');
    let banners = [];

    const cventBanners = this.GetCventImageURL();
    if (cventBanners) {
      banners = cventBanners;
    }

    const joinnowBanenrs = this.GetJoinnowBanners();
    if (joinnowBanenrs) {
      banners = joinnowBanenrs;
    }

    const projectBanners = this.GetProjectBanner();
    if (projectBanners) {
      banners = projectBanners;
    }

    banners = this.GetPossibleBanners(banners);

    log('possible banners', { banners });

    return banners;
  }

  GetDefaultbanner() {
    return DEFAULT_BANNER;
  }

  IsDefaultBanner(banner = '') {
    return banner && banner.includes(DEAULT_IMAGE_NAME);
  }

  isSmaller(banner) {
    const imageURL = (banner && banner.URL) || '';
    const smallBanners = [FOLDER.JOINNOW, `${FOLDER.PROJECT}/`];

    return smallBanners.some((folder) => imageURL.includes(folder));
  }

  GetAppClassName() {
    const { app } = this.cbAuthURLService.getAllURLParams();

    if (!app) {
      return '';
    }

    return `app-${app}`;
  }
}
