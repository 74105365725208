import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { CbAuthService } from './cb-auth.service';

@Injectable()
export class CbAuthPageGuard implements CanActivate, CanDeactivate<any> {
  constructor(private cbAuthService: CbAuthService) {}

  canActivate(): // route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  any {
    this.cbAuthService.notifyCbActive(true);
    return true;
  }

  canDeactivate(): // component: any,
  // currentRoute: ActivatedRouteSnapshot,
  // currentState: RouterStateSnapshot,
  // nextState?: RouterStateSnapshot
  boolean {
    this.cbAuthService.notifyCbActive(false);
    return true;
  }
}
