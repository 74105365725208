<div *ngIf="!pageLoading" [hidden]="formLoading">
  <div class="columns is-flex is-centered">
    <div class="column is-two-fifths is-four-fifths-mobile">
      <ng-container *ngIf="!isExpired">
        <cb-alert *ngIf="formSubmitted && changePasswordSuccess" type="success">
          Password Changed.
        </cb-alert>
        <cb-alert *ngIf="formSubmitted && changePasswordFailed" type="error">
          {{ failedMessage }}
        </cb-alert>
        <div *ngIf="changePasswordSuccess && displayCloseWindowMessage" class="has-text-centered has-text-weight-bold">
          You can close this window.
        </div>
        <form [formGroup]="form" [hidden]="changePasswordSuccess">
          <!-- <div class="columns">
            <div class="column">
              <div class="title has-text-info is-size-4 has-text-centered">
                Change Password
              </div>
            </div>
          </div> -->
          <div class="columns is-marginless pb-1">
            <div class="column">
              <div class="has-text-lfblack font-18px bold has-text-centered">
                CHANGE PASSWORD
              </div>
            </div>
          </div>
          <div class="field">
            <cb-input
              autofocus
              iconName="lock"
              type="password"
              label="New Password"
              formControlName="password"
              [errors]="passwordErrors"
            >
            </cb-input>
          </div>
          <div class="field">
            <cb-input
              type="password"
              iconName="lock"
              label="Confirm Password"
              formControlName="confirmPassword"
              [errors]="confirmPasswordErrors"
              [formGroupRef]="form"
            ></cb-input>
            <span *ngIf="form.errors?.passwordMatch && confirmPassword.valid" class="password-matcher-message active">
              {{ confirmPasswordErrors.passwordMatch }}
            </span>
          </div>
          <div class="cb-separator-1rem"></div>
          <div class="cb-text-center">
            <cb-button buttonType="submit" type="is-info px-3" [disabled]="!form.valid" (click)="submit()">
              Submit
            </cb-button>
            <div class="cb-separator-2rem"></div>
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="isExpired">
        <cb-alert type="error">
          Your link has expired.
        </cb-alert>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="formLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>
