import { CbAuthURLService } from 'src/app/lib/services/cb-auth-url.service';
import { parse } from 'querystring';
import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
import * as i1 from "./cb-auth-url.service";
var log = LogFactory('CbGaService');
var CbGaService = /** @class */ (function () {
    function CbGaService(cbAuthURLService) {
        this.cbAuthURLService = cbAuthURLService;
    }
    CbGaService.prototype.getParamsFromURL = function (URL) {
        if (URL === void 0) { URL = ''; }
        log('entered getParamsFromURL', { URL: URL });
        if (!URL) {
            return {};
        }
        var _a = URL.split('?'), params = _a[1];
        if (!params) {
            return {};
        }
        var queryParams = parse(params);
        log('queryParams ? ', { queryParams: queryParams });
        return queryParams;
    };
    CbGaService.prototype.getGAParamFromReturnTo = function () {
        log('entered getGAParamFromReturnTo');
        var returnPath = this.cbAuthURLService.getReturnPath();
        if (!returnPath) {
            return '';
        }
        var params = this.getParamsFromURL(returnPath);
        var ga = params._ga || '';
        return ga;
    };
    CbGaService.ngInjectableDef = i0.defineInjectable({ factory: function CbGaService_Factory() { return new CbGaService(i0.inject(i1.CbAuthURLService)); }, token: CbGaService, providedIn: "root" });
    return CbGaService;
}());
export { CbGaService };
