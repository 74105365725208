<cb-loading-page [hidden]="!isLoading" loadingMessage="Please wait, we are processing your request"> </cb-loading-page>
<div class="cb-row" [hidden]="isLoading">
  <div class="cb-content">
    <cb-alert *ngIf="errorMessage" type="error">
      {{ errorMessage }}
    </cb-alert>

    <cb-alert *ngIf="emailSent" type="success">
      Email sent successfully.
    </cb-alert>

    <div class="actions">
      <div class="cb-title-group">
        <div class="primary-text">Please Verify your Email Address</div>
        <div>
          <br />
          We sent a confirmation email to
          <b>{{ email }}</b>
          , please click on the link in the email to verify your address.
          <br />
          <br />
        </div>
      </div>
      <div class="cb-separator-2rem"></div>
      <div class="actions">
        <cb-button type="is-info px-3" (click)="resendEmail()" [disabled]="isLoading">
          Resend Email
        </cb-button>
      </div>
    </div>
  </div>
</div>
