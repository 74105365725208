<div class="cb-title-group">
  <div class="cb-separator-5rem"></div>
  <br />
  <div class="cb-separator-5rem"></div>
  <div class="primary-text">
    <div style="font-size: 2rem;">
      We were unable to verify your email
    </div>

    <p style="width: 600px; margin: 0 auto; margin-top: 1rem; font-weight: normal;">
      We were regrettably unable to verify your email address. It was likely due to your browser being closed before you
      completed the verification.
    </p>

    <p style="width: 600px; margin: 3rem auto 2rem auto; text-align: left; padding-left: 1rem;">
      Please follow these steps to verify your email:
    </p>
    <ol style="width: 600px; margin: 0 auto 3rem; text-align: left; font-weight: normal;">
      <li style="margin: 0.75rem 0;">
        Proceed to the <a class="cb-link skyblue" (click)="gotoLogin()">Login</a> page and log In with your username and
        password.
      </li>
      <li style="margin: 0.75rem 0;">
        Click on the button to resend the verification email.
      </li>
      <li style="margin: 0.75rem 0;">
        Leave your browser open, and verify your email address in the same browser where you are logged in.
      </li>
    </ol>
  </div>
</div>
