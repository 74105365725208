<cb-top-banner-zoom-banner *ngIf="cbLoginBannerService.isRegistration"></cb-top-banner-zoom-banner>
<cb-top-banner-cvent *ngIf="cbLoginBannerService.eventImages.length"></cb-top-banner-cvent>
<cb-top-banner-app *ngIf="cbLoginBannerService.appImages.length"></cb-top-banner-app>
<app-lfx-meetings *ngIf="cbLoginBannerService.isMeeting" (hideLogin)="onHideLogin()"></app-lfx-meetings>

<div *ngIf="cbLoginBannerService.displayDefault" class="top-banner-default columns is-centered my-1">
  <div class="column has-text-centered">
    <figure class="">
      <img
        style="width: 300px;"
        src="https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/thelinuxfoundation-color.svg"
      />
    </figure>
  </div>
</div>
