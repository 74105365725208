import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { to } from 'await-to-js';
import { ServiceDeskService } from '../../services/service-desk.service';
import { CbAuthService } from '../../services/cb-auth.service';
import { CbRecaptchaService } from '../../services/cb-recpatcha.service';
import { CbErrorService } from '../../services/cb-error.service';
import { CbLoginConfig } from '../../services/cb-login.config';
import { LogFactory } from './../../services/cb-debug';
var log = LogFactory('ServiceDeskComponent');
var ServiceDeskComponent = /** @class */ (function () {
    function ServiceDeskComponent(serviceDeskService, fb, loginConfig, cbRecaptchaService, cbErrorService, cbAuthService) {
        this.serviceDeskService = serviceDeskService;
        this.fb = fb;
        this.loginConfig = loginConfig;
        this.cbRecaptchaService = cbRecaptchaService;
        this.cbErrorService = cbErrorService;
        this.cbAuthService = cbAuthService;
        this.destroy$ = new Subject();
        this.formLoading = false;
        this.requestNotSent = false;
        this.captchaToken = null;
        this.showbackLink = true;
        this.emailErrors = {
            required: 'Email is a required field.',
            pattern: 'Email is invalid',
        };
        this.nameErrors = {
            required: 'Name is a required field.',
            minlength: 'Name should be at least 5 characters long',
            maxlength: 'Name should be less than 255 characters long',
        };
        this.descriptionErrors = {
            required: 'Description is a required field.',
            maxlength: 'Name should be less than 255 characters long',
        };
    }
    ServiceDeskComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fetchRecaptchaToken();
        this.captchaInterval = setInterval(function () {
            _this.fetchRecaptchaToken();
        }, 120000);
        if (this.loginConfig.cbSsoStaticApp) {
            this.showbackLink = false;
        }
        this.form = this.fb.group({
            email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
                ]),
            ],
            name: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(255)])],
            description: ['', [Validators.required, Validators.maxLength(32700)]],
        });
        this.email = this.form.get('email');
        this.name = this.form.get('name');
        this.description = this.form.get('description');
    };
    ServiceDeskComponent.prototype.fetchRecaptchaToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err, token;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        log('entered fetchRecaptchaToken');
                        return [4 /*yield*/, to(this.cbRecaptchaService.getCaptchaToken('serviceDesk').toPromise())];
                    case 1:
                        _a = _b.sent(), err = _a[0], token = _a[1];
                        if (err) {
                            log('Error fetchRecaptchaToken', { err: err });
                            return [2 /*return*/];
                        }
                        this.captchaToken = token;
                        return [2 /*return*/];
                }
            });
        });
    };
    ServiceDeskComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var request, _a, err, data;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.formLoading = true;
                        this.requestNotSent = false;
                        if (!this.form.valid) {
                            return [2 /*return*/];
                        }
                        request = {
                            name: this.name.value,
                            email: this.email.value,
                            description: this.description.value,
                            captchaToken: this.captchaToken,
                        };
                        return [4 /*yield*/, to(this.serviceDeskService.sendRequest(request).toPromise())];
                    case 1:
                        _a = _b.sent(), err = _a[0], data = _a[1];
                        this.formLoading = false;
                        log(' response ? ', { err: err, data: data });
                        if (err) {
                            this.requestNotSent = true;
                            this.fetchRecaptchaToken();
                            return [2 /*return*/];
                        }
                        if (data.error) {
                            this.requestNotSent = true;
                            return [2 /*return*/];
                        }
                        this.cbAuthService.navigateTo('/cb/request-confirmation/' + data.issueKey);
                        return [2 /*return*/];
                }
            });
        });
    };
    ServiceDeskComponent.prototype.goBack = function () {
        this.cbAuthService.navigateTo('/cb/login');
    };
    ServiceDeskComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.captchaInterval);
        this.destroy$.next();
        this.destroy$.complete();
    };
    return ServiceDeskComponent;
}());
export { ServiceDeskComponent };
