<div [hidden]="formLoading" class="service-desk-page">
  <div class="columns is-flex is-centered">
    <div class="column">
      <div class="columns is-marginless pb-1">
        <div class="column">
          <div class="has-text-lfblack font-18px bold has-text-centered">
            CONTACT US
          </div>
        </div>
      </div>
      <div class="cb-title-group" style="text-align: left; max-width: 600px; margin: 0 auto;">
        <div class="secondary-text" style="color: #333; line-height: 1.6;">
          <h2 style="color: #09c; margin-bottom: 20px; font-size: 16px;">
            Please use the following options to direct your request:
          </h2>

          <ul style="list-style-type: none; padding: 0;">
            <li style="margin-bottom: 15px;">
              <strong>Training & Certification Inquiries: </strong>
              <a
                href="https://training.linuxfoundation.org/about/contact-us/"
                style="color: #09c; text-decoration: none; border-bottom: 1px solid #09c;"
                >Contact Training</a
              >
            </li>
            <li style="margin-bottom: 15px;">
              <strong>Exam session issues or inquiries:</strong> Please log in to your LF account and
              <a
                href="https://jira.linuxfoundation.org/plugins/servlet/desk/portal/15"
                style="color: #09c; text-decoration: none; border-bottom: 1px solid #09c;"
                >open a ticket with the dedicated support team</a
              >
            </li>
            <li style="margin-bottom: 15px;">
              <strong>Events Inquiries: </strong>
              <a
                href="https://events.linuxfoundation.org/about/contact/"
                style="color: #09c; text-decoration: none; border-bottom: 1px solid #09c;"
                >Contact Events Team</a
              >
            </li>
            <li style="margin-bottom: 15px;">
              <strong>For any other inquiries</strong> not related to your LF login account:
              <a
                href="https://www.linuxfoundation.org/about/contact"
                style="color: #09c; text-decoration: none; border-bottom: 1px solid #09c;"
                >General Contact</a
              >
            </li>
          </ul>

          <p style="background-color: #e9ecef; padding: 15px; border-radius: 5px; margin-top: 20px;">
            <strong style="color: #09c;">Unable to log in?</strong> If you can't access your LF account, use the form
            below to request assistance. Please provide your contact information and a description of the problem you're
            experiencing. Your request will be submitted to our technical support team for review.
          </p>
        </div>
      </div>

      <div class="column is-centered" style="margin: auto; max-width: 600px;">
        <form class="form" [formGroup]="form" (ngSubmit)="submit()">
          <div class="field">
            <cb-input iconName="user" autofocus label="Name" formControlName="name" [errors]="nameErrors"></cb-input>
          </div>
          <div class="field">
            <cb-input
              iconName="envelope"
              label="Email address"
              formControlName="email"
              [errors]="emailErrors"
            ></cb-input>
          </div>
          <div class="field">
            <cb-textarea formControlName="description" label="Description" rows="8" [errors]="descriptionErrors">
            </cb-textarea>
          </div>
          <div class="service-desk-alert">
            <cb-alert *ngIf="requestNotSent" type="error">
              Message request not sent
            </cb-alert>
          </div>
          <div class="actions">
            <cb-button buttonType="submit" type="is-info px-3" [disabled]="!form.valid || !captchaToken">
              Send
            </cb-button>
            <a class="cb-link gray cancel" *ngIf="showbackLink" cbNavigateTo="/cb/login">Back</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="formLoading">
  <cb-loading-page loadingMessage="We are processing your request, please wait"> </cb-loading-page>
</div>
