import * as tslib_1 from "tslib";
import { WebAuth } from 'auth0-js';
import { CbLoginConfig } from './cb-login.config';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { switchMap, map, filter, take, delay } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { stringify } from 'querystring';
import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
import * as i1 from "./cb-login.config";
import * as i2 from "@angular/router";
var log = LogFactory('CbAuthService', false);
var OPENID_CONNECT_SCOPES = 'openid profile email';
var REALM = 'Username-Password-Authentication';
var STORAGE_KEY = 'cbtoken';
var STORAGE_REDIRECT_KEY = 'cb-redirectTo';
var QUERY_KYES = {
    token: 'token',
    return: 'return',
    email: 'email',
    state: 'state',
    clientName: 'clientName',
};
var CbAuthService = /** @class */ (function () {
    function CbAuthService(loginConfig, router, activatedRoute) {
        this.loginConfig = loginConfig;
        this.router = router;
        this.activatedRoute = activatedRoute;
        // Allow track the current user session, like form.valuesChanges (ReactiveForm Module)
        this.cbSession$ = new BehaviorSubject(undefined);
        this.user$ = new BehaviorSubject(undefined);
        this.isCBActive$ = new BehaviorSubject(false);
        this.isCBActive = false;
        this.cbWarningExpiryTime$ = new BehaviorSubject({ active: false });
        this.returnURL = '';
        /**
         * Error Page Flow:
         * If there is any error, user will be redirected to /cb/error-page?error-info......
         * also this is an dead End so we won't provide a return option
         *
         * On auth0 error:
         * ---> User redirection ---> Error Page -> Help Page -> User Send Error info within the Help request
         *
         * - Logo Link no required
         * - back link in help page no required
         * - Other pages must be restricted
         *
         */
        this.isErrorFlow = false;
        // @todo: pass storage as dependency
        this.storage = localStorage;
        // @todo: pass window.document as dependency
        this.documentLocation = document.location;
        if (this.loginConfig.cbSsoStaticApp) {
            this.loginConfig.cbSkipLocalChanges = true;
        }
        this.initializeAuth0Library();
        this.syncStoreToken();
        this.addExpiryTimeListener();
    }
    CbAuthService.prototype.serializeToQueryParams = function (obj, prefix) {
        if (prefix === void 0) { prefix = ''; }
        var str = [];
        var p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + '[' + p + ']' : p;
                var v = obj[p];
                str.push(v !== null && typeof v === 'object'
                    ? this.serializeToQueryParams(v, k)
                    : encodeURIComponent(k) + '=' + encodeURIComponent(v));
            }
        }
        return str.join('&');
    };
    CbAuthService.prototype.goToErrorPage = function (errorInfo) {
        var errorData = {
            error: (errorInfo && errorInfo.error) || 'unknown Error',
            error_description: (errorInfo && errorInfo.message) || 'Please contact support for additional help.',
        };
        if (errorInfo.extras) {
            errorData = tslib_1.__assign({}, errorData, errorInfo.extras);
        }
        this.router.navigateByUrl("/cb/error-page?" + this.serializeToQueryParams(errorData));
        return;
    };
    CbAuthService.prototype.navigateTo = function (path, extras) {
        if (path === void 0) { path = ''; }
        if (extras === void 0) { extras = {}; }
        var skipLocationChange = this.loginConfig.cbAuthCentralizedLogin || this.loginConfig.cbSkipLocalChanges;
        this.router.navigate([path], tslib_1.__assign({}, extras, { skipLocationChange: skipLocationChange }));
    };
    CbAuthService.prototype.notifyCbActive = function (value) {
        if (value === this.isCBActive) {
            return;
        }
        this.isCBActive = value;
        this.isCBActive$.next(value);
    };
    CbAuthService.prototype.initializeAuth0Library = function () {
        try {
            // Angular Environment eg.LFX
            // Config is provided by CbLoginConfig
            var authOptions = this.getAuth0ConfigFromApp();
            // Auth0 Tenant Universal Login
            // Config is provided by  window[auth0Key]
            if (this.loginConfig.cbAuthCentralizedLogin) {
                authOptions = this.getAuth0ConfigFromAuth0();
            }
            this.auth0 = new WebAuth(authOptions);
        }
        catch (error) {
            console.error('There was an error initiating cb-login module');
        }
    };
    CbAuthService.prototype.getAuth0ConfigFromApp = function () {
        return {
            clientID: this.loginConfig.clientId,
            clientName: this.loginConfig.clientName,
            domain: this.loginConfig.domain,
            responseType: this.loginConfig.cbResponseType,
            redirectUri: this.getCallbackRedirection(),
            scope: OPENID_CONNECT_SCOPES,
        };
    };
    CbAuthService.prototype.getAuth0ConfigFromAuth0 = function () {
        var config = this.getConfigFromAuth0Environment();
        if (!config) {
            return {};
        }
        var responseType = (config.internalOptions || {}).response_type || (config.callbackOnLocationHash ? 'token' : 'code');
        var commonParams = {
            clientID: config.clientID,
            clientName: config.dict.signin.title,
            domain: config.auth0Domain,
            responseType: responseType,
            overrides: {
                __tenant: config.auth0Tenant,
                __token_issuer: config.authorizationServer.issuer,
            },
        };
        return Object.assign(commonParams, config.internalOptions);
    };
    CbAuthService.prototype.getConfigFromAuth0Environment = function () {
        log('entered getConfigFromAuth0Environment');
        var auth0Key = '_auth0Config';
        var config = window[auth0Key];
        return config;
    };
    CbAuthService.prototype.generateReturnFromAuth0Config = function (config) {
        if (!config) {
            return '';
        }
        var returnPath = 'authorize';
        var extraParams = config.extraParams || {};
        var internalOptions = config.internalOptions || {};
        if ((extraParams.protocol || internalOptions.protocol || '') === 'samlp') {
            returnPath = "samlp/" + config.clientID;
        }
        var queryParams = {
            client_id: config.clientID,
        };
        Object.keys(config.extraParams).forEach(function (key) {
            queryParams[key] = config.extraParams[key];
        });
        var returnQuery = stringify(queryParams);
        var returnURL = encodeURIComponent(returnPath + "?" + returnQuery);
        return returnURL;
    };
    CbAuthService.prototype.persistConfigParamsForReturn = function () {
        var config = this.getConfigFromAuth0Environment();
        return this.generateReturnFromAuth0Config(config);
    };
    CbAuthService.prototype.getLogoLinkInAuth0Page = function () {
        log('entered getLogoLinkInAuth0Page');
        return this.getClientDomainFromAuth0Config();
    };
    CbAuthService.prototype.getClientDomainFromAuth0Config = function () {
        log('entered getClientDomainFromAuth0Config');
        if (this.returnURL) {
            return this.returnURL;
        }
        var config = this.getConfigFromAuth0Environment();
        if (!config) {
            return '';
        }
        var url = this.parseUrl(config.callbackURL);
        this.returnURL = url.origin;
        return this.returnURL;
    };
    CbAuthService.prototype.parseUrl = function (url) {
        if (url === void 0) { url = ''; }
        var a = document.createElement('a');
        a.setAttribute('href', url);
        var host = a.host, hostname = a.hostname, pathname = a.pathname, port = a.port, protocol = a.protocol, search = a.search, hash = a.hash;
        var origin = protocol + "//" + hostname + (port.length ? ":" + port : '');
        return { origin: origin, host: host, hostname: hostname, pathname: pathname, port: port, protocol: protocol, search: search, hash: hash };
    };
    CbAuthService.prototype.getValuesFromFragments = function (frags) {
        return ((frags &&
            frags.split('&').reduce(function (acc, item) {
                var _a = item.split('='), key = _a[0], value = _a[1];
                acc[key] = value;
                return tslib_1.__assign({}, acc);
            }, {})) ||
            {});
    };
    CbAuthService.prototype.getDataFromFragment = function (key) {
        if (key === void 0) { key = ''; }
        this.currentFragments = this.fragmentToObject();
        if (key) {
            return this.currentFragments[key];
        }
        return this.currentFragments;
    };
    CbAuthService.prototype.fragmentToObject = function () {
        var fragments = this.activatedRoute.snapshot.fragment || '';
        var _a = fragments.split('&return='), extra = _a[0], returnURL = _a[1];
        var frags = this.getValuesFromFragments(extra);
        if (returnURL) {
            frags[QUERY_KYES.return] = returnURL;
        }
        return frags;
    };
    CbAuthService.prototype.setDefaultReturnURL = function (returnURL) {
        this.returnURL = returnURL;
    };
    CbAuthService.prototype.getReturnURLInSSOStaticApp = function () {
        if (this.returnURL) {
            return this.returnURL;
        }
        var returnURL = this.getReturnURLFromFragments() || this.getReturnURLFromQueryParam();
        if (!returnURL) {
            return '';
        }
        this.returnURL = "https://" + this.loginConfig.domain + "/" + decodeURIComponent(returnURL);
        return this.returnURL;
    };
    CbAuthService.prototype.getReturnURLFromFragments = function () {
        var fragments = this.getDataFromFragment();
        return fragments[QUERY_KYES.return];
    };
    CbAuthService.prototype.getReturnURLFromQueryParam = function () {
        return this.activatedRoute.snapshot.queryParams.return;
    };
    CbAuthService.prototype.getCallbackRedirection = function () {
        return this.documentLocation.origin + "/auth";
    };
    CbAuthService.prototype.getToHomePage = function () {
        return "" + this.documentLocation.origin;
    };
    CbAuthService.prototype.goToLogInPage = function (redirectTo) {
        if (redirectTo === void 0) { redirectTo = '/'; }
        this.navigateTo('/cb/login', { queryParams: { redirectTo: redirectTo } });
    };
    CbAuthService.prototype.saveRedirectTo = function (redirectTo) {
        if (redirectTo === void 0) { redirectTo = '/'; }
        this.storage.setItem(STORAGE_REDIRECT_KEY, redirectTo);
    };
    CbAuthService.prototype.clearRedirectTo = function () {
        this.storage.removeItem(STORAGE_REDIRECT_KEY);
        return true;
    };
    CbAuthService.prototype.redirectTo = function () {
        var redirectToUrl = this.storage.getItem(STORAGE_REDIRECT_KEY) || '/';
        this.clearRedirectTo();
        this.navigateTo(redirectToUrl);
    };
    CbAuthService.prototype.syncStoreToken = function () {
        var _this = this;
        this.getSavedCbToken().subscribe(function (cbStoredToken) { return _this.notifyUserSession$(cbStoredToken); });
    };
    CbAuthService.prototype.isUserSessionValid = function () {
        var _this = this;
        return this.getSavedCbToken().pipe(switchMap(function (cBStoredToken) {
            var invalid = of(false);
            var valid = of(true);
            if (!cBStoredToken) {
                return invalid;
            }
            // Verify expiresAt
            var token = cBStoredToken.token;
            if (token.expiresAt.getTime() < new Date().getTime()) {
                _this.logout().subscribe();
                return invalid;
            }
            return valid;
        }));
    };
    CbAuthService.prototype.getSavedCbToken = function () {
        var _this = this;
        return new Observable(function (observer) {
            var cbStoredToken = _this.storage.getItem(STORAGE_KEY);
            if (!cbStoredToken) {
                observer.next(undefined);
                observer.complete();
                return;
            }
            var _a = JSON.parse(cbStoredToken), user = _a.user, token = _a.token;
            var cbToken = {
                user: user,
                token: tslib_1.__assign({}, token, { expiresAt: new Date(token.expiresAt) }),
            };
            observer.next(cbToken);
            observer.complete();
        });
    };
    CbAuthService.prototype.getUserInfo = function () {
        return this.getSavedCbToken().pipe(map(function (storedToken) { return (storedToken && storedToken.user) || undefined; }));
    };
    CbAuthService.prototype.login = function (username, password) {
        var _this = this;
        return new Observable(function (observer) {
            _this.auth0.login({ username: username, password: password, realm: REALM }, function (error) {
                if (error) {
                    observer.error(error);
                    observer.complete();
                    return;
                }
            });
        });
    };
    CbAuthService.prototype.socialLogin = function (connectionName) {
        var _this = this;
        return new Observable(function (observer) {
            _this.auth0.authorize({ connection: connectionName }, function (error) { return observer.error(error); });
        });
    };
    // @info: redirect by default to Login page
    // It requires Auth0 app suport this page as Allowed logout URLs
    CbAuthService.prototype.logout = function (redirectTo) {
        var _this = this;
        if (redirectTo === void 0) { redirectTo = '/cb/login'; }
        var returnTo = this.getToHomePage() + redirectTo;
        return new Observable(function (observer) {
            _this.auth0.logout({ returnTo: returnTo });
            _this.storage.removeItem(STORAGE_KEY);
            _this.notifyUserSession$(undefined);
            _this.notifyWarning();
            observer.next(true);
            observer.complete();
        });
    };
    CbAuthService.prototype.saveToken = function (hash) {
        var _this = this;
        return this.parseHash(hash).pipe(switchMap(function (decodedToken) {
            return _this.parseToken(decodedToken).pipe(map(function (userInfo) {
                var userSession = { token: decodedToken, user: userInfo };
                _this.notifyUserSession$(userSession);
                return _this.saveInStorage(userSession);
            }));
        }));
    };
    CbAuthService.prototype.notifyWarning = function (active, expiresIn) {
        if (active === void 0) { active = false; }
        if (expiresIn === void 0) { expiresIn = 0; }
        this.cbWarningExpiryTime$.next({ expiresIn: expiresIn, active: active });
    };
    CbAuthService.prototype.addExpiryTimeListener = function () {
        var _this = this;
        this.cbSession$
            .pipe(filter(function (value) { return !!value; }), take(1))
            .subscribe(function (userSession) {
            var WARNING_TIME = _this.loginConfig.cbWarningTime;
            var expiryTime = userSession.token.expiresAt;
            var timeUntilExpiry = expiryTime.getTime() - new Date().getTime();
            var timeUntilWarning = timeUntilExpiry - WARNING_TIME;
            var isInWarningTime = timeUntilExpiry <= WARNING_TIME;
            if (isInWarningTime) {
                _this.notifyWarning(true, timeUntilExpiry);
                if (timeUntilExpiry > 0) {
                    of(true)
                        .pipe(delay(timeUntilExpiry))
                        .subscribe(function () { return _this.addExpiryTimeListener(); });
                }
                return;
            }
            _this.notifyWarning(false, timeUntilExpiry);
            of(true)
                .pipe(delay(timeUntilWarning))
                .subscribe(function () { return _this.addExpiryTimeListener(); });
        });
    };
    CbAuthService.prototype.renewAuthToken = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.auth0.checkSession({}, function (error, decodedHash) {
                if (error) {
                    observer.error(new Error('Failed to load token.'));
                    observer.complete();
                    return;
                }
                var userSession = {
                    token: tslib_1.__assign({}, decodedHash, { expiresAt: _this.getExpiresAt() }),
                    user: tslib_1.__assign({}, decodedHash.idTokenPayload),
                };
                // @info: not necessary in token Object
                delete userSession.token.idTokenPayload;
                _this.notifyUserSession$(userSession);
                var WARNING_TIME = _this.loginConfig.cbWarningTime;
                var timeUntilExpiry = userSession.token.expiresAt.getTime() - new Date().getTime();
                var timeUntilWarning = timeUntilExpiry - WARNING_TIME;
                _this.notifyWarning(false, timeUntilWarning);
                _this.addExpiryTimeListener();
                _this.saveInStorage(userSession);
                observer.next(true);
                observer.complete();
            });
        });
    };
    CbAuthService.prototype.notifyUserSession$ = function (value) {
        this.cbSession$.next(value);
        this.user$.next((value && value.user) || undefined);
    };
    CbAuthService.prototype.getExpiresAt = function () {
        // @info: 10h is the time assign in auth0 app
        var expireTime = 36000000;
        return new Date(new Date().getTime() + expireTime);
    };
    CbAuthService.prototype.saveInStorage = function (object) {
        this.storage.setItem(STORAGE_KEY, JSON.stringify(object));
        return true;
    };
    CbAuthService.prototype.parseHash = function (hash) {
        var _this = this;
        return new Observable(function (observer) {
            _this.auth0.parseHash({ hash: hash }, function (err, authResult) {
                if (err) {
                    observer.error(err);
                }
                var cbToken = tslib_1.__assign({}, authResult, { expiresAt: _this.getExpiresAt() });
                observer.next(cbToken);
                observer.complete();
            });
        });
    };
    CbAuthService.prototype.parseToken = function (decodedToken) {
        var _this = this;
        return new Observable(function (observer) {
            _this.auth0.client.userInfo(decodedToken.accessToken, function (err, user) {
                if (err) {
                    observer.error(err);
                }
                observer.next(user);
                observer.complete();
            });
        });
    };
    CbAuthService.ngInjectableDef = i0.defineInjectable({ factory: function CbAuthService_Factory() { return new CbAuthService(i0.inject(i1.CbLoginConfig), i0.inject(i2.Router), i0.inject(i2.ActivatedRoute)); }, token: CbAuthService, providedIn: "root" });
    return CbAuthService;
}());
export { CbAuthService };
