import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CbLoginBannerService } from './cb-login-banner.service';
import { LogFactory } from './cb-debug';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./cb-login-banner.service";
var log = LogFactory('HttpService', false);
export var HTTP_BAD_REQUEST = 400;
export var HTTP_CONFLICT = 409;
export var HTTP_UNAUTHORIZED = 401;
export var HTTP_NOT_FOUND = 404;
var PARAMS_REGEX = /\/-(?=\/)?/;
var PARAMS_MULTI_REGEX = /\/-(?=\/)?/g;
var HttpService = /** @class */ (function () {
    function HttpService(httpClient, cbLoginBannerService) {
        this.httpClient = httpClient;
        this.cbLoginBannerService = cbLoginBannerService;
        this.requestInProgress = false;
    }
    HttpService.prototype.initHttpHeaders = function (token) {
        if (token === void 0) { token = ''; }
        log('entered initHttpHeaders', { token: token });
        var _a = this.cbLoginBannerService.getPersistentParamsInURL(), appName = _a.appName, clientId = _a.clientId;
        var headers = new HttpHeaders().set('x-clientId', clientId || '').set('x-clientName', appName || '');
        if (token) {
            headers = headers.set('authorization', "Bearer " + token);
        }
        return headers;
    };
    HttpService.prototype.serializeResourceURl = function (resourceUrl, optionalParams) {
        var queryParams = optionalParams.queryParams, urlMultiParams = optionalParams.urlMultiParams, urlParam = optionalParams.urlParam;
        var query = this._getQueryString(queryParams);
        resourceUrl = this._setUrlParam(resourceUrl, urlParam);
        if (urlMultiParams) {
            resourceUrl = this._setUrlMultiParams(resourceUrl, urlMultiParams);
        }
        return "" + resourceUrl + query;
    };
    HttpService.prototype.get = function (resourceUrl, optionalParams, token) {
        var _this = this;
        if (optionalParams === void 0) { optionalParams = {}; }
        if (token === void 0) { token = ''; }
        this.requestInProgress = true;
        var httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);
        var httpHeaders = this.initHttpHeaders(token);
        return this.httpClient
            .get(httpUrl, { headers: httpHeaders })
            .pipe(tap(function () { return (_this.requestInProgress = false); }), catchError(function (err) { return _this._handleError(err); }));
    };
    HttpService.prototype.post = function (resourceUrl, optionalParams, token) {
        var _this = this;
        if (optionalParams === void 0) { optionalParams = {}; }
        if (token === void 0) { token = ''; }
        this.requestInProgress = true;
        var httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);
        var httpHeaders = this.initHttpHeaders(token);
        return this.httpClient.post(httpUrl, optionalParams.requestBody, { headers: httpHeaders }).pipe(tap(function () { return (_this.requestInProgress = false); }), catchError(function (err) { return _this._handleError(err); }));
    };
    HttpService.prototype.put = function (resourceUrl, optionalParams) {
        var _this = this;
        if (optionalParams === void 0) { optionalParams = {}; }
        this.requestInProgress = true;
        var httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);
        var httpHeaders = this.initHttpHeaders();
        return this.httpClient.put(httpUrl, optionalParams.requestBody, { headers: httpHeaders }).pipe(tap(function () { return (_this.requestInProgress = false); }), catchError(function (err) { return _this._handleError(err); }));
    };
    HttpService.prototype.isRequestInProgress = function () {
        return this.requestInProgress;
    };
    HttpService.prototype._getQueryString = function (queryParamsList) {
        if (!queryParamsList) {
            return '';
        }
        var queryString = queryParamsList
            .map(function (_a) {
            var name = _a.name, value = _a.value;
            return encodeURIComponent(name) + "=" + encodeURIComponent(value);
        })
            .join('&');
        return "?" + queryString;
    };
    HttpService.prototype._handleError = function (error) {
        this.requestInProgress = false;
        return throwError(error);
    };
    HttpService.prototype._setUrlParam = function (resourceUrl, parameter) {
        if (!parameter) {
            return resourceUrl;
        }
        return resourceUrl.replace(PARAMS_REGEX, "/" + parameter);
    };
    HttpService.prototype._setUrlMultiParams = function (resourceUrl, parameters) {
        return resourceUrl.replace(PARAMS_MULTI_REGEX, function () { return "/" + parameters.shift(); });
    };
    HttpService.ngInjectableDef = i0.defineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.inject(i1.HttpClient), i0.inject(i2.CbLoginBannerService)); }, token: HttpService, providedIn: "root" });
    return HttpService;
}());
export { HttpService };
