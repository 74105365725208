import { Component, OnInit, AfterViewInit } from '@angular/core';
import { to } from 'await-to-js';
import { utcToZonedTime } from 'date-fns-tz';

import { CbRegistrationService } from '../../services/cb-registration.service';
import { CbLoginBannerService } from '../../services/cb-login-banner.service';

import { LogFactory } from '../../services/cb-debug';
const log = LogFactory('CbTopBannerRegistrationComponent', false);

@Component({
  selector: 'cb-top-banner-registration',
  templateUrl: 'cb-top-banner-registration.component.html',
  styleUrls: ['./cb-top-banner-registration.component.scss'],
})
export class CbTopBannerRegistrationComponent implements OnInit, AfterViewInit {
  type = '';
  date = '';
  title = '';
  description = ``;
  speakers = ``;
  zonedDate = undefined;
  timezoneFriendlyName = '';

  showEllipsis = true;

  constructor(
    private cbRegistrationService: CbRegistrationService,
    private cbLoginBannerService: CbLoginBannerService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.setRegistrationValues();
  }

  async setRegistrationValues() {
    const registrationType = this.cbLoginBannerService.registrationType;
    const id = this.cbLoginBannerService.id;

    const [err, registration] = await to(this.cbRegistrationService.GetRegistration(id, registrationType).toPromise());
    log('err , registration', { err, registration });

    this.type = registrationType;
    this.title = registration.topic;
    this.description = registration.agenda;
    this.date = this.cbRegistrationService.GetTime(registration);
    this.timezoneFriendlyName = this.cbRegistrationService.GetTimezoneFriendlyName(registration.timezone);

    const date = new Date(this.date);
    const timeZone = registration.timezone;
    this.zonedDate = utcToZonedTime(date, timeZone);
  }
}
